import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
} from 'reactstrap';
import forEach from 'lodash/forEach';
import { useDispatch } from 'react-redux';
import useBreakPoint from 'helpers/useBreakPoint';
import { useHistory } from 'react-router';
import CardsHeader from 'components/Headers/CardsHeader';
import NavigationDropdown, {
  DesktopNavigation,
} from 'components/NavigationDropdown';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import {
  deleteProject,
  duplicateProjectById,
  editProject as editProjectAction,
} from 'store/actions/projects';
import { analyticsConstants } from 'helpers/analytics';
import { permissions, useAccess } from 'helpers/permission';
import DuplicateProjectForm from './DuplicateProject.Form';
import duplicateFields from './duplicateFields';
import classes from './projects.module.scss';
import ProjectForm from 'views/pages/Project/Project.Form';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const ProjectHeader = ({
  projectName,
  projectId,
  isMemberOfProject,
  analyticsSendEvent,
  contents,
  onOptionClick,
  activeContent,
}) => {
  const history = useHistory();
  const isMobile = useBreakPoint('sm', 'down');
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const shouldAllowProjectDuplicate = useAccess([
    permissions.DUPLICATE_ALL_PROJECTS,
    {
      permission: permissions.DUPLICATE_MY_PROJECTS,
      value: isMemberOfProject,
    },
  ]);

  const shouldAllowProjectEdit = useAccess([
    permissions.EDIT_ALL_PROJECTS,
    {
      permission: permissions.EDIT_MY_PROJECTS,
      value: isMemberOfProject,
    },
  ]);

  const shouldAllowProjectDelete = useAccess([
    permissions.DELETE_ALL_PROJECTS,
    {
      permission: permissions.DELETE_MY_PROJECTS,
      value: isMemberOfProject,
    },
  ]);

  const confirmDelete = async data => {
    analyticsSendEvent({ action: analyticsConstants.action.delete_project });
    await dispatch(deleteProject(data.id, { page: 1, sort: 'desc', q: '' }));
    history.push('/admin/projects');
  };

  const handleProjectDelete = () => {
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Project',
      text: `You are about to delete "${projectName}". Do you want to continue?`,
      data: { id: projectId },
    });
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const closeDuplicateModal = () => setDuplicateModal(false);

  const openDuplicateModal = () => setDuplicateModal(true);

  const openUpdateModal = () => setShowProjectForm(true);

  const closeProjectFormModal = () => setShowProjectForm(false);

  const handleDuplicateProject = async values => {
    setDuplicating(true);
    let data = {
      fields: [],
      relationships: [],
    };
    forEach(duplicateFields, module => {
      forEach(module.fields, obj => {
        if (values[obj.name]) {
          data[obj.objKey].push(obj.name);
        }
      });
    });
    const { data: duplicatedProject, status, errorStatus } = await dispatch(
      duplicateProjectById(projectId, { name: values.name, ...data })
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showProjectsLimit();
      setDuplicating(false);
      setDuplicateModal(false);
      return;
    }
    if (status) {
      history.push(`/admin/projects/${duplicatedProject.id}`);
    }
    setDuplicating(false);
    setDuplicateModal(false);
  };

  const dropdownOptions = [
    ...(shouldAllowProjectDuplicate
      ? [
          {
            text: 'Duplicate Project',
            onClick: openDuplicateModal,
          },
        ]
      : []),
    ...(shouldAllowProjectEdit
      ? [
          {
            text: 'Edit Project',
            onClick: openUpdateModal,
          },
        ]
      : []),
    ...(shouldAllowProjectDelete
      ? [
          {
            text: 'Delete Project',
            onClick: handleProjectDelete,
          },
        ]
      : []),
  ];

  const [showProjectForm, setShowProjectForm] = useState(false);
  const getProjectLoading = useGetFieldFromObjects(
    'project',
    'getProject.isInProgress',
    false
  );

  const updateProject = async values => {
    setUpdating(true);
    analyticsSendEvent({ action: analyticsConstants.action.edit_project });
    await dispatch(editProjectAction(values.projectId, values, {}));
    setUpdating(false);
    setShowProjectForm(false);
  };

  return (
    <>
      <CardsHeader
        showActionMenu
        name="Project Profile"
        parentName="Projects"
        currentPage={
          projectName.length > 25
            ? `${projectName.slice(0, 24)}...`
            : projectName
        }
        parentLink="/admin/projects"
        actionMenu={
          dropdownOptions && dropdownOptions.length ? (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className={classes.projectDropdown}
            >
              <DropdownToggle caret={false} size="sm" className="ml-2" color="">
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu>
                {dropdownOptions.map((item, index) => {
                  return (
                    <DropdownItem
                      key={index}
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        item.onClick();
                      }}
                    >
                      {item.text}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          ) : null
        }
      />
      {isDuplicateModal ? (
        <DuplicateProjectForm
          projectName={projectName}
          isModalOpen={isDuplicateModal}
          closeModal={closeDuplicateModal}
          submitValues={handleDuplicateProject}
          isLoading={isDuplicating}
        />
      ) : null}
      {showProjectForm && !getProjectLoading && (
        <ProjectForm
          isModalOpen={showProjectForm && !getProjectLoading}
          submitValues={updateProject}
          closeModal={closeProjectFormModal}
          viewMode="edit"
          loading={isUpdating}
        />
      )}
      <Nav id="projectNavigationButtons">
        {isMobile ? (
          <NavigationDropdown
            options={contents}
            activeContent={activeContent}
            handleOptionClick={onOptionClick}
          />
        ) : (
          <DesktopNavigation
            options={contents}
            handleOptionClick={onOptionClick}
            activeContent={activeContent}
          />
        )}
      </Nav>
    </>
  );
};

export default ProjectHeader;
