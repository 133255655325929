import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import CardsHeader from 'components/Headers/CardsHeader';
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
  DropdownItem,
  UncontrolledTooltip,
  Container,
  Row,
  Col,
} from 'reactstrap';
import classNames from 'classnames';
import Button from 'components/Button';
import BaseTable from 'components/Table';
import history from 'helpers/history';
import ConnectorSvg from 'assets/img/brand/Connector.svg';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import NoAgreementsImg from 'assets/img/theme/No_Agreements.svg';
import UnderCollaborationImg from 'assets/img/theme/Under_Collaboration.png';
import classes from './ClientProfile.module.scss';
import {
  downloadFile,
  fetchAgreementsList,
  getClientProfile,
  createAgreement,
  deleteAgreement,
  deleteAttachedFile,
  updateAgreement,
  getAgreementData,
  getTimeSpent,
  getViewedProjects,
  getRecentTimeEntries,
} from 'store/actions/clientProfile';
import { createProject } from 'store/actions/projects';
import AgreementForm from './Agreement.Form';
import ProjectForm from '../Project/Project.Form';
import Loading from 'components/Loading';
import {
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import TimeLoggedChart from './TimeLoggedByClient';
import RecentlyViewedProjects from './RecentlyViewedProjects';
import RecentTimeEntries from './RecentTimeEntries';
import { permissions, useAccess } from 'helpers/permission';
import isEqual from 'lodash/isEqual';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { Redirect } from 'react-router';
import toNumber from 'lodash/toNumber';
import analytics, { analyticsConstants } from 'helpers/analytics';
import UpgradeAlert from 'components/UpgradeAlert';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';

const ClientProfile = props => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.clients,
      ...rest,
    });
  };
  const clientId = get(props, 'match.params.id');
  const useGetValue = (field, defaultValue) =>
    useSelector(
      ({ clientProfile }) => get(clientProfile, field, defaultValue),
      isEqual
    );

  const [isDataLoading, setIsDataLoading] = useState(0);

  const [page, setPage] = useState(1);
  const [isModal, setModal] = useState(false);
  const [isCreateProjectModal, setCreateProjectModal] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [sowInitialValues, setSowInitialValues] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });

  const isUserAllowedCreateProject = useAccess(permissions.CREATE_PROJECTS);
  const isUserAllowedAddAgreement = useAccess(permissions.LIST_THEMES);

  useEffect(() => {
    setIsDataLoading(5);
    dispatch(
      getClientProfile(clientId, () => {
        dispatch(getViewedProjects(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(getRecentTimeEntries(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(getTimeSpent(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(fetchAgreementsList(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
      })
    ).finally(() => setIsDataLoading(loading => loading - 1));
  }, [clientId, dispatch]);

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  useEffect(() => {
    /*
     When component is mounted for first time we are making sure we have shouldOpenPopup flag set,
     then we are opening popup and after then pushing the same route with updated state,
     so if user refreshes the page it won't open the alert this time
    */

    if (get(props, 'location.state.shouldOpenPopup')) {
      setTimeout(() => {
        AlertPopupHandler.openCustom({
          onConfirm: openModal,
          onCancel: () => {
            handleTourClear();
          },
          confirmBtnText: 'Add Agreement',
          title: 'Success!',
          text:
            'Do you have a contract or scope of work you would like to attach to this client?',
          cancelBtnBsStyle: 'secondary',
          confirmBtnBsStyle: 'info border-0',
          cancelBtnText: 'No, Thank You',
          customIcon: (
            <div className={classes.customIconWrapper}>
              <img src={ConnectorSvg} alt="Connector" />
            </div>
          ),
        });

        // This is to update the flag once we have opened the popup when we created the client
        history.push(`/admin/clients/${props.match.params.id}`, {
          shouldOpenPopup: false,
        });
      }, 1000);
    }
    analyticsSendEvent({
      action: analyticsConstants.action.view_client_profile,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agreementState = useGetValue('agreementState');
  const clientProfile = useGetValue('clientProfile');
  const createReducer = useGetValue('createAgreement');
  const updateReducer = useGetValue('updateAgreement');
  const deleteReducer = useGetValue('deleteAgreement');
  const reducerId = useGetValue('clientProfile.id');

  const clientProfileLoading = get(clientProfile, 'isInProgress', false);
  const listLoading = get(agreementState, 'isInProgress', false);
  const createLoading = get(createReducer, 'isInProgress', false);
  // const editLoading = get(editReducer, 'isInProgress', false);
  const updateLoading = get(updateReducer, 'isInProgress', false);
  const deleteLoading = get(deleteReducer, 'isInProgress', false);
  const agreements = get(agreementState, 'data.data', []);
  const agreementMetadata = get(agreementState, 'data.meta', {});
  const client = get(clientProfile, 'data', {});
  const isClientError = get(clientProfile, 'isError', false);
  const clientProfileMessage = get(clientProfile, 'message');
  const cellFormatter = (cell, row) => (
    <>
      <div id={`aggreement-type-${row.id}`}>{cell}</div>
      <UncontrolledTooltip
        target={`aggreement-type-${row.id}`}
        delay={0}
        fade={false}
        placement="bottom"
        popperClassName={classes.popoverClass}
      >
        {cell}
      </UncontrolledTooltip>
    </>
  );

  const NoDataIndication = () => {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column m-auto w-100 h-100 p-3">
        <img
          className={classNames('w-100', classes.image)}
          src={NoAgreementsImg}
          alt="No Agreements"
        />

        <h4 className="display-4 mb-0 text-center pt-4">
          {isUserAllowedAddAgreement
            ? 'Go ahead and create your first agreement!'
            : 'No Agreements created!'}
        </h4>
        {isUserAllowedAddAgreement && (
          <p className="text-center mt-3">
            To add an agreement, click on the{' '}
            <span className={classes.addAgreement} onClick={openModal}>
              Add Agreement
            </span>{' '}
            button in the upper right corner
          </p>
        )}
      </div>
    );
  };

  const openModal = () => {
    setModal(true);
  };

  const handleTourClear = () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'client_creation' &&
      !quickStartTour.step
    ) {
      if (quickStartTour) {
        dispatch(
          setUserPreference({
            ...onBoardingTour,
            value: {
              ...get(onBoardingTour, 'value', {}),
              status: 'Active',
            },
          })
        );
      }
      dispatch(
        updateActiveTourStatus({
          activeTour: null,
          step: null,
          isRestarted: false,
        })
      );
    }
  };

  const closeModal = () => {
    setModal(false);
    setViewMode('');
    setEditValues(null);
  };

  const confirmCreateProject = () => {
    setCreateProjectModal(true);
  };

  const cancelCreateProject = () => {
    setCreateProjectModal(false);
    setSowInitialValues(null);
  };

  const submitValues = async values => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    let agreement = {};
    const data = new FormData();
    data.append('name', values.name);
    if (viewMode === 'edit') {
      // when editing if link is blank we can send it
      data.append('link', values.link || '');
      data.append(
        'agreement_type_id',
        values.agreement_type_id ? values.agreement_type_id.id : ''
      );

      if (values.attachment) {
        data.append('attachment', values.attachment);
      }
      if (
        (!values.attachment || (values.attachment && !values.attachment.id)) &&
        editValues.attachment &&
        editValues.attachment.id
      ) {
        await deleteAttachedFile(editValues.attachment.id);
      }
      data.append('_method', 'PUT');
      agreement = await dispatch(
        updateAgreement(editValues.id, data, { page, sort, q: '' }, clientId)
      );
      if (agreement.errorStatus === SHOW_UPGRADE_ALERT_CODE) {
        UpgradeAlert.showStorageLimit();
        return 0;
      }
    } else {
      // when creating we should not send parameters which are not present
      if (values.link) {
        data.append('link', values.link);
      }
      if (values.agreement_type_id && values.agreement_type_id.id) {
        data.append('agreement_type_id', values.agreement_type_id.id);
      }
      if (values.attachment) {
        data.append('attachment', values.attachment);
      }
      analyticsSendEvent({ action: analyticsConstants.action.add_agreement });
      agreement = await dispatch(
        createAgreement(data, { page, sort, q: '' }, clientId)
      );
      if (agreement.errorStatus === SHOW_UPGRADE_ALERT_CODE) {
        UpgradeAlert.showStorageLimit();
        return 0;
      }
    }
    setModal(false);
    handleTourClear();
    // When agreement type is SOW setting details for create Project flow
    if (
      isUserAllowedCreateProject &&
      agreement.status &&
      agreement.data.agreement_type &&
      agreement.data.agreement_type.type === 'Scope of Work'
    ) {
      setSowInitialValues({
        client,
        contract: { id: agreement.data.id, name: agreement.data.name },
      });
      AlertPopupHandler.openCustom({
        onConfirm: confirmCreateProject,
        onCancel: cancelCreateProject,
        confirmBtnText: 'Create a Project',
        title: 'Success!',
        text: 'Would you like to create a project for this Scope of Work?',
        cancelBtnBsStyle: 'secondary',
        confirmBtnBsStyle: 'info border-0',
        cancelBtnText: 'No, Thank You',
        customIcon: (
          <div className={classes.customIconWrapper}>
            <img src={ConnectorSvg} alt="Connector" />
          </div>
        ),
      });
    }
    return agreement.status;
  };

  const submitProjectValues = async values => {
    const { status, data: project, errorStatus } = await dispatch(
      createProject(values, {}, false)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showProjectsLimit();
      return;
    }
    if (status) {
      history.push(`/admin/projects/${project.id}`);
    }
  };
  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    analyticsSendEvent({ action: analyticsConstants.action.delete_agreement });
    await dispatch(deleteAgreement(data.id, { page, sort, q: '' }, clientId));
  };

  const editAction = row => async e => {
    e.preventDefault();
    setEditValues({ ...row });
    setViewMode('edit');
    setModal(true);
    await dispatch(getAgreementData(row.id));
  };

  const deleteAction = row => e => {
    e.preventDefault();
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Agreement',
      text: `You are about to delete "${row.name}". Do you want to continue?`,
      data: row,
    });
  };

  const handleTableChange = (
    type,
    { page, sortOrder, sortField, searchText }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setPage(1);
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
      setPage(1);
    }
    const field =
      sortField === 'agreement_type.type' ? 'agreement_type' : sortField;
    const sort = sortOrder === 'desc' ? `-${field}` : field;
    if (type !== 'init')
      dispatch(fetchAgreementsList(clientId, page, sort, searchText));
  };

  if (clientProfileLoading) {
    return <Loading wrapperClass={classes.profileLoader} />;
  }

  if (isClientError && clientId === reducerId) {
    NotificationHandler.open({
      message: clientProfileMessage,
      operation: 'failure',
    });
    return <Redirect to="/admin/dashboard" />;
  }

  return (
    <>
      <AgreementForm
        isModalOpen={isModal}
        submitValues={submitValues}
        closeModal={closeModal}
        viewMode={viewMode}
        editValues={editValues}
      />
      {isCreateProjectModal && (
        <ProjectForm
          isModalOpen={isCreateProjectModal}
          submitValues={submitProjectValues}
          closeModal={cancelCreateProject}
          isFromSow={true}
          sowInitialValues={sowInitialValues}
        />
      )}
      {isDataLoading ? (
        <Loading wrapperClass={classes.loading} />
      ) : (
        <div className={classes.clientProfile}>
          <CardsHeader
            name={client.name}
            parentName="Clients"
            parentLink="/admin/clients"
          />
          <Container fluid>
            <Row>
              <Col md={4}>
                <RecentlyViewedProjects />
                <Card className={classes.cards}>
                  <CardHeader>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div className="d-flex align-items-center">
                        <h3 className="mb-0">Agreements</h3>
                        <span className="ml-3 badge badge-info badge-circle">
                          {agreements.length}
                        </span>
                      </div>
                      {isUserAllowedAddAgreement && (
                        <Button
                          color="primary"
                          size="sm"
                          type="button"
                          onClick={openModal}
                        >
                          Add Agreement
                        </Button>
                      )}{' '}
                    </div>
                  </CardHeader>
                  <CardBody
                    className={classNames('p-0', classes.agreementsCardBody)}
                  >
                    <BaseTable
                      keyField="id"
                      defaultSorted={[sortBy]}
                      bootstrap4
                      remote
                      bordered={false}
                      loading={
                        listLoading ||
                        createLoading ||
                        updateLoading ||
                        deleteLoading
                      }
                      paginationOptions={{
                        page: page,
                        totalSize: agreementMetadata.total,
                        sizePerPage: parseInt(agreementMetadata.per_page),
                      }}
                      data={agreements}
                      noDataIndication={NoDataIndication}
                      columns={[
                        {
                          dataField: 'name',
                          text: 'Name',
                          sort: true,
                          sortCaret: renderSortCaret,
                          classes: classNames(classes.textField, classes.name),
                          formatter: (cell, row) => {
                            return (
                              <>
                                <div id={`aggreement-name-${row.id}`}>
                                  {cell}
                                </div>
                                <UncontrolledTooltip
                                  target={`aggreement-name-${row.id}`}
                                  delay={0}
                                  fade={false}
                                  placement="bottom-start"
                                  boundariesElement="viewport"
                                  offset={-5}
                                  popperClassName={classes.popoverClass}
                                >
                                  {cell}
                                </UncontrolledTooltip>
                              </>
                            );
                          },
                        },
                        {
                          dataField: 'agreement_type.type',
                          text: 'Type',
                          sort: true,
                          classes: classNames(classes.textField),
                          sortCaret: renderSortCaret,
                          formatter: cellFormatter,
                        },
                        {
                          dataField: '',
                          text: '',
                          sort: false,
                          classes: classes.options,
                          attrs: { width: '200px' },
                          headerAttrs: { width: '200px' },
                          formatter: (cell, row) => {
                            return (
                              <div className="d-flex align-items-center">
                                <span
                                  className={classNames(
                                    'd-flex justify-content-center flex-fill',
                                    classes.actions,
                                    classes.grey
                                  )}
                                >
                                  {row.attachment && (
                                    <>
                                      <i
                                        className="ni ni-cloud-download-95"
                                        id="tooltip-download"
                                        onClick={() => {
                                          analyticsSendEvent({
                                            action:
                                              analyticsConstants.action
                                                .open_agreement,
                                          });
                                          dispatch(
                                            downloadFile(row.attachment)
                                          );
                                        }}
                                      />
                                      <UncontrolledTooltip
                                        delay={0}
                                        placement="bottom"
                                        target="tooltip-download"
                                      >
                                        Download Attachment
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </span>
                                <span
                                  className={classNames(
                                    'd-flex justify-content-center flex-fill',
                                    classes.actions,
                                    classes.grey
                                  )}
                                >
                                  {row.link ? (
                                    <a
                                      target="_blank"
                                      href={row.link}
                                      rel="noopener noreferrer"
                                      className={classes.grey}
                                      onClick={() => {
                                        analyticsSendEvent({
                                          action:
                                            analyticsConstants.action
                                              .open_agreement,
                                        });
                                      }}
                                    >
                                      <i
                                        className="fa fa-eye"
                                        id="tooltip-attachment"
                                      />
                                      <UncontrolledTooltip
                                        delay={0}
                                        placement="bottom"
                                        target="tooltip-attachment"
                                      >
                                        Open Link
                                      </UncontrolledTooltip>
                                    </a>
                                  ) : null}
                                </span>
                                <span
                                  className={classNames(
                                    'd-flex justify-content-center flex-fill',
                                    classes.actions,
                                    classes.grey
                                  )}
                                >
                                  <Button
                                    className="btn-icon-only text-light"
                                    id={`dropdown-${row.id}`}
                                    size="sm"
                                    outline={false}
                                    block={false}
                                    color="none"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </Button>
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    target={`dropdown-${row.id}`}
                                    placement="bottom-end"
                                    hideArrow
                                    container={
                                      document.getElementsByClassName(
                                        classes.agreementsCardBody
                                      )[0]
                                    }
                                    popperClassName={classes.selectorPopover}
                                    innerClassName={
                                      classes.selectorPopoverWrapper
                                    }
                                  >
                                    <PopoverBody
                                      className={classNames(
                                        'p-0',
                                        classes.popoverBody
                                      )}
                                    >
                                      <DropdownItem
                                        href="#"
                                        onClick={e => e.preventDefault()}
                                      >
                                        View Agreement
                                      </DropdownItem>
                                      <DropdownItem
                                        href="#"
                                        onClick={editAction(row)}
                                      >
                                        Edit Agreement
                                      </DropdownItem>
                                      <DropdownItem
                                        href="#"
                                        onClick={deleteAction(row)}
                                      >
                                        Delete Agreement
                                      </DropdownItem>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </span>
                              </div>
                            );
                          },
                        },
                      ]}
                      onTableChange={handleTableChange}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={8}>
                <TimeLoggedChart clientId={clientId} />
                <RecentTimeEntries clientId={clientId} />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default ClientProfile;
