import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Card, CardBody, CardTitle, Progress, Row } from 'reactstrap';
import { fetchAvailabilityByUserId } from 'store/actions/profile';
import classes from './profile.module.scss';
import { useHistory } from 'react-router';
import { useAccess, permissions } from 'helpers/permission';

const AvailabilityStats = ({ userId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { availability: availabilityState } = useSelector(
    ({ profile }) => profile
  );
  const profile = useSelector(({ profile }) => get(profile, 'details.data'));

  const availability = get(availabilityState, 'data.availability', 0);
  const loggedHours = get(availabilityState, 'data.logged_hours', 0);
  const progress = get(availabilityState, 'data.progress', 0);

  useEffect(() => {
    dispatch(fetchAvailabilityByUserId(userId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Card className={`border-0 ${classes.availability}`}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              className="text-uppercase text-muted mb-0 text-white"
              tag="h5"
            >
              Hours Logged This Week
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-white">
              {loggedHours}/{availability > 0 ? availability : '--'}
            </span>
          </div>
        </Row>
        <div className="mt-3 mb-0">
          <Progress
            color="success"
            value={availability === 0 && loggedHours > 0 ? 100 : progress}
            className="progress-xs"
          />
          {useAccess([permissions.VIEW_ALL_TIMELOGS]) && (
            <button
              className="btn-link btn text-muted  text-white p-0 m-0"
              onClick={() =>
                history.push(
                  `/admin/timelog?values={"users":[{"id":${profile.id},"name":"${profile.name}"}]}`
                )
              }
            >
              View Timelogs
            </button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default AvailabilityStats;
