import get from 'lodash/get';
import map from 'lodash/map';
import clone from 'lodash/clone';
import orderBy from 'lodash/orderBy';
import { reassignOrder } from 'helpers/dragDropHelpers';
import cloneDeep from 'lodash/cloneDeep';
import {
  getSprint,
  addSprint,
  editSprint,
  addStory,
  getStoryList,
  getKanbanBoard,
  addColumn,
  editColumn,
  removeColumn,
  reOrderColumns,
  reOrderKanbanStories,
  updateStoryStatus,
} from 'api/sprint';
import Types from 'store/types/sprint';
import backlogTypes from 'store/types/backlogs';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { fetchSectionsList } from 'store/actions/backlogs';

export const fetchSprint = id => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_SPRINT_INPROGRESS });
    try {
      const resp = await getSprint(id);
      const status = get(resp, 'status');
      const data = get(resp, 'data');
      const message = get(resp, 'message');

      if (status) {
        dispatch({
          type: Types.FETCH_SPRINT_SUCCESS,
          data,
          message,
        });
      } else {
        dispatch({
          type: Types.FETCH_SPRINT_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_SPRINT_FAILURE,
        message: error,
      });
    }
  };
};

export const createSprint = (id, sprintData) => {
  return async dispatch => {
    dispatch({ type: Types.CREATE_SPRINT_INPROGRESS });

    try {
      const resp = await addSprint(id, sprintData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_SPRINT_SUCCESS,
          message,
        });
        dispatch({
          type: backlogTypes.ADD_BACKLOG_SECTION_SUCCESS,
          data,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_SPRINT_FAILURE,
          message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_SPRINT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updateSprint = (
  projectId,
  sprintId,
  updatedData,
  isSprinKanban = false
) => {
  return async dispatch => {
    dispatch({ type: Types.EDIT_SPRINT_INPROGRESS });
    try {
      const resp = await editSprint(sprintId, updatedData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');

      if (status) {
        dispatch({
          type: Types.EDIT_SPRINT_SUCCESS,
          message,
        });
        NotificationHandler.open({
          message,
          operation: 'update',
        });
        if (isSprinKanban) {
          await dispatch(doGetDataSprintKanban(sprintId, ''));
        } else {
          await dispatch(fetchSectionsList(projectId));
        }
      } else {
        dispatch({
          type: Types.EDIT_SPRINT_FAILURE,
          message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_SPRINT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const fetchSprintStoryList = (sprintID, q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_STORY_LIST_INPROGRESS,
      data: {
        sprintID,
      },
    });
    try {
      const resp = await getStoryList(sprintID, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_STORY_LIST_SUCCESS,
          message,
          data: {
            sprintID,
            items: resp,
          },
        });
      } else {
        dispatch({
          type: Types.FETCH_STORY_LIST_FAILURE,
          data: {
            sprintID,
          },
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STORY_LIST_FAILURE,
        data: {
          sprintID,
        },
        message: error,
      });
    }
  };
};

export const createStoryForSprint = (sprintID, storyData, projectId) => {
  return async dispatch => {
    dispatch({ type: Types.CREATE_SPRINT_STORY_INPROGRESS });
    try {
      const resp = await addStory(sprintID, storyData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_SPRINT_STORY_SUCCESS,
          message,
          data,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
        await dispatch(fetchSprint(projectId));
        await dispatch(fetchSprintStoryList(sprintID));
      } else {
        dispatch({
          type: Types.CREATE_SPRINT_STORY_FAILURE,
          message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_SPRINT_STORY_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const createSprintColumn = (sprintId, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_SPRINT_COLUMN_INPROGRESS,
    });

    try {
      const resp = await addColumn(sprintId, reqData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.CREATE_SPRINT_COLUMN_SUCCESS,
          status,
          data,
        });
      } else {
        dispatch({
          type: Types.CREATE_SPRINT_COLUMN_FAILURE,
          status,
          message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.CREATE_SPRINT_COLUMN_FAILURE,
        message: 'Something went wrong',
      });
      NotificationHandler.open({
        message: 'Something went wrong',
        operation: 'failure',
      });
    }
  };
};

export const modifySprintColumn = (sprintId, columnId, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_SPRINT_COLUMN_INPROGRESS,
    });

    try {
      const resp = await editColumn(sprintId, columnId, reqData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.EDIT_SPRINT_COLUMN_SUCCESS,
          status,
          data,
        });
      } else {
        dispatch({
          type: Types.EDIT_SPRINT_COLUMN_FAILURE,
          status,
          message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.EDIT_SPRINT_COLUMN_FAILURE,
        message: 'Something went wrong',
      });
      NotificationHandler.open({
        message: 'Something went wrong',
        operation: 'failure',
      });
    }
  };
};

export const deleteSprintColumn = (sprintId, columnId) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_SPRINT_COLUMN_INPROGRESS,
    });

    try {
      const resp = await removeColumn(sprintId, columnId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.DELETE_SPRINT_COLUMN_SUCCESS,
          status,
          data,
        });
        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_SPRINT_COLUMN_FAILURE,
          status,
          message,
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.DELETE_SPRINT_COLUMN_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const reOrderSprintColumns = (sprintId, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.REORDER_SPRINT_COLUMN_INPROGRESS,
      data: reqData,
    });
    try {
      const resp = await reOrderColumns(sprintId, map(reqData, 'id'));
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.REORDER_SPRINT_COLUMN_SUCCESS,
          status,
          data,
        });
      } else {
        dispatch({
          type: Types.REORDER_SPRINT_COLUMN_FAILURE,
          status,
          message,
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.REORDER_SPRINT_COLUMN_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const doReOrderKanbanStories = (
  projectId,
  sourceId,
  updatedItems,
  columnId,
  storyId,
  mode
) => {
  return async (dispatch, getState) => {
    if (sourceId) {
      const storeData = getState();
      const kanban = get(storeData, 'sprint.getSprintKanban.data');
      const index = kanban.columns.findIndex(item => item.id === +sourceId);
      let columns = [...kanban.columns];
      columns[index]['stories'] = reassignOrder(
        updatedItems,
        'sprint_board_order'
      );
      dispatch({
        type: Types.GET_SPRINT_KANBAN_SUCCESS,
        status: 1,
        data: {
          ...kanban,
          columns,
        },
      });
    }
    if (mode === 'FLUID') {
      const resp = await reOrderKanbanStories(projectId, columnId, {
        story: storyId,
        stories: reassignOrder(updatedItems, 'sprint_board_order'),
      });

      if (resp.status !== 1) {
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
        });
      }

      return resp.status;
    }
  };
};

export const moveStoryCard = (
  projectId,
  storyId,
  sourceColumn,
  destColumn,
  sourceItems,
  destinationItems,
  destinationSection,
  mode
) => {
  return async (dispatch, getState) => {
    const sourceId = get(sourceColumn, 'id');
    const destinationId = get(destColumn, 'id');
    const sourceStatus = get(sourceColumn, 'status.status');
    const destinationStatus = get(destColumn, 'status.status');
    const storeData = getState();
    const kanban = get(storeData, 'sprint.getSprintKanban.data');
    const newKanbanData = cloneDeep(kanban);
    const stories_summary = get(newKanbanData, 'stories_summary');
    const srcItemIndex = newKanbanData.columns.findIndex(
      item => item.id === sourceId
    );
    newKanbanData.columns[srcItemIndex].stories = reassignOrder(
      sourceItems,
      'sprint_board_order'
    );
    const destItemIndex = newKanbanData.columns.findIndex(
      item => item.id === destinationId
    );
    newKanbanData.columns[destItemIndex].stories = reassignOrder(
      destinationItems,
      'sprint_board_order'
    );
    if (destinationStatus === 'In Progress') {
      if (sourceStatus !== destinationStatus) {
        newKanbanData.stories_summary = {
          stories_summary,
          active: stories_summary.active + 1,
        };
      }
    }
    if (destinationStatus !== 'In Progress') {
      newKanbanData.stories_summary = {
        stories_summary,
        active: stories_summary.active === 0 ? 0 : stories_summary.active - 1,
      };
    }
    dispatch({
      type: Types.GET_SPRINT_KANBAN_SUCCESS,
      status: 1,
      data: newKanbanData,
    });

    if (mode === 'FLUID') {
      const resp = await updateStoryStatus(projectId, storyId, {
        sprint_status_id: destinationId,
      });

      if (resp.status === 1) {
        const story = resp.data;
        let stories = newKanbanData.columns[destItemIndex].stories.map(item =>
          item.id === story.id ? story : item
        );
        newKanbanData.columns[destItemIndex].stories = stories;
        dispatch({
          type: Types.GET_SPRINT_KANBAN_SUCCESS,
          status: resp.status,
          data: newKanbanData,
        });
        await dispatch(
          doReOrderKanbanStories(
            projectId,
            '',
            destinationItems,
            destinationSection,
            storyId,
            mode
          )
        );
      } else {
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
        });

        dispatch({
          type: Types.GET_SPRINT_KANBAN_FAILURE,
          status: resp.status,
          message: resp.message,
          data: kanban,
        });
      }

      return resp;
    }
  };
};

export const doGetDataSprintKanban = (projectId, keyword) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_SPRINT_KANBAN_INPROGRESS,
    });

    const resp = await getKanbanBoard(projectId, keyword);

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.GET_SPRINT_KANBAN_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      }
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.GET_SPRINT_KANBAN_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const sprintStoryAdded = data => {
  return async (dispatch, getState) => {
    const storeDate = getState();
    const sprintKanban = get(storeDate, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = clone(sprintKanban);
    const { sprint } = data;
    const { column } = sprint;
    const { story } = sprint;
    const columnIndex =
      newSprintKanban && newSprintKanban.columns
        ? newSprintKanban.columns.findIndex(col => col.id === column.id)
        : -1;
    if (columnIndex > -1) {
      const oldStories = newSprintKanban.columns[columnIndex].stories;
      const isStoryPresent = oldStories.findIndex(s => s.id === story.id);
      if (isStoryPresent === -1) {
        oldStories.push(story);
      } else {
        const indexOfOldStory = oldStories.findIndex(s => s.id === story.id);
        const updatedStories = [
          ...oldStories.slice(0, indexOfOldStory),
          story,
          ...oldStories.slice(indexOfOldStory + 1),
        ];
        newSprintKanban.columns[columnIndex].stories = updatedStories;
      }
      newSprintKanban.columns[columnIndex].stories = orderBy(
        oldStories,
        ['sprint_board_order'],
        ['asc']
      );
      dispatch({
        type: Types.GET_SPRINT_KANBAN_SUCCESS,
        status: 1,
        data: newSprintKanban,
      });
    }
  };
};

export const sprintStoryUpdated = (data, setAutoDragId, dragId) => {
  return async (dispatch, getState) => {
    const storeDate = getState();
    const sprintKanban = get(storeDate, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = clone(sprintKanban);
    const { sprint } = data;
    const { column } = sprint;
    const { story } = sprint;
    const columnIndex =
      newSprintKanban && newSprintKanban.columns
        ? newSprintKanban.columns.findIndex(col => col.id === column.id)
        : null;
    if (columnIndex > -1) {
      const oldStories = newSprintKanban.columns[columnIndex].stories;
      const oldStory = oldStories.find(s => s.id === story.id);
      if (oldStory?.sprint_board_order !== story?.sprint_board_order) {
        const storyData = {
          id: dragId,
          oldPosition: get(oldStory, 'sprint_board_order', null),
          newPosition: get(story, 'sprint_board_order', null),
        };
        try {
          setAutoDragId(storyData);
        } catch (error) {}
      } else {
        const updatedStories = map(oldStories, story => {
          if (story.id === sprint.story.id) {
            return sprint.story;
          } else {
            return story;
          }
        });
        newSprintKanban.columns[columnIndex].stories = orderBy(
          updatedStories,
          ['sprint_board_order'],
          ['asc']
        );
        dispatch({
          type: Types.GET_SPRINT_KANBAN_SUCCESS,
          status: 1,
          data: newSprintKanban,
        });
      }
    }
  };
};

export const sprintStoryDelete = data => {
  return async (dispatch, getState) => {
    const storeDate = getState();
    const sprintKanban = get(storeDate, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = clone(sprintKanban);
    const { sprint } = data;
    const { column } = sprint;
    const deletedStoryId = sprint.story.id;
    const columnIndex =
      newSprintKanban && newSprintKanban.columns
        ? newSprintKanban.columns.findIndex(col => col.id === column.id)
        : null;
    if (columnIndex > -1) {
      const oldStories = newSprintKanban.columns[columnIndex].stories;
      const indexToDeleteStory = oldStories.findIndex(
        story => story.id === deletedStoryId
      );
      if (indexToDeleteStory > -1) {
        const updatedStories = [
          ...oldStories.slice(0, indexToDeleteStory),
          ...oldStories.slice(indexToDeleteStory + 1),
        ];
        newSprintKanban.columns[columnIndex].stories = orderBy(
          updatedStories,
          ['sprint_board_order'],
          ['asc']
        );
      }
      dispatch({
        type: Types.GET_SPRINT_KANBAN_SUCCESS,
        status: 1,
        data: newSprintKanban,
      });
    }
  };
};

export const sprintStoryStatusUpdated = (data, dragId, setAutoDragId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const sprintKanban = get(state, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = clone(sprintKanban);
    const { sprint } = data;
    const { story } = sprint;
    if (story) {
      const oldColumnId = get(story, 'old.sprint_status_id', null);
      const newColumnId = get(story, 'new.sprint_status.id', null);
      if (newColumnId && oldColumnId) {
        const oldColumnIndex = newSprintKanban.columns.findIndex(
          column => column.id === oldColumnId
        );
        const newColumnIndex = newSprintKanban.columns.findIndex(
          column => column.id === newColumnId
        );
        if (oldColumnIndex > -1 && newColumnIndex > -1) {
          const storyData = {
            id: dragId,
            oldColumnId: oldColumnIndex,
            newColumnId: newColumnIndex,
            newStory: story.new,
          };
          try {
            setAutoDragId(storyData);
          } catch (error) {
            throw new Error(error);
          }
        }
      }
    }
  };
};

export const sprintColumnReorder = data => {
  return async (dispatch, getState) => {
    const storeDate = getState();
    const sprintKanban = get(storeDate, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = { ...sprintKanban };
    const { sprint } = data;
    const { columns } = sprint;
    const sortedNewColumns = orderBy(columns, ['id'], ['asc']);
    const sortedOldColumns = orderBy(newSprintKanban.columns, ['id'], ['asc']);
    const updatedColumnOrder = sortedNewColumns.map((column, index) => ({
      ...sortedOldColumns[index],
      sort_order: column.sort_order,
    }));
    newSprintKanban.columns = orderBy(
      updatedColumnOrder,
      ['sort_order'],
      ['asc']
    );
    dispatch({
      type: Types.GET_SPRINT_KANBAN_SUCCESS,
      status: 1,
      data: newSprintKanban,
    });
  };
};

export const sprintColumnRenamed = data => {
  return async (dispatch, getState) => {
    const storeDate = getState();
    const sprintKanban = get(storeDate, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = { ...sprintKanban };
    const { sprint } = data;
    const { column } = sprint;
    const columnIndex =
      newSprintKanban && newSprintKanban.columns
        ? newSprintKanban.columns.findIndex(col => col.id === column.id)
        : null;
    if (columnIndex > -1) {
      const updatedColumn = {
        ...newSprintKanban.columns[columnIndex],
        ...column,
      };
      newSprintKanban.columns[columnIndex] = updatedColumn;
      dispatch({
        type: Types.GET_SPRINT_KANBAN_SUCCESS,
        status: 1,
        data: newSprintKanban,
      });
    }
  };
};

export const sprintColumnAdded = data => {
  return async (dispatch, getState) => {
    const storeDate = getState();
    const sprintKanban = get(storeDate, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = clone(sprintKanban);
    const { sprint } = data;
    const { column } = sprint;
    const updatedColumns = [...newSprintKanban.columns];
    updatedColumns.push(column);
    newSprintKanban.columns = orderBy(updatedColumns, ['sort_order'], ['asc']);
    dispatch({
      type: Types.GET_SPRINT_KANBAN_SUCCESS,
      status: 1,
      data: newSprintKanban,
    });
  };
};

export const sprintColumnDeleted = data => {
  return async (dispatch, getState) => {
    const storeDate = getState();
    const sprintKanban = get(storeDate, 'sprint.getSprintKanban.data', {});
    const newSprintKanban = clone(sprintKanban);
    const { sprint } = data;
    const { column } = sprint;
    const columnIndex =
      newSprintKanban && newSprintKanban.columns
        ? newSprintKanban.columns.findIndex(col => col.id === column.id)
        : null;
    if (columnIndex > -1) {
      const updatedColumns = [
        ...newSprintKanban.columns.slice(0, columnIndex),
        ...newSprintKanban.columns.slice(columnIndex + 1),
      ];
      newSprintKanban.columns = orderBy(
        updatedColumns,
        ['sort_order'],
        ['asc']
      );
      dispatch({
        type: Types.GET_SPRINT_KANBAN_SUCCESS,
        status: 1,
        data: newSprintKanban,
      });
    }
  };
};
