import React from 'react';
import PropTypes from 'prop-types';
import classes from './RSelect.module.scss';
import cs from 'classnames';
import Select from 'react-select';
import DropdownIndicator from 'components/DropDownIndicator';

const RSelect = props => {
  const {
    getOptionLabel,
    getOptionValue,
    placeholder,
    onChange,
    isClearable,
    options,
    touched,
    error,
    label,
    components,
    leftIcon,
    leftIconClass,
    ...rest
  } = props;

  return (
    <>
      {label && <label className="form-control-label">{label}</label>}
      <div
        className={cs(
          {
            [classes['is-invalid']]: touched && error,
            [classes.hasLeftIcon]: leftIcon,
          },
          classes.root
        )}
      >
        {leftIcon && (
          <span className={cs(classes.leftIcon, leftIconClass)}>
            {leftIcon}
          </span>
        )}
        <Select
          classNamePrefix="rselect"
          isClearable={isClearable}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={onChange}
          options={options}
          components={{
            DropdownIndicator,
            ...components,
          }}
          {...rest}
        />

        {error && touched && (
          <div className={classes['invalid-feedback']}>{error}</div>
        )}
      </div>
    </>
  );
};

RSelect.propTypes = {
  label: PropTypes.string,
};

RSelect.defaultProps = {
  isClearable: true,
  placeholder: '',
  getOptionLabel: option => option.text,
  getOptionValue: option => option.id,
  onChange: () => {},
  options: [],
  components: {},
};

export default RSelect;

// all Props : https://react-select.com/props
