import React from 'react';
import Modal, { ModalBody, ModalFooter } from '../Modal';
import PropTypes from 'prop-types';
import cs from 'classnames';
import classes from './FormModal.module.scss';
import Button from 'components/Button';

const FormModal = ({
  toggle,
  isOpen,
  heading,
  children,
  submit,
  cancel,
  size,
  bodyClassName,
  showDelete,
  deleteBtn,
  ...rest
}) => {
  const defaultSubmitProps = {
    buttonDisabled: false,
    color: 'primary',
    className: '',
  };
  const defaultCancelProps = {
    buttonDisabled: false,
    color: 'link',
    className: '',
  };
  const defaultDeleteProps = {
    buttonDisabled: false,
    color: 'danger',
    className: '',
  };
  const cancelProps = { ...defaultCancelProps, ...cancel };
  const submitProps = { ...defaultSubmitProps, ...submit };
  const deleteProps = { ...defaultDeleteProps, ...deleteBtn };
  return (
    <Modal
      toggle={toggle}
      backdrop="static"
      centered
      isOpen={isOpen}
      title={heading}
      size={size}
      {...rest}
    >
      <ModalBody className={cs('pb-0', bodyClassName)}>{children}</ModalBody>
      <ModalFooter className="d-flex justify-content-between pt-0">
        <Button
          onClick={cancelProps.onClick}
          color="link"
          className={'float-right'}
        >
          {cancelProps.buttonText}
        </Button>
        <div className="float-left">
          {showDelete ? (
            <Button
              className={classes.deleteButton}
              outline
              onClick={deleteProps.onClick}
              color="danger"
            >
              {deleteProps.buttonText}
            </Button>
          ) : null}
          <Button
            id={submitProps.id}
            type="submit"
            onClick={submitProps.onClick}
            disabled={!submitProps.isValid || submitProps.loading}
            color={submitProps.isValid ? 'primary' : 'secondary'}
            loading={submitProps.loading}
          >
            {submitProps.buttonText}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

FormModal.prototype = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  heading: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  submit: {
    isValid: PropTypes.bool.isRequired,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  },
  cancel: {
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  },
  size: PropTypes.string,
};

FormModal.defaultProps = {
  toggle: false,
  isOpen: false,
  showDelete: false,
  size: 'lg',
};

export default FormModal;
