import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import classes from './StoryCard.module.scss';
import Button from 'components/Button';
import { Badge, Collapse } from 'reactstrap';
import { shortName } from 'helpers/formatName';
import Avatar from '../Avatar';
import get from 'lodash/get';
import map from 'lodash/map';
const StoryCard = ({
  storyId,
  storyName,
  storyNumber,
  score,
  ownerName,
  epicName,
  epicColor,
  epicBackground,
  progressColorFrom,
  progressColorTo,
  progressPercent,
  totalHrs,
  consumedHrs,
  openStoryModal,
  tasks,
  tasksSummary,
  expanded,
  toggleExpanded,
}) => {
  const ownerShotName = shortName(ownerName);

  return (
    <div className={classes.root} onClick={() => openStoryModal()}>
      <div
        className={classes.progressBar}
        style={{
          backgroundImage: `linear-gradient(to right,${progressColorFrom}80,${progressColorTo}80)`,
        }}
      >
        <div
          style={{
            backgroundImage: `linear-gradient(to right,${progressColorFrom},${progressColorTo})`,
            width: `${progressPercent}%`,
          }}
        >
          <span>
            {consumedHrs ? Math.round(consumedHrs * 10) / 10 : 0} /{' '}
            {totalHrs ? Math.round(totalHrs * 10) / 10 : 0} hrs
          </span>
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.storyId}>Story ID - {storyNumber}</div>
        <div className={classes.storyTitle}>{storyName}</div>
      </div>
      <div className={classes.footer}>
        <div className="d-flex align-items-center">
          {score ? (
            <Button color="primary" className={classes.score}>
              {score}
            </Button>
          ) : (
            ''
          )}

          <span className={classes.owner}>{ownerShotName} </span>
        </div>

        {epicName && (
          <Badge
            pill
            className={classes.epic}
            style={{
              color: epicColor,
              background: epicBackground,
            }}
          >
            {epicName}
          </Badge>
        )}
      </div>
      {tasksSummary && tasks && tasks.length ? (
        <>
          <Collapse isOpen={expanded.includes(storyId)}>
            <div className="d-flex flex-column">
              {map(tasks, t => (
                <div
                  key={get(t, 'id')}
                  className={cs(
                    'd-flex justify-content-between',
                    classes.taskDetails
                  )}
                >
                  {get(t, 'status.status') === 'Not Started' &&
                  !get(t, 'completed_at') ? (
                    <div className={classes.grayOverlay} />
                  ) : null}
                  <div className="d-flex align-items-center position-relative">
                    {get(t, 'completed_at') ? (
                      <div
                        className={cs(
                          classes.circleBackground,
                          classes.completed
                        )}
                      >
                        <i className="fas fa-check" />
                      </div>
                    ) : null}
                    {get(t, 'user') ? (
                      <div className="d-flex align-items-center">
                        <Avatar
                          className={classes.userAvatar}
                          url={get(t, 'user.avatar')}
                        />
                      </div>
                    ) : (
                      <div
                        className={cs(classes.circleBackground, classes.noUser)}
                      >
                        <i className="fas fa-user" />
                      </div>
                    )}
                    <small className="ml-2">
                      {get(t, 'task_type') ? get(t, 'task_type.name') : '--'}
                    </small>
                  </div>
                  {get(t, 'health') ? (
                    <small>
                      {get(t, 'health.consumed_hrs') ||
                      (get(t, 'status.status') !== 'Not Started' &&
                        get(t, 'health.consumed_hrs') === 0)
                        ? `${get(t, 'health.consumed_hrs')} / `
                        : null}
                      {get(t, 'health.total_hrs')} Hrs
                    </small>
                  ) : null}
                </div>
              ))}
            </div>
          </Collapse>
          <div
            className="d-flex justify-content-center"
            onClick={toggleExpanded(storyId)}
          >
            <div className={classes.summary}>
              {tasksSummary.completed} / {tasksSummary.total} Assignments
              Completed
              <i
                className={cs(
                  'ml-2 fas',
                  { 'fa-chevron-down': !expanded.includes(storyId) },
                  { 'fa-chevron-up': expanded.includes(storyId) }
                )}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

StoryCard.propTypes = {
  storyName: PropTypes.string.isRequired,
  storyNumber: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  ownerName: PropTypes.string.isRequired,
  epicName: PropTypes.string,
  epicColor: PropTypes.string.isRequired,
  epicBackground: PropTypes.string.isRequired,
  progressColorFrom: PropTypes.string.isRequired,
  progressColorTo: PropTypes.string.isRequired,
  progressPercent: PropTypes.number.isRequired,
  totalHrs: PropTypes.number.isRequired,
  consumedHrs: PropTypes.number.isRequired,
};
StoryCard.defaultProps = {};

export default StoryCard;
