import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import Dropdowns from 'components/Dropdowns';
import classes from '../teams.module.scss';
import { useAccess, permissions } from 'helpers/permission';

const ProjectAccessCell = ({
  cell,
  row,
  onRemoveMember,
  onUpdateMemberRate,
  onViewMember,
}) => {
  return (
    <div className="w-100 d-flex">
      <div
        className={cs(
          'd-flex justify-content-between align-items-center',
          classes.accessText
        )}
      >
        {cell}
      </div>
      <Dropdowns
        className=" text-light ml-1"
        text={<i className="fas fa-ellipsis-v" />}
        size="sm"
        caret={false}
        role="button"
        color=""
        options={[
          {
            text: 'View Member',
            onClick: onViewMember,
          },
          ...(useAccess(permissions.UPDATE_TEAM_MEMBER_RATE)
            ? [
                {
                  text: 'Update Rate',
                  onClick: () => onUpdateMemberRate(row),
                },
              ]
            : []),
          ...(useAccess(permissions.REMOVE_TEAM_MEMBER)
            ? [
                {
                  text: 'Remove Member',
                  onClick: () => onRemoveMember(row),
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

ProjectAccessCell.prototype = {
  cell: PropTypes.any,
  row: PropTypes.any,
  onRemoveMember: PropTypes.func,
  onViewMember: PropTypes.func,
};

export default ProjectAccessCell;
