import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';
import NoViewedStories from 'assets/img/theme/No_Viewed_Stories.svg';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from 'views/pages/dashboards/Dashboard.module.scss';
import { useIntersection } from 'react-use';
import get from 'lodash/get';
import map from 'lodash/map';
import {
  getViewedStories as getViewedStoriesAction,
  updateStoryInRecentyViewedStories,
} from 'store/actions/userDashboard';
import { useDispatch, useSelector } from 'react-redux';
import StoryStatus from 'components/Status';
import StoryPriority from 'components/StoryPriority';
import { fetchStoryPriorities } from 'store/actions/Story/priorities';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { fetchComplexities } from 'store/actions/Story/complexities';
import { fetchScoreMatrix } from 'store/actions/Story/scoreMatrix';
import StoryModal from 'views/pages/Backlogs/Stories/StoryModal';
import { useAccess, permissions } from 'helpers/permission';
import find from 'lodash/find';
import {
  updateSelectedStoryStatus,
  updateStoryPriority,
} from 'store/actions/Story/details';
import InfiniteScroll from 'react-infinite-scroller';
import StoryToolTip from 'components/StoryToolTip';
import analytics, { analyticsConstants } from 'helpers/analytics';
import DuplicateStoryForm from 'views/pages/Backlogs/Stories/DuplicateStory.Form';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import forEach from 'lodash/forEach';
import duplicateFields from 'views/pages/Project/duplicateFields';
import some from 'lodash/some';
import { duplicateStoryForSection } from 'store/actions/backlogs';
import history from 'helpers/history';
import { uploadStoryAttachment } from 'store/actions/Story/fileAttachments';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const RecentlyViewedStories = ({ getDetails, isMobile }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStoryStatuses());
    dispatch(fetchStoryPriorities());
    dispatch(fetchComplexities());
    dispatch(fetchScoreMatrix());
  }, [dispatch]);

  const getViewedStories = (field, defaultValue) =>
    getDetails(`viewedStories.${field}`, defaultValue);
  const recentlyViewedStories = getViewedStories('data.data', []);
  const metaDetails = getViewedStories('data.meta');
  const isError = getViewedStories('isError', false);
  const loading = getViewedStories('isInProgress', false);
  const lastPage = get(metaDetails, 'last_page', 1);
  const currentPage = get(metaDetails, 'current_page', 1);
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );

  const priorities = useSelector(({ story }) =>
    get(story, 'priorityOptions.data', [])
  );

  const complexities = useSelector(({ story }) =>
    get(story, 'complexityOptions.data', [])
  );

  const scoreMatrix = useSelector(({ story }) =>
    get(story, 'scoreMatrix.data', [])
  );

  const [storyId, setStoryId] = useState();
  const [projectId, setProjectId] = useState();
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);

  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const isUserAllowedEditAllStories = useAccess(permissions.EDIT_ALL_STORIES);
  const isUserAllowedEditProjectStories = useAccess(
    permissions.EDIT_PROJECT_STORIES
  );

  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);
  const storyName = useGetFieldValue('name');

  const isUserAllowedEditForStories = useMemo(
    () =>
      map(recentlyViewedStories, story => {
        const isUserMemberOfTeam = find(
          get(story, 'initiative.assignees', []),
          member => member.id === userId
        );
        return (
          isUserAllowedEditAllStories ||
          (isUserAllowedEditProjectStories && isUserMemberOfTeam)
        );
      }),
    [
      isUserAllowedEditAllStories,
      isUserAllowedEditProjectStories,
      recentlyViewedStories,
      userId,
    ]
  );

  const closeDuplicateModal = () => setDuplicateModal(false);

  const openDuplicateModal = () => setDuplicateModal(true);

  const handleDuplicateStory = async values => {
    setDuplicating(true);
    const { data: duplicatedStory, status, errorStatus } = await dispatch(
      duplicateStoryForSection(storyId, values, null)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showStorageLimit();
      return;
    }
    if (status) {
      history.push(`/admin/projects/${duplicatedStory.initiative.id}/backlog`);
    }
    setDuplicating(false);
    setDuplicateModal(false);
  };

  const getStoryModal = useCallback(
    () =>
      Boolean(storyId) && (
        <StoryModal
          storyId={storyId}
          projectId={projectId}
          closeModal={() => {
            setStoryId();
            setProjectId();
            let pageCount = 1;
            while (pageCount <= currentPage) {
              dispatch(getViewedStoriesAction(pageCount));
              pageCount++;
            }
          }}
          isOpen={Boolean(storyId)}
          openDuplicateModal={openDuplicateModal}
        />
      ),
    [currentPage, dispatch, projectId, storyId]
  );

  return (
    <>
      {getStoryModal()}
      {isDuplicateModal ? (
        <DuplicateStoryForm
          storyData={{ name: storyName }}
          isModalOpen={isDuplicateModal}
          closeModal={closeDuplicateModal}
          submitValues={handleDuplicateStory}
          isLoading={isDuplicating}
          openDuplicateModal={openDuplicateModal}
        />
      ) : null}
      <Card className={classes.viewedStories}>
        <CardHeader>
          <h3 className="mb-0">Recently Viewed Stories</h3>
        </CardHeader>
        <CardBody
          id="recently-viewed-stories-card-body"
          className={cx('p-0', classes.cardBody, !isMobile && classes.scroll)}
        >
          <InfiniteScroll
            useWindow={isMobile}
            hasMore={currentPage < lastPage && !loading && !isError}
            initialLoad={false}
            pageStart={1}
            loadMore={() => {
              dispatch(getViewedStoriesAction(currentPage + 1));
            }}
          >
            <BaseTable
              keyField="id"
              defaultSorted={null}
              noDataIndication={() => (
                <div
                  className={cx(
                    'd-flex flex-column justify-content-centre align-items-centre',
                    classes.tableWrapper
                  )}
                >
                  <img
                    src={NoViewedStories}
                    className="mx-auto my-3"
                    alt="No Recently Viewed Stories"
                  />
                </div>
              )}
              search={false}
              bordered={false}
              loading={loading}
              paginationOptions={false}
              data={recentlyViewedStories}
              classes="mb-0"
              wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
              columns={[
                {
                  dataField: 'name',
                  text: 'Story Title',
                  sort: false,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <h5
                          id={`recent-story-name-${get(row, 'id')}`}
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName
                          )}
                          onClick={() => {
                            setProjectId(get(row, 'initiative.id'));
                            analyticsSendEventStory({
                              action: analyticsConstants.action.open_story,
                              opened_from:
                                'User Dashboard Recently Viewed Stories',
                            });
                            setStoryId(get(row, 'id'));
                          }}
                        >
                          {cell}
                        </h5>
                        <StoryToolTip
                          targetId={`recent-story-name-${row.id}`}
                          name={cell}
                        />
                      </>
                    );
                  },
                },
                {
                  dataField: 'initiative.name',
                  text: 'Project',
                  sort: false,
                  formatter: cell => {
                    return (
                      <p
                        className={cx(
                          'm-0 text-sm text-muted w-100',
                          classes.ellipsis
                        )}
                      >
                        {cell}
                      </p>
                    );
                  },
                },
                {
                  dataField: 'score',
                  text: 'Priority',
                  sort: false,
                  headerClasses: classes.priorityHeader,
                  formatter: (cell, row, index) => {
                    return (
                      <StoryPriority
                        score={cell}
                        isEditAllowed={isUserAllowedEditForStories[index]}
                        priorities={priorities}
                        complexities={complexities}
                        scoreMatrix={scoreMatrix}
                        priority={get(row, 'priority')}
                        complexity={get(row, 'complexity')}
                        onChange={data => {
                          analyticsSendEventStory({
                            action:
                              analyticsConstants.action.update_story_priority,
                            updated_from:
                              'User Dashboard Recently Viewed Stories',
                          });

                          dispatch(
                            updateStoryPriority(
                              row.id,
                              data,
                              updateStoryInRecentyViewedStories
                            )
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  dataField: 'status',
                  text: 'Status',
                  sort: false,
                  formatter: (cell, row, index) => {
                    return (
                      <StoryStatus
                        disabled={!isUserAllowedEditForStories[index]}
                        statuses={statuses}
                        selectedStatus={cell}
                        handleChange={data => {
                          analyticsSendEventStory({
                            action:
                              analyticsConstants.action.update_story_status,
                            updated_from:
                              'User Dashboard Recently Viewed Stories',
                          });
                          dispatch(
                            updateSelectedStoryStatus(
                              row.id,
                              data,
                              updateStoryInRecentyViewedStories
                            )
                          );
                        }}
                      />
                    );
                  },
                },
              ]}
            />
          </InfiniteScroll>
        </CardBody>
      </Card>
    </>
  );
};

RecentlyViewedStories.propTypes = {};

export default RecentlyViewedStories;
