import * as React from 'react';
import PropTypes from 'prop-types';
import classes from './DatePicker.module.scss';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import { isEmpty } from 'lodash';

const DatePicker = props => {
  const {
    name,
    placeholder,
    className,
    touched,
    error,
    id,
    onChange,
    isValidDate,
    value,
    dateFormat,
    ...rest
  } = props;

  return (
    <>
      <ReactDatetime
        inputProps={{
          placeholder: placeholder,
        }}
        closeOnSelect
        value={isEmpty(value) ? value : moment(value).format(dateFormat)}
        isValidDate={isValidDate}
        timeFormat={false}
        name={name}
        onChange={onChange}
        className={className}
        dateFormat={dateFormat}
        id={id}
        {...rest}
      />

      {touched && error && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </>
  );
};

DatePicker.prototype = {
  touched: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  isValidDate: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(typeof moment),
  ]),
  dateFormat: PropTypes.string,
};

DatePicker.defaultProps = {
  label: null,
  id: null,
  touched: false,
  className: '',
  name: '',
  placeholder: 'Date Picker',
  value: '',
  dateFormat: 'MMM DD, YYYY',
};

export default DatePicker;
