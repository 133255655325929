import React from 'react';
import moment from 'moment';

const LastUpdated = ({ getFieldValue }) => {
  const lastUpdated = moment(getFieldValue('updated_at')).format('M/D/YY');
  return (
    <>
      <h6 className="text-uppercase text-muted">Last Updated</h6>
      <p>{lastUpdated}</p>
    </>
  );
};

export default LastUpdated;
