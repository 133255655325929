import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Badge } from 'reactstrap';
import classes from './QuickView.module.scss';
import cx from 'classnames';
const QuickView = ({ heading, content, iconBg, icon, footer }) => {
  return (
    <Card className={classes.card}>
      <CardBody
        className={cx('d-flex flex-column position-relative', classes.cardBody)}
      >
        <h5 className="text-uppercase text-muted mb-0 card-title mr-5">
          {heading}
        </h5>
        <h2 className="h2 font-weight-bold mb-0 text-capitalize mr-5">
          {content}
        </h2>
        <div className={cx('mt-4', classes.footer)}>{footer}</div>
        <Badge
          className={cx(
            `bg-gradient-${iconBg}`,
            'icon icon-shape circle rounded-circle position-absolute',
            classes.badge
          )}
        >
          <i className={`text-white ${icon}`} />
        </Badge>
      </CardBody>
    </Card>
  );
};

QuickView.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconBg: PropTypes.string,
  icon: PropTypes.string,
};

export default QuickView;
