import React, { useState, useRef, useEffect } from 'react';

import Column from './Column';
import classes from './Kanban.module.scss';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { reorder, move, reassignOrder } from 'helpers/dragDropHelpers';
import get from 'lodash/get';
import cs from 'classnames';
import {
  doPostReOrderStories,
  doMoveStoryCard,
  reOrderColumns,
} from 'store/actions/kanbanActions';
import {
  reOrderSprintColumns,
  doReOrderKanbanStories,
  moveStoryCard,
} from 'store/actions/sprint';
import { useDispatch } from 'react-redux';
import Loading from 'components/Loading';
import { analyticsConstants } from 'helpers/analytics';
import map from 'lodash/map';
import find from 'lodash/find';
import toString from 'lodash/toString';
import { createStoryQuickAction } from 'store/actions/quickActions';
import CreateStoryForm from 'views/pages/QuickActions/CreateStory/CreateStory.Form';
import history from 'helpers/history';
import { useParams } from 'react-router-dom';
import socket from 'helpers/socket';

const DragContainer = ({
  projectId,
  expanded,
  toggleExpanded,
  isAllowedEdit,
  isAllowedKanbanEdit,
  analyticsSendEvent,
  columns,
  setColumnModification,
  columnModification,
  onColumnModification,
  isSprintKanban,
  id: sprintId,
  sectionID,
  isSprintUpdateAllowed,
}) => {
  const dispatch = useDispatch();

  // show data
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isCreateStory, setCreateStory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const addStoryColumn = useRef();
  const { id } = useParams();
  const [autoDragId, setAutoDragId] = useState();

  // Socket call for sprint socket event is commented
  useEffect(() => {
    socket.kanbanStoryChanges(id, setAutoDragId);
  }, [id]);

  // Auto Drag function commented for stories
  useEffect(() => {
    startDrag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDragId]);

  const openModal = column => {
    addStoryColumn.current = column;
    setCreateStory(true);
  };
  const closeModal = () => {
    setCreateStory(false);
  };
  const reload = () => {
    setCount(count + 1);
  };
  const submitValues = async values => {
    setIsLoading(true);
    const { section, name } = values;
    if (isSprintKanban) {
      const { data, status } = await dispatch(
        createStoryQuickAction(sectionID, {
          name,
          sprint_status_id: addStoryColumn.current.id,
        })
      );
      onColumnModification();
      addStoryColumn.current = undefined;
      closeModal();
      setIsLoading(false);
      if (status) {
        history.push(
          `/admin/projects/${id}/backlog/sprint/${sprintId}/board?story=${data.id}`
        );
      }
    } else {
      const { data, status } = await dispatch(
        createStoryQuickAction(section.id, {
          name,
          initiative_board_column_id: addStoryColumn.current.id,
        })
      );
      onColumnModification();
      addStoryColumn.current = undefined;
      closeModal();
      setIsLoading(false);
      if (status) {
        history.push(`/admin/projects/${projectId}/boards/?story=${data.id}`);
      }
    }
  };
  const onDragEnd = ({ type, ...rest }) => {
    switch (type) {
      case 'columns':
        return moveStories(rest);
      case 'columns-container':
        return moveColumn(rest);
    }
  };

  const moveColumn = ({ source, destination }) => {
    const destinationIndex = get(destination, 'index');
    const sourceIndex = get(source, 'index');
    if (destination && destinationIndex !== sourceIndex) {
      if (isSprintKanban) {
        dispatch(
          reOrderSprintColumns(
            sprintId,
            reassignOrder(reorder(columns, sourceIndex, destinationIndex))
          )
        );
      } else {
        dispatch(
          reOrderColumns(
            projectId,
            reassignOrder(reorder(columns, sourceIndex, destinationIndex))
          )
        );
      }
    }
  };

  const moveStories = async ({ destination, source, mode }) => {
    // dropped outside the list
    if (!destination) {
      return;
    }

    analyticsSendEvent({
      action: analyticsConstants.action.drag_card_on_board,
    });

    const {
      index: destinationIndex,
      droppableId: destinationSection,
    } = destination;

    const { index: sourceIndex, droppableId: sourceSection } = source;
    if (destinationSection === sourceSection) {
      if (destinationIndex !== sourceIndex) {
        const sourceStories = get(
          find(columns, column => {
            return toString(column.id) === sourceSection;
          }),
          'stories',
          []
        );
        const updatedItems = reorder(
          sourceStories,
          sourceIndex,
          destinationIndex
        );
        if (isSprintKanban) {
          await dispatch(
            doReOrderKanbanStories(
              sprintId,
              sourceSection,
              updatedItems,
              destinationSection,
              sourceStories[sourceIndex].id,
              mode
            )
          );
        } else {
          await dispatch(
            doPostReOrderStories(projectId, sourceSection, updatedItems)
          );
        }
      }
    } else {
      const sourceColumn = find(columns, column => {
        return toString(column.id) === sourceSection;
      });
      const destColumn = find(columns, column => {
        return toString(column.id) === destinationSection;
      });
      const sourceStories = get(sourceColumn, 'stories', []);
      const destinationStories = get(destColumn, 'stories', []);
      const newResult = move(
        sourceStories,
        destinationStories,
        sourceIndex,
        destinationIndex
      );
      setLoading(true);
      if (isSprintKanban) {
        if (autoDragId && autoDragId.newStory) {
          newResult.destClone = newResult.destClone.map(s =>
            s.id === autoDragId.newStory.id ? autoDragId.newStory : s
          );
          setAutoDragId(null);
        }
        await dispatch(
          moveStoryCard(
            sprintId,
            sourceStories[sourceIndex].id,
            sourceColumn,
            destColumn,
            newResult.sourceClone,
            newResult.destClone,
            destinationSection,
            mode
          )
        );
      } else {
        await dispatch(
          doMoveStoryCard(
            projectId,
            sourceStories[sourceIndex].id,
            get(sourceColumn, 'id'),
            get(destColumn, 'id'),
            newResult.sourceClone,
            newResult.destClone
          )
        );
      }

      reload();
      setLoading(false);
    }
  };

  let api = null;
  const autoDrag = value => {
    api = value;
  };

  const startDrag = async () => {
    if (autoDragId) {
      const { id } = autoDragId;
      const preDrag = api.tryGetLock(id);
      if (!preDrag) {
        return;
      }
      const drag = preDrag.snapLift();

      if (autoDragId.newColumnId > -1) {
        const { oldColumnId, newColumnId } = autoDragId;
        const columnDiff = newColumnId - oldColumnId;
        if (newColumnId > oldColumnId) {
          for (let i = 0; i < columnDiff; i++) {
            await drag.moveRight();
          }
        }

        if (newColumnId < oldColumnId) {
          for (let i = 0; i > columnDiff; i--) {
            await drag.moveLeft();
          }
        }
      }

      if (autoDragId.oldPosition) {
        const { oldPosition, newPosition } = autoDragId;
        const positionDiff = newPosition - oldPosition;
        if (newPosition > oldPosition) {
          for (let i = 0; i < positionDiff; i++) {
            await drag.moveDown();
          }
        }

        if (newPosition < oldPosition) {
          for (let i = 0; i > positionDiff; i--) {
            await drag.moveUp();
          }
        }
      }

      setTimeout(() => {
        drag.drop();
      }, 500);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} sensors={[autoDrag]}>
        {loading && <Loading wrapperClass={classes.loading} size="100px" />}
        <div className={classes.content}>
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              display: 'flex',
              minHeight: '100%',
            }}
          >
            <Droppable
              droppableId="all-columns"
              type="columns-container"
              direction="horizontal"
            >
              {provided => (
                <div
                  {...provided.droppableProps}
                  className={cs('d-flex', classes.droppableContainer)}
                  ref={provided.innerRef}
                >
                  {map(columns, (column, columnIndex) => {
                    return (
                      <Column
                        projectId={`${projectId}`}
                        key={column.id}
                        data={column}
                        expanded={expanded}
                        toggleExpanded={toggleExpanded}
                        isAllowedKanbanEdit={isAllowedKanbanEdit}
                        isAllowedEdit={isAllowedEdit}
                        closeColumnModification={() => {
                          setColumnModification();
                        }}
                        onColumnModification={onColumnModification}
                        columnIndex={columnIndex}
                        columnModification={columnModification}
                        isSprintUpdateAllowed={isSprintUpdateAllowed}
                        openEdit={column => {
                          setColumnModification(column);
                        }}
                        openCreateStory={openModal}
                        isSprintKanban={isSprintKanban}
                        sprintId={sprintId}
                      />
                    );
                  })}
                  {columnModification === 'new' && (
                    <Column
                      isSprintKanban={isSprintKanban}
                      columnIndex={columns.length}
                      projectId={`${projectId}`}
                      sprintId={sprintId}
                      columnModification={columnModification}
                      onColumnModification={onColumnModification}
                      closeColumnModification={() => {
                        setColumnModification();
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
            {isAllowedKanbanEdit && (
              <div
                className={cs(
                  'align-items-center py-5 px-2',
                  classes.column,
                  classes.addColumn
                )}
                onClick={() => {
                  setColumnModification('new');
                }}
              >
                <h2 className={cs(classes.columnHeading, 'font-weight-700')}>
                  Add a new column
                </h2>
                <i className="fas fa-plus my-auto"></i>
              </div>
            )}
          </div>
        </div>
      </DragDropContext>
      <CreateStoryForm
        isModalOpen={isCreateStory}
        closeModal={closeModal}
        submitValues={submitValues}
        isLoading={isLoading}
        isSprint={isSprintKanban}
        initialValues={{ project: { id } }}
      />
    </>
  );
};

export default DragContainer;
