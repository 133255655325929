import React, { useState } from 'react';
import {
  Badge,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import classNames from 'classnames';
import classes from './navigationdropdown.module.scss';

const NavigationDropdown = ({
  options,
  handleOptionClick,
  activeContent,
  right,
  showBadge,
  count,
  id,
  onToggle,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onOptionClick = content => () => {
    setIsDropdownOpen(false);
    handleOptionClick(content);
  };

  const setDropdownState = () => {
    setIsDropdownOpen(!isDropdownOpen);
    onToggle && onToggle(!isDropdownOpen);
  };

  return (
    <Dropdown nav isOpen={isDropdownOpen} toggle={setDropdownState}>
      <DropdownToggle id={id} className="nav-link m-0" color="">
        <i className={classNames(activeContent.iconClassName, 'text-black')} />
        {` ${activeContent.name}`}
        <i className="ni ni-bold-down" />
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-lg dropdown-menu-dark bg-default"
        right={right}
      >
        <Row className="shortcuts px-4">
          {options.map((content, index) => (
            <Col
              key={index}
              className={classNames('shortcut-item', classes.cursor)}
              onClick={onOptionClick(content)}
              xs="4"
              tag="a"
            >
              <span
                className={classNames(
                  'shortcut-media avatar rounded-circle',
                  `bg-gradient-${content.gradientColor}`
                )}
                {...(content.id ? { id: content.id } : {})}
              >
                <i className={content.iconClassName} />
              </span>
              <small className="text-white">{content.name}</small>
              {content.showBadge && content.count ? (
                <Badge
                  color="danger"
                  className={classNames(
                    'badge-sm badge-circle badge-floating border-white',
                    classes.badgeCount
                  )}
                >
                  {content.count > 99 ? '99+' : content.count}
                </Badge>
              ) : null}
            </Col>
          ))}
        </Row>
      </DropdownMenu>
    </Dropdown>
  );
};

NavigationDropdown.defaultProps = {
  right: true,
  handleOptionClick: () => {},
  activeContent: {},
  options: [],
};

export default NavigationDropdown;
