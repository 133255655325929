import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardFooter, Form } from 'reactstrap';
import classNames from 'classnames';
import { get, isEmpty, trim } from 'lodash';
import StoryImg from 'assets/img/icons/common/Story.svg';
import Input from 'components/FormFields/Input';
import { createStoryForSection } from 'store/actions/backlogs';
import classes from './Backlogs.module.scss';
import usePrevious from 'helpers/usePrevious';
import Types from 'store/types/backlogs';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { updateActiveTourStatus } from 'store/actions/profile';
import CreateStoryForm from 'views/pages/QuickActions/CreateStory/CreateStory.Form';
import history from 'helpers/history';

const SectionFooter = ({ id, name }) => {
  const dispatch = useDispatch();
  const [newStoryName, setNewStoryName] = useState('');
  const [isCreateStory, setCreateStory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setCreateStory(true);
  };

  const closeModal = () => {
    setCreateStory(false);
  };

  const footerRef = useRef(null);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const stories = useSelector(({ backlog }) =>
    get(backlog, `itemsState.data.${id}.data`, [])
  );

  const creatingSectionId = useSelector(({ backlog }) =>
    get(backlog, 'createStory.sectionId')
  );

  const prevStories = usePrevious(stories);

  useEffect(() => {
    if (
      stories &&
      prevStories &&
      stories.length - 1 === prevStories.length &&
      creatingSectionId === id
    ) {
      const boundingClientRect = footerRef.current.getBoundingClientRect();
      if (window.innerHeight - boundingClientRect.y < 80) {
        // To scroll the input if needed
        footerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      footerRef.current.focus();
      dispatch({ type: Types.RESET_CREATE_STORY });
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stories]);

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const createStory = async e => {
    e.preventDefault();
    e.stopPropagation();
    analyticsSendEvent({ action: analyticsConstants.action.create_story });
    await dispatch(createStoryForSection(id, { name: newStoryName }));
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'story_creation' &&
      quickStartTour.step === 6
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step: 7,
          })
        );
      }, 500);
    }
    setNewStoryName('');
  };

  const submitValues = async values => {
    setIsLoading(true);

    const { section, name, project } = values;
    const { data, status } = await dispatch(
      createStoryForSection(section.id, { name: name })
    );
    if (status) {
      history.push(`/admin/projects/${project.id}/backlog/?story=${data.id}`);
    }
    closeModal();
    setIsLoading(false);
  };

  return (
    <CardFooter className="py-2 px-0 border-top-0">
      <Form
        className="d-flex justify-content-between w-100 align-items-center"
        onSubmit={createStory}
      >
        <div>
          <div
            className={classNames(
              'btn-icon btn-icon-only rounded-circle mr-2',
              classes.storyBtn
            )}
            onClick={openModal}
          >
            <img src={StoryImg} alt="Story" />
          </div>
        </div>
        <Input
          className="border-0 shadow-none"
          placeholder="+ Create a new story"
          onChange={e => setNewStoryName(e.target.value)}
          value={newStoryName}
          maxLength={255}
          innerRef={footerRef}
        />
        <div>
          <Button
            type="submit"
            outline={isEmpty(trim(newStoryName))}
            className={classNames(
              'btn-icon btn-icon-only rounded-circle ml-4',
              classes.createBtn
            )}
            color={isEmpty(trim(newStoryName)) ? '' : 'primary'}
            disabled={isEmpty(trim(newStoryName))}
          >
            <i className="fas fa-plus" />
          </Button>
        </div>
      </Form>
      {isCreateStory ? (
        <CreateStoryForm
          isModalOpen={isCreateStory}
          closeModal={closeModal}
          submitValues={submitValues}
          isLoading={isLoading}
          initialValues={{ section: { id, name } }}
        />
      ) : null}
    </CardFooter>
  );
};

export default React.memo(SectionFooter);
