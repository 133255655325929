import React from 'react';
import { Progress, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
import classes from '../teams.module.scss';

const ThisWeeksUtilization = ({ cell, row }) => {
  return (
    <div className="d-flex w-100 align-items-center">
      <span className={classes.text}>{cell.percentage}%</span>
      <div className="ml-3" id={`progress-${row.id}`}>
        <UncontrolledTooltip target={`progress-${row.id}`}>
          <div className="d-flex flex-column align-items-start">
            <span className="font-weight-bold">
              This Week ({cell.hours_logged_this_week}/
              {cell.weekly_availability})
            </span>
            <div className="d-flex align-items-center">
              <span
                className={classNames('mr-2', classes.legend)}
                style={{
                  background: `#${cell.this_project.color}`,
                }}
              />
              This Project: {cell.this_project.hours} hrs
            </div>
            <div className="d-flex align-items-center">
              <span
                className={classNames('mr-2', classes.legend)}
                style={{
                  background: `#${cell.other_projects.color}`,
                }}
              />
              Others: {cell.other_projects.hours} hrs
            </div>
            <div className="d-flex align-items-center">
              <span className={classNames('mr-2', classes.legend)} />
              Remaining: {cell.remaining_hours_this_week} hrs
            </div>
          </div>
        </UncontrolledTooltip>
        <Progress multi max={100}>
          <Progress bar color="primary" value={cell.this_project.percentage} />
          <Progress
            bar
            color="default"
            value={cell.other_projects.percentage}
          />
        </Progress>
      </div>
    </div>
  );
};

export default ThisWeeksUtilization;
