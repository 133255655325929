export default {
  story_details: {
    label: 'Story Details',
    name: 'story_details',
    fields: [
      {
        label: 'Priority',
        name: 'priority_id',
        objKey: 'fields',
      },
      {
        label: 'Status',
        name: 'status_id',
        objKey: 'fields',
      },
      {
        label: 'Epic',
        name: 'epic_id',
        objKey: 'fields',
      },
      {
        label: 'Description',
        name: 'description',
        objKey: 'fields',
      },
      {
        label: 'Attachments',
        name: 'attachments',
        objKey: 'fields',
      },
    ],
  },
  assignmentFields: {
    label: 'Assignments',
    name: 'assignmentFields',
    fields: [
      {
        label: 'Assignment Types',
        name: 'task_type_id',
        objKey: 'assignmentFields',
      },
      {
        label: 'Assignees (Assigned To)',
        name: 'user_id',
        objKey: 'assignmentFields',
      },
      {
        name: 'date_due',
        label: 'Due Dates',
        objKey: 'assignmentFields',
      },
      {
        name: 'checklists',
        label: 'Checklist Items',
        objKey: 'assignmentFields',
      },
      {
        name: 'budget_hours',
        label: 'Budget Hours',
        objKey: 'assignmentFields',
      },
    ],
  },
};
