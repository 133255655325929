import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import toNumber from 'lodash/toNumber';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
// reactstrap components
import { Col, Container, Row } from 'reactstrap';
import ProfileHeader from 'components/Headers/ProfileHeader.js';
import ProfileDetails from './ProfileDetails';
import AvailabilityStats from './AvailabilityStats';
import TasksStats from './TasksStats';
// core components
import { fetchStory } from 'store/actions/Story/details';
import AssignedProjectsWidget from 'views/widgets/AssignedProjects';
import TimeSpent from 'views/widgets/TimeSpent';
import ProfileActivity from './ProfileActivity';
import StoryModal from '../Backlogs/Stories/StoryModal';
import analytics, { analyticsConstants } from 'helpers/analytics';
import Loading from 'components/Loading';
import classes from './profile.module.scss';
import DuplicateStoryForm from 'views/pages/Backlogs/Stories/DuplicateStory.Form';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import forEach from 'lodash/forEach';
import duplicateStoryFields from 'views/pages/Backlogs/Stories/duplicateStoryFields';
import some from 'lodash/some';
import { duplicateStoryForSection } from 'store/actions/backlogs';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const Profile = props => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_profile,
      ...rest,
    });
  };
  const userId = get(props, 'match.params.id');
  const details = useSelector(({ profile }) =>
    JSON.parse(JSON.stringify(get(profile, 'details.data', {})))
  );
  const loading = useSelector(({ profile }) =>
    get(profile, 'details.isInProgress', false)
  );
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const history = useHistory();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [storyId, setStoryId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [count, setCount] = useState(0);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);

  const getStoryDetail = async story => {
    const { status, data } = await dispatch(fetchStory(story));

    if (status === 1) {
      setStoryId(story);
      setProjectId(get(data, 'initiative.id', ''));
      analyticsSendEventStory({
        action: analyticsConstants.action.open_story,
        opened_from: 'User Profile Recent Activity',
      });
      setIsStoryModalOpen(true);
    }
  };

  const handleDuplicateStory = async values => {
    setDuplicating(true);
    const { data: duplicatedStory, status, errorStatus } = await dispatch(
      duplicateStoryForSection(storyId, values, null)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showStorageLimit();
      return;
    }
    if (status) {
      history.push(`/admin/projects/${duplicatedStory.initiative.id}/backlog`);
    }
    setDuplicating(false);
    setDuplicateModal(false);
  };

  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);
  const storyName = useGetFieldValue('name');

  const closeDuplicateModal = () => setDuplicateModal(false);

  const openDuplicateModal = () => setDuplicateModal(true);

  useEffect(() => {
    analyticsSendEvent({ action: analyticsConstants.action.view_user_profile });
  }, []);

  const reload = () => setCount(count + 1);

  return (
    <>
      {loading && <Loading wrapperClass={classes.loading} />}
      <ProfileHeader userId={userId} name={get(details, 'name')} />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="4">
            <ProfileDetails
              userId={userId}
              mode="view"
              analyticsSendEvent={analyticsSendEvent}
            />
            <AssignedProjectsWidget userId={userId} />
          </Col>
          <Col className="order-xl-2 d-flex flex-column" xl="8">
            <Row>
              <Col lg="6">
                <AvailabilityStats userId={userId} />
              </Col>
              <Col lg="6">
                <TasksStats userId={userId} />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <TimeSpent userId={userId} />
              </Col>
            </Row>
            <ProfileActivity
              userId={userId}
              onStoryClick={url => {
                getStoryDetail(get(queryString.parseUrl(url), 'query.story'));
              }}
            />
          </Col>
        </Row>
        {isStoryModalOpen && (
          <StoryModal
            storyId={toNumber(storyId)}
            projectId={toNumber(projectId)}
            isOpen={isStoryModalOpen}
            closeModal={() => {
              setIsStoryModalOpen(false);
              history.push(`?`);
            }}
            currentTab={get(queryProps, 'tab', 'comments')}
            onChange={() => reload()}
            openDuplicateModal={openDuplicateModal}
          />
        )}
        {isDuplicateModal ? (
          <DuplicateStoryForm
            storyData={{ name: storyName }}
            isModalOpen={isDuplicateModal}
            closeModal={closeDuplicateModal}
            submitValues={handleDuplicateStory}
            isLoading={isDuplicating}
            openDuplicateModal={openDuplicateModal}
          />
        ) : null}
      </Container>
    </>
  );
};

export default Profile;
