import * as React from 'react';
import cx from 'classnames';
import classes from './Tabs.module.scss';

const Tabs = ({ activeTab, onChangeTab }) => {
  const renderTabItem = (key, name, color) => {
    return (
      <div
        className={cx(
          classes.tab,
          classes[color],
          activeTab === key && classes.activeTab
        )}
        onClick={() => onChangeTab(key)}
      >
        {name}
      </div>
    );
  };

  return (
    <div className={classes.tabs}>
      {renderTabItem('starter', 'Starter', 'green')}
      {renderTabItem('pro', 'Pro', 'blue')}
      {renderTabItem('enterprise', 'Enterprise', 'yellow')}
    </div>
  );
};

export default Tabs;
