import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './DateRangePicker.module.scss';

import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Input from 'components/FormFields/Input';
import cs from 'classnames';
import useClickAway from 'react-use/lib/useClickAway';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import useBreakPoint from 'helpers/useBreakPoint';
import navNextIcon from 'assets/img/icons/navNext.svg';
import navPrevIcon from 'assets/img/icons/navPrev.svg';

const dateFormat = 'YYYY-MM-DD';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const DateRangePicker = props => {
  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const { label, value, onChange, startDate, endDate, ...rest } = props;

  const [active, setActive] = useState(3);
  const [focus, setFocus] = useState(false);
  const [dates, setDates] = useState({
    startDate: startDate || null,
    endDate: endDate || null,
  });
  const [focusedInput, setFocusInput] = useState('startDate');

  const isMobile = useBreakPoint('xs', 'down');

  const ref = useRef(null);

  const leftPanelActions = [
    {
      id: 1,
      text: 'Custom Range',
      onClick: () => {
        setActive(1);
        // onChange({ startDate: null, endDate: null });
      },
      startDate: null,
      endDate: null,
    },
    {
      id: 2,
      text: 'Today',
      onClick: () => {
        setActive(2);
        onChange({
          startDate: moment().tz(timezone),
          endDate: moment().tz(timezone),
        });
      },
      startDate: moment().tz(timezone),
      endDate: moment().tz(timezone),
    },
    {
      id: 3,
      text: 'This Week',
      onClick: () => {
        setActive(3);

        onChange({
          startDate: moment()
            .tz(timezone)
            .startOf('week'),
          endDate: moment()
            .tz(timezone)
            .endOf('week'),
        });
      },
      startDate: moment()
        .tz(timezone)
        .startOf('week'),
      endDate: moment()
        .tz(timezone)
        .endOf('week'),
    },
    {
      id: 4,
      text: 'This Month',
      onClick: () => {
        setActive(4);

        onChange({
          startDate: moment()
            .tz(timezone)
            .startOf('month'),
          endDate: moment()
            .tz(timezone)
            .endOf('month'),
        });
      },
      startDate: moment()
        .tz(timezone)
        .startOf('month'),
      endDate: moment()
        .tz(timezone)
        .endOf('month'),
    },
    {
      id: 5,
      text: 'This Year',
      onClick: () => {
        setActive(5);
        onChange({
          startDate: moment()
            .tz(timezone)
            .startOf('year'),
          endDate: moment().tz(timezone),
        });
      },
      startDate: moment()
        .tz(timezone)
        .startOf('year'),
      endDate: moment().tz(timezone),
    },
    {
      id: 6,
      text: 'Yesterday',
      onClick: () => {
        setActive(6);

        onChange({
          startDate: moment()
            .tz(timezone)
            .subtract(1, 'day'),
          endDate: moment()
            .tz(timezone)
            .subtract(1, 'day'),
        });
      },
      startDate: moment()
        .tz(timezone)
        .subtract(1, 'day'),
      endDate: moment()
        .tz(timezone)
        .subtract(1, 'day'),
    },
    {
      id: 7,
      text: 'Last Week',
      onClick: () => {
        setActive(7);

        onChange({
          startDate: moment()
            .tz(timezone)
            .subtract(1, 'week')
            .startOf('week'),
          endDate: moment()
            .tz(timezone)
            .subtract(1, 'week')
            .endOf('week'),
        });
      },
      startDate: moment()
        .tz(timezone)
        .subtract(1, 'week')
        .startOf('week'),
      endDate: moment()
        .tz(timezone)
        .subtract(1, 'week')
        .endOf('week'),
    },
    {
      id: 8,
      text: 'Last Month',
      onClick: () => {
        setActive(8);

        onChange({
          startDate: moment()
            .tz(timezone)
            .subtract(1, 'month')
            .startOf('month'),
          endDate: moment()
            .tz(timezone)
            .subtract(1, 'month')
            .endOf('month'),
        });
      },
      startDate: moment()
        .tz(timezone)
        .subtract(1, 'month')
        .startOf('month'),
      endDate: moment()
        .tz(timezone)
        .subtract(1, 'month')
        .endOf('month'),
    },
    {
      id: 9,
      text: 'Last Year',
      onClick: () => {
        setActive(9);

        onChange({
          startDate: moment()
            .tz(timezone)
            .subtract(1, 'year')
            .startOf('year'),
          endDate: moment()
            .tz(timezone)
            .subtract(1, 'year')
            .endOf('year'),
        });
      },
      startDate: moment()
        .tz(timezone)
        .subtract(1, 'year')
        .startOf('year'),
      endDate: moment()
        .tz(timezone)
        .subtract(1, 'year')
        .endOf('year'),
    },
  ];

  const activeTab = () => {
    const action = leftPanelActions.find(item => {
      if (!item.startDate || !item.endDate || !startDate || !endDate)
        return false;

      if (
        item.startDate.format(dateFormat) ===
          startDate.tz(timezone).format(dateFormat) &&
        item.endDate.format(dateFormat) ===
          endDate.tz(timezone).format(dateFormat)
      )
        return true;

      return false;
    });

    if (!value) setActive(3);
    else setActive(action ? action.id : 1);
  };

  useClickAway(ref, _e => {
    setFocus(false);
    activeTab();
  });

  useEffect(() => {
    setDates({
      startDate:
        startDate ||
        moment()
          .tz(timezone)
          .startOf('week'),
      endDate:
        endDate ||
        moment()
          .tz(timezone)
          .endOf('week'),
    });

    activeTab();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, timezone]);

  return (
    <>
      {label && <label className="form-control-label">{label}</label>}
      <div className={classes.root} ref={ref}>
        <Input
          {...rest}
          alternative={false}
          rightIcon={<i className="fas fa-calendar"></i>}
          focused={focus}
          onFocus={() => setFocus(true)}
          value={value}
          onChange={() => {}}
        />

        {focus && (
          <div className={cs(classes.calendar)}>
            <div className={classes.leftPanel}>
              {leftPanelActions.map((item, index) => {
                return (
                  <Button
                    key={index}
                    color="link"
                    className={cs({ [classes.active]: active === item.id })}
                    onClick={() => item.onClick()}
                  >
                    {item.text}
                  </Button>
                );
              })}
            </div>
            <DayPickerRangeController
              firstDayOfWeek={1}
              enableOutsideDays={true}
              numberOfMonths={isMobile ? 1 : active === 1 ? 2 : 1}
              minimumNights={0}
              startDate={dates.startDate} // momentPropTypes.momentObj or null,
              endDate={dates.endDate} // momentPropTypes.momentObj or null,
              onDatesChange={({ startDate, endDate }) => {
                setDates({ startDate, endDate });

                // active left tab
                const action = leftPanelActions.find(item => {
                  if (
                    !item.startDate ||
                    !item.endDate ||
                    !startDate ||
                    !endDate
                  )
                    return false;

                  if (
                    item.startDate.format(dateFormat) ===
                      startDate.tz(timezone).format(dateFormat) &&
                    item.endDate.format(dateFormat) ===
                      endDate.tz(timezone).format(dateFormat)
                  )
                    return true;

                  return false;
                });

                if (action) setActive(action.id);
                else {
                  setActive(1);
                }

                if (startDate && endDate) {
                  onChange({ startDate, endDate });
                } else if (startDate === null && endDate === null) {
                  onChange({ startDate, endDate });
                }
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput =>
                setFocusInput(focusedInput || 'startDate')
              } // PropTypes.func.isRequired,
              navPrev={
                <div className="navPrev">
                  <img src={navPrevIcon} alt="previous" />
                </div>
              }
              navNext={
                <div className="navNext">
                  <img src={navNextIcon} alt="next" />
                </div>
              }
              renderDayContents={e => {
                const day = e.format('D');

                if (
                  moment(dates.startDate).format('YYYY-MM-DD') ===
                  moment(dates.endDate).format('YYYY-MM-DD')
                ) {
                  return <div className="startDate">{day}</div>;
                } else if (
                  moment(dates.startDate).format('YYYY-MM-DD') ===
                  e.format('YYYY-MM-DD')
                ) {
                  return (
                    <>
                      <div className="startDate">{day}</div>
                      <div className="rightBG"></div>
                    </>
                  );
                } else if (
                  moment(dates.endDate).format('YYYY-MM-DD') ===
                  e.format('YYYY-MM-DD')
                ) {
                  return (
                    <>
                      <div className="endDate">{day}</div>
                      <div className="leftBG"></div>
                    </>
                  );
                }

                return day;
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

DateRangePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
};

export default DateRangePicker;
