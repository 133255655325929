import get from 'lodash/get';
import { getBillingPlans } from 'api/billing';
import Types from '../types/billing';

export const fetchBillingPlan = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const companyId = get(state, 'auth.user.company.id');
    dispatch({
      type: Types.FETCH_BILLING_PLANS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getBillingPlans(companyId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_BILLING_PLANS_SUCCESS,
          data: get(resp, 'data') || [],
        });
      } else {
        dispatch({
          type: Types.FETCH_BILLING_PLANS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_BILLING_PLANS_FAILURE,
        message: error,
      });
    }
  };
};
