import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Request from 'api/request';
import get from 'lodash/get';
import {
  doLoginSocial,
  socialLogin,
  socialLoginInprogess,
} from 'store/actions/authActions';
import { useDispatch } from 'react-redux';
import history from 'helpers/history';
import Types from 'store/types/AuthTypes';
import { includes } from 'lodash';

const ButtonSocialLogin = props => {
  const dispatch = useDispatch();
  const { children, isSignUp, ...rest } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      dispatch(socialLoginInprogess());
      setLoading(true);
      const resp = await getLoginSocialCallback(props.social, isSignUp);
      setLoading(false);

      if (resp.status === 1) {
        if (get(resp, 'data.id')) {
          await dispatch(doLoginSocial(resp));
          const redirectL =
            sessionStorage.getItem('redirect_URL') || '/admin/my-documents';
          dispatch(socialLogin(resp.status));
          history.push(redirectL);
          sessionStorage.removeItem('redirect_URL');
        } else {
          const email = get(resp, 'social_data.email');
          const name = get(resp, 'social_data.name');
          const message = get(resp, 'message');
          dispatch(socialLogin(resp.status));
          history.push(
            `/auth/register?email=${email}&name=${name}&message=${message}`
          );
        }
      } else {
        if (resp.errorStatus === 423) {
          const userId = get(resp, 'data.id');
          dispatch(socialLogin(resp.errorStatus));
          history.push({
            pathname: `/auth/invitations/invalid`,
            state: {
              redirectTo: ``,
              btnText: 'Create Your Company',
              userId: userId,
              description:
                'You can create your own Company Account here to get started.',
            },
          });
        } else if (resp.errorStatus === 404) {
          dispatch({
            type: Types.LOGIN_FAILURE,
            status: resp.status,
            message: resp.message,
          });
          dispatch(socialLogin(resp.errorStatus));
          return history.push('/auth/login');
        } else {
          dispatch({
            type: Types.LOGIN_DEACTIVATED,
            user: resp.data,
          });
          return history.push('/auth/login');
        }
      }
    }
    if (
      /\/auth\/login\/(microsoft|google)\/callback/.test(
        props.history.location.pathname
      ) &&
      includes(props.history.location.pathname, props.social)
    ) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location]);

  const getLoginSocialURL = async social => {
    return Request.call({
      url: `/login/${social}`,
      method: 'GET',
    });
  };

  const getLoginSocialCallback = async (social, isSignUp) => {
    return Request.call({
      url: `/login/${social}/callback${props.history.location.search}${
        isSignUp ? '?isSignUp=true' : ''
      }`,
      method: 'GET',
    });
  };

  const handleClick = async e => {
    e.preventDefault();
    setLoading(true);
    const resp = await getLoginSocialURL(props.social);
    setLoading(false);
    const url = get(resp, 'url');
    window.location.href = url;
  };

  return (
    <Button {...rest} loading={loading} onClick={handleClick}>
      {children}
    </Button>
  );
};

ButtonSocialLogin.prototype = {
  social: PropTypes.string,
  history: PropTypes.object,
  isSignUp: PropTypes.bool,
};

ButtonSocialLogin.defaultProps = {
  social: 'google',
  block: true,
  className: '',
  color: 'white',
  isSignUp: false,
  leftIcon: (
    <img alt="google" src={require('assets/img/icons/common/google.svg')} />
  ),
};

export default ButtonSocialLogin;
