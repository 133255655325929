import React from 'react';
import { useDispatch } from 'react-redux';
import { updateStoryEpic } from 'store/actions/backlogs';
import EpicSelect from 'components/EpicSelect';
import analytics, { analyticsConstants } from 'helpers/analytics';

const StoryEpicDropdown = ({
  story,
  sectionId,
  isStoryEditAllowed,
  projectId,
}) => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const handleEpicClick = async epic => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_epic,
      updated_from: 'Project Backlogs',
    });
    await dispatch(updateStoryEpic(story.id, epic, sectionId));
  };

  return (
    <EpicSelect
      id={`epic-${story.id}`}
      onChange={handleEpicClick}
      projectId={projectId}
      selectorPlaceHolder="Search Epics"
      disabled={!isStoryEditAllowed}
      epic={story.epic}
    />
  );
};

export default StoryEpicDropdown;
