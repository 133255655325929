import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { get, orderBy, map } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { Card, CardHeader, CardBody } from 'reactstrap';
import CardsHeader from 'components/Headers/CardsHeader.js';
import BaseTable from 'components/Table';
import Button from 'components/Button';
import {
  fetchUsersList,
  fetchRoles,
  updateUserRoles,
  inviteMembers,
  resendInviteToUser,
  reInviteUser,
  deactivateUser,
  cancelInvite,
} from 'store/actions/users';
import history from 'helpers/history';
import Badge from 'reactstrap/es/Badge';
import Dropdowns from 'components/Dropdowns';
import RoleSelector from 'components/RoleSelector';
import classes from './users.module.scss';
import InviteUserForm from './InviteUser.Form';
import { isAdmin, showUnAuhtorizedError, roles } from 'helpers/permission';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import analytics, { analyticsConstants } from 'helpers/analytics';
import UpgradeAlert from 'components/UpgradeAlert';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';

const Users = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_management,
      ...rest,
    });
  };
  const [isModal, setModal] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [resentUsers, setResentUsers] = useState([]);
  const [loadingFor, setLoadingFor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isAdmin()) {
      showUnAuhtorizedError();
    } else {
      analyticsSendEvent({
        action: analyticsConstants.action.view_user_management,
      });
      dispatch(fetchRoles());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userState } = useSelector(({ user }) => user);
  const loggedInUser = useSelector(({ auth }) => get(auth, 'user.id', null));
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const users = get(userState, 'data.data', []);
  const userMetadata = get(userState, 'data.meta', {});

  const listLoading = get(userState, 'isInProgress', false);

  const NoDataIndication = () => {
    return 'Loading';
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const handleRoleChange = (id, values) => {
    analyticsSendEvent({ action: analyticsConstants.action.update_user_role });
    dispatch(updateUserRoles(id, values));
  };

  const openModal = async () => {
    await setModal(true);
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'user_invitation' &&
      quickStartTour.step === 2
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step: 3,
          })
        );
      }, 1000);
    }
    setEditValues(null);
  };

  const closeModal = () => {
    setModal(false);
    setViewMode('');
    setEditValues(null);
  };

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const submitValues = async values => {
    setIsLoading(true);
    const { email, role } = values;
    const allEmails = email.split(',').filter(f => f.trim());
    const data = {
      roles: [role.name],
    };
    if (viewMode === 're-invite') {
      const { errorStatus } = await dispatch(reInviteUser(editValues.id, data));
      if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
        UpgradeAlert.showTeamLimit();
        return 0;
      }
    } else {
      data.email_addresses = allEmails;
      data.initiatives = map(get(values, 'projects'), 'id');
      analyticsSendEvent({
        action: analyticsConstants.action.invite_users,
        number_users: allEmails.length,
      });

      const { errorStatus } = await dispatch(inviteMembers(data));
      if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
        UpgradeAlert.showTeamLimit();
        return 0;
      }
    }
    setIsLoading(false);
    closeModal();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'user_invitation'
    ) {
      if (onBoardingTour && onBoardingTour.key === 'admin') {
        analytics.sendEvent({
          category: analyticsConstants.category.onboarding,
          action:
            analyticsConstants.action.complete_invite_your_team_onboarding_flow,
        });
      }

      dispatch(
        setUserPreference({
          ...onBoardingTour,
          value: {
            ...get(onBoardingTour, 'value', {}),
            status: 'Active',
            ...(!quickStartTour.isRestarted
              ? {
                  steps: {
                    ...get(onBoardingTour, 'value.steps', {}),
                    [`${quickStartTour.activeTour}`]: {
                      status: 'Completed',
                    },
                  },
                }
              : {}),
          },
        })
      );
      dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
          isRestarted: false,
        })
      );
      setTimeout(() => {
        didAllOnboardingFlowCompleted();
      });
    }
    return 1;
  };

  const handleResendInviteClick = user => async () => {
    setLoadingFor(user.id);
    analyticsSendEvent({
      action: analyticsConstants.action.resend_user_invite,
    });
    await dispatch(resendInviteToUser(user.id));
    setResentUsers([...resentUsers, user.id]);
    setLoadingFor(null);
  };

  const handleReInviteUser = user => () => {
    setModal(true);
    setEditValues({ ...user });
    setViewMode('re-invite');
  };

  const confirmCancelInvite = async user => {
    analyticsSendEvent({
      action: analyticsConstants.action.cancel_user_invite,
    });
    await dispatch(cancelInvite(user.id));
  };

  const handleCancelInvite = user => () => {
    AlertPopupHandler.open({
      onConfirm: confirmCancelInvite,
      confirmBtnText: 'Cancel Invite',
      text:
        'This user will no longer be able to accept the invite and join your company. Do you want to continue?',
      data: user,
    });
  };

  const confirmDeactivateUser = async user => {
    analyticsSendEvent({ action: analyticsConstants.action.deactivate_user });
    await dispatch(deactivateUser(user.id));
  };

  const handleDeactivateUser = user => () => {
    AlertPopupHandler.open({
      onConfirm: confirmDeactivateUser,
      confirmBtnText: 'Deactivate User',
      text: `You are about to remove ${user.name} from your company account. You can still reactivate their account later. Do you want to continue?`,
      data: user,
    });
  };

  const getDropdownOptions = user => {
    switch (user.status.status) {
      case 'Active':
        return [
          {
            text: 'Edit User',
            onClick: () => history.push(`/admin/users/${user.id}/edit`),
          },
          ...(loggedInUser !== user.id
            ? [
                {
                  text: 'Deactivate User',
                  onClick: handleDeactivateUser(user),
                },
              ]
            : []),
        ];
      case 'Deactivated':
        return [
          {
            text: 'Re-Invite User',
            onClick: handleReInviteUser(user),
          },
        ];
      case 'Pending':
        return [
          {
            text: 'Cancel Invite',
            onClick: handleCancelInvite(user),
          },
        ];
      default:
        return [];
    }
  };
  return (
    <>
      {isModal ? (
        <InviteUserForm
          isModalOpen={isModal}
          submitValues={submitValues}
          closeModal={closeModal}
          viewMode={viewMode}
          editValues={editValues}
          quickStartTour={quickStartTour}
          isLoading={isLoading}
        />
      ) : null}
      <div className={classes.users}>
        <CardsHeader name="User Management" parentName="Users" isRoot={true} />
        <div className="px-4">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center w-100">
                <h3 className="mb-0">User List</h3>
                <Button
                  id="inviteUserBtn"
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={openModal}
                >
                  <i className="fas fa-user-plus mr-2" />
                  Invite Members
                </Button>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <BaseTable
                keyField="id"
                defaultSorted={[sortBy]}
                noDataIndication={NoDataIndication}
                bootstrap4
                remote
                bordered={false}
                loading={listLoading}
                paginationOptions={{
                  page: page,
                  totalSize: userMetadata.total,
                }}
                data={users}
                columns={[
                  {
                    dataField: 'name',
                    text: 'Name',
                    sort: true,
                    classes: classNames(
                      classes.userName,
                      classes.detailColumns
                    ),
                    headerClasses: classNames(
                      classes.userName,
                      classes.detailColumns
                    ),
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <Link to={`/admin/users/${row.id}`}>{cell}</Link>
                      </div>
                    ),
                  },
                  {
                    dataField: 'email',
                    text: 'Email',
                    sort: true,
                    classes: classNames(
                      'text-muted',
                      classes.middle,
                      classes.detailColumns
                    ),
                    headerClasses: classNames(
                      'text-muted',
                      classes.middle,
                      classes.detailColumns
                    ),
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <span className={classes.ellipsis}>{cell}</span>
                      </div>
                    ),
                  },
                  {
                    dataField: 'status.status',
                    text: 'Status',
                    sort: true,
                    classes: classNames(
                      'text-muted',
                      classes.middle,
                      classes.statusColumns
                    ),
                    headerClasses: classNames(
                      'text-muted',
                      classes.middle,
                      classes.statusColumns
                    ),
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex w-100 align-items-center">
                        <Badge className="badge-dot m-0 mr-2" color="">
                          <i
                            style={{
                              // TODO: this will be used from API so do not worry about static color
                              backgroundColor: row.status.color,
                            }}
                          />
                        </Badge>
                        {cell}
                      </div>
                    ),
                  },
                  {
                    dataField: 'joined_at',
                    text: 'Joined',
                    sort: true,
                    classes: classNames(
                      'text-muted',
                      classes.middle,
                      classes.statusColumns
                    ),
                    headerClasses: classNames(
                      'text-muted',
                      classes.middle,
                      classes.statusColumns
                    ),
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) =>
                      cell ? (
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          {moment(cell)
                            .tz(userTimezone)
                            .fromNow()}
                        </div>
                      ) : resentUsers.findIndex(u => u === row.id) === -1 ? (
                        <Button
                          color="primary"
                          size="sm"
                          type="button text-muted"
                          disabled={loadingFor === row.id}
                          loading={loadingFor === row.id}
                          onClick={handleResendInviteClick(row)}
                        >
                          Resend Invite
                        </Button>
                      ) : (
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          Invitation resent
                        </div>
                      ),
                  },
                  {
                    dataField: 'roles',
                    text: 'Roles',
                    classes: classNames('text-muted', classes.roleColumn),
                    headerClasses: classNames('text-muted', classes.roleColumn),
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div
                        className={classNames(
                          'd-flex justify-content-between align-items-center',
                          {
                            [classes.applyHeight]:
                              row.status.status === 'Deactivated',
                          }
                        )}
                      >
                        <div className="d-flex justify-content-between w-75 align-items-center">
                          {row.status.status !== 'Deactivated' ? (
                            <RoleSelector
                              key={row.id}
                              isOptionDisabled={option =>
                                loggedInUser === row.id &&
                                option.name === roles.COMPANY_ADMIN[0]
                              }
                              selectedValue={cell[0] ? cell[0] : cell}
                              id={row.id}
                              handleChange={handleRoleChange}
                            />
                          ) : null}
                        </div>
                        {row.status.status !== 'Invitation Expired' ? (
                          <Dropdowns
                            className=" text-light ml-1"
                            text={<i className="fas fa-ellipsis-v" />}
                            size="sm"
                            caret={false}
                            role="button"
                            color=""
                            options={getDropdownOptions(row)}
                          />
                        ) : null}
                      </div>
                    ),
                  },
                ]}
                onTableChange={async (
                  type,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                    searchText,
                    ...rest
                  }
                ) => {
                  if (type === 'pagination') {
                    setPage(page);
                  } else if (type === 'sort') {
                    setPage(1);
                    setSortBy({
                      dataField: sortField,
                      order: sortOrder,
                    });
                  }
                  if (type === 'search') {
                    analyticsSendEvent({
                      action: analyticsConstants.action.search_users,
                    });

                    setPage(1);
                  }
                  const field =
                    sortField === 'status.status' ? 'status' : sortField;
                  const sort = sortOrder === 'desc' ? `-${field}` : field;
                  await dispatch(
                    fetchUsersList(page, sort, searchText, sizePerPage)
                  );
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Users;
