import Types from 'store/types/sprint';
import get from 'lodash/get';
import size from 'lodash/size';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

const initialState = {
  createSprints: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: null,
  },
  fetchSprints: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: null,
    data: {},
  },
  editSprints: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: null,
  },
  deleteSprint: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: null,
  },
  createStories: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: null,
  },
  fetchStories: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: null,
    data: {},
  },
  reorderSprintStories: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  itemsState: {
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateItem: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  selectedStories: {},
  getSprintKanban: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  addColumn: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  editColumn: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  deleteColumn: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  reorderColumns: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
};

export default function SprintReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_SPRINT_KANBAN_INPROGRESS:
      return {
        ...state,
        getSprintKanban: {
          ...state.getSprintKanban,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.GET_SPRINT_KANBAN_SUCCESS:
      return {
        ...state,
        getSprintKanban: {
          ...state.getSprintKanban,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_SPRINT_KANBAN_FAILURE:
      return {
        ...state,
        getSprintKanban: {
          ...state.getSprintKanban,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
          data: action.data ? action.data : state.getSprintKanban.data,
        },
      };

    case Types.CREATE_SPRINT_INPROGRESS:
      return {
        ...state,
        createSprints: {
          ...state.creatSprints,
          isInProgress: true,
        },
      };

    case Types.CREATE_SPRINT_SUCCESS:
      return {
        ...state,
        createSprints: {
          ...state.creatSprints,
          isInProgress: false,
          status: 1,
          message: action.message,
        },
      };

    case Types.CREATE_SPRINT_FAILURE:
      return {
        ...state,
        createSprints: {
          ...state.creatSprints,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_SPRINT_INPROGRESS:
      return {
        ...state,
        fetchSprints: {
          ...state.fetchSprints,
          isInProgress: true,
        },
      };
    case Types.FETCH_SPRINT_SUCCESS:
      return {
        ...state,
        fetchSprints: {
          ...state.fetchSprints,
          isInProgress: false,
          status: 1,
          message: action.message,
          data: action.data,
        },
      };

    case Types.FETCH_SPRINT_FAILURE:
      return {
        ...state,
        fetchSprints: {
          ...state.fetchSprints,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.EDIT_SPRINT_INPROGRESS:
      return {
        ...state,
        editSprints: {
          ...state.editSprints,
          isInProgress: true,
        },
      };

    case Types.EDIT_SPRINT_SUCCESS:
      return {
        ...state,
        editSprints: {
          ...state.editSprints,
          isInProgress: false,
          status: 1,
          message: action.message,
        },
      };

    case Types.EDIT_SPRINT_FAILURE:
      return {
        ...state,
        editSprints: {
          ...state.editSprints,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.CREATE_SPRINT_STORY_INPROGRESS:
      return {
        ...state,
        createStories: {
          ...state.createStories,
          isInProgress: true,
        },
      };

    case Types.CREATE_SPRINT_STORY_SUCCESS:
      return {
        ...state,
        createStories: {
          ...state.createStories,
          isInProgress: false,
          status: 1,
          message: action.message,
        },
      };

    case Types.CREATE_SPRINT_STORY_FAILURE:
      return {
        ...state,
        createStories: {
          ...state.createStories,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_STORY_LIST_INPROGRESS:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sprintID]: {
              ...get(state, `itemsState.data.${action.data.sprintID}`, {}),
              isInProgress: true,
            },
          },
        },
      };

    case Types.FETCH_STORY_LIST_SUCCESS:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sprintID]: {
              ...get(state, `itemsState.data.${action.data.sprintID}`, {}),
              isInProgress: false,
              ...action.data.items,
            },
          },
        },
      };

    case Types.FETCH_STORY_LIST_FAILURE:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sprintID]: {
              ...get(state, `itemsState.data.${action.data.sprintID}`, {}),
              isInProgress: false,
              isError: true,
              message: action.message,
            },
          },
        },
      };

    case Types.DELETE_SPRINT_INPROGRESS:
      return {
        ...state,
        deleteSprint: {
          ...state.deleteSprint,
          isInProgress: true,
        },
      };

    case Types.DELETE_SPRINT_SUCCESS:
      return {
        ...state,
        deleteSprint: {
          ...state.deleteSprint,
          isInProgress: false,
          status: 1,
          message: action.message,
        },
      };

    case Types.DELETE_SPRINT_FAILURE:
      return {
        ...state,
        deleteSprint: {
          ...state.deleteSprint,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.REORDER_SPRINT_STORIES_INPROGRESS:
      return {
        ...state,
        reorderSprintStories: {
          ...state.reorderSprintStories,
          isInProgress: true,
        },
      };
    case Types.REORDER_SPRINT_STORIES_SUCCESS:
      return {
        ...state,
        reorderSprintStories: {
          ...state.reorderSprintStories,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.REORDER_SPRINT_STORIES_FAILURE:
      return {
        ...state,
        reorderSprintStories: {
          ...state.reorderSprintStories,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SORT_ORDER_SPRINT_STATE:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sprintId]: {
              ...get(state, `itemsState.data.${action.data.sprintId}`, {}),
              sorted_list: action.data.items,
            },
          },
        },
      };
    case Types.UPDATE_SPRINT_STORIES_STATE:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sprintId]: {
              ...get(state, `itemsState.data.${action.data.sprintId}`, {}),
              data: action.data.items,
            },
          },
        },
        sectionsState: {
          ...state.sectionsState,
          data: map(get(state, `sectionsState.data`, []), section =>
            toNumber(section.id) === toNumber(action.data.sprintId)
              ? {
                  ...section,
                  stories: size(action.data.items),
                }
              : section
          ),
        },
      };

    case Types.UPDATE_SPRINT_ITEM_INPROGRESS:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          isInProgress: true,
        },
      };
    case Types.UPDATE_SPRINT_ITEM_SUCCESS:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_SPRINT_ITEM_FAILURE:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.CREATE_SPRINT_COLUMN_INPROGRESS:
      return {
        ...state,
        addColumn: {
          ...state.addColumn,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.CREATE_SPRINT_COLUMN_SUCCESS:
      return {
        ...state,
        getSprintKanban: {
          ...state.getSprintKanban,
          data: {
            ...get(state, 'getSprintKanban.data', {}),
            columns: [
              ...get(state, 'getSprintKanban.data.columns', []),
              action.data,
            ],
          },
        },
        addColumn: {
          ...state.addColumn,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.CREATE_SPRINT_COLUMN_FAILURE:
      return {
        ...state,
        addColumn: {
          ...state.addColumn,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.EDIT_SPRINT_COLUMN_INPROGRESS:
      return {
        ...state,
        editColumn: {
          ...state.editColumn,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.EDIT_SPRINT_COLUMN_SUCCESS:
      return {
        ...state,
        getSprintKanban: {
          ...state.getSprintKanban,
          data: {
            ...get(state, 'getSprintKanban.data', {}),
            columns: map(
              get(state, 'getSprintKanban.data.columns', []),
              column => {
                return column.id === action.data.id ? action.data : column;
              }
            ),
          },
        },
        editColumn: {
          ...state.editColumn,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.EDIT_SPRINT_COLUMN_FAILURE:
      return {
        ...state,
        editColumn: {
          ...state.editColumn,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.DELETE_SPRINT_COLUMN_INPROGRESS:
      return {
        ...state,
        deleteColumn: {
          ...state.deleteColumn,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.DELETE_SPRINT_COLUMN_SUCCESS:
      return {
        ...state,
        getSprintKanban: {
          ...state.getSprintKanban,
          data: {
            ...get(state, 'getSprintKanban.data', {}),
            columns: differenceWith(
              get(state, 'getSprintKanban.data.columns', []),
              [action.data],
              isEqual
            ),
          },
        },
        deleteColumn: {
          ...state.deleteColumn,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DELETE_SPRINT_COLUMN_FAILURE:
      return {
        ...state,
        deleteColumn: {
          ...state.deleteColumn,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.REORDER_SPRINT_COLUMN_INPROGRESS:
      return {
        ...state,
        getSprintKanban: {
          ...state.getSprintKanban,
          data: {
            ...get(state, 'getSprintKanban.data', {}),
            columns: action.data,
          },
        },
        reorderColumns: {
          ...state.reorderColumns,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.REORDER_SPRINT_COLUMN_SUCCESS:
      return {
        ...state,
        reorderColumns: {
          ...state.reorderColumns,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.REORDER_SPRINT_COLUMN_FAILURE:
      return {
        ...state,
        reorderColumns: {
          ...state.reorderColumns,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
