import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import compact from 'lodash/compact';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import Types from 'store/types/backlogs';
import StoryTypes from 'store/types/story';
import ComponentTypes from 'store/types/components';
import { getStory, updateStory as updateStoryApi } from 'api/story';
import { fetchStoryPriorities } from 'store/actions/Story/priorities';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { fetchComplexities } from 'store/actions/Story/complexities';
import { fetchScoreMatrix } from 'store/actions/Story/scoreMatrix';

export const assignStoryOwner = (storyId, userId) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.UPDATE_STORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateStoryApi(storyId, { owner_id: userId });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch(updateStoryInSection(storyId, data));
        dispatch({
          type: StoryTypes.UPDATE_STORY_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: StoryTypes.UPDATE_STORY_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: StoryTypes.UPDATE_STORY_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchStory = (storyId, cb = null) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.FETCH_STORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getStory(storyId);
      const status = get(resp, 'status');
      const data = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch(fetchStoryStatuses());
        dispatch(fetchStoryPriorities());
        dispatch(fetchComplexities());
        dispatch(fetchScoreMatrix());
        dispatch({
          type: StoryTypes.FETCH_STORY_SUCCESS,
          data,
        });

        if (cb) cb(data);
      } else {
        dispatch({
          type: StoryTypes.FETCH_STORY_FAILURE,
          message: message,
          id: storyId,
        });
      }

      return { status, data };
    } catch (error) {
      dispatch({
        type: StoryTypes.FETCH_STORY_FAILURE,
        message: error,
        id: storyId,
      });
    }
  };
};

export const updateStory = (storyId, data) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.UPDATE_STORY_INPROGRESS,
    });
    dispatch({
      type: ComponentTypes.REMOVE_MENTION_URL,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateStoryApi(storyId, data);
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch(updateStoryInSection(storyId, responseData));
        dispatch({
          type: StoryTypes.UPDATE_STORY_SUCCESS,
          data: responseData,
        });
      } else {
        dispatch({
          type: StoryTypes.UPDATE_STORY_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: StoryTypes.UPDATE_STORY_FAILURE,
        message: error,
      });
    }
  };
};

export const assignEpicToStory = (storyId, epicId) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.UPDATE_STORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateStoryApi(storyId, {
        epic_id: epicId,
      });
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch(updateStoryInSection(storyId, responseData));
        dispatch({
          type: StoryTypes.UPDATE_STORY_SUCCESS,
          data: responseData,
        });
      } else {
        dispatch({
          type: StoryTypes.UPDATE_STORY_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: StoryTypes.UPDATE_STORY_FAILURE,
        message: error,
      });
    }
  };
};

export const updateStoryPriority = (
  storyId,
  data,
  cb = updateStoryInSection
) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.UPDATE_STORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateStoryApi(storyId, data);
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch(cb(storyId, responseData));
        dispatch({
          type: StoryTypes.UPDATE_STORY_SUCCESS,
          data: responseData,
        });
      } else {
        dispatch({
          type: StoryTypes.UPDATE_STORY_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: StoryTypes.UPDATE_STORY_FAILURE,
        message: error,
      });
    }
  };
};

export const updateSelectedStoryStatus = (
  storyId,
  statusData,
  cb = updateStoryInSection
) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.UPDATE_STORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const data = {
        status_id: statusData ? statusData.id : null,
      };
      const resp = await updateStoryApi(storyId, data);
      const respData = get(resp, 'data');
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch(cb(storyId, respData));
        dispatch({
          type: StoryTypes.UPDATE_STORY_SUCCESS,
          data: respData,
        });
      } else {
        dispatch({
          type: StoryTypes.UPDATE_STORY_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: StoryTypes.UPDATE_STORY_FAILURE,
        message: error,
      });
    }
  };
};

const updateStoryInSection = (storyId, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const sectionData = get(state, 'backlog.itemsState.data', {});
    each(
      compact(
        map(sectionData, (sections, key) =>
          isEmpty(
            filter(
              sections,
              section =>
                find(section, story => story.id === storyId) !== undefined
            )
          )
            ? null
            : key
        )
      ),
      sectionId => {
        const stories = get(
          state,
          `backlog.itemsState.data.${sectionId}.data`,
          []
        );
        const foundIndex = stories.findIndex(s => s.id === storyId);
        dispatch({
          type: Types.UPDATE_STORY_DATA,
          data: {
            items: [
              ...stories.slice(0, foundIndex),
              data,
              ...stories.slice(foundIndex + 1),
            ],
            sectionId,
          },
        });
      }
    );
  };
};
