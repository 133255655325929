import AuthHeader from 'components/Headers/AuthHeader';
import React, { useEffect } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
import * as Yup from 'yup';
// reactstrap components
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import classes from './InviteUsers.module.scss';
// core components
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router-dom';
import BrandLogo from 'assets/img/brand/Lyght_whiteLogo.svg';
import InviteUsersForm from './InviteUsers.Form';

const InviteUsers = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (!get(location, 'state.fromCompany', false)) {
      history.push('/admin/dashboard');
    }
  }, [history, location]);
  useEffect(() => {
    document.getElementById('footer-main').classList.add('d-none');
    document.getElementById('navbar-main').classList.add('d-none');
    return () => {
      document.getElementById('footer-main').classList.remove('d-none');
      document.getElementById('navbar-main').classList.remove('d-none');
    };
  }, []);
  const inviteSchema = Yup.object().shape({
    email_addresses: Yup.array()
      .of(Yup.string().email('Email is invalid'))
      .compact(val => isEmpty(toString(val)))
      .min(1),
  });
  return (
    <>
      <AuthHeader
        title={<img className={classes.brandImage} alt="..." src={BrandLogo} />}
      />
      <Container className={classnames('mt--8 pb-5', classes.root)}>
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={classnames(classes.card, 'border-0')}>
              <CardBody className="px-lg-5 py-lg-5">
                <InviteUsersForm />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InviteUsers;
