import React from 'react';
import { Form } from 'reactstrap';
import className from 'classnames';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import FormModal from 'components/FormFields/FormModal';
import { updateActiveTourStatus } from 'store/actions/profile';

const ClientForm = ({
  viewMode,
  closeModal,
  submitValues,
  isModalOpen,
  editValues,
  quickStartTour,
  loading,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    name: '',
  };

  if (viewMode === 'edit') {
    initialValues = {
      ...initialValues,
      name: editValues.name,
    };
  }

  const ClientSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const handleCloseModal = () => {
    closeModal();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'client_creation'
    ) {
      dispatch(
        updateActiveTourStatus({
          step: 1,
        })
      );
    }
  };

  const RenderForm = ({ handleSubmit, isValid, handleReset }) => {
    return (
      <FormModal
        toggle={handleCloseModal}
        isOpen={isModalOpen}
        id="createClientModal"
        heading={viewMode === 'edit' ? 'Edit Client' : 'Create a Client'}
        submit={{
          id: 'createClientBtn',
          onClick: handleSubmit,
          loading: loading,
          isValid: isValid,
          buttonText: `${
            viewMode === 'edit' ? 'Save Changes' : 'Create Client'
          } `,
        }}
        cancel={{
          onClick: handleCloseModal,
          buttonText: 'Cancel',
        }}
      >
        <Form role="form" onSubmit={handleSubmit}>
          <FormikInput
            inputId="clientNameInput"
            groupClassName="mb-3"
            name="name"
            placeholder="Client Name*"
            type="text"
            onBlur={() => {
              if (isValid) {
                if (
                  quickStartTour &&
                  quickStartTour.activeTour &&
                  quickStartTour.activeTour === 'client_creation' &&
                  quickStartTour.step === 2
                ) {
                  dispatch(
                    updateActiveTourStatus({
                      step: 3,
                    })
                  );
                }
              }
            }}
          />
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={ClientSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        submitValues(values);
        resetForm();
      }}
    >
      {RenderForm}
    </Formik>
  );
};

export default ClientForm;
