import Request from './request';

export const getRecentlyViewedProjects = () => {
  return Request.call({
    url: '/me/dashboard/recent-projects',
    method: 'GET',
  });
};

export const getWeeklyHoursLogged = () => {
  return Request.call({
    url: '/me/dashboard/weekly-hours',
    method: 'GET',
  });
};

export const getOpenAssignments = () => {
  return Request.call({
    url: '/me/dashboard/open-tasks',
    method: 'GET',
  });
};

export const getTimeSpent = async ({
  group = 'week',
  startDate = '',
  endDate = '',
}) => {
  return Request.call({
    url: `/me/dashboard/project-hours?group=${group}&date_from=${startDate}&date_to=${endDate}`,
    method: 'GET',
  });
};

export const getRecentlyViewedStories = async (page = 1) => {
  return Request.call({
    url: '/me/dashboard/recent-stories',
    method: 'GET',
    params: {
      page,
    },
  });
};

export const getDashboardActivities = async page => {
  return Request.call({
    url: `/me/dashboard/activities`,
    method: 'GET',
    params: {
      page,
    },
  });
};
