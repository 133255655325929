import * as React from 'react';
import { goToStripeCheckout, getManagePlanUrl } from 'api/billing';
import cx from 'classnames';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import classes from './billing.module.scss';
import eventEmitter, { EMIT_EVENTS } from 'helpers/eventEmitter';
import Tabs from './Tabs';
import history from 'helpers/history';
import Loading from 'components/Loading';
import { showSupportForm } from 'store/actions/app';
import PlanList from './PlanList';
import { useData } from './useData';
import { showDowngradeErrors, showSuccessAlert } from './helpers';
import analytics, { analyticsConstants } from 'helpers/analytics';

const analyticsActions = analyticsConstants.action;

const Billing = () => {
  const {
    queryParams,
    activeTab,
    companyId,
    isMobile,
    isPro,
    isStarter,
    loadingPlan,
    openingStripe,
    proPlan,
    setActiveTab,
    setLoadingPlan,
    setOpeningStripe,
    shouldShowEnterprisePlan,
    shouldShowProPlan,
    shouldShowStarterPlan,
    starterPlan,
    subId,
  } = useData();
  const dispatch = useDispatch();

  const analyticsSendEvent = action => {
    analytics.sendEvent({
      category: analyticsConstants.category.billing,
      action,
    });
  };

  React.useEffect(() => {
    analyticsSendEvent(analyticsActions.view_billing_settings);
  }, []);

  React.useEffect(() => {
    const onEvent = sub => {
      let msg = '';
      if (sub.plan_name === 'Pro') {
        analyticsSendEvent(analyticsActions.upgrade_to_pro);
        msg = 'Your plan is successfully upgraded to Pro Plan';
      } else if (sub.plan_name === 'Startup') {
        analyticsSendEvent(analyticsActions.downgrade_to_starter);
        msg = "You've switched to the Starter Plan.";
      }
      showSuccessAlert(msg);
      setLoadingPlan('');
    };
    eventEmitter.on(EMIT_EVENTS.SUBSCRIPTION_CHANGE, onEvent);

    return () => {
      eventEmitter.off(EMIT_EVENTS.SUBSCRIPTION_CHANGE, onEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const checkoutProPlan = async () => {
      try {
        setOpeningStripe(true);
        await goToStripeCheckout(companyId, get(proPlan, 'plan_id'), subId);
        // we will remove subscribeToPro query params
        history.replace('/admin/billing');
        setOpeningStripe(false);
      } catch (error) {
        setOpeningStripe(false);
      }
    };
    if (queryParams.subscribeToPro) {
      // this query parameter we are using to directly redirect to stripe pro plan on page load
      checkoutProPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (queryParams.success && queryParams.companyId) {
      // if we get success in query then that means we are redirected from stripe to lyght
      analyticsSendEvent(analyticsActions.upgrade_to_pro);
      showSuccessAlert('Your plan is successfully upgraded to Pro Plan');
      history.replace('/admin/billing');
    }
  }, [queryParams]);

  const getSessionUrl = async (planName, planId) => {
    try {
      setLoadingPlan(planName);
      const res = await goToStripeCheckout(companyId, planId, subId);
      if (res.status === 0) {
        showDowngradeErrors([].concat(res.message), isMobile);
        setLoadingPlan('');
        return;
      }
    } catch (error) {
      setLoadingPlan('');
    }
  };

  const onManagePlan = async () => {
    try {
      analyticsSendEvent(analyticsActions.manage_billing_settings);
      setLoadingPlan('pro');
      const windowRef = window.open('about:blank', '_blank');
      const res = await getManagePlanUrl(companyId);
      if (res.url) {
        windowRef.location = res.url;
      }
      setLoadingPlan('');
    } catch (error) {
      setLoadingPlan('');
    }
  };

  const onContactUs = () => {
    analyticsSendEvent(analyticsActions.initiate_contact_for_enterprise);
    dispatch(showSupportForm(true));
  };

  if (openingStripe) {
    return (
      <div className={classes.mainLoading}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={classes.billing}>
      <div
        className={cx(
          classes.title,
          isPro && classes.leftAligned,
          isMobile && classes.isMobile
        )}
      >
        {!isPro && !isStarter && 'Billing Settings'}
        {isPro && 'Billing Settings'}
        {isStarter && 'Break Free from Limitations. Upgrade to Pro.'}
      </div>
      {isMobile && <Tabs activeTab={activeTab} onChangeTab={setActiveTab} />}
      <PlanList
        loadingPlan={loadingPlan}
        isStarter={isStarter}
        isPro={isPro}
        shouldShowStarterPlan={shouldShowStarterPlan}
        shouldShowProPlan={shouldShowProPlan}
        shouldShowEnterprisePlan={shouldShowEnterprisePlan}
        goToStripe={planName => {
          if (planName === 'starter') {
            analyticsSendEvent(analyticsActions.initiate_downgrade_to_starter);
            getSessionUrl('starter', get(starterPlan, 'plan_id'));
          } else if (planName === 'pro') {
            analyticsSendEvent(analyticsActions.initiate_upgrade_to_pro);
            getSessionUrl('pro', get(proPlan, 'plan_id'));
          }
        }}
        onManagePlan={onManagePlan}
        onContactUs={onContactUs}
      />
    </div>
  );
};

export default Billing;
