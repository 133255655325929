import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Input from 'components/FormFields/Input';
import RSelect from 'components/FormFields/RSelect';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import map from 'lodash/map';
import get from 'lodash/get';
import replace from 'lodash/replace';
import { analyticsConstants } from 'helpers/analytics';

const BasicInformation = ({ onChange, isAllowedEdit, analyticsSendEvent }) => {
  const useGetDetails = field =>
    useGetFieldFromObject('company', `details.data.${field}`);
  const addressLine1 = useGetDetails('address1');
  const addressLine2 = useGetDetails('address2');
  const city = useGetDetails('city');
  const website = useGetDetails('website');
  const phone = useGetDetails('phone');
  const email = useGetDetails('email');
  const extension = useGetDetails('phone_ext');
  const states = useGetFieldFromObject('company', 'states.data', {});
  const zip = useGetDetails('zipcode');
  const statesOptions = map(states, (value, key) => ({
    id: key,
    text: replace(value, /.*\((.*?)\)$/, '$1'),
  }));
  const selectedState = useGetDetails('state');

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Basic Information</h3>
      </CardHeader>
      <CardBody className="px-lg-5">
        <Input
          disabled={!isAllowedEdit}
          label="Address"
          className="mb-3"
          placeholder="Address line 1"
          defaultValue={addressLine1}
          onChange={e => {
            analyticsSendEvent({
              action: analyticsConstants.action.update_company_address,
            });
            onChange('address1', e.target.value);
          }}
        />
        <Input
          disabled={!isAllowedEdit}
          className="mb-3"
          placeholder="Address line 2"
          defaultValue={addressLine2}
          onChange={e => {
            analyticsSendEvent({
              action: analyticsConstants.action.update_company_address,
            });
            onChange('address2', e.target.value);
          }}
        />
        <Row>
          <Col md={6} className="mb-3">
            <Input
              disabled={!isAllowedEdit}
              placeholder="City"
              defaultValue={city}
              onChange={e => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_address,
                });
                onChange('city', e.target.value);
              }}
            />
          </Col>
          <Col md={3} className="mb-3">
            <RSelect
              isClearable={false}
              isDisabled={!isAllowedEdit}
              placeholder="State"
              value={
                selectedState && {
                  ...selectedState,
                  text: replace(selectedState.state, /.*\((.*?)\)$/, '$1'),
                }
              }
              options={statesOptions}
              onChange={selectedOption => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_address,
                });
                onChange('state_id', get(selectedOption, 'id', null));
              }}
            />
          </Col>
          <Col md={3} className="mb-3">
            <Input
              disabled={!isAllowedEdit}
              defaultValue={zip}
              onChange={e => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_address,
                });
                onChange('zipcode', e.target.value);
              }}
              placeholder="ZIP Code"
            />
          </Col>
        </Row>
        <label className="form-control-label">Contact Info</label>
        <Row>
          <Col md={5} className="mb-3">
            <Input
              disabled={!isAllowedEdit}
              placeholder="Primary Phone Number"
              defaultValue={phone}
              onChange={e => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_contact_info,
                });
                onChange('phone', e.target.value);
              }}
            />
          </Col>
          <Col md={2} className="mb-3 pl-0">
            <Input
              disabled={!isAllowedEdit}
              placeholder="Ext."
              defaultValue={extension}
              onChange={e => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_contact_info,
                });
                onChange('phone_ext', e.target.value);
              }}
            />
          </Col>
          <Col md={5} className="mb-3">
            <Input
              disabled={!isAllowedEdit}
              placeholder="Email Address"
              defaultValue={email}
              onChange={e => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_contact_info,
                });
                onChange('email', e.target.value);
              }}
            />
          </Col>
        </Row>
        <Input
          disabled={!isAllowedEdit}
          label="Website"
          placeholder="Company Website"
          defaultValue={website}
          onChange={e => {
            analyticsSendEvent({
              action: analyticsConstants.action.update_company_website,
            });
            onChange('website', e.target.value);
          }}
        />
      </CardBody>
    </Card>
  );
};

export default BasicInformation;
