import React from 'react';
import moment from 'moment';

const CreatedOn = ({ getFieldValue }) => {
  const createdAt = moment(getFieldValue('created_at')).format('M/D/YY');
  return (
    <>
      <h6 className="text-uppercase text-muted">Created on</h6>
      <p>{createdAt}</p>
    </>
  );
};

export default CreatedOn;
