import React from 'react';
import Kanban from 'views/pages/Kanban/Kanban';
import { getGlobalKanbanData } from 'store/actions/kanbanActions';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { permissions } from 'helpers/permission';
import Can from 'components/Can';

const GlobalKanban = () => {
  const dispatch = useDispatch();
  const companyId = useSelector(({ auth }) => get(auth, 'user.company.id'));

  const fetchKanbanData = async (period, keyword) => {
    await dispatch(getGlobalKanbanData(companyId, period, keyword));
  };

  const data = useSelector(({ kanban }) =>
    get(kanban, 'globalKanban.data', {})
  );
  return (
    <Can permissions={[permissions.ACCESS_GLOBAL_KANBAN]}>
      <Kanban
        title="Global Kanban"
        isGlobal
        fetchKanbanData={fetchKanbanData}
        data={data}
      />
    </Can>
  );
};

export default GlobalKanban;
