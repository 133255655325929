import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import isEmpty from 'lodash/isEmpty';
import Input from 'components/FormFields/Input';
import { useClickAway } from 'react-use';
import Button from 'components/Button';
import analytics, { analyticsConstants } from 'helpers/analytics';

const Description = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  defaultValue,
}) => {
  const description = getFieldValue('description', '');
  const [editDescription, setEditDescription] = useState(false);
  const descRef = useRef(null);
  const inputRef = useRef(null);
  const [newDescription, setNewDescription] = useState(description || '');
  useEffect(() => {
    setNewDescription(description || '');
  }, [description]);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.project_dashboard,
      ...rest,
    });
  };
  const changeDescription = () => {
    setEditDescription(false);
    analyticsSendEvent({
      action: analyticsConstants.action.edit_project_overview,
    });
    onChange('projectDescription', newDescription);
  };
  useClickAway(descRef, () => {
    changeDescription();
  });

  return editDescription ? (
    <div ref={descRef}>
      <Input
        type="textarea"
        value={newDescription}
        onChange={e => setNewDescription(e.target.value)}
        rows={5}
        className={classes.inputDescription}
        autoFocus
        innerRef={inputRef}
        onFocus={e => {
          e.target.value = '';
          e.target.value = newDescription;
        }}
      />
      <div className="d-flex flex-row flex-nowrap justify-content-end align-items-center mt-2">
        <Button
          color="link"
          onClick={() => {
            setEditDescription(false);
            setNewDescription(description);
          }}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={changeDescription}>
          Save
        </Button>
      </div>
    </div>
  ) : (
    <div
      className={classnames(
        classes['description'],
        isEditAllowed && classes.hoverHand,
        isEmpty(description) && isEditAllowed
          ? classnames(
              'p-3 text-light',
              classes.emptyDescription,
              classes.emptyContent
            )
          : 'text-justify'
      )}
      onClick={() => {
        if (isEditAllowed) {
          setEditDescription(true);
        }
      }}
    >
      <p>
        {isEmpty(description)
          ? isEditAllowed
            ? 'Add a Description for this project'
            : defaultValue
          : description}
      </p>
    </div>
  );
};

export default Description;
