import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { reorder, move, reassignOrder } from 'helpers/dragDropHelpers';
import queryString from 'query-string';
import useBreakPoint from 'helpers/useBreakPoint';
import classNames from 'classnames';
import get from 'lodash/get';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import StoryModal from './Stories/StoryModal';
import SectionForm from './Section.Form';
import {
  fetchSectionsList,
  deleteStory,
  deleteSection,
  updateStoriesOrder,
  updateItemDetails,
  updateSectionsOrder,
  addSection,
  fetchItemsForSection,
  duplicateStoryForSection,
} from 'store/actions/backlogs';
import { createSprint, updateSprint } from 'store/actions/sprint';
import Input from 'components/FormFields/Input';
import { Card, CardBody, Button } from 'reactstrap';
import { fetchStoryPriorities } from 'store/actions/Story/priorities';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { fetchComplexities } from 'store/actions/Story/complexities';
import { fetchScoreMatrix } from 'store/actions/Story/scoreMatrix';
import {
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import { useLocation, useHistory } from 'react-router';
import Section from './Section';
import { useAccess, permissions } from 'helpers/permission';
import find from 'lodash/find';
import toNumber from 'lodash/toNumber';
import forEach from 'lodash/forEach';
import Types from 'store/types/backlogs';
import isEqual from 'lodash/isEqual';
import { useDebounce } from 'react-use';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import analytics, { analyticsConstants } from 'helpers/analytics';
import Loading from 'components/Loading';
import classes from './Backlogs.module.scss';
import { createStoryQuickAction } from 'store/actions/quickActions';
import CreateStoryForm from 'views/pages/QuickActions/CreateStory/CreateStory.Form';
import DuplicateStoryForm from 'views/pages/Backlogs/Stories/DuplicateStory.Form';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';
import CreateSprint from 'views/pages/QuickActions/CreateSprint';

const Backlogs = ({
  projectId,
  isSectionEditAllowed,
  isSectionDeleteAllowed,
  isAddStoryAllowed,
  isStoryReorderAllowed,
  isStoryEditAllowed,
  isStoryDeleteAllowed,
  isStoryViewAllowed,
  isStoryDuplicateAllowed,
  isSprintEditAllowed,
  isSprintViewAllowed,
  isSprintDeleteAllowed,
  isSprintAddAllowed,
  isAddStoryToSprintAllowed,
}) => {
  const dispatch = useDispatch();
  const analyticsSendEventBacklog = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.backlog,
      ...rest,
    });
  };
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const history = useHistory();
  const isTablet = useBreakPoint('sm', 'down');
  const [openSections, setOpenSections] = useState([]);
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const [isStoryDragging, setIsStoryDragging] = useState(false);
  const [initialDataLoad, setInitialDataLoad] = useState(0);
  const [isCreateStory, setCreateStory] = useState(false);
  const [isCreateSprint, setCreateSprint] = useState(false);
  const [sprintViewMode, setSprintViewMode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);
  const [storyDataToDuplicate, setStoryDataToDuplicate] = useState({});
  const [sprintID, setSprintID] = useState(null);

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const sections = useSelector(
    ({ backlog }) => get(backlog, 'sectionsState.data', []),
    isEqual
  );

  const itemsState = useSelector(
    ({ backlog }) => get(backlog, 'itemsState.data', {}),
    isEqual
  );

  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );

  const priorities = useSelector(({ story }) =>
    get(story, 'priorityOptions.data', [])
  );

  const complexities = useSelector(({ story }) =>
    get(story, 'complexityOptions.data', [])
  );

  const scoreMatrix = useSelector(({ story }) =>
    get(story, 'scoreMatrix.data', [])
  );
  const projectMemberDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data.team_members', [])
  );
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));

  const isUserMemberOfTeam = find(
    projectMemberDetails,
    member => member.id === userId
  );
  const isUserAllowedViewStory = useAccess([
    permissions.VIEW_ALL_STORIES,
    { permission: permissions.VIEW_PROJECT_STORIES, value: isUserMemberOfTeam },
  ]);
  const isUserAllowedSectionReorder = useAccess([
    permissions.REORDER_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.REORDER_MY_PROJECTS_BACKLOG_SECTIONS,
      value: isUserMemberOfTeam,
    },
  ]);
  const isUserAllowedAddSection = useAccess([
    permissions.ADD_SECTIONS_TO_ALL_PROJECTS,
    {
      permission: permissions.ADD_SECTIONS_TO_MY_PROJECTS,
      value: isUserMemberOfTeam,
    },
  ]);

  const isMassUpdateAllowed = useAccess([
    permissions.MASS_UPDATE_STORIES_OF_ALL_SECTIONS,
    {
      permission: permissions.MASS_UPDATE_STORIES_OF_MY_SECTIONS,
      value: isUserMemberOfTeam,
    },
  ]);

  const isMassDeleteAllowed = useAccess([
    permissions.MASS_DELETE_STORIES_OF_ALL_SECTIONS,
    {
      permission: permissions.MASS_DELETE_STORIES_OF_MY_SECTIONS,
      value: isUserMemberOfTeam,
    },
  ]);

  const isCreateStoryAllowedInAllProjects = useAccess(
    permissions.ADD_STORIES_TO_ALL_BACKLOG_SECTIONS
  );
  const isCreateStoryAllowedInMyProjects = useAccess(
    permissions.ADD_STORIES_TO_PROJECT_BACKLOG_SECTIONS
  );
  const loggedInUserID = useSelector(({ auth }) => get(auth, 'user.id', null));

  const projectMembers = useSelector(({ project }) =>
    get(project, `getProject.data.data.team_members`, [])
  );
  const isMemberOfProject =
    projectMembers.findIndex(m => m.id === loggedInUserID) > -1;

  const initialiseOpenSections = useCallback(() => {
    const expandedSections = [];
    forEach(sections, section => {
      if (section.state === 'expand') expandedSections.push(section.id);
    });
    setOpenSections(expandedSections);
  }, [sections]);

  const [isSectionDragging, setIsSectionDragging] = useState(false);

  const getInitialData = () => {
    if (!statuses || !statuses.length) {
      dispatch(fetchStoryStatuses());
    }
    if (!priorities || !Object.keys(priorities).length) {
      dispatch(fetchStoryPriorities());
    }
    if (!complexities || !Object.keys(complexities).length) {
      dispatch(fetchComplexities());
    }
    if (!scoreMatrix || !scoreMatrix.length) {
      dispatch(fetchScoreMatrix());
    }
  };

  const openModal = () => {
    setCreateStory(true);
  };

  const closeModal = () => {
    setCreateStory(false);
  };

  const submitValues = async values => {
    setIsLoading(true);

    const { section, name, project } = values;
    const { data, status } = await dispatch(
      createStoryQuickAction(section.id, { name })
    );
    if (sections.findIndex(s => s.id === section.id) > -1) {
      dispatch(fetchItemsForSection(section.id));
    }
    closeModal();
    if (status) {
      history.push(`/admin/projects/${project.id}/backlog/?story=${data.id}`);
    }
    setIsLoading(false);
  };

  const sprintSubmitValues = async values => {
    setIsLoading(true);
    if (sprintViewMode === 'edit') {
      const { name, date_start, date_end } = values;
      const updatedValues = {
        name,
        date_start,
        date_end,
      };
      await dispatch(updateSprint(projectId, sprintID, updatedValues));
      handleCreateModal();
    } else {
      await dispatch(createSprint(projectId, values));
      handleCreateModal();
    }
    setIsLoading(false);
  };

  const handleEditMode = id => () => {
    setSprintViewMode('edit');
    setSprintID(id);
    setCreateSprint(true);
  };

  const handleCreateModal = () => {
    setSprintViewMode(null);
    setSprintID(null);
    setCreateSprint(false);
  };

  useEffect(() => {
    analyticsSendEventBacklog({
      action: analyticsConstants.action.view_project_backlog,
    });
    getInitialData();
    initialiseOpenSections();
    dispatch({ type: Types.DESELECT_ALL_STORIES });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => initialiseOpenSections(), [initialiseOpenSections, sections]);

  useEffect(() => {
    if (queryProps.story) {
      analyticsSendEventStory({
        action: analyticsConstants.action.open_story,
        opened_from: 'Project Backlog',
      });
      setIsStoryModalOpen(true);
      // dispatch(fetchStoryAction(queryProps.story));
    }
  }, [queryProps.story]);
  const [sectionSearchTerm, setSectionSearchTerm] = useState('');

  useDebounce(
    async () => {
      if (initialDataLoad === 0) {
        await dispatch(fetchSectionsList(projectId, true, sectionSearchTerm));
        setInitialDataLoad(currentLoad => currentLoad + 1);
        if (
          quickStartTour &&
          quickStartTour.activeTour &&
          quickStartTour.activeTour === 'story_creation' &&
          (quickStartTour.step === 5 || quickStartTour.nextStep === 6)
        ) {
          dispatch(
            updateActiveTourStatus({
              step: 6,
            })
          );
        }
      } else dispatch(fetchSectionsList(projectId, true, sectionSearchTerm));
    },
    500,
    [sectionSearchTerm]
  );

  useDebounce(
    () => {
      if (!isEmpty(sectionSearchTerm))
        analyticsSendEventBacklog({
          action: analyticsConstants.action.search_project_backlog,
        });
    },
    1000,
    [sectionSearchTerm]
  );

  const toggleSections = id => () => {
    dispatch(
      setUserPreference({
        category: 'backlog',
        key: `section_${id}_state`,
        value: openSections.includes(id) ? 'collapse' : 'expand',
      })
    );
    dispatch({
      type: Types.FETCH_BACKLOG_SECTIONS_SUCCESS,
      data: map(sections, section =>
        section.id === id
          ? {
              ...section,
              state: openSections.includes(id) ? 'collapse' : 'expand',
            }
          : section
      ),
    });
  };

  const confirmDelete = async data => {
    analyticsSendEventStory({
      action: analyticsConstants.action.delete_story,
    });
    if (data.isSprint) {
      await dispatch(
        deleteStory(data.id, data.section_id, data.isSprint, data.projectId)
      );
    } else {
      await dispatch(deleteStory(data.id, data.section_id));
    }
  };

  const fetchStory = id => {
    if (isUserAllowedViewStory) {
      history.push(`?story=${id}`);
    }
  };

  const deleteAction = (row, sectionId, isSprint, projectId) => () => {
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Story',
      text: `You are about to delete this story. Do you want to continue?`,
      data: { ...row, section_id: sectionId, isSprint, projectId },
    });
  };

  const reorderBacklogDetails = ({ type, ...rest }) => {
    switch (type) {
      case 'backlog-stories':
        analyticsSendEventBacklog({
          action: analyticsConstants.action.drag_backlog_story,
        });
        reorderStories(rest);
        setIsStoryDragging(false);
        break;
      case 'sections-container':
        reorderSections(rest);
        setIsSectionDragging(false);
        initialiseOpenSections();
        break;
    }
  };

  const closeDuplicateModal = () => {
    setDuplicateModal(false);
    setStoryDataToDuplicate({});
  };

  const duplicateStoryClick = data => {
    setDuplicateModal(true);
    setStoryDataToDuplicate(data);
  };

  const reorderSections = ({ destination, source }) => {
    const destinationIndex = get(destination, 'index');
    const sourceIndex = get(source, 'index');
    if (destination && destinationIndex !== sourceIndex) {
      analyticsSendEventBacklog({
        action: analyticsConstants.action.drag_backlog_section,
      });
      dispatch(
        updateSectionsOrder(
          projectId,
          reassignOrder(reorder(sections, sourceIndex, destinationIndex))
        )
      );
    }
  };

  const reorderStories = ({ destination, source }) => {
    const destinationIndex = get(destination, 'index');
    const sourceIndex = get(source, 'index');
    const destinationSection = get(destination, 'droppableId');
    const sourceSection = get(source, 'droppableId');
    if (
      destination &&
      !(
        destinationIndex === sourceIndex && destinationSection === sourceSection
      )
    ) {
      const sourceStories = get(itemsState, [sourceSection, 'data'], []);
      const sourceSectionSortedList = get(
        itemsState,
        [sourceSection, 'sorted_list'],
        []
      );
      const destinationStories = get(
        itemsState,
        [destinationSection, 'data'],
        []
      );
      const destinationSectionSortedList = get(
        itemsState,
        [destinationSection, 'sorted_list'],
        []
      );
      const indexCalc =
        isEmpty(sectionSearchTerm) ||
        (sourceIndex < destinationIndex && sourceSection === destinationSection)
          ? 0
          : 1;
      const actualDestinationIndex =
        destinationIndex && destinationIndex - indexCalc;

      const existingStory = get(destinationStories, actualDestinationIndex, {});
      const existingStoryIndex = findIndex(
        destinationSectionSortedList,
        story => story.id === existingStory.id
      );
      const droppedStory = get(sourceStories, sourceIndex);
      const droppedStoryIndex = findIndex(
        sourceSectionSortedList,
        story => story.id === droppedStory.id
      );
      if (destinationSection === sourceSection) {
        dispatch(
          updateStoriesOrder(
            destinationSection,
            reassignOrder(
              reorder(
                destinationSectionSortedList,
                droppedStoryIndex,
                existingStoryIndex + (destinationIndex && indexCalc)
              )
            ),
            reassignOrder(
              reorder(destinationStories, sourceIndex, destinationIndex)
            )
          )
        );
      } else {
        const isDroppedSectionOpen = includes(
          openSections,
          toNumber(destinationSection)
        );
        const sectionChange = dispatch(
          updateItemDetails(droppedStory.id, {
            backlog_section_id: destinationSection,
          })
        );
        const { sourceClone, destClone } = move(
          sourceSectionSortedList,
          destinationSectionSortedList,
          droppedStoryIndex,
          isDroppedSectionOpen
            ? existingStoryIndex +
                (!isEmpty(sectionSearchTerm) && destinationIndex && 1)
            : destinationSectionSortedList.length
        );
        const {
          sourceClone: newSourceOrder,
          destClone: newDestinationOrder,
        } = move(
          sourceStories,
          destinationStories,
          sourceIndex,
          isDroppedSectionOpen ? destinationIndex : destinationStories.length
        );
        const reassignedSourceOrder = reassignOrder(newSourceOrder);
        const reassignedDestinationOrder = reassignOrder(newDestinationOrder);
        dispatch({
          type: Types.UPDATE_STORIES_STATE,
          data: {
            sectionId: sourceSection,
            items: reassignedSourceOrder,
          },
        });
        dispatch({
          type: Types.UPDATE_STORIES_STATE,
          data: {
            sectionId: destinationSection,
            items: reassignedDestinationOrder,
          },
        });
        sectionChange.then(() => {
          dispatch(
            updateStoriesOrder(
              destinationSection,
              reassignOrder(destClone),
              reassignedDestinationOrder
            )
          );
          dispatch(
            updateStoriesOrder(
              sourceSection,
              reassignOrder(sourceClone),
              reassignedSourceOrder
            )
          );
        });
      }
    }
  };

  const isUserAllowedDeleteStory = useAccess([
    permissions.DELETE_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.DELETE_PROJECT_BACKLOG_SECTIONS,
      value: isUserMemberOfTeam,
    },
  ]);

  const deleteSectionAction = sectionId => {
    const sectionDetails = find(sections, section => section.id === sectionId);
    const storiesInSection = get(sectionDetails, 'stories');
    if (storiesInSection !== 0) {
      AlertPopupHandler.open({
        onConfirm: () => {},
        showCancel: false,
        title: 'Sorry, you can’t do that',
        text: `Please remove all of the stories from the ${
          sectionDetails.sprint ? 'sprint' : 'section'
        } before deleting.`,
      });
    } else {
      AlertPopupHandler.open({
        onConfirm: id => {
          analyticsSendEventBacklog({
            action: analyticsConstants.action.delete_backlog_section,
          });
          dispatch(deleteSection(id));
        },
        text: `You are about to delete this ${
          sectionDetails.sprint ? 'sprint' : 'section'
        }. Do you want to continue?`,
        data: sectionId,
        confirmBtnText: sectionDetails.sprint
          ? 'Delete Sprint'
          : 'Delete Section',
      });
    }
  };

  const handleDuplicateStory = async values => {
    setDuplicating(true);
    const { data: duplicatedStory, status, errorStatus } = await dispatch(
      duplicateStoryForSection(storyDataToDuplicate.id, values, null)
    );

    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showStorageLimit();
      return;
    }
    if (status) {
      dispatch(fetchSectionsList(duplicatedStory.initiative.id));
    }
    setDuplicating(false);
    setDuplicateModal(false);
    setStoryDataToDuplicate({});
  };

  return (
    <>
      {isStoryModalOpen && (
        <StoryModal
          storyId={toNumber(queryProps.story)}
          projectId={toNumber(projectId)}
          isOpen={isStoryModalOpen}
          closeModal={() => {
            history.replace(history.location.pathname);
            setIsStoryModalOpen(false);
            if (
              quickStartTour &&
              quickStartTour.activeTour &&
              quickStartTour.activeTour === 'story_creation' &&
              quickStartTour.step === 8
            ) {
              dispatch(
                updateActiveTourStatus({
                  step: 7,
                })
              );
            }
          }}
          onTabChange={tab =>
            history.push(`?story=${queryProps.story}&tab=${tab}`)
          }
          comment={get(queryProps, 'comment')}
          currentTab={get(queryProps, 'tab')}
          openDuplicateModal={duplicateStoryClick}
        />
      )}
      {isAddSectionOpen && (
        <SectionForm
          isModalOpen={isAddSectionOpen}
          submitValues={values => {
            analyticsSendEventBacklog({
              action: analyticsConstants.action.add_backlog_section,
            });

            dispatch(addSection(projectId, values));
            setIsAddSectionOpen(false);
          }}
          closeModal={() => {
            setIsAddSectionOpen(false);
          }}
          viewMode="create"
        />
      )}
      {isCreateStory ? (
        <CreateStoryForm
          isModalOpen={isCreateStory}
          closeModal={closeModal}
          submitValues={submitValues}
          isLoading={isLoading}
        />
      ) : null}
      {isCreateSprint ? (
        <CreateSprint
          isLoading={isLoading}
          isOpen={isCreateSprint}
          closeModal={handleCreateModal}
          submitValues={sprintSubmitValues}
          viewMode={sprintViewMode}
          sprintValues={
            sprintID
              ? sections.find(section => section.sprint.id === sprintID).sprint
              : {}
          }
        />
      ) : null}
      {isDuplicateModal ? (
        <DuplicateStoryForm
          storyData={storyDataToDuplicate}
          isModalOpen={isDuplicateModal}
          closeModal={closeDuplicateModal}
          submitValues={handleDuplicateStory}
          isLoading={isDuplicating}
        />
      ) : null}
      <div
        className={classNames('w-100 pr-4 h-100', {
          'pl-4': isTablet,
        })}
      >
        {initialDataLoad === 0 ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <>
            <Card>
              <CardBody
                className={classNames(
                  'd-flex px-4 py-3',
                  classes.sectionSearch
                )}
              >
                <div className={classNames('d-flex', classes.searchWrapper)}>
                  <span className="m-0">Search:</span>
                  <Input
                    bsSize="sm"
                    className="mx-2"
                    value={sectionSearchTerm}
                    onChange={event => setSectionSearchTerm(event.target.value)}
                  />
                </div>
                <div className={classNames('d-flex', classes.actionWrapper)}>
                  {isUserAllowedAddSection && (
                    <Button
                      className={classNames('ml-3', classes.createSprintButton)}
                      size="sm"
                      onClick={() => setCreateSprint(true)}
                    >
                      Create Sprint
                    </Button>
                  )}
                  {isCreateStoryAllowedInAllProjects ||
                  (isCreateStoryAllowedInMyProjects && isMemberOfProject) ? (
                    <Button
                      className="ml-3"
                      color="success"
                      size="sm"
                      onClick={openModal}
                    >
                      Create Story
                    </Button>
                  ) : null}
                  {isUserAllowedAddSection && (
                    <Button
                      className="ml-3"
                      color="primary"
                      size="sm"
                      onClick={() => setIsAddSectionOpen(true)}
                    >
                      Add Section
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
            {isEmpty(sections) && !isEmpty(sectionSearchTerm) ? (
              <div className="d-flex justify-content-center align-items-center text-xl font-weight-bold text-dark">
                No stories match this filter
              </div>
            ) : (
              <DragDropContext
                onBeforeCapture={({ draggableId }) => {
                  if (startsWith(draggableId, 'section')) {
                    setIsSectionDragging(true);
                    setOpenSections([]);
                  } else {
                    setIsStoryDragging(true);
                  }
                }}
                onDragEnd={reorderBacklogDetails}
              >
                <Droppable
                  droppableId="all-sections"
                  type="sections-container"
                  className="h-100"
                >
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <div className={classes.sectionMargin}>
                        {sections.map(
                          ({ name, id, stories, sprint }, index) => {
                            return (
                              <Section
                                key={id}
                                id={id}
                                isFirstSection={index === 0}
                                isLastSection={index === sections.length - 1}
                                openSections={openSections}
                                toggleSections={toggleSections}
                                name={name}
                                sprint={sprint}
                                editMode={handleEditMode(sprint?.id)}
                                storiesCount={stories}
                                fetchStoryAction={fetchStory}
                                deleteAction={deleteAction}
                                reorderStories={reorderStories}
                                projectId={projectId}
                                isSectionEditAllowed={isSectionEditAllowed}
                                deleteSectionAction={
                                  isSectionDeleteAllowed && deleteSectionAction
                                }
                                isAddStoryAllowed={isAddStoryAllowed}
                                isStoryReorderAllowed={isStoryReorderAllowed}
                                isStoryEditAllowed={isStoryEditAllowed}
                                isStoryDeleteAllowed={isStoryDeleteAllowed}
                                isStoryViewAllowed={isStoryViewAllowed}
                                isStoryDuplicateAllowed={
                                  isStoryDuplicateAllowed
                                }
                                isMassUpdateAllowed={isMassUpdateAllowed}
                                isMassDeleteAllowed={isMassDeleteAllowed}
                                index={index}
                                isSectionDragging={isSectionDragging}
                                disableStoryToolTip={isStoryDragging}
                                isUserAllowedSectionReorder={
                                  isUserAllowedSectionReorder &&
                                  isEmpty(sectionSearchTerm)
                                }
                                analyticsConstants={analyticsConstants}
                                analyticsSendEvent={analyticsSendEventBacklog}
                                analyticsSendEventStory={
                                  analyticsSendEventStory
                                }
                                duplicateStoryClick={duplicateStoryClick}
                                isSprintEditAllowed={isSprintEditAllowed}
                                isSprintViewAllowed={isSprintViewAllowed}
                                isSprintDeleteAllowed={isSprintDeleteAllowed}
                                isSprintAddAllowed={isSprintAddAllowed}
                                isAddStoryToSprintAllowed={
                                  isAddStoryToSprintAllowed
                                }
                                isMemberOfProject={isMemberOfProject}
                              />
                            );
                          }
                        )}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Backlogs;
