import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TimeLoggedGraph from 'views/widgets/TimeLoggedGraph';
import { getTimeSpent } from 'store/actions/clientProfile';
import classes from './ClientProfile.module.scss';
import get from 'lodash/get';

const TimeLoggedByClient = ({ clientId }) => {
  const dispatch = useDispatch();
  const data = useSelector(({ clientProfile }) =>
    get(clientProfile, 'timeLogGraph.data', {})
  );
  const loading = useSelector(({ clientProfile }) =>
    get(clientProfile, 'timeLogGraph.isInProgress', false)
  );

  return (
    <div className={classes.timeSpentChart}>
      <TimeLoggedGraph
        data={data}
        timePeriod="week"
        loading={loading}
        onChange={async (timePeriod, startTime, endTime) => {
          await dispatch(
            getTimeSpent(clientId, timePeriod, startTime, endTime)
          );
        }}
        fieldName="project"
      />
    </div>
  );
};

export default TimeLoggedByClient;
