import React, { useState, useRef, useEffect } from 'react';
import startTimerIcon from 'assets/img/icons/start-timer.svg';
import stopTimerIcon from 'assets/img/icons/stop-timer.svg';
import historyTimerIcon from 'assets/img/icons/history-timer.svg';
import projectIcon from 'assets/img/icons/project.svg';
import classes from './Timer.module.scss';

import RSelectAsync from 'components/FormFields/RSelectAsync';
import { useSelector, useDispatch } from 'react-redux';
import { doPostStartTimer } from 'store/actions/timerActions';
import Types from 'store/types/timerTypes';
import analytics, { analyticsConstants } from 'helpers/analytics';
import TimerModal from 'components/TimerModal';

import {
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Dropdown,
} from 'reactstrap';
import TimerRecentTimeLogs from 'components/TimerRecentTimeLogs';
import RunningTime from './RunningTime';
import get from 'lodash/get';
import moment from 'moment-timezone';
import useClickAway from 'react-use/lib/useClickAway';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import { useLocation } from 'react-router';

const Timer = () => {
  const dispatch = useDispatch();

  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const runningTime = useSelector(({ timer }) => get(timer, 'runningTime'));
  const projectDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data', {})
  );
  const currentRunningTime = useSelector(({ timer }) =>
    get(timer, 'currentRunningTime.data')
  );
  const currentProject = useSelector(({ timer }) =>
    get(timer, 'currentProject')
  );

  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(null);
  const [entryId, setEntryId] = useState(null);
  const location = useLocation();

  const [isOpenRecenTime, setIsOpenRecenTime] = useState(false);
  const RecentEntryRef = useRef(null);
  const RecentEntryCompRef = useRef();

  useClickAway(RecentEntryRef, e => {
    if (isOpen === false && AlertPopupHandler.isOpen() === false)
      setIsOpenRecenTime(false);
  });
  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.time_entries,
      ...rest,
    });
  };

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const handleClickTimerButton = async () => {
    if (runningTime === null) {
      analyticsSendEvent({ action: analyticsConstants.action.start_timer });
      dispatch(doPostStartTimer());
      /*
        If user is inside projects view, then start timer for that project
        so setting the current project based on project user is checking out
       */
      if (
        location.pathname.indexOf('/admin/projects') > -1 &&
        location.pathname.length > `/admin/projects`.length
      ) {
        dispatch({
          type: Types.SET_CURRENT_PROJECT,
          data: { id: projectDetails.id, text: projectDetails.name },
        });
      }
      if (
        quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'time_logging' &&
        quickStartTour.step === 0
      ) {
        dispatch(
          updateActiveTourStatus({
            step: 1,
          })
        );
      }
    } else {
      setMode('add');
      dispatch({
        type: Types.SET_END_RUNNINGTIME,
        data: moment()
          .tz(timezone)
          .format(),
      });
      setEntryId(get(currentRunningTime, 'id'));
      await toggle();
      if (
        quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'time_logging' &&
        quickStartTour.step === 1
      ) {
        dispatch(
          updateActiveTourStatus({
            step: 2,
          })
        );
      }
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!runningTime) setIsOpen(false);
  }, [runningTime]);

  const onTimerModalSuccess = () => {
    if (RecentEntryCompRef.current) RecentEntryCompRef.current.reload();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'time_logging' &&
      quickStartTour.step === 2
    ) {
      dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
        })
      );
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action.complete_make_a_time_entry_onboarding_flow,
      });
      if (quickStartTour) {
        dispatch(
          setUserPreference({
            ...onBoardingTour,
            value: {
              ...get(onBoardingTour, 'value', {}),
              status: 'Active',
              steps: {
                ...get(onBoardingTour, 'value.steps', {}),
                time_logging: {
                  status: 'Completed',
                },
              },
            },
          })
        );
        didAllOnboardingFlowCompleted();
      }
    }
    toggle();
  };

  return (
    <div className={classes.root}>
      <Nav
        className="align-items-center ml-md-auto mr-2"
        navbar
        id="topTimerInput"
      >
        <NavItem className="">
          <img
            className={classes['timer-btn']}
            alt="Timer Button"
            src={runningTime ? stopTimerIcon : startTimerIcon}
            onClick={handleClickTimerButton}
          />
        </NavItem>
        <NavItem className="">
          <RunningTime />
        </NavItem>
        <Dropdown
          nav
          className="recent-timelogs"
          isOpen={isOpenRecenTime}
          toggle={() => {
            analyticsSendEvent({
              action: analyticsConstants.action.view_recent_time_entries,
            });
            setIsOpenRecenTime(true);
          }}
        >
          <DropdownToggle className="nav-link" color="" tag="a">
            <img
              className={classes['history-btn']}
              alt="History"
              src={historyTimerIcon}
            />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-xl py-0 overflow-hidden"
            left="true"
          >
            <div ref={RecentEntryRef}>
              {isOpenRecenTime ? (
                <TimerRecentTimeLogs
                  ref={RecentEntryCompRef}
                  toggle={(mode, id = null) => {
                    setMode(mode);
                    setEntryId(id);
                    toggle();
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </DropdownMenu>
        </Dropdown>
      </Nav>
      {runningTime && (
        <div className={classes.selectProject}>
          <RSelectAsync
            className={classes.selectProject}
            url="/timelogs/initiatives/search"
            getOptionLabel={option => option.name}
            placeholder="Select Project"
            leftIcon={<img src={projectIcon} alt="Project" />}
            onChange={data => {
              let selected = null;
              if (data)
                selected = {
                  id: get(data, 'id', ''),
                  text: get(data, 'name', ''),
                };

              dispatch({
                type: Types.SET_CURRENT_PROJECT,
                data: selected,
              });
            }}
            value={
              currentProject
                ? {
                    id: currentProject.id,
                    name: currentProject.text,
                  }
                : null
            }
          />
        </div>
      )}

      {isOpen && (
        <TimerModal
          entryId={entryId}
          isOpen={isOpen}
          toggle={toggle}
          mode={mode}
          source="Recent Time Entries"
          success={onTimerModalSuccess}
        />
      )}
    </div>
  );
};

export default Timer;
