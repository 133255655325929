import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import pluralize from 'pluralize';
import toNumber from 'lodash/toNumber';
import classes from 'views/pages/dashboards/Dashboard.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QuickView from 'views/widgets/QuickView';

const OpenAssigments = ({ getDetails }) => {
  const openAssignments = getDetails('openAssignments.data.count', 0);
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const history = useHistory();

  return (
    <QuickView
      heading="Open Assignments"
      content={pluralize('Assignment', toNumber(openAssignments), true)}
      iconBg="purple"
      icon="fas fa-book-open"
      footer={
        <p
          className={classNames(classes.hoverHand)}
          onClick={() => {
            history.push(`/admin/users/${userId}/kanban`);
          }}
        >
          View Kanban
          <i className="fas fa-arrow-right ml-2 text-xs" />
        </p>
      }
    />
  );
};

OpenAssigments.propTypes = {
  getDetails: PropTypes.func,
};

export default OpenAssigments;
