import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import CardsHeader from 'components/Headers/CardsHeader';
import { Card, CardHeader, CardBody } from 'reactstrap';
import ProjectForm from './Project.Form';
import classes from './projects.module.scss';
import Button from 'components/Button';
import {
  createProject,
  editProject as editProjectAction,
} from 'store/actions/projects';
import Types from 'store/types/projects';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import { updateActiveTourStatus } from 'store/actions/profile';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
} from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';
import UpgradeAlert from 'components/UpgradeAlert';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import ProjectsTable from 'views/pages/Project/ProjectsTable';

const Projects = () => {
  const dispatch = useDispatch();
  if (!useAccess([permissions.LIST_PROJECTS])) {
    showUnAuhtorizedError();
  }

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'desc',
  });
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.projects,
      ...rest,
    });
  };
  const getProjectLoading = useGetFieldFromObjects(
    'project',
    'getProject.isInProgress',
    false
  );

  const [showProjectForm, setShowProjectForm] = useState(false);
  const [viewMode, setViewMode] = useState(null);

  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_projects_list,
    });
  }, []);

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const handleProjectOnboardingCB = () => {
    if (
      (quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'project_creation' &&
        quickStartTour.step === 3) ||
      ((quickStartTour.activeTour === 'invite_team' ||
        quickStartTour.activeTour === 'story_creation') &&
        quickStartTour.step === 4)
    ) {
      dispatch(
        updateActiveTourStatus({
          step:
            quickStartTour.activeTour === 'invite_team' ||
            quickStartTour.activeTour === 'story_creation'
              ? 5
              : 4,
        })
      );
    }
  };
  const submitValues = async values => {
    setIsLoading(true);
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    if (viewMode === 'edit') {
      analyticsSendEvent({ action: analyticsConstants.action.edit_project });
      await dispatch(
        editProjectAction(values.projectId, values, { page, sort, q: '' })
      );
      setViewMode(null);
    } else {
      const { errorStatus } = await dispatch(
        createProject(
          values,
          { page, sort, q: '' },
          true,
          handleProjectOnboardingCB
        )
      );
      if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
        UpgradeAlert.showProjectsLimit();
        setIsLoading(false);
        return;
      }
      analyticsSendEvent({ action: analyticsConstants.action.create_project });
    }
    setIsLoading(false);
    setShowProjectForm(false);
  };
  const addProject = () => {
    setShowProjectForm(true);
    if (
      (quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'project_creation' &&
        quickStartTour.step === 1) ||
      ((quickStartTour.activeTour === 'invite_team' ||
        quickStartTour.activeTour === 'story_creation') &&
        quickStartTour.step === 2)
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step:
              quickStartTour.activeTour === 'invite_team' ||
              quickStartTour.activeTour === 'story_creation'
                ? 3
                : 2,
          })
        );
      }, 100);
    }
  };

  const closeProjectFormModal = () => {
    setShowProjectForm(false);
    setViewMode(null);
    dispatch({ type: Types.GET_PROJECT_RESET });
  };

  const isUserAllowedCreateProjects = useAccess(permissions.CREATE_PROJECTS);

  return (
    <>
      {showProjectForm && !getProjectLoading && (
        <ProjectForm
          isModalOpen={showProjectForm && !getProjectLoading}
          submitValues={submitValues}
          closeModal={closeProjectFormModal}
          viewMode={viewMode}
          quickStartTour={quickStartTour}
          loading={isLoading}
        />
      )}
      <div className={classes.projects}>
        <CardsHeader name="Projects" parentName="Projects" isRoot={true} />
        <div className="px-4" id="projectsList">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center w-100">
                <h3 className="mb-0">Project List</h3>
                {isUserAllowedCreateProjects && (
                  <Button
                    color="primary"
                    size="sm"
                    type="button"
                    onClick={addProject}
                    id="addProjectBtn"
                  >
                    Add Project
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <ProjectsTable
                page={page}
                sortBy={sortBy}
                isUserAllowedCreateProjects={isUserAllowedCreateProjects}
                setPage={setPage}
                setSortBy={setSortBy}
                analyticsSendEvent={analyticsSendEvent}
                setShowProjectForm={setShowProjectForm}
                setViewMode={setViewMode}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Projects;
