import React, { useRef, useEffect } from 'react';
import { Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import FormModal from 'components/FormFields/FormModal';

const SectionForm = ({
  viewMode,
  closeModal,
  submitValues,
  isModalOpen,
  editValues,
}) => {
  const inputRef = useRef(null);
  let initialValues = {
    name: '',
  };

  if (viewMode === 'edit') {
    initialValues = {
      ...initialValues,
      name: editValues.name,
    };
  }

  const SectionSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  useEffect(() => {
    setTimeout(() => inputRef.current.focus(), 50);
  }, []);

  const renderForm = ({ handleSubmit, isValid, handleReset, values }) => {
    return (
      <FormModal
        size="sm"
        toggle={closeModal}
        isOpen={isModalOpen}
        heading={viewMode === 'edit' ? 'Rename Section' : 'Create a Section'}
        submit={{
          onClick: handleSubmit,
          isValid: isValid && initialValues.name !== values.name,
          buttonText: `${
            viewMode === 'edit' ? 'Save Changes' : 'Create Section'
          } `,
        }}
        cancel={{
          onClick: () => {
            handleReset();
            closeModal();
          },
          buttonText: 'Cancel',
        }}
      >
        <Form role="form" onSubmit={handleSubmit}>
          <FormikInput
            groupClassName="mb-3"
            name="name"
            placeholder="Section Name*"
            type="text"
            innerRef={inputRef}
          />
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={SectionSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        submitValues(values);
        resetForm();
      }}
    >
      {renderForm}
    </Formik>
  );
};

export default SectionForm;
