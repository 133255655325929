import React, { useState } from 'react';
import cs from 'classnames';
import classes from '../QuickActions.module.scss';
import ClientForm from 'views/pages/Clients/Client.Form';
import { useDispatch } from 'react-redux';
import { createClient } from 'store/actions/clients';
import history from 'helpers/history';
import get from 'lodash/get';

const CreateClient = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={classes.actionBox}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div className={cs(classes.box, classes.createClient)}>
          <i className="fas fa-handshake text-primary text-xl"></i>
          <h2>Create Client</h2>
        </div>
      </div>
      {showModal && (
        <ClientForm
          isModalOpen={showModal}
          loading={isSubmitting}
          submitValues={async values => {
            setIsSubmitting(true);
            const response = await dispatch(createClient(values));
            if (get(response, 'status')) {
              history.push(`/admin/clients/${get(response, 'data.id')}`, {
                shouldOpenPopup: true,
              });
              setShowModal(false);
            }
            setIsSubmitting(false);
          }}
          closeModal={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default CreateClient;
