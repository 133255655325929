import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './SelectAsync.module.scss';
import cs from 'classnames';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import useClickAway from 'react-use/lib/useClickAway';
import customStyles from 'assets/scss/custom/_variables.scss';
import get from 'lodash/get';
import omit from 'lodash/omit';
const SelectAsync = ({
  onChange,
  disabled,
  id,
  children,
  className,
  selectorClassName,
  isClearable,
  placeholder,
  getOptionValue,
  getOptionLabel,
  url,
  popperClassName,
  styles,
  ...rest
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  const handleClick = data => {
    onChange(data);
    setTimeout(() => {
      setDropdownOpen(false);
    }, 200);
  };

  useClickAway(ref, _e => {
    setDropdownOpen(false);
  });

  return (
    <div className={cs(classes.root, className)}>
      <div
        ref={ref}
        id={id}
        onClick={() => {
          setDropdownOpen(currentState => !currentState);
        }}
      >
        <>
          {children}

          {!disabled && isDropdownOpen && (
            <div
              className={cs(classes.popover, 'popover', popperClassName)}
              style={{ top: `${height}px` }}
            >
              <div className={classes.popoverBody}>
                <RSelectAsync
                  autoFocus
                  menuIsOpen
                  className={cs(selectorClassName)}
                  isClearable={isClearable}
                  placeholder={placeholder}
                  getOptionValue={getOptionValue}
                  getOptionLabel={getOptionLabel}
                  url={url}
                  onChange={handleClick}
                  styles={{
                    menu: (provided, state) => {
                      const customMenuStyles = get(
                        styles,
                        'menu',
                        dataProvided => dataProvided
                      );
                      return customMenuStyles(
                        {
                          ...provided,
                          marginTop: '0.125rem',
                          boxShadow: 'none',
                          position: 'relative',
                        },
                        state
                      );
                    },
                    menuList: (provided, state) => {
                      const customMenuListStyles = get(
                        styles,
                        'menuList',
                        dataProvided => dataProvided
                      );
                      return customMenuListStyles(
                        { ...provided, maxHeight: '15rem' },
                        state
                      );
                    },
                    option: (provided, state) => {
                      const customOptionStyles = get(
                        styles,
                        'option',
                        dataProvided => dataProvided
                      );

                      return customOptionStyles(
                        {
                          ...provided,
                          color: customStyles.bodyColor,
                          ...(state.isFocused || state.isSelected
                            ? {
                                backgroundColor: customStyles.lyghtBlue,
                                color: customStyles.white,
                                borderRadius: '0.375rem',
                              }
                            : {}),
                          ':active': {
                            backgroundColor: customStyles.lyghtBlue,
                          },
                        },
                        state
                      );
                    },
                    ...omit(styles, ['menuList', 'option', 'menu']),
                  }}
                  {...rest}
                />
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

SelectAsync.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  selectorClassName: PropTypes.string,
  popperClassName: PropTypes.string,
  styles: PropTypes.object,
};

SelectAsync.defaultProps = {
  isClearable: false,
  getOptionLabel: option => option.name,
  getOptionValue: option => option.name,
  placeholder: '',
  disabled: false,
  selectorClassName: '',
  popperClassName: '',
  styles: {},
};

export default SelectAsync;
