import React from 'react';
import classes from './UserInfo.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Avatar from 'components/Avatar';
import get from 'lodash/get';

const UserInfo = ({ info, altText }) => {
  const name = get(info, 'name');
  const avatar = get(info, 'avatar');
  return (
    <>
      <div className="d-flex flex-row flex-nowrap align-items-center overflow-hidden">
        {!isEmpty(avatar) ? (
          <Avatar url={avatar} className={classnames('avatar-xs mr-2')} />
        ) : (
          <div
            className={classnames(
              'avatar-xs avatar mr-2 d-flex align-items-center justify-content-center',
              classes['empty-avatar'],
              'rounded-circle'
            )}
          >
            <span className="fa fa-user text-white" />
          </div>
        )}

        <p className={classnames(!name && 'text-light', classes['ellipsis'])}>
          {name || altText}
        </p>
      </div>
    </>
  );
};

UserInfo.propTypes = {
  info: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  altText: PropTypes.string.isRequired,
};

UserInfo.defaultProps = {
  altText: '',
  info: {},
};

export default UserInfo;
