import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { get, isNil } from 'lodash';
import cx from 'classnames';
import { Card, CardHeader, CardBody } from 'reactstrap';
import BaseTable from 'components/Table';
import classes from './teams.module.scss';
import {
  addTeamMember,
  deleteTeamMember,
  fetchTeamMembersList,
  updateMemberRate,
} from 'store/actions/teamMembers';
import { useAccess, permissions } from 'helpers/permission';
import {
  NameCell,
  TodayCell,
  ThisWeekCell,
  ThisWeeksUtilizationCell,
  ProjectAccessHeader,
  ProjectAccessCell,
  ProjectRateCell,
} from './Cells';
import TeamsTableHeader from './TeamsTableHeader';
import InviteToProjectForm from './InviteToProject.Form';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import history from 'helpers/history';
import MemberRateModal from './MemberRate';
import analytics, { analyticsConstants } from 'helpers/analytics';
import Loading from 'components/Loading';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import InviteUserForm from 'views/pages/Users/InviteUser.Form';
import { inviteMembers } from 'store/actions/users';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const Teams = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = get(params, 'id');
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.project_teams,
      ...rest,
    });
  };

  useEffect(() => {
    analyticsSendEvent({ action: analyticsConstants.action.view_project_team });
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'invite_team' &&
      (quickStartTour.step || quickStartTour.nextStep)
    ) {
      dispatch(
        updateActiveTourStatus({
          step: quickStartTour.nextStep,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(1);
  const [inviteToProjectModal, setInviteToProjectModal] = useState(false);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [sortBy, setSortBy] = useState({
    dataField: 'project_rate',
    order: 'desc',
  });
  const [initialDataLoad, setInitialDataLoad] = useState(false);
  const isAllowedViewMemberRate = useAccess([
    permissions.VIEW_TEAM_MEMBER_RATE,
  ]);
  const teamMembers = useSelector(({ teamMembers }) =>
    get(teamMembers, 'teamMembersState.data.data', [])
  );

  const [currentRateForMember, setCurrentRateForMember] = useState();
  const [memberIdForRateUpdate, setMemberIdForRateUpdate] = useState();
  const teamMembersMetadata = useSelector(({ teamMembers }) =>
    get(teamMembers, 'teamMembersState.data.meta', {})
  );

  const projectDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data', {})
  );
  const listLoading = useSelector(({ teamMembers }) =>
    get(teamMembers, 'teamMembersState.isInProgress', false)
  );

  const NoDataIndication = () => {
    return 'Loading';
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const onTableChange = async (
    type,
    { page, sizePerPage, sortOrder, sortField, searchText, ...rest }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setPage(1);
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    }
    if (type === 'search') {
      analyticsSendEvent({
        action: analyticsConstants.action.search_project_team,
      });
      setPage(1);
    }
    const field =
      sortField === 'this_week_utilization'
        ? 'hours_logged_this_week'
        : sortField;
    const sort = sortOrder === 'desc' ? `-${field}` : field;
    if (type === 'init') setInitialDataLoad(true);
    await dispatch(fetchTeamMembersList(id, page, sort, searchText));
    if (type === 'init') setInitialDataLoad(false);
  };

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const openModal = async () => {
    await setInviteToProjectModal(true);
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'invite_team' &&
      (quickStartTour.step === 6 || quickStartTour.nextStep === 6)
    ) {
      dispatch(
        updateActiveTourStatus({
          step: 7,
        })
      );
    }
  };

  const closeModal = () => {
    setInviteToProjectModal(false);
    setViewMode('');
    setEditValues(null);
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'invite_team' &&
      (quickStartTour.step === 7 || quickStartTour.step === 8)
    ) {
      dispatch(
        updateActiveTourStatus({
          step: 6,
        })
      );
    }
  };

  const handleNewUserInvite = options => () => {
    if (!options.hasValue && options.value.length === 0) {
      closeModal();
    }
    setInviteUserModal(true);
  };

  const handleCloseInviteUserForm = () => {
    setInviteUserModal(false);
  };

  const submitValues = async ({ users }) => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    analyticsSendEvent({
      action: analyticsConstants.action.add_project_team_member,
    });
    await dispatch(
      addTeamMember(
        id,
        { members: users.map(u => u.id) },
        { page, sort, q: '' }
      )
    );
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'invite_team'
    ) {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action
            .complete_invite_project_team_onboarding_flow,
      });
      if (quickStartTour.withCreateProject) {
        analytics.sendEvent({
          category: analyticsConstants.category.onboarding,
          action:
            analyticsConstants.action.complete_create_a_project_onboarding_flow,
        });
      }
      dispatch(
        setUserPreference({
          ...onBoardingTour,
          value: {
            ...get(onBoardingTour, 'value', {}),
            status: 'Active',
            ...(!quickStartTour.isRestarted
              ? {
                  steps: {
                    ...get(onBoardingTour, 'value.steps', {}),
                    [`${quickStartTour.activeTour}`]: {
                      status: 'Completed',
                    },
                    ...(quickStartTour.withCreateProject
                      ? {
                          project_creation: {
                            status: 'Completed',
                          },
                        }
                      : {}),
                  },
                }
              : {}),
          },
        })
      );
      dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
          isRestarted: false,
          withCreateProject: false,
        })
      );
      didAllOnboardingFlowCompleted();
    }
    setInviteToProjectModal(false);
  };

  const inviteUserToPlatform = async values => {
    setIsLoading(true);
    const { email, role, projects } = values;
    const allEmails = email.split(',').filter(f => f.trim());
    const data = {
      roles: [role.name],
      initiatives: projects.map(p => p.id),
    };
    data.email_addresses = allEmails;
    analyticsSendEvent({
      category: analyticsConstants.category.user_management,
      action: analyticsConstants.action.invite_users,
      number_users: allEmails.length,
    });

    const { errorStatus } = await dispatch(inviteMembers(data));
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showTeamLimit();
    }
    setIsLoading(false);
    handleCloseInviteUserForm();
  };
  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    analyticsSendEvent({
      action: analyticsConstants.action.remove_project_member,
    });
    await dispatch(deleteTeamMember(id, data.id, { page, sort, q: '' }));
  };

  const handleMemberRemove = member => {
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Remove Member',
      text: `${member.name} will no longer ${
        member.access === 'Manage'
          ? 'show up as a team member on this project'
          : 'have access to the project'
      }. Do you wish to continue?`,
      data: member,
    });
  };
  return (
    <>
      <MemberRateModal
        open={!isNil(currentRateForMember)}
        currentRate={currentRateForMember}
        updateRate={rate => {
          analyticsSendEvent({
            action: analyticsConstants.action.update_project_member_rate,
          });

          dispatch(updateMemberRate(id, memberIdForRateUpdate, rate));
          setCurrentRateForMember();
          setMemberIdForRateUpdate();
        }}
        closeModal={() => {
          setCurrentRateForMember();
          setMemberIdForRateUpdate();
        }}
      />
      {inviteToProjectModal ? (
        <InviteToProjectForm
          projectId={id}
          isModalOpen={inviteToProjectModal}
          submitValues={submitValues}
          closeModal={closeModal}
          handleNewUserInvite={handleNewUserInvite}
          viewMode={viewMode}
          editValues={editValues}
          quickStartTour={quickStartTour}
        />
      ) : null}
      {inviteUserModal ? (
        <InviteUserForm
          isModalOpen={inviteUserModal}
          submitValues={inviteUserToPlatform}
          closeModal={handleCloseInviteUserForm}
          editValues={{
            projects: [
              {
                name: projectDetails.name,
                id: projectDetails.id,
              },
            ],
          }}
          quickStartTour={quickStartTour}
          isLoading={isLoading}
        />
      ) : null}
      <div
        className={cx(classes.teamMembers, 'px-4', {
          [classes.teamLoading]: initialDataLoad,
        })}
      >
        {initialDataLoad && <Loading wrapperClass={classes.loading} />}
        <Card className={cx({ 'shadow-none': initialDataLoad })}>
          <CardHeader>
            <TeamsTableHeader openModal={openModal} teamMembers={teamMembers} />
          </CardHeader>
          <CardBody className="p-0">
            <BaseTable
              keyField="id"
              defaultSorted={[sortBy]}
              noDataIndication={NoDataIndication}
              bootstrap4
              remote
              bordered={false}
              loading={listLoading}
              paginationOptions={{
                page: page,
                totalSize: teamMembersMetadata.total,
                sizePerPage: parseInt(teamMembersMetadata.per_page),
              }}
              data={!initialDataLoad ? teamMembers : []}
              columns={[
                {
                  dataField: 'name',
                  text: 'Name',
                  sort: true,
                  classes: classes.userName,
                  headerClasses: classes.userName,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => <NameCell cell={cell} row={row} />,
                },
                {
                  dataField: 'hours_logged_today',
                  text: 'Today',
                  sort: true,
                  classes: cx(
                    'text-muted',
                    classes.middle,
                    classes.smallColumns
                  ),
                  headerClasses: cx(
                    'text-muted',
                    classes.middle,
                    classes.smallColumns
                  ),
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => <TodayCell cell={cell} row={row} />,
                },
                {
                  dataField: 'hours_logged_this_week',
                  text: 'This Week',
                  sort: true,
                  classes: cx(
                    'text-muted',
                    classes.middle,
                    classes.smallColumns
                  ),
                  headerClasses: cx(
                    'text-muted',
                    classes.middle,
                    classes.smallColumns
                  ),
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => (
                    <ThisWeekCell cell={cell} row={row} />
                  ),
                },
                {
                  dataField: 'this_week_utilization',
                  text: 'This week’s utilization',
                  sort: true,
                  classes: cx(
                    'text-muted',
                    classes.middle,
                    classes.utilizationColumn
                  ),
                  headerClasses: cx(
                    'text-muted',
                    classes.middle,
                    classes.utilizationColumn
                  ),
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => (
                    <ThisWeeksUtilizationCell cell={cell} row={row} />
                  ),
                },
                ...(isAllowedViewMemberRate
                  ? [
                      {
                        dataField: 'project_rate',
                        text: 'Project Rate',
                        sort: true,
                        classes: cx(
                          'text-muted',
                          classes.middle,
                          classes.smallColumns
                        ),
                        headerClasses: cx(
                          'text-muted',
                          classes.middle,
                          classes.smallColumns
                        ),
                        sortCaret: renderSortCaret,
                        formatter: cell => <ProjectRateCell cell={cell} />,
                      },
                    ]
                  : []),
                {
                  dataField: 'access',
                  text: 'Project Access',
                  classes: cx(
                    'text-muted d-flex justify-content-between align-items-center',
                    classes.access,
                    classes.accessColumn
                  ),
                  headerClasses: cx('text-muted d-flex', classes.accessColumn),
                  headerFormatter: column => (
                    <ProjectAccessHeader column={column} />
                  ),
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => (
                    <ProjectAccessCell
                      cell={cell}
                      row={row}
                      onViewMember={() =>
                        history.push(`/admin/users/${row.id}`)
                      }
                      onUpdateMemberRate={row => {
                        setCurrentRateForMember(get(row, 'project_rate'));
                        setMemberIdForRateUpdate(get(row, 'id'));
                      }}
                      onRemoveMember={handleMemberRemove}
                    />
                  ),
                },
              ]}
              onTableChange={onTableChange}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Teams;
