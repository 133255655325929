import Alternative from 'views/pages/dashboards/Alternative.js';
// import Buttons from 'views/pages/components/Buttons.js';
// import Calendar from 'views/pages/Calendar.js';
// import Cards from 'views/pages/components/Cards.js';
// import Charts from 'views/pages/Charts.js';
// import Components from 'views/pages/forms/Components.js';
import Dashboard from 'views/pages/dashboards/Dashboard.js';
import Clients from 'views/pages/Clients';
import ClientProfile from './views/pages/ClientProfile';
import Projects from 'views/pages/Project';
// import Elements from 'views/pages/forms/Elements.js';
// import Google from 'views/pages/maps/Google.js';
// import Grid from 'views/pages/components/Grid.js';
// import Icons from 'views/pages/components/Icons.js';
// import Lock from 'views/pages/examples/Lock.js';
import ViewProject from 'views/pages/Project/ViewProject';
import Login from 'views/pages/Auth/Login';
import InviteUsers from 'views/pages/Auth/InviteUsers';
import InitialRegister from 'views/pages/Auth/InitialRegister/InitialRegister';
import AcceptAndSetup from 'views/pages/Auth/AcceptAndSetup';
import Invalid from 'views/pages/Auth/Invalid';
// import Notifications from 'views/pages/components/Notifications.js';
// import Pricing from 'views/pages/examples/Pricing.js';
import Profile from 'views/pages/Profile';
import EditProfile from 'views/pages/Profile/EditProfile';
// import ReactBSTables from 'views/pages/tables/ReactBSTables.js';
import Register from 'views/pages/Auth/Register';
import AuthFlowCompanySettings from 'views/pages/Auth/CompanyDetails';
import VerifyEmail from 'views/pages/Auth/VerifyEmail';
import ForgotPassword from 'views/pages/Auth/ForgotPassword';
import ResetPassword from 'views/pages/Auth/ResetPassword';
import SubscriptionOutdated from 'views/pages/Auth/SubscriptionOutdated';
// import RTLSupport from 'views/pages/examples/RTLSupport.js';
// import Sortable from 'views/pages/tables/Sortable.js';
// import Tables from 'views/pages/tables/Tables.js';
// import Timeline from 'views/pages/examples/Timeline.js';
// import Typography from 'views/pages/components/Typography.js';
// import Validation from 'views/pages/forms/Validation.js';
// import Vector from 'views/pages/maps/Vector.js';
// import Widgets from 'views/pages/Widgets.js';
import BadgePro from 'components/BadgePro';
import KnowledgeBase from 'views/pages/KnowledgeBase';
import KnowledgeBaseArticle from 'views/pages/KnowledgeBaseArticle';
import TimelogReport from 'views/pages/TimelogReport';
import Users from 'views/pages/Users';
import CompanySettings from 'views/pages/CompanySettings';
import Billing from 'views/pages/Billing';
import AssingmentTypeCustomizations from 'views/pages/AssingmentTypeCustomizations';
import { permissions } from 'helpers/permission';
import MyKanban from 'views/pages/Kanban/MyKanban';
import GlobalKanban from 'views/pages/Kanban/GlobalKanban';
import UserKanban from 'views/pages/Kanban/UserKanban';
import SprintKanban from 'views/pages/Backlogs/Sprint/SprintKanban';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fas fa-th-large text-primary',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/company',
    name: 'Company Settings',
    component: CompanySettings,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/projects',
    name: 'Projects',
    icon: 'fas fa-cubes text-success',
    component: Projects,
    layout: '/admin',
    isExact: true,
    permission: permissions.LIST_PROJECTS,
  },
  {
    path: '/projects/:id/:page?',
    isExact: true,
    name: 'Projects',
    icon: 'fas fa-cubes text-success',
    component: ViewProject,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/projects/:id/:page/sprint/:sprintID/board',
    isExact: true,
    name: 'Projects',
    icon: 'fas fa-cubes text-success',
    component: SprintKanban,
    layout: '/admin',
    hide: true,
  },
  // {
  //   path: '/projects/:id/:page?',
  //   isExact: true,
  //   name: 'Projects',
  //   icon: 'fas fa-cubes text-success',
  //   component: Demo,
  //   layout: '/demo',
  //   hide: true,
  // },
  /*{
    collapse: true,
    name: 'People',
    icon: 'fas fa-users text-purple',
    state: 'peopleCollapse',
    views: [
      {
        path: '/users-teams',
        name: 'Users',
        miniName: '',
        component: Dashboard,
        layout: '/admin',
      },
      // {
      //   path: '/channels',
      //   name: 'Channels',
      //   miniName: 'C',
      //   component: Alternative,
      //   layout: '/admin',
      // },
      {
        path: '/recruitment',
        name: 'Recruitment',
        miniName: '',
        component: Alternative,
        badge: BadgePro,
        layout: '/admin',
      },
    ],
  },*/
  {
    path: '/clients',
    name: 'Clients',
    isExact: true,
    icon: 'fas fa-handshake text-purple',
    component: Clients,
    layout: '/admin',
    permission: permissions.LIST_CLIENTS,
  },
  {
    path: '/clients/:id',
    name: 'Clients',
    isExact: true,
    icon: 'fas fa-handshake text-primary',
    component: ClientProfile,
    layout: '/admin',
    hide: true,
  },
  {
    collapse: true,
    name: 'Kanban',
    icon: 'ni ni-chart-bar-32 rotate-180 text-red ml--3 mr-3',
    state: 'kanbanCollapse',
    permission: [
      permissions.ACCESS_GLOBAL_KANBAN,
      permissions.ACCESS_GLOBAL_KANBAN,
    ],
    views: [
      {
        isExact: true,
        path: '/kanban/company',
        name: 'Global Kanban',
        miniName: '',
        component: GlobalKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
      },
      {
        isExact: true,
        path: '/kanban/personal',
        name: 'Personal Kanban',
        miniName: '',
        component: MyKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
      },
    ],
  },
  {
    path: '/timelog',
    name: 'Timelog',
    icon: 'fas fa-clock text-yellow',
    component: TimelogReport,
    layout: '/admin',
    permission: permissions.LIST_TIMELOGS,
  },
  {
    path: '/knowledge-base/:id',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBaseArticle,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBase,
    layout: '/admin',
    hide: true,
  },
  // {
  //   collapse: true,
  //   name: 'Dashboards',
  //   icon: 'ni ni-shop text-primary',
  //   state: 'dashboardsCollapse',
  //   views: [
  //     {
  //       path: '/dashboard',
  //       name: 'Dashboard',
  //       miniName: 'D',
  //       component: Dashboard,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/alternative-dashboard',
  //       name: 'Alternative',
  //       miniName: 'A',
  //       component: Alternative,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Examples',
  //   icon: 'ni ni-ungroup text-orange',
  //   state: 'examplesCollapse',
  //   views: [
  //     {
  //       path: '/pricing',
  //       name: 'Pricing',
  //       miniName: 'P',
  //       component: Pricing,
  //       layout: '/auth',
  //     },
  {
    path: '/company',
    name: 'AuthFlowCompanySettings',
    component: VerifyEmail,
    layout: '/auth',
    hide: true,
  },
  {
    path: '/login',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/invitations/accept-and-Setup',
    name: 'Accept and setup',
    miniName: 'A',
    component: AcceptAndSetup,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/invitations/invalid',
    name: 'Invalid',
    miniName: 'I',
    component: Invalid,
    layout: '/auth',
    redirect: true,
    hide: true,
  },
  {
    path: '/login/google/callback',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/register',
    name: 'Register',
    miniName: 'R',
    component: Register,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/email/verify',
    name: 'Set Password',
    miniName: 'SP',
    component: VerifyEmail,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/invite',
    name: 'Invite Users',
    miniName: 'SP',
    component: VerifyEmail,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/password/reset',
    name: 'Reset Password',
    miniName: 'RP',
    component: ResetPassword,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/email/reset-password',
    name: 'Reset Password',
    miniName: 'SP',
    component: ForgotPassword,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/subscription/outdated',
    name: 'Subscription outdated',
    miniName: 'SO',
    component: SubscriptionOutdated,
    layout: '/auth',
    redirect: true,
  },
  // {
  //   path: '/lock',
  //   name: 'Lock',
  //   miniName: 'L',
  //   component: Lock,
  //   layout: '/auth',
  // },
  //     {
  //       path: '/timeline',
  //       name: 'Timeline',
  //       miniName: 'T',
  //       component: Timeline,
  //       layout: '/admin',
  //     },
  {
    path: '/users/:id/edit',
    name: 'Profile',
    component: EditProfile,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/users/:id/kanban',
    name: 'Personal Kanban',
    miniName: 'PK',
    component: UserKanban,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/users/:id',
    name: 'Profile',
    miniName: 'P',
    component: Profile,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/users/',
    name: 'User Management',
    component: Users,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/assignment-types/:modalOpen?',
    name: 'Assignment Types',
    component: AssingmentTypeCustomizations,
    layout: '/admin',
    hide: true,
    permission: permissions.CREATE_TASK_TYPES,
  },
  //     {
  //       path: '/rtl-support',
  //       name: 'RTL Support',
  //       miniName: 'RS',
  //       component: RTLSupport,
  //       layout: '/rtl',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Components',
  //   icon: 'ni ni-ui-04 text-info',
  //   state: 'componentsCollapse',
  //   views: [
  //     {
  //       path: '/buttons',
  //       name: 'Buttons',
  //       miniName: 'B',
  //       component: Buttons,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/cards',
  //       name: 'Cards',
  //       miniName: 'C',
  //       component: Cards,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/grid',
  //       name: 'Grid',
  //       miniName: 'G',
  //       component: Grid,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/notifications',
  //       name: 'Notifications',
  //       miniName: 'N',
  //       component: Notifications,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/icons',
  //       name: 'Icons',
  //       miniName: 'I',
  //       component: Icons,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/typography',
  //       name: 'Typography',
  //       miniName: 'T',
  //       component: Typography,
  //       layout: '/admin',
  //     },
  //     {
  //       collapse: true,
  //       name: 'Multi Level',
  //       miniName: 'M',
  //       state: 'multiCollapse',
  //       views: [
  //         {
  //           path: '#pablo',
  //           name: 'Third level menu',
  //           component: () => {},
  //           layout: '/',
  //         },
  //         {
  //           path: '#pablo',
  //           name: 'Just another link',
  //           component: () => {},
  //           layout: '/',
  //         },
  //         {
  //           path: '#pablo',
  //           name: 'One last link',
  //           component: () => {},
  //           layout: '/',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Forms',
  //   icon: 'ni ni-single-copy-04 text-pink',
  //   state: 'formsCollapse',
  //   views: [
  //     {
  //       path: '/elements',
  //       name: 'Elements',
  //       miniName: 'E',
  //       component: Elements,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/components',
  //       name: 'Components',
  //       miniName: 'C',
  //       component: Components,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/validation',
  //       name: 'Validation',
  //       miniName: 'V',
  //       component: Validation,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Tables',
  //   icon: 'ni ni-align-left-2 text-default',
  //   state: 'tablesCollapse',
  //   views: [
  //     {
  //       path: '/tables',
  //       name: 'Tables',
  //       miniName: 'T',
  //       component: Tables,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/sortable',
  //       name: 'Sortable',
  //       miniName: 'S',
  //       component: Sortable,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/react-bs-table',
  //       name: 'React BS Tables',
  //       miniName: 'RBT',
  //       component: ReactBSTables,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Maps',
  //   icon: 'ni ni-map-big text-primary',
  //   state: 'mapsCollapse',
  //   views: [
  //     {
  //       path: '/google',
  //       name: 'Google',
  //       miniName: 'G',
  //       component: Google,
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/vector',
  //       name: 'Vector',
  //       miniName: 'V',
  //       component: Vector,
  //       layout: '/admin',
  //     },
  //   ],
  // },
  // {
  //   path: '/widgets',
  //   name: 'Widgets',
  //   icon: 'ni ni-archive-2 text-green',
  //   component: Widgets,
  //   layout: '/admin',
  // },
  // {
  //   path: '/charts',
  //   name: 'Charts',
  //   icon: 'ni ni-chart-pie-35 text-info',
  //   component: Charts,
  //   layout: '/admin',
  // },
  // {
  //   path: '/calendar',
  //   name: 'Calendar',
  //   icon: 'ni ni-calendar-grid-58 text-red',
  //   component: Calendar,
  //   layout: '/admin',
  // },
];

export default routes;
