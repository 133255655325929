import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import classes from '../StoryAssignments.module.scss';
import ReactDatetime from 'react-datetime';
import cs from 'classnames';
import useClickAway from 'react-use/lib/useClickAway';
import moment from 'moment';
import { analyticsConstants } from 'helpers/analytics';
const formatDate = 'MM/DD/YYYY';

const DueDate = ({ formik, isEditAllowed, analyticsSendEvent }) => {
  const DateRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { values, setFieldValue, handleSubmit } = formik;
  const dueDate = values.date_due;

  useClickAway(DateRef, () => {
    if (isEditAllowed) setShowDatePicker(!showDatePicker);
  });

  const onClick = () => {
    if (isEditAllowed) setShowDatePicker(!showDatePicker);
  };

  return (
    <div className={classes.assignmentDueDate}>
      <div className={classes.title}>Due Date</div>
      {dueDate ? (
        <div
          onClick={onClick}
          className={cs(classes.pointer, {
            [classes.noPointer]: isEditAllowed === false,
          })}
        >
          {moment(dueDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}
        </div>
      ) : (
        <span
          onClick={onClick}
          className={cs({
            [classes.noPointer]: isEditAllowed === false,
          })}
        >
          + Add a Due Date
        </span>
      )}

      {showDatePicker && (
        <div
          ref={DateRef}
          className={cs(classes.dropdownCalendar, classes.right)}
        >
          <ReactDatetime
            dateFormat={formatDate}
            timeFormat={false}
            value={dueDate ? moment(dueDate, 'YYYY-MM-DD') : moment()}
            input={false}
            onChange={date => {
              analyticsSendEvent({
                action: analyticsConstants.action.update_assignment_due_date,
              });
              setFieldValue('date_due', moment(date).format('YYYY-MM-DD'));
              setShowDatePicker(false);
              handleSubmit();
            }}
            className={cs(classes.calendar)}
            id={`due-date-task-${values.id}`}
          />
        </div>
      )}
    </div>
  );
};

DueDate.propTypes = {};
DueDate.defaultProps = {};

export default DueDate;
