import React, { useState, useEffect } from 'react';

import { useInterval } from 'react-use';
import { secondsToHHMMSS } from 'helpers/times';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import cs from 'classnames';

const RunningTime = () => {
  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const [runningTime, setRunningTime] = useState('00:00:00');
  const timerRunningTime = useSelector(({ timer }) =>
    get(timer, 'runningTime')
  );

  useInterval(
    () => {
      const startTime = moment(timerRunningTime)
        .tz(timezone)
        .format('X');
      const endTime = moment()
        .tz(timezone)
        .format('X');

      const time = secondsToHHMMSS(endTime - startTime);
      setRunningTime(time);
      document.title = time;
    },
    timerRunningTime ? 1000 : null
  );

  useEffect(() => {
    if (!timerRunningTime) {
      setRunningTime('00:00:00');
      window.document.title =
        'Lyght Activity Management | CRM | Resource Management';
    }
  }, [timerRunningTime]);

  return (
    <span className={cs('runningTimer', { stop: runningTime === '00:00:00' })}>
      {runningTime}
    </span>
  );
};

export default RunningTime;
