import React, { useRef, useState, useEffect } from 'react';
import classes from '../StoryModal.module.scss';
import classnames from 'classnames';
import Editor from 'components/FormFields/Editor';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Button from 'components/Button';
import ContentEditor from 'components/ContentEditor';
import { useClickAway } from 'react-use';
import { useDispatch } from 'react-redux';
import { addInMentions } from 'helpers/mentions';
import { setMentionsUrl } from 'store/actions/components';
import includes from 'lodash/includes';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import analytics, { analyticsConstants } from 'helpers/analytics';

const Description = ({
  editField,
  projectId,
  changeDescription,
  onClick,
  onCancel,
  isEditAllowed,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const description = useGetFieldFromObjects(
    'story',
    'details.data.description'
  );
  const [newDescription, setNewDescription] = useState(
    addInMentions(description)
  );
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  useEffect(() => {
    setNewDescription(addInMentions(description));
  }, [description]);
  const descRef = useRef(null);
  const descriptionContent = new DOMParser().parseFromString(
    description,
    'text/html'
  );
  const isDescriptionEmpty = isEmpty(descriptionContent.body.textContent || '');
  useClickAway(descRef, () => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_description,
    });
    changeDescription(newDescription);
  });
  return (
    <>
      <h4>Description</h4>
      {editField === 'description' ? (
        <div ref={descRef}>
          <Editor
            value={isEmpty(newDescription) ? '' : newDescription}
            onChange={content => {
              setNewDescription(content);
            }}
            scrollingContainer={
              isMobile ? '#storyModal-body' : '#storyModal-leftColumn'
            }
            assignRef={ref => {
              if (ref && !get(ref, 'editor').getSelection()) {
                const editor = ref.getEditor();
                editor.focus();
                editor.setSelection(editor.getLength());
              }
            }}
            shouldAllowMention={true}
          />
          <div className="d-flex flex-row flex-nowrap justify-content-end align-items-center mt-2">
            <Button
              color="link"
              onClick={() => {
                setNewDescription(description);
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_description,
                });
                changeDescription(newDescription);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : isDescriptionEmpty && !isEditAllowed ? (
        <p className="text-muted">No Description</p>
      ) : (
        <ContentEditor
          className={classnames(
            classes['description'],
            isEditAllowed && classes['hover-hand'],
            isDescriptionEmpty &&
              isEditAllowed &&
              classnames(
                'p-3 text-light',
                classes['empty-description'],
                classes['empty-content']
              )
          )}
          onClick={event => {
            const isAnchor = node => includes(['A'], node.tagName);

            if (
              isAnchor(event.target) ||
              isAnchor(event.target.parentNode) ||
              isAnchor(event.target.parentNode.parentNode)
            )
              return;
            const openIfImg = node => {
              if (includes(['IMG'], node.tagName)) {
                window.open(node.src, '_blank');
                return true;
              }
            };
            if (
              openIfImg(event.target) ||
              openIfImg(event.target.parentNode) ||
              openIfImg(event.target.parentNode.parentNode)
            )
              return;
            if (isEditAllowed) {
              dispatch(
                setMentionsUrl(
                  `/users/list/dropdown?status=Active&initiative=${projectId}`
                )
              );
              onClick();
            }
          }}
          content={
            isDescriptionEmpty
              ? 'Add a Description for this story'
              : description
          }
        />
      )}
    </>
  );
};

Description.propTypes = {
  editField: PropTypes.string,
  description: PropTypes.string,
  changeDescription: PropTypes.func,
  onClick: PropTypes.func,
};

Description.defaultProps = {
  description: '',
};

export default Description;
