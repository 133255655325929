import React, { useState, useRef } from 'react';
import { get, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import classNames from 'classnames';
import Dropdowns from 'components/Dropdowns';
import classes from './Backlogs.module.scss';
import SectionsHeader from './SectionsHeader';
import Stories from './Stories/Stories';
import SectionFooter from './SectionFooter';
import SectionForm from './Section.Form';
import { renameSection } from 'store/actions/backlogs';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Types from 'store/types/backlogs';
import * as moment from 'moment';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { useAccess, permissions } from 'helpers/permission';
import useBreakPoint from 'helpers/useBreakPoint';

const Section = ({
  toggleSections,
  isLastSection,
  isFirstSection,
  openSections,
  id,
  name,
  deleteAction,
  deleteSectionAction,
  reorderStories,
  fetchStoryAction,
  projectId,
  isSectionEditAllowed,
  isAddStoryAllowed,
  isStoryReorderAllowed,
  isStoryEditAllowed,
  isStoryDeleteAllowed,
  isStoryViewAllowed,
  isStoryDuplicateAllowed,
  isMassUpdateAllowed,
  isMassDeleteAllowed,
  storiesCount,
  index: sectionIndex,
  isSectionDragging,
  isUserAllowedSectionReorder,
  disableStoryToolTip,
  analyticsConstants,
  analyticsSendEvent,
  analyticsSendEventStory,
  duplicateStoryClick,
  sprint,
  editMode,
  isMemberOfProject,
}) => {
  const dispatch = useDispatch();
  const [isModal, setModal] = useState(false);
  const editValues = useRef(null);
  const isMobile = useBreakPoint('xs', 'down');
  const viewMode = useRef(null);
  const history = useHistory();

  const stories = useSelector(({ backlog }) =>
    get(backlog, `itemsState.data.${id}.data`, [])
  );
  const companyGuid = useSelector(({ auth }) => get(auth, 'user.company.guid'));
  const specialSectionName = 'New Items';

  const selectedStoriesForSection = useSelector(({ backlog }) =>
    get(backlog, `selectedStories.${id}`, [])
  );

  const isUserAllowedViewSprint = useAccess([
    permissions.VIEW_ALL_SPRINTS,
    {
      permission: permissions.VIEW_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  let mouseDown = false;

  const closeModal = () => {
    setModal(false);
    viewMode.current = '';
    editValues.current = null;
  };

  const editAction = row => {
    editValues.current = { ...row };
    viewMode.current = 'edit';
    setModal(true);
  };

  const submitValues = async values => {
    setModal(false);
    if (viewMode.current === 'edit') {
      analyticsSendEvent({
        action: analyticsConstants.action.rename_backlog_section,
      });
      await dispatch(renameSection(id, values));
    } else {
      // we will need to add api logic when we support create section
    }
  };

  const selectStory = data => {
    dispatch({
      type: Types.SELECT_STORY,
      data: {
        storyId: data,
        sectionId: id,
      },
    });
  };

  const deSelectStory = data => {
    dispatch({
      type: Types.DESELECT_STORY,
      data: {
        storyId: data,
        sectionId: id,
      },
    });
  };

  const selectAllStoriesInSection = data => {
    dispatch({
      type: Types.SELECT_ALL_STORIES_IN_SECTION,
      data,
    });
  };

  const deSelectAllStoriesInSection = data => {
    dispatch({
      type: Types.DESELECT_ALL_STORIES_IN_SECTION,
      data,
    });
  };

  return (
    <>
      {isModal ? (
        <SectionForm
          isModalOpen={isModal}
          submitValues={submitValues}
          closeModal={closeModal}
          viewMode={viewMode.current}
          editValues={editValues.current}
        />
      ) : null}
      <Draggable
        draggableId={`section-${id}`}
        index={sectionIndex}
        isDragDisabled={!isUserAllowedSectionReorder}
      >
        {(dragProvided, snapshot) => {
          return (
            <Card
              {...dragProvided.draggableProps}
              innerRef={dragProvided.innerRef}
              key={id}
              className={classNames(isLastSection && 'mb-0', classes.section, {
                [classes.dragging]: isSectionDragging,
                [classes.sprint]: sprint,
              })}
            >
              <Droppable droppableId={`${id}`} type="backlog-stories">
                {(provided, dropSnapshot) => (
                  <div
                    {...(openSections.includes(id)
                      ? {}
                      : {
                          ...provided.droppableProps,
                          ref: provided.innerRef,
                        })}
                    {...(isFirstSection
                      ? {
                          id: 'sectionStoryInput',
                        }
                      : {})}
                  >
                    <CardHeader
                      className={classNames(
                        'd-flex justify-content-between align-items-center w-100 py-3 pr-3',
                        `border-bottom${storiesCount && '-0'}`,
                        {
                          [classes.closedHeader]: !openSections.includes(id),
                          [classes.hoverHand]: !isUserAllowedSectionReorder,
                          [classes.draggedOverCard]:
                            dropSnapshot.isDraggingOver &&
                            !openSections.includes(id),
                          [classes.sprintHeader]: sprint,
                        }
                      )}
                      onClick={toggleSections(id)}
                      {...dragProvided.dragHandleProps}
                    >
                      <div className="d-flex align-items-center">
                        {isUserAllowedSectionReorder && (
                          <i
                            className={classNames(
                              'fas fa-grip-vertical text-light mr-2',
                              classes.grabHand
                            )}
                          />
                        )}
                        <i
                          className={classNames(
                            'fas fa-caret-right',
                            classes.toggleIcon,
                            {
                              [classes.rotate]: openSections.includes(id),
                            }
                          )}
                        />
                        <h3 className="ml-2 mb-0">{name}</h3>
                        <span className="ml-2 badge badge-info rounded">
                          {stories.length}
                        </span>
                      </div>
                      {sprint ? (
                        <div className="d-flex float-right align-items-center">
                          <div className={classes.dateText}>
                            {moment(sprint.date_start).format('MMM D')} -{' '}
                            {moment(sprint.date_end).format('MMM D')}
                          </div>
                          {!isMobile && isUserAllowedViewSprint && (
                            <Button
                              size="sm"
                              className={classes.viewButton}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(
                                  `/admin/projects/${projectId}/backlog/sprint/${sprint.id}/board`
                                );
                              }}
                            >
                              View Sprint
                            </Button>
                          )}
                          {!(
                            isEqual(name, specialSectionName) &&
                            isEqual(companyGuid, '1')
                          ) &&
                            (isSectionEditAllowed ||
                              deleteSectionAction ||
                              (isMobile && isUserAllowedViewSprint)) && (
                              <Dropdowns
                                onClick={event => event.stopPropagation()}
                                dropdownClasses={classes.dropdown}
                                text={<i className="fas fa-ellipsis-v" />}
                                className="btn-icon-only m-0 text-light float-right"
                                options={[
                                  ...(isMobile && isUserAllowedViewSprint
                                    ? [
                                        {
                                          text: 'View Sprint',
                                          onClick: () =>
                                            history.push(
                                              `/admin/projects/${projectId}/backlog/sprint/${sprint.id}/board`
                                            ),
                                        },
                                      ]
                                    : []),
                                  ...(isSectionEditAllowed
                                    ? [
                                        {
                                          text: 'Edit Sprint',
                                          onClick: event => {
                                            event.stopPropagation();
                                            editMode();
                                          },
                                        },
                                      ]
                                    : []),
                                  ...(deleteSectionAction
                                    ? [
                                        {
                                          text: 'Delete Sprint',
                                          onClick: event => {
                                            event.stopPropagation();
                                            deleteSectionAction(id);
                                          },
                                        },
                                      ]
                                    : []),
                                ]}
                                caret={false}
                                size="sm"
                                color=""
                              />
                            )}
                        </div>
                      ) : (
                        !(
                          isEqual(name, specialSectionName) &&
                          isEqual(companyGuid, '1')
                        ) &&
                        (isSectionEditAllowed || deleteSectionAction) && (
                          <Dropdowns
                            onClick={event => event.stopPropagation()}
                            dropdownClasses={classes.dropdown}
                            text={<i className="fas fa-ellipsis-v" />}
                            className="btn-icon-only m-0 text-light float-right"
                            options={[
                              ...(isSectionEditAllowed
                                ? [
                                    {
                                      text: 'Rename Section',
                                      onClick: event => {
                                        event.stopPropagation();
                                        editAction({ name });
                                      },
                                    },
                                  ]
                                : []),
                              ...(deleteSectionAction
                                ? [
                                    {
                                      text: 'Delete Section',
                                      onClick: event => {
                                        event.stopPropagation();
                                        deleteSectionAction(id);
                                      },
                                    },
                                  ]
                                : []),
                            ]}
                            caret={false}
                            size="sm"
                            color=""
                          />
                        )
                      )}
                    </CardHeader>
                    <Collapse
                      {...(isSectionDragging ? { timeout: 0 } : {})}
                      isOpen={openSections.includes(id) && !isSectionDragging}
                      unmountOnExit
                    >
                      <CardBody
                        className="container-fluid"
                        id={`section-card-body-${id}`}
                      >
                        {storiesCount > 0 && (
                          <SectionsHeader
                            id={id}
                            isChecked={
                              selectedStoriesForSection.length === storiesCount
                            }
                            onClickCheckbox={checked => {
                              checked
                                ? selectAllStoriesInSection(id)
                                : deSelectAllStoriesInSection(id);
                            }}
                            isMassUpdateAllowed={isMassUpdateAllowed}
                            isMassDeleteAllowed={isMassDeleteAllowed}
                          />
                        )}

                        <div
                          {...(openSections.includes(id)
                            ? {
                                ...provided.droppableProps,
                                ref: provided.innerRef,
                              }
                            : {})}
                        >
                          <Stories
                            id={id}
                            isFirstSection={isFirstSection}
                            projectId={projectId}
                            deleteAction={deleteAction}
                            reorderStories={reorderStories}
                            fetchStoryAction={fetchStoryAction}
                            isStoryReorderAllowed={isStoryReorderAllowed}
                            isStoryEditAllowed={isStoryEditAllowed}
                            isStoryDeleteAllowed={isStoryDeleteAllowed}
                            isStoryViewAllowed={isStoryViewAllowed}
                            isStoryDuplicateAllowed={isStoryDuplicateAllowed}
                            onStorySelect={selectStory}
                            onStoryDeSelect={deSelectStory}
                            isMassUpdateAllowed={isMassUpdateAllowed}
                            isMassDeleteAllowed={isMassDeleteAllowed}
                            disableStoryToolTip={disableStoryToolTip}
                            analyticsConstants={analyticsConstants}
                            analyticsSendEvent={analyticsSendEvent}
                            analyticsSendEventStory={analyticsSendEventStory}
                            duplicateStoryClick={duplicateStoryClick}
                          />
                          {provided.placeholder}
                          {isAddStoryAllowed ? (
                            <SectionFooter
                              isFirstSection={isFirstSection}
                              id={id}
                              name={name}
                            />
                          ) : null}
                        </div>
                      </CardBody>
                    </Collapse>
                  </div>
                )}
              </Droppable>
            </Card>
          );
        }}
      </Draggable>
    </>
  );
};

export default React.memo(Section);
