import React from 'react';
import cs from 'classnames';
import { components } from 'react-select';
import { Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { FormikInput } from 'components/FormFields/Input';
import RoleSelector from 'components/RoleSelector';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import { roles as accessRoles } from 'helpers/permission';
import { updateActiveTourStatus } from 'store/actions/profile';
import classes from './users.module.scss';

const emailRegEx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/;

const CustomOption = ({ innerProps, data }) => (
  <div
    {...innerProps}
    className={cs('d-flex align-items-center p-2', classes.projectOption)}
  >
    <div>{data.name}</div>
  </div>
);

const MultiValueLabel = props => {
  return (
    <div className="d-flex align-items-center">
      <components.MultiValueLabel {...props} />
    </div>
  );
};

const MultiValueContainer = props => {
  return (
    <div className={classes.selectedOption}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

const InviteUserForm = ({
  viewMode,
  closeModal,
  submitValues,
  isModalOpen,
  editValues,
  quickStartTour,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { roleOptions, inviteUsersState } = useSelector(({ user }) => user);
  const isInviting = get(inviteUsersState, 'isInProgress', false);
  const roles = get(roleOptions, 'data.data', []);

  const InviteUserSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .test('validEmails', 'Must be valid email addresses', val => {
        if (!val) {
          return false;
        }
        const emails = val.split(',').filter(f => f);
        return emails.length ? emails.every(e => emailRegEx.test(e)) : false;
      }),
    role: Yup.object().required(),
    projects: Yup.array(),
  });

  let initialValues = {
    email: '',
    role: roles.find(r => r.name === accessRoles.BASIC_TEAM_MEMBER),
    projects: editValues ? editValues.projects : [],
  };

  if (viewMode === 're-invite') {
    initialValues = {
      ...initialValues,
      email: editValues ? editValues.email : '',
    };
  }

  const handleCloseModal = () => {
    closeModal();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'user_invitation'
    ) {
      dispatch(
        updateActiveTourStatus({
          step: 2,
        })
      );
    }
  };

  const RenderForm = ({
    handleSubmit,
    isValid,
    handleReset,
    values,
    setFieldValue,
  }) => {
    return (
      <>
        <ModalBody className="pb-0">
          <Form role="form" onSubmit={handleSubmit}>
            <h5>Invite with Email</h5>
            <FormikInput
              inputId="usersEmailInput"
              disabled={viewMode === 're-invite'}
              groupClassName="mb-3"
              name="email"
              placeholder="Enter one or more email addresses..."
              type="text"
              onBlur={() => {
                if (isValid) {
                  if (
                    quickStartTour &&
                    quickStartTour.activeTour &&
                    quickStartTour.activeTour === 'user_invitation' &&
                    quickStartTour.step === 3
                  ) {
                    dispatch(
                      updateActiveTourStatus({
                        step: 4,
                      })
                    );
                  }
                }
              }}
            />
            <h5>Invite to Projects (optional)</h5>
            <div className="w-100 mb-3">
              <RSelectAsync
                isMulti
                isClearable={false}
                url="/initiatives/list/dropdown"
                getOptionLabel={option => option.name}
                placeholder="Select Project"
                onChange={value => {
                  setFieldValue('projects', value ? value : []);
                }}
                value={values.projects}
                components={{
                  Option: CustomOption,
                  MultiValueLabel,
                  MultiValueContainer,
                }}
                styles={{
                  multiValue: base => ({
                    ...base,
                    background: '#F4F5F7',
                    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
                    borderRadius: '4px',
                    padding: '4px 5px',
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '12px',
                    color: '#32325d',
                  }),
                }}
              />
            </div>
            <h5>Role</h5>
            <div className="w-100 mb-3">
              <RoleSelector
                showDetails
                options={roles}
                selectedValue={values.role}
                tooltipPlacement="top"
                handleChange={(id, values) => setFieldValue('role', values)}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between pt-0">
          <Button
            onClick={() => {
              handleReset();
              handleCloseModal();
            }}
            color="link"
            className={'float-right'}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            id="inviteUsersBtn"
            onClick={handleSubmit}
            disabled={!isValid || isInviting}
            color={!isValid || isInviting ? 'secondary' : 'primary'}
            className={'float-left'}
            loading={isInviting || isLoading}
          >
            Invite
          </Button>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      toggle={handleCloseModal}
      centered
      id="inviteUserModal"
      isOpen={isModalOpen}
      title="Invite Members"
      size="md"
    >
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={InviteUserSchema}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          const res = await submitValues(values);
          if (res) {
            resetForm();
          }
        }}
      >
        {RenderForm}
      </Formik>
    </Modal>
  );
};

export default InviteUserForm;
