import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState } from 'react';
// nodejs library that concatenates classes
import cs from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
// reactstrap components
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from 'reactstrap';

// core components
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { doLoginWithoutRedirect } from 'store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { validatePassword } from 'helpers/constants';
import history from 'helpers/history';
import classes from './LockScreen.module.scss';
import ButtonSocialLogin from 'components/ButtonSocialLogin';

const LockScreen = props => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [focus, setFocus] = useState({});
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
  });

  const onHandleSubmit = async values => {
    const email = get(auth, 'user.email', '');
    const password = values.password;

    setLoading(true);
    const rs = await dispatch(doLoginWithoutRedirect(email, password));
    if (rs === 0) setLoading(false);
  };

  const renderLockScreen = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  }) => {
    return (
      <Form role="form" onSubmit={handleSubmit} className="pt-3">
        <FormGroup
          className={cs({
            focused: focus.password,
          })}
        >
          <Input
            name="password"
            placeholder="Password"
            type="password"
            error={errors.password}
            value={values.password}
            onChange={handleChange}
            touched={touched.password}
            onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
            leftIcon={<i className="ni ni-lock-circle-open" />}
          />
        </FormGroup>
        <div className={cs('text-center', classes.unlock)}>
          <Button className="mt-2" color="info" type="submit" loading={loading}>
            Unlock
          </Button>
        </div>
        <div
          className={cs(
            'btn-wrapper text-center border-top',
            classes.googleWrapper
          )}
        >
          <CardBody className="px-0 py-5">
            <ButtonSocialLogin history={props.history}>
              <span className="btn-inner--text">Google</span>
            </ButtonSocialLogin>
          </CardBody>
        </div>
      </Form>
    );
  };

  return (
    <>
      <AuthHeader
        title="Locked"
        lead="Looks like you're away, let's protect your account"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="card-profile bg-secondary mt-5">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <img
                      alt="avatar"
                      className={cs(
                        'rounded-circle border-secondary',
                        classes.avatar
                      )}
                      src={auth.user.avatar}
                    />
                  </div>
                </Col>
              </Row>
              <CardBody className={cs('px-5', classes.lockScreenBody)}>
                <div className="text-center mb-4">
                  <h3>{auth.user.name}</h3>
                </div>

                {auth.isError === true && auth.message && (
                  <Alert
                    text={auth.message}
                    dismiss={true}
                    fade={true}
                    color="danger"
                  />
                )}

                <Formik
                  initialValues={{
                    password: '',
                  }}
                  validationSchema={schema}
                  enableReinitialize
                  onSubmit={onHandleSubmit}
                >
                  {renderLockScreen}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Row className="justify-content-center">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/auth/email/reset-password');
                  }}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/auth/login');
                  }}
                >
                  <small>Back to Sign In</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LockScreen;
