import React, { useEffect, useCallback, useState, useMemo } from 'react';
import useBreakPoint from 'helpers/useBreakPoint';
import { Navbar, Row, Container, Col } from 'reactstrap';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import classes from './projects.module.scss';
import classNames from 'classnames';
import { useParams, useHistory, Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getProject as getProjectAction } from 'store/actions/projects';
import EpicsList from 'views/pages/Epics/EpicsList';
import Backlogs from '../Backlogs';
import PropTypes from 'prop-types';
import Teams from './Teams';
import {
  permissions,
  useAccess,
  showUnAuhtorizedError,
} from 'helpers/permission';
import Kanban from 'views/pages/Project/Kanban';
import Dashboard from 'views/pages/Project/Dashboard';
import compact from 'lodash/compact';
import toNumber from 'lodash/toNumber';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import flatMap from 'lodash/flatMap';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { updateActiveTourStatus } from 'store/actions/profile';
import Loading from 'components/Loading';
import replace from 'lodash/replace';
import ProjectHeader from 'views/pages/Project/ProjectHeader';
import ActionBar from 'views/pages/Project/ActionBar';
import UnderCollaboration from 'views/pages/Project/UnderCollaboration';

const ViewProject = ({ isMockUp, mockUpPage, mockUpData, ...rest }) => {
  const history = useHistory();
  const analyticsSendEvent = ({ ...rest }) => {
    if (!isMockUp)
      analytics.sendEvent({
        category: analyticsConstants.category.backlog,
        ...rest,
      });
  };
  const params = useParams();
  const id = get(params, 'id');
  const page = isMockUp ? mockUpPage : get(params, 'page', '');
  const loggedInUserID = useSelector(({ auth }) => get(auth, 'user.id', null));
  const useGetSelectedProjectProperty = (property, defaultValue) =>
    useSelector(({ project }) => {
      return !isMockUp
        ? get(project, `getProject.${property}`, defaultValue)
        : get(mockUpData, replace(property, 'data.data.', ''));
    }, isEqual);
  const isGetProjectLoading = useGetSelectedProjectProperty(
    'isInProgress',
    true
  );
  const projectMembers = useGetSelectedProjectProperty(
    'data.data.team_members',
    []
  );
  const isSelectedProjectError = useGetSelectedProjectProperty(
    'isError',
    false
  );
  const selectedProjectErrorMessage = useGetSelectedProjectProperty(
    'message',
    ''
  );

  const reducerId = useGetSelectedProjectProperty('id');
  const isMemberOfProject =
    projectMembers.findIndex(m => m.id === loggedInUserID) > -1;
  const isUserAssignedProject = useSelector(({ auth }) =>
    includes(get(auth, 'user.assigned_initiatives', []), toNumber(id))
  );
  const isUserAllowedViewProject = useAccess({
    permission: permissions.VIEW_ALL_PROJECTS,
    value: isUserAssignedProject,
    operation: 'or',
  });

  const selectedStories = useSelector(({ backlog }) =>
    get(backlog, 'selectedStories', {})
  );
  const getSelectedStoriesList = useCallback(() => flatMap(selectedStories), [
    selectedStories,
  ]);

  const totalSelectedStoriesCount = useMemo(
    () => getSelectedStoriesList().length,
    [getSelectedStoriesList]
  );

  const shouldShowTeam = useAccess([
    permissions.VIEW_ALL_PROJECTS_TEAM,
    { permission: permissions.VIEW_MY_PROJECTS_TEAM, value: isMemberOfProject },
  ]);

  const shouldShowBoard = useAccess([
    permissions.VIEW_ALL_PROJECTS_KANBAN,
    {
      permission: permissions.VIEW_MY_PROJECTS_KANBAN,
      value: isMemberOfProject,
    },
  ]);

  const shouldShowDashboard = useAccess([
    permissions.VIEW_ALL_PROJECTS_DASHBOARD,
    {
      permission: permissions.VIEW_MY_PROJECTS_DASHBOARD,
      value: isMemberOfProject,
    },
  ]);
  const isAllowedEdit = useAccess([
    permissions.EDIT_ALL_STORIES,
    { permission: permissions.EDIT_PROJECT_STORIES, value: isMemberOfProject },
  ]);

  const isSectionDeleteAllowed = useAccess([
    permissions.DELETE_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.DELETE_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isSectionEditAllowed = useAccess([
    permissions.EDIT_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.EDIT_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintEditAllowed = useAccess([
    permissions.EDIT_ALL_SPRINTS,
    {
      permission: permissions.EDIT_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintViewAllowed = useAccess([
    permissions.VIEW_ALL_SPRINTS,
    {
      permission: permissions.VIEW_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintDeleteAllowed = useAccess([
    permissions.DELETE_ALL_SPRINTS,
    {
      permission: permissions.DELETE_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintAddAllowed = useAccess([
    permissions.ADD_SPRINTS_TO_ALL_PROJECTS,
    {
      permission: permissions.ADD_SPRINTS_TO_MY_PROJECTS,
      value: isMemberOfProject,
    },
  ]);

  const isAddStoryToSprintAllowed = useAccess([
    permissions.ADD_STORIES_TO_ALL_PROJECT_SPRINTS,
    {
      permission: permissions.ADD_STORIES_TO_MY_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  const isAddStoryAllowed = useAccess([
    permissions.ADD_STORIES_TO_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.ADD_STORIES_TO_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isStoryReorderAllowed = useAccess([
    permissions.PRIORITIZE_ALL_PROJECTS_BACKLOG,
    {
      permission: permissions.PRIORITIZE_MY_PROJECTS_BACKLOG,
      value: isMemberOfProject,
    },
  ]);

  const isStoryEditAllowed = useAccess([
    permissions.EDIT_ALL_STORIES,
    { permission: permissions.EDIT_PROJECT_STORIES, value: isMemberOfProject },
  ]);

  const isStoryDeleteAllowed = useAccess(permissions.DELETE_ALL_STORIES);

  const isStoryViewAllowed = useAccess([
    permissions.VIEW_ALL_STORIES,
    { permission: permissions.VIEW_PROJECT_STORIES, value: isMemberOfProject },
  ]);

  const isStoryDuplicateAllowed = useAccess([
    permissions.DUPLICATE_ALL_STORIES,
    { permission: permissions.DUPLICATE_MY_STORIES, value: isMemberOfProject },
  ]);

  // creating contents option here as we need to check conditions from project data
  const contents = compact([
    (shouldShowDashboard || isMockUp) && {
      name: 'Dashboard',
      iconClassName: 'fas fa-th-large text-xs',
      gradientColor: 'green',
      pageLink: 'dashboard',
    },
    {
      name: 'Backlog',
      iconClassName: 'ni ni-bullet-list-67',
      gradientColor: 'info',
      pageLink: 'backlog',
      id: 'backlogTab',
    },
    (shouldShowBoard || isMockUp) && {
      name: 'Boards',
      iconClassName: classNames('ni ni-chart-bar-32', classes['invert-icon']),
      gradientColor: 'purple',
      pageLink: 'boards',
    },
    (shouldShowTeam || isMockUp) && {
      name: 'Team',
      iconClassName: 'fas fa-users',
      gradientColor: 'red',
      pageLink: 'team',
      id: 'teamsTab',
    },
  ]);
  const projectName = useGetSelectedProjectProperty('data.data.name', '');
  const projectId = useGetSelectedProjectProperty('data.data.id', '');

  const activeContent = find(contents, obj => obj.pageLink === page);
  const isMobile = useBreakPoint('sm', 'down');
  const dispatch = useDispatch();
  const runningTime = useSelector(({ timer }) => get(timer, 'runningTime'));

  // useEffect(() => {
  //   socket.kanbanStoryChanges(id);
  // }, [id]);

  useEffect(() => {
    if (!isMockUp) {
      dispatch(getProjectAction(id));
      dispatch(fetchStoryStatuses());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onOptionClick = async content => {
    if (
      (quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'story_creation') ||
      quickStartTour.activeTour === 'invite_team'
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step: null,
            nextStep: 6,
          })
        );
      }, 1000);
    }
    history.replace(`/admin/projects/${id}/${content.pageLink}`);
  };

  const { data: preferences = [] } = useSelector(
    ({ profile }) => profile.preference
  );
  const [collapsePreference] = filter(
    preferences,
    element =>
      get(element, 'category') === 'epic' &&
      get(element, 'key') === 'toggle_epics_window'
  );
  const [collapse, setCollapse] = useState(
    !isEqual(get(collapsePreference, 'value', 'expand'), 'expand')
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const renderContent = useCallback(() => {
    if (isGetProjectLoading || (projectId !== toNumber(id) && !isMockUp)) {
      return <div />;
    } else {
      switch (get(activeContent, 'name', '')) {
        case 'Dashboard':
          return shouldShowDashboard ? (
            <Dashboard />
          ) : (
            <Redirect to={`/admin/projects/${id}/${contents[0].pageLink}`} />
          );
        case 'Backlog': {
          return (
            <>
              {!isMobile && (
                <EpicsList
                  collapse={collapse}
                  setCollapse={setCollapse}
                  id={projectId}
                  collapsePreference={collapsePreference}
                />
              )}
              <Col
                md={!isMobile && 'auto'}
                className={classNames({
                  [classes['content-body']]: !isMobile,
                  [classes.fullWidth]: collapse,
                })}
              >
                <Backlogs
                  projectId={id}
                  isSectionEditAllowed={isSectionEditAllowed}
                  isSectionDeleteAllowed={isSectionDeleteAllowed}
                  isAddStoryAllowed={isAddStoryAllowed}
                  isStoryReorderAllowed={isStoryReorderAllowed}
                  isStoryEditAllowed={isStoryEditAllowed}
                  isStoryDeleteAllowed={isStoryDeleteAllowed}
                  isStoryViewAllowed={isStoryViewAllowed}
                  isStoryDuplicateAllowed={isStoryDuplicateAllowed}
                  isSprintEditAllowed={isSprintEditAllowed}
                  isSprintViewAllowed={isSprintViewAllowed}
                  isSprintDeleteAllowed={isSprintDeleteAllowed}
                  isSprintAddAllowed={isSprintAddAllowed}
                  isAddStoryToSprintAllowed={isAddStoryToSprintAllowed}
                />
              </Col>
            </>
          );
        }
        case 'Team': {
          return shouldShowTeam ? (
            <Teams id={projectId} />
          ) : (
            <Redirect to={`/admin/projects/${id}/${contents[0].pageLink}`} />
          );
        }

        case 'Boards': {
          return shouldShowBoard || isMockUp ? (
            <Kanban
              projectId={projectId}
              isAllowedEdit={isAllowedEdit}
              isMockUp={isMockUp}
              isSprintKanban={false}
              {...rest}
            />
          ) : (
            <Redirect to={`/admin/projects/${id}/${contents[0].pageLink}`} />
          );
        }

        default: {
          return <UnderCollaboration />;
        }
      }
    }
  }, [
    activeContent,
    collapse,
    collapsePreference,
    contents,
    id,
    isAddStoryAllowed,
    isAddStoryToSprintAllowed,
    isAllowedEdit,
    isGetProjectLoading,
    isMobile,
    isMockUp,
    isSectionDeleteAllowed,
    isSectionEditAllowed,
    isSprintAddAllowed,
    isSprintDeleteAllowed,
    isSprintEditAllowed,
    isSprintViewAllowed,
    isStoryDeleteAllowed,
    isStoryDuplicateAllowed,
    isStoryEditAllowed,
    isStoryReorderAllowed,
    isStoryViewAllowed,
    projectId,
    rest,
    shouldShowBoard,
    shouldShowDashboard,
    shouldShowTeam,
  ]);
  if (!isUserAllowedViewProject && page && !isMockUp) {
    showUnAuhtorizedError();
  }

  if (projectId === toNumber(id) && !isGetProjectLoading && !activeContent) {
    return <Redirect to={`/admin/projects/${id}/${contents[0].pageLink}`} />;
  }

  if (
    isSelectedProjectError &&
    reducerId === id &&
    isUserAllowedViewProject &&
    !isMockUp
  ) {
    NotificationHandler.open({
      message:
        selectedProjectErrorMessage || 'You do not have access to that page',
      operation: 'failure',
    });
    return <Redirect to="/admin/dashboard" />;
  }
  if (!page) {
    return <Redirect to={`/admin/projects/${id}/dashboard`} />;
  }

  return (
    <div className={classNames(classes['project-profile'])}>
      <Navbar
        className={classNames(
          'navbar-top navbar-expand p-0',
          classes['project-navbar'],
          { [classes['project-navbar-timer-on']]: runningTime && !isMockUp }
        )}
      >
        {isGetProjectLoading && <Loading wrapperClass={classes.loading} />}

        <Container
          fluid
          className={classNames(classes['action-bar'], 'flex-wrap')}
        >
          <ProjectHeader
            projectName={projectName}
            projectId={projectId}
            isMemberOfProject={isMemberOfProject}
            analyticsSendEvent={analyticsSendEvent}
            contents={contents}
            onOptionClick={onOptionClick}
            activeContent={activeContent}
          />
          {totalSelectedStoriesCount > 0 &&
            activeContent.name === 'Backlog' && (
              <ActionBar
                totalSelectedStoriesCount={totalSelectedStoriesCount}
                isMemberOfProject={isMemberOfProject}
                projectId={projectId}
                analyticsSendEvent={analyticsSendEvent}
                getSelectedStoriesList={getSelectedStoriesList}
              />
            )}
        </Container>
      </Navbar>
      <Row noGutters className={classes.mainWrapper}>
        {renderContent()}
      </Row>
    </div>
  );
};

ViewProject.propTypes = {
  isMockUp: PropTypes.bool,
};
ViewProject.defaultProps = {
  isMockUp: false,
  mockUpPage: 'boards',
};

export default ViewProject;
