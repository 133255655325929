import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import queryString from 'query-string';
import { Container, Col, Row } from 'reactstrap';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import RecentlyViewedProjects from './Fields/RecentlyViewedProjects';
import RecentlyViewedStories from './Fields/RecentlyViewedStories';
import WeeklyTimeLogs from './Fields/WeeklyTimeLogs';
import OpenAssignments from './Fields/OpenAssignments';
import TimeSpentGraph from './Fields/TimeSpentGraph';
import Greeting from './Fields/Greeting';
import RecentActivity from './Fields/RecentActivity';
import StoryModal from '../Backlogs/Stories/StoryModal';
import {
  getViewedProjects,
  getWeeklyHoursLogged,
  getOpenAssignments,
  getTimeSpent,
  getViewedStories,
} from 'store/actions/userDashboard';
import { fetchStory } from 'store/actions/Story/details';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import { fetchStoryPriorities } from 'store/actions/Story/priorities';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { fetchComplexities } from 'store/actions/Story/complexities';
import { fetchScoreMatrix } from 'store/actions/Story/scoreMatrix';
import analytics, { analyticsConstants } from 'helpers/analytics';
import PersonalDashboardQuickActions from '../QuickActions/PersonalDashboardQuickActions';
import DuplicateStoryForm from 'views/pages/Backlogs/Stories/DuplicateStory.Form';
import forEach from 'lodash/forEach';
import duplicateFields from 'views/pages/Project/duplicateFields';
import some from 'lodash/some';
import { duplicateStoryForSection } from 'store/actions/backlogs';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const Dashboard = () => {
  const dispatch = useDispatch();
  const analyticsSendEvent = (action, label, value) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_dashboard,
      action,
      label,
      value,
    });
  };
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  useEffect(() => {
    analyticsSendEvent(analyticsConstants.action.view_personal_dashboard);
    dispatch(getOpenAssignments());
    dispatch(getWeeklyHoursLogged());
    dispatch(getViewedProjects());
    dispatch(getViewedStories());
    dispatch(getTimeSpent());
    dispatch(fetchStoryStatuses());
    dispatch(fetchStoryPriorities());
    dispatch(fetchComplexities());
    dispatch(fetchScoreMatrix());
  }, [dispatch]);

  const isMobile = useBreakPoint('sm', 'down');
  const isTablet = useBreakPoint('md', 'down');
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);

  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('userDashboard', field, defaultValue);
  const commonProps = {
    isMobile,
    getDetails: useGetDetails,
    isTablet,
    analyticsConstants,
  };

  const history = useHistory();
  const [storyId, setStoryId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [comment, setComment] = useState(null);
  const tab = useRef('comment');
  const [count, setCount] = useState(0);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);

  const getStoryDetail = async story => {
    const { status, data } = await dispatch(fetchStory(story));
    if (status === 1) {
      setStoryId(story);
      setProjectId(get(data, 'initiative.id', ''));
      analyticsSendEventStory({
        action: analyticsConstants.action.open_story,
        opened_from: 'User Dashboard Recent Activity',
      });
      setIsStoryModalOpen(true);
    }
  };

  const reload = () => setCount(count + 1);

  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);
  const storyName = useGetFieldValue('name');

  const closeDuplicateModal = () => setDuplicateModal(false);

  const openDuplicateModal = () => setDuplicateModal(true);

  const handleDuplicateStory = async values => {
    setDuplicating(true);
    const { data: duplicatedStory, status, errorStatus } = await dispatch(
      duplicateStoryForSection(storyId, values, null)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showStorageLimit();
      return;
    }
    if (status) {
      history.push(`/admin/projects/${duplicatedStory.initiative.id}/backlog`);
    }
    setDuplicating(false);
    setDuplicateModal(false);
  };

  return (
    <>
      <Greeting {...commonProps} />
      <Container className="mt--4" fluid>
        <Row>
          <Col className={cx(!isMobile && 'pl-0')} md={7}>
            <Row>
              <Col md={12}>
                <PersonalDashboardQuickActions
                  {...{ setStoryId, setProjectId, setIsStoryModalOpen }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <WeeklyTimeLogs {...commonProps} />
              </Col>
              <Col md={6}>
                <OpenAssignments {...commonProps} />
              </Col>
            </Row>
            <TimeSpentGraph {...commonProps} />
            <Row>
              <Col>
                <RecentlyViewedStories {...commonProps} />
              </Col>
            </Row>
          </Col>
          <Col className={cx(!isMobile && 'pr-0')} md={5}>
            <RecentlyViewedProjects {...commonProps} />
            <RecentActivity
              onStoryClick={url => {
                const params = queryString.parseUrl(url);
                setComment(get(params, 'query.comment'));
                tab.current = get(params, 'query.tab', tab.current);
                getStoryDetail(get(params, 'query.story'));
              }}
              {...commonProps}
            />
          </Col>
        </Row>
        {isStoryModalOpen && (
          <StoryModal
            storyId={toNumber(storyId)}
            projectId={toNumber(projectId)}
            isOpen={isStoryModalOpen}
            closeModal={() => {
              setIsStoryModalOpen(false);
              tab.current = 'comment';
              history.push(`?`);
            }}
            currentTab={tab.current}
            onChange={() => reload()}
            comment={comment}
            openDuplicateModal={openDuplicateModal}
          />
        )}
        {isDuplicateModal ? (
          <DuplicateStoryForm
            storyData={{ name: storyName }}
            isModalOpen={isDuplicateModal}
            closeModal={closeDuplicateModal}
            submitValues={handleDuplicateStory}
            isLoading={isDuplicating}
          />
        ) : null}
      </Container>
    </>
  );
};

export default Dashboard;
