import React from 'react';
import { Badge } from 'reactstrap';
import classes from './BadgePro.module.scss';
import DiamondIcon from 'assets/img/icons/bade-pro-diamond.svg';
const BadgePro = () => {
  return (
    <Badge color="info" pill className={classes.root}>
      Pro <img src={DiamondIcon} alt="Badge Pro" />
    </Badge>
  );
};

export default BadgePro;
