import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import cs from 'classnames';

const CardsHeader = props => {
  const {
    currentPage = '',
    name = '',
    parentName = '',
    parentLink = '',
    childName = '',
    childLink = '',
    dark,
    isRoot,
    showActionMenu = false,
    actionMenu = false,
  } = props;
  return (
    <>
      <div className="header">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col className="d-flex align-items-center">
                <h2
                  className={cs('d-inline-block mb-0 ', {
                    'text-white': dark === true,
                  })}
                >
                  {currentPage
                    ? currentPage
                    : name.length > 25
                    ? `${name.slice(0, 24)}...`
                    : name}
                </h2>{' '}
                {showActionMenu ? actionMenu : null}
                {!isRoot && (
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName={cs('breadcrumb-links', {
                      'breadcrumb-dark ': dark === true,
                    })}
                  >
                    <BreadcrumbItem>
                      <Link to="/admin/dashboard">
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={parentLink}>
                        {parentName.length > 25
                          ? `${parentName.slice(0, 24)}...`
                          : parentName}
                      </Link>
                    </BreadcrumbItem>
                    {childName ? (
                      <BreadcrumbItem>
                        <Link to={childLink}>
                          {childName.length > 25
                            ? `${childName.slice(0, 24)}...`
                            : childName}
                        </Link>
                      </BreadcrumbItem>
                    ) : null}
                    <BreadcrumbItem aria-current="page" className="active">
                      {name.length > 25 ? `${name.slice(0, 24)}...` : name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  currentPage: PropTypes.string,
  dark: PropTypes.bool,
  isRoot: PropTypes.bool,
  parentLink: PropTypes.string,
};

CardsHeader.defaultProps = {
  dark: false,
};
export default CardsHeader;
