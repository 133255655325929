import Request from './request';

export const fetchList = async (page, sort, query) => {
  return Request.call({
    url: '/clients',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
    },
  });
};

export const update = async (id, data) => {
  return Request.call({
    url: `/clients/${id}`,
    method: 'PUT',
    data,
  });
};

export const remove = async id => {
  return Request.call({
    url: `/clients/${id}`,
    method: 'DELETE',
  });
};

export const create = async data => {
  return Request.call({
    url: '/clients',
    method: 'POST',
    data,
  });
};
