import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useBreakPoint from 'helpers/useBreakPoint';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import difference from 'lodash/difference';
import map from 'lodash/map';
import each from 'lodash/each';
import fill from 'lodash/fill';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import cx from 'classnames';
import Button from 'components/Button';
import { Bar } from 'react-chartjs-2';
import truncate from 'lodash/truncate';
import replace from 'lodash/replace';
import classes from './TimeLoggedGraph.module.scss';
import pluralize from 'pluralize';
import NoTimeLog from 'assets/img/theme/No_TimeLog.svg';
import every from 'lodash/every';
import Loading from 'components/Loading';
import analytics, { analyticsConstants } from 'helpers/analytics';
import capitalize from 'lodash/capitalize';

const TimeLoggedGraph = ({
  data,
  loading,
  timePeriod,
  onChange: finalOnChange,
  fieldName,
  wrapperClassName,
  title,
  analyticsObj,
}) => {
  const [groupName, setGroupName] = useState(timePeriod);
  useEffect(() => {
    setGroupName(timePeriod);
  }, [timePeriod]);
  const weekNavClick = useRef(0);
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const isMobile = useBreakPoint('xs', 'down');
  const startDate =
    groupName === 'month'
      ? moment(get(data, 'date_from'))
          .tz(userTimeZone)
          .format('MMMM YYYY')
      : moment(get(data, 'date_from'))
          .tz(userTimeZone)
          .format('MMM DD, YYYY');
  const endDate =
    groupName === 'month'
      ? moment(get(data, 'date_end'))
          .tz(userTimeZone)
          .format('MMMM YYYY')
      : moment(get(data, 'date_end'))
          .tz(userTimeZone)
          .format('MMM DD, YYYY');

  const onChange = (currentTimePeriod, ...rest) => {
    if (weekNavClick.current === -1) {
      analytics.sendEvent({
        action: 'View past week in Time Logged on Projects widget',
        label: analyticsConstants.action.view_timelogged_graph,
        ...analyticsObj,
      });
    } else if (currentTimePeriod !== groupName) {
      analytics.sendEvent({
        action: `Switch to ${capitalize(
          currentTimePeriod
        )} in Time Logged on Projects widget`,
        label: analyticsConstants.action.view_timelogged_graph,
        ...analyticsObj,
      });
    }
    finalOnChange(currentTimePeriod, ...rest);
  };
  const dataReq = get(data, 'graph_data[0].data', []);
  const isChartDataEmpty = useMemo(() => every(dataReq, isEmpty), [dataReq]);
  const parseChartData = useCallback(
    (groupName, data, chartField) => {
      const stackVal = Math.ceil(Math.random() * 100).toString();
      if (isEmpty(dataReq)) return { labels: [], datasets: [] };
      if (groupName === 'week') {
        const weeks = keys(dataReq);
        const weekNums = sortBy(
          weeks.map(week => parseInt(moment(week, 'ddd').format('d'))),
          x => x
        );
        weekNums.push(weekNums.shift());
        const labels = map(weekNums, elem => moment(elem, 'd').format('ddd'));
        let dataSets = [];
        each(labels, (day, dayIndex) => {
          const monthData = dataReq[day];
          each(monthData, details => {
            const label = get(details, `${chartField}.name`);
            const data = {
              stack: stackVal,
              data: [
                ...fill(Array(dayIndex), null),
                details.hours,
                ...fill(Array(weekNums.length - dayIndex - 1), null),
              ],
              label,
              backgroundColor:
                '#' + replace(get(details, `${chartField}.color`), '#', ''),
              maxBarThickness: 10,
            };
            dataSets.push(data);
          });
        });
        return { labels, datasets: dataSets };
      }
      const months = keys(dataReq);
      const monthNums = sortBy(
        months.map(month => parseInt(moment(month, 'MMMM').format('MM'))),
        x => x
      );
      const findDiffMonth = monthArray => {
        for (let i = 0; i < monthArray.length - 1; i++) {
          if (monthArray[i + 1] - monthArray[i] > 1) return i + 1;
        }
        return null;
      };
      const orderedArray = monthArray => {
        const arrayUnorderedIndex = findDiffMonth(monthArray);
        if (arrayUnorderedIndex) {
          const arrayToShift = monthArray.filter(
            (elem, i) => i >= arrayUnorderedIndex
          );
          return [...arrayToShift, ...difference(monthArray, arrayToShift)];
        } else return monthNums;
      };
      const arrayOrder = orderedArray(monthNums);
      arrayOrder.splice(0, arrayOrder.length - 7);

      const labels = map(arrayOrder, elem => moment(elem, 'MM').format('MMM'));
      let dataSets = [];
      each(arrayOrder, (monthNumber, monthIndex) => {
        const monthData = dataReq[moment(monthNumber, 'MM').format('MMMM')];

        each(monthData, details => {
          const label = get(details, `${chartField}.name`);
          const data = {
            stack: stackVal,
            data: [
              ...fill(Array(monthIndex), null),
              details.hours,
              ...fill(Array(arrayOrder.length - monthIndex - 1), null),
            ],
            key: Math.random(),
            label,
            backgroundColor:
              '#' + replace(get(details, `${chartField}.color`), '#', ''),
            maxBarThickness: 10,
          };
          dataSets.push(data);
        });
      });
      return { labels, datasets: dataSets };
    },
    [dataReq]
  );

  const chartData = useMemo(() => parseChartData(groupName, data, fieldName), [
    parseChartData,
    groupName,
    data,
    fieldName,
  ]);
  return (
    <Card className={cx('w-100', wrapperClassName, classes.card)}>
      <div className=" px-4 pt-4 pb-2 d-flex justify-content-between align-items-center border-bottom flex-wrap">
        <div className="flex-column">
          <h6 className="text-gray m-0 text-uppercase">{title}</h6>
          <div className="d-flex flex-nowrap">
            <h2>
              {startDate} - {endDate}
            </h2>
            <div
              className={cx(
                'ml-2 mb-1 justify-content-center',
                groupName === 'month' ? 'd-none' : 'd-flex'
              )}
            >
              <Button
                className="border-0 m-0"
                disabled={loading}
                onClick={() => {
                  --weekNavClick.current;
                  onChange(
                    'week',
                    moment(get(data, 'date_from'))
                      .tz(userTimeZone)
                      .subtract(7, 'days')
                      .format('YYYY/MM/DD'),
                    moment(get(data, 'date_end'))
                      .tz(userTimeZone)
                      .subtract(7, 'days')
                      .format('YYYY/MM/DD')
                  );
                }}
                outline
                color="primary"
                size="sm"
                id="previous-week"
              >
                <i style={{ fontSize: '16px' }} className="fas fa-angle-left" />
              </Button>
              <UncontrolledTooltip placement="bottom" target="previous-week">
                Previous Week
              </UncontrolledTooltip>
              <Button
                disabled={
                  moment(get(data, 'date_end')).isSameOrAfter(
                    moment(),
                    'day'
                  ) || loading
                }
                onClick={() => {
                  ++weekNavClick.current;
                  onChange(
                    'week',
                    moment(get(data, 'date_from'))
                      .tz(userTimeZone)
                      .add(7, 'days')
                      .format('YYYY/MM/DD'),
                    moment(get(data, 'date_end'))
                      .tz(userTimeZone)
                      .add(7, 'days')
                      .format('YYYY/MM/DD')
                  );
                }}
                className="border-0 m-0"
                outline
                color="primary"
                size="sm"
                id="next-week"
              >
                <i
                  style={{ fontSize: '16px' }}
                  className="fas fa-angle-right"
                />
              </Button>
              <UncontrolledTooltip placement="bottom" target="next-week">
                Next Week
              </UncontrolledTooltip>
            </div>
          </div>
        </div>
        <div className="d-flex flex-nowrap">
          <Button
            onClick={async () => {
              weekNavClick.current = 0;
              await onChange('month');
              setGroupName('month');
            }}
            outline
            active={groupName === 'month'}
            color="primary"
          >
            Month
          </Button>
          <Button
            outline
            onClick={async () => {
              weekNavClick.current = 0;
              await onChange('week');
              setGroupName('week');
            }}
            active={groupName === 'week'}
            color="primary"
          >
            Week
          </Button>
        </div>
      </div>
      <CardBody
        className={cx(
          isChartDataEmpty &&
            'd-flex flex-column align-items-center justify-content-center',
          'position-relative'
        )}
      >
        {loading && (
          <Loading
            key="loader"
            wrapperClass={classes.loaderOverlay}
            size="7.5rem"
          />
        )}
        {isChartDataEmpty ? (
          <img
            className={cx(classes.noTimeLogImg, 'm-auto w-25')}
            src={NoTimeLog}
            alt="No Time Logged"
          />
        ) : (
          <Bar
            data={chartData}
            options={{
              animation: false,
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: 0,
              },
              legend: {
                display: false,
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  padding: 16,
                },
              },
              elements: {
                point: {
                  radius: 0,
                },
                line: {
                  tension: 0.4,
                  borderWidth: 4,
                  borderCapStyle: 'rounded',
                },
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      drawOnChartArea: false,
                      drawTicks: false,
                    },
                    ticks: {
                      beginAtZero: true,
                      padding: 20,
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      borderDash: [2],
                      borderDashOffset: [2],
                      drawBorder: false,
                      drawTicks: false,
                      lineWidth: 1,
                      zeroLineWidth: 1,
                      zeroLineBorderDash: [2],
                      zeroLineBorderDashOffset: [2],
                    },
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      suggestedMax: groupName === 'week' ? 10 : 250,
                      stepSize: groupName === 'week' ? 2 : 50,
                      callback: number => {
                        return number;
                      },
                    },
                  },
                ],
              },
              tooltips: {
                enabled: true,
                mode: 'x',
                intersect: false,
                position: 'average',
                callbacks: {
                  label(item, data) {
                    if (item.yLabel) {
                      const label =
                        data.datasets[item.datasetIndex].label || '';
                      const { yLabel } = item;
                      let content = '';
                      content += truncate(label, {
                        length: isMobile ? 25 : 50,
                      });
                      content += `: ${pluralize('hr', yLabel, true)}`;
                      return content;
                    }
                  },
                },
              },
            }}
            datasetKeyProvider={() => Math.random()}
            className="chart-canvas"
            id="chart-bars"
          />
        )}
      </CardBody>
    </Card>
  );
};

TimeLoggedGraph.propType = {
  data: PropTypes.shape({
    date_from: PropTypes.instanceOf(moment),
    date_end: PropTypes.instanceOf(moment),
    graph_data: PropTypes.array,
  }),
  loading: PropTypes.bool,
  timePeriod: PropTypes.string,
  onChange: PropTypes.func,
  wrapperClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
};

TimeLoggedGraph.defaultProps = {
  data: {
    data: {
      graph_data: [],
    },
  },
  timePeriod: 'week',
  loading: false,
  onChange: () => {},
  wrapperClassName: '',
  title: 'Time Logged On Projects',
  fieldName: 'project',
  analyticsObj: {},
};
export default TimeLoggedGraph;
