import React from 'react';
import classNames from 'classnames';
import classes from 'views/pages/Project/projects.module.scss';
import ViewProjectImg from 'assets/img/theme/View_Project.svg';
import { Col } from 'reactstrap';

const UnderCollaboration = () => {
  return (
    <Col>
      <div className="d-flex justify-content-center align-items-center flex-column mw-100">
        <img
          className={classNames(classes['image-max-width'], 'w-100 h-100')}
          src={ViewProjectImg}
          alt="View Project"
        />
        <h4 className="display-4 text-center mb-0">
          Currently under collaboration!
        </h4>
        <p className="text-center pl-2 pr-2">
          We’re currently building a simpler way to create and collaborate.
        </p>
      </div>
    </Col>
  );
};

export default UnderCollaboration;
