import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState } from 'react';
import cx from 'classnames';
import { goToStripeCheckout } from 'api/billing';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Button from 'components/Button';
import { isAdmin } from 'helpers/permission';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import eventEmitter, { EMIT_EVENTS } from 'helpers/eventEmitter';
import history from 'helpers/history';
import analytics, { analyticsConstants } from 'helpers/analytics';

const SubscriptionOutdated = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const companyId = useSelector(({ auth }) => get(auth, 'user.company.id'));
  const allPlans =
    useSelector(({ billing }) => get(billing, 'plans.data')) || [];
  const proPlan = allPlans.find(p => p.name === 'Pro') || {};

  React.useEffect(() => {
    const onEvent = sub => {
      if (!isEmpty(sub)) {
        history.replace(`/admin/billing?success=true&companyId=${companyId}`);
      }
    };
    eventEmitter.on(EMIT_EVENTS.SUBSCRIPTION_CHANGE, onEvent);

    return () => {
      eventEmitter.off(EMIT_EVENTS.SUBSCRIPTION_CHANGE, onEvent);
    };
  }, [companyId]);

  if (!user) {
    return <Redirect to={'/auth/login'} />;
  }

  const getSessionUrl = async () => {
    try {
      analytics.sendEvent({
        category: analyticsConstants.category.billing,
        action: analyticsConstants.action.reactivate_account,
      });
      setIsLoading(true);
      await goToStripeCheckout(get(user, 'company.id'), proPlan.plan_id);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AuthHeader
        title="Welcome Back to Lyght!"
        lead="Looks like your account is no longer active or needs to be upgraded."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={cx('bg-secondary border-0', classes.card)}>
              <CardBody className={cx('px-lg-5 py-lg-5', classes.cardBody)}>
                {isAdmin() ? (
                  <>
                    <div className={cx('text-center', classes.text)}>
                      Re-activate your plan to access your account and start
                      collaborating with your team again
                    </div>
                    <div className={'mt-4 text-center'}>
                      <Button
                        color="lyght-blue"
                        className={cx('btn-primary', classes.manageBtn)}
                        onClick={() => getSessionUrl()}
                        loading={isLoading}
                      >
                        Re-activate Plan
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className={cx('text-center', classes.text)}>
                    Please contact your system administrator to re-activate your
                    account and start collaborating again!
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="6" md="8" className="text-center">
            <Link className="text-light" to={'/auth/login'}>
              Back to sign in
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubscriptionOutdated;
