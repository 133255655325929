import React from 'react';
import { CardBody, Card } from 'reactstrap';
import { permissions, useAccess } from 'helpers/permission';
import CreateStory from './CreateStory';
import CreateEpic from './CreateEpic';
import InviteTeamMember from './InviteTeamMember';
import ViewBacklog from './ViewBacklog';
import useBreakPoint from 'helpers/useBreakPoint';

const QuickActions = ({ isMemberOfProject, projectId }) => {
  const isInviteTeamMemberAllowed = useAccess(permissions.ADD_TEAM_MEMBER);
  const isCreateStoryAllowedInAllProjects = useAccess(
    permissions.ADD_STORIES_TO_ALL_BACKLOG_SECTIONS
  );
  const isCreateStoryAllowedInMyProjects = useAccess(
    permissions.ADD_STORIES_TO_PROJECT_BACKLOG_SECTIONS
  );
  const isCreateEpicInAllProjects = useAccess(
    permissions.ADD_EPICS_TO_ALL_PROJECTS
  );
  const isCreateEpicInMyProjects = useAccess(
    permissions.ADD_EPICS_TO_MY_PROJECTS
  );
  const isViewAllProjectsBacklog = useAccess(
    permissions.VIEW_ALL_PROJECTS_BACKLOG
  );
  const isViewMyProjectsBacklog = useAccess(
    permissions.VIEW_MY_PROJECTS_BACKLOG
  );
  const isMobile = useBreakPoint('sm', 'down');

  return (
    <Card className="d-flex">
      <CardBody className="d-flex flex-column position-relative pt-3">
        <h5 className="text-uppercase text-muted mb-0 card-title">
          Quick Actions
        </h5>
        <div className="d-flex w-100 mt-2 justify-content-between flex-wrap">
          {isCreateStoryAllowedInAllProjects ||
          (isCreateStoryAllowedInMyProjects && isMemberOfProject) ? (
            <CreateStory projectId={projectId} />
          ) : null}
          {isInviteTeamMemberAllowed ? (
            <InviteTeamMember projectId={projectId} />
          ) : null}
          {!isMobile &&
          (isCreateEpicInAllProjects ||
            (isCreateEpicInMyProjects && isMemberOfProject)) ? (
            <CreateEpic projectId={projectId} />
          ) : null}
          {isViewAllProjectsBacklog ||
          (isViewMyProjectsBacklog && isMemberOfProject) ? (
            <ViewBacklog projectId={projectId} />
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
};

export default QuickActions;
