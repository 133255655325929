import { stripePromise } from 'helpers/stripe';
import Request from './request';

export const getBillingSessionUrl = async (companyId, planId, currentSubId) => {
  return Request.call({
    url: `/companies/${companyId}/subscriptions/change`,
    method: 'POST',
    data: {
      plan: planId,
      current_sub_id: currentSubId,
      success_url: `${window.location.origin}/admin/billing?success=true&companyId=${companyId}`,
      cancel_url: `${window.location.origin}${window.location.pathname}`,
    },
  });
};

export const getBillingPlans = async companyId => {
  return Request.call({
    url: `/companies/${companyId}/subscriptions/plans`,
    method: 'GET',
  });
};

export const getManagePlanUrl = async companyId => {
  return Request.call({
    url: `/companies/${companyId}/subscriptions/manage`,
    method: 'GET',
  });
};

export const goToStripeCheckout = async (companyId, planId, currentSubId) => {
  const stripe = await stripePromise;
  const res = await getBillingSessionUrl(companyId, planId, currentSubId);
  if (res.session_id && res.redirect) {
    await stripe.redirectToCheckout({ sessionId: res.session_id });
  }
  return res;
};
