import {
  fetchEpics,
  createEpic,
  removeEpic as deleteEpic,
  editEpic,
  reOrderEpic,
} from 'api/epic';
import Types from '../types/epics';
import BacklogTypes from '../types/backlogs';
import { get, orderBy, findIndex } from 'lodash';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const fetchEpicsList = id => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_EPICS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchEpics(id);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_EPICS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_EPICS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_EPICS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const reorderEpics = (projectId, newOrder, newEpicData) => {
  return async dispatch => {
    dispatch({
      type: Types.REORDER_EPIC_INPROGRESS,
    });
    dispatch({
      type: Types.UPDATE_EPIC_STATE,
      data: newEpicData,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await reOrderEpic(projectId, newOrder);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.REORDER_EPIC_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.REORDER_EPIC_FAILURE,
          message: 'Something went wrong',
        });
      }
      //  await dispatch(fetchEpicsList(projectId));
    } catch (error) {
      dispatch({
        type: Types.REORDER_EPIC_FAILURE,
        message: error,
      });
    }
  };
};

export const addEpic = (id, name, epicData) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_EPIC_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createEpic(id, name);
      const status = get(resp, 'status');
      const epicId = get(resp, 'data.id');
      const elementToEnterBefore = findIndex(
        epicData,
        elem => elem.sort_order > resp.data.sort_order
      );
      const newEpicData = {
        data:
          elementToEnterBefore === -1
            ? [...epicData, resp.data]
            : epicData.splice(elementToEnterBefore - 1, 0, resp.data),
      };
      if (status) {
        dispatch({
          type: Types.CREATE_EPIC_SUCCESS,
          epicId,
          data: newEpicData,
        });
      } else {
        dispatch({
          type: Types.CREATE_EPIC_FAILURE,
          message: 'Something went wrong',
        });
      }
      // await dispatch(fetchEpicsList(id));
    } catch (error) {
      dispatch({
        type: Types.CREATE_EPIC_FAILURE,
        message: error,
      });
    }
  };
};

export const removeEpic = (projectId, data, epicId) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_EPIC_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteEpic(epicId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');

      if (status) {
        dispatch({
          type: Types.DELETE_EPIC_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.open({
          message,
          operation: 'success',
          icon: 'fas fa-bell',
          title: 'Epic Deleted Successfully',
        });
      } else {
        dispatch({
          type: Types.DELETE_EPIC_FAILURE,
          message: 'Something went wrong',
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
          icon: 'fas fa-bell',
          title: 'Something went wrong',
        });
      }
      await dispatch(fetchEpicsList(projectId));
    } catch (error) {
      dispatch({
        type: Types.DELETE_EPIC_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
        icon: 'fas fa-bell',
        title: 'Something went wrong',
      });
    }
  };
};

export const updateEpic = (projectId, data, epicId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_EPIC_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await editEpic(epicId, data);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.UPDATE_EPIC_SUCCESS,
          data: resp || {},
        });

        // Updating sections data here
        const sectionStoryState = get(
          getState(),
          `backlog.itemsState.data`,
          []
        );
        Object.keys(sectionStoryState).forEach(k => {
          const newSectionStoryData = sectionStoryState[k].data.map(story => {
            if (story.epic && story.epic.id === epicId) {
              return { ...story, epic: { ...story.epic, ...data } };
            } else {
              return { ...story };
            }
          });
          dispatch({
            type: BacklogTypes.UPDATE_STORIES_STATE,
            data: {
              sectionId: k,
              items: orderBy(newSectionStoryData, ['sort_order'], ['asc']),
            },
          });
        });
      } else {
        dispatch({
          type: Types.UPDATE_EPIC_FAILURE,
          message: 'Something went wrong',
        });
      }
      await dispatch(fetchEpicsList(projectId));
    } catch (error) {
      dispatch({
        type: Types.UPDATE_EPIC_FAILURE,
        message: error,
      });
    }
  };
};
