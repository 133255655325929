import React, { useState, useEffect } from 'react';
import ColorPicker from 'components/ColorPicker';
import replace from 'lodash/replace';

const Color = ({
  getFieldValue,
  onChange,
  isEditAllowed,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const [popOverState, setPopOverState] = useState();
  const defaultColor = '#' + getFieldValue('color');
  const [color, setColor] = useState(defaultColor);
  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);
  return (
    <>
      <h6 className="text-uppercase text-muted">Color</h6>
      <i
        id="project-color"
        onClick={() => isEditAllowed && setPopOverState(true)}
        className="text-lg fas fa-circle"
        style={{ color }}
      />
      <ColorPicker
        color={color}
        isOpen={popOverState}
        onChange={hex => setColor(hex)}
        targetElement="project-color"
        onChangeComplete={() => {
          setPopOverState(false);
          analyticsSendEvent({
            action: analyticsConstants.action.edit_project_color,
          });
          onChange('color', replace(color, '#', ''));
        }}
        onCancel={() => {
          setColor(defaultColor);
          setPopOverState(false);
        }}
      />
    </>
  );
};

export default Color;
