import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getTimeSpent } from 'store/actions/userDashboard';
import TimeLoggedGraph from 'views/widgets/TimeLoggedGraph';
const TimeSpentGraph = ({ getDetails, gaCategory }) => {
  const dispatch = useDispatch();
  const getData = getDetails('timeLogGraph.data', []);
  const loading = getDetails('timeLogGraph.isInProgress', []);
  return (
    <TimeLoggedGraph
      data={getData}
      timePeriod="week"
      loading={loading}
      onChange={async (timePeriod, startTime, endTime) => {
        await dispatch(getTimeSpent(timePeriod, startTime, endTime));
      }}
      fieldName="project"
      title="Time Logged On Projects"
      analyticsObj={{
        category: gaCategory,
      }}
    />
  );
};

TimeSpentGraph.propTypes = {
  getDetails: PropTypes.func,
};

export default TimeSpentGraph;
