import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import classes from './AssignmentCard.module.scss';
import Button from 'components/Button';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import Avatar from 'components/Avatar';

const AssignmentCard = ({
  assignmentId,
  dependency,
  projectName,
  assignmentTitle,
  dateDue,
  storyName,
  storyNumber,
  score,
  ownerAvatar,
  epicName,
  epicColor,
  epicBackground,
  progressColorFrom,
  progressColorTo,
  progressPercent,
  totalHrs,
  consumedHrs,
  openStoryModal,
}) => {
  return (
    <div className={classes.root} onClick={() => openStoryModal()}>
      <div
        className={classes.progressBar}
        style={{
          backgroundImage: `linear-gradient(to right,${progressColorFrom}80,${progressColorTo}80)`,
        }}
      >
        <div
          style={{
            backgroundImage: `linear-gradient(to right,${progressColorFrom},${progressColorTo})`,
            width: `${progressPercent}%`,
          }}
        >
          <span>
            {consumedHrs ? Math.round(consumedHrs * 10) / 10 : 0} /{' '}
            {totalHrs ? Math.round(totalHrs * 10) / 10 : 0} hrs
          </span>
        </div>

        {dependency && (
          <>
            <i
              id={`div-assignment-${assignmentId}`}
              className={cs('fas fa-lock', classes.locked)}
            ></i>

            <UncontrolledTooltip
              boundariesElement="window"
              innerClassName={classes.popoverLocked}
              placement="bottom"
              target={`#div-assignment-${assignmentId}`}
            >
              Dependent on: <b>{dependency.name}</b> <br />
              Assignee: <b>{dependency.user ? dependency.user.name : 'N/A'}</b>
            </UncontrolledTooltip>
          </>
        )}
      </div>
      <div className={classes.body}>
        <div className={classes.storyId}>
          <span>
            {projectName} - Story ID - {storyNumber}
          </span>
          <Avatar url={ownerAvatar} className={classes.avatar} />
        </div>
        <div className={classes.assignmentTitle}>{assignmentTitle || '-'}</div>
        <div className={classes.storyTitle}>{storyName || '-'}</div>
      </div>
      <div className={classes.footer}>
        <div className="d-flex align-items-center">
          {score ? (
            <Button color="primary" className={classes.score}>
              {score}
            </Button>
          ) : (
            <Button color="primary" className={classes.score}>
              +
            </Button>
          )}

          <span className={classes.owner}>
            {dateDue
              ? moment(dateDue, 'YYYY-MM-DD').format('MMM DD, YYYY')
              : '-'}
          </span>
        </div>

        {epicName && (
          <Badge
            pill
            className={classes.epic}
            style={{
              color: epicColor,
              background: epicBackground,
            }}
          >
            {epicName}
          </Badge>
        )}
      </div>
    </div>
  );
};

AssignmentCard.propTypes = {
  storyName: PropTypes.string.isRequired,
  storyNumber: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  epicName: PropTypes.string,
  epicColor: PropTypes.string.isRequired,
  epicBackground: PropTypes.string.isRequired,
  progressColorFrom: PropTypes.string.isRequired,
  progressColorTo: PropTypes.string.isRequired,
  progressPercent: PropTypes.number.isRequired,
  totalHrs: PropTypes.number.isRequired,
  consumedHrs: PropTypes.number.isRequired,
};
AssignmentCard.defaultProps = {};

export default AssignmentCard;
