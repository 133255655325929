import React, { useCallback, useEffect } from 'react';
import classes from '../Tabs.module.scss';
import { UncontrolledTooltip } from 'reactstrap';
import cs from 'classnames';
import StoryOwner from '../../../Fields/StoryOwner';
import CreatedBy from '../../../Fields/CreatedBy';
import StoryStatus from 'components/Status';
import StoryPriority from 'components/StoryPriority';
import useBreakPoint from 'helpers/useBreakPoint';
import ScrollBar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import analytics, { analyticsConstants } from 'helpers/analytics';

const StoryInfo = ({
  owner,
  projectName,
  priority,
  createdBy,
  updatedOn,
  createdOn,
  status,
  assignStoryOwner,
  isUserAllowedEdit,
  changeStatus,
  complexity,
  complexities,
  scoreMatrix,
  score,
  priorities,
  changePriority,
}) => {
  const isMobile = useBreakPoint('xs', 'down');
  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };

  const ConditionallyRenderScrollBar = useCallback(
    ({ children }) => {
      return isMobile ? (
        children
      ) : (
        <ScrollBar
          style={{
            maxHeight: '600px',
            marginRight: '-15px',
            paddingRight: '15px',
          }}
          options={{
            suppressScrollX: true,
          }}
        >
          {children}
        </ScrollBar>
      );
    },
    [isMobile]
  );

  return (
    <div className="d-flex justify-content-between h-100 flex-column">
      <ConditionallyRenderScrollBar>
        <StoryOwner
          onChange={assignStoryOwner}
          isEditAllowed={isUserAllowedEdit}
          info={owner}
        />
        <h4>Project</h4>
        <p
          id="tootip-project-name"
          className={cs(classes.projectName, classes['hide-overflow'])}
        >
          {projectName}
        </p>
        <UncontrolledTooltip
          delay={0}
          placement="bottom"
          target="tootip-project-name"
        >
          {projectName}
        </UncontrolledTooltip>
        <h4>Priority</h4>
        <StoryPriority
          complexity={complexity}
          complexities={complexities}
          scoreMatrix={scoreMatrix}
          score={score}
          priorities={priorities}
          priority={priority}
          isEditAllowed={isUserAllowedEdit}
          onChange={changePriority}
        />
        <h4>Status</h4>
        <StoryStatus
          statuses={statuses}
          disabled={!isUserAllowedEdit}
          selectedStatus={status}
          handleChange={changeStatus}
        />
        <CreatedBy info={createdBy} />
      </ConditionallyRenderScrollBar>
      <div className={cs('mt-auto border-top', classes.dateDetails)}>
        <div className="text-light mt-3">
          <h6>Updated On: {updatedOn}</h6>
          <h6>Created On: {createdOn}</h6>
        </div>
      </div>
    </div>
  );
};

export default StoryInfo;
