import React, { useState, useRef } from 'react';
import classes from './epics.module.scss';
import ColorPicker from 'components/ColorPicker';
import { useIntersection } from 'react-use';
import cx from 'classnames';

const BadgeColorPicker = ({
  onSelect,
  color: defaultColor,
  rowId,
  disabled,
}) => {
  const [popOverState, setPopOverState] = useState();
  const [color, setColor] = useState(defaultColor);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  if (intersection && intersection.intersectionRatio < 1 && popOverState) {
    setPopOverState(false);
  }
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between"
        ref={intersectionRef}
      >
        <i
          onClick={() => {
            setPopOverState(true);
          }}
          id={`id-${rowId}`}
          style={{
            color,
          }}
          className={cx(
            'fas fa-circle mr-1',
            classes['color-badge'],
            !disabled && classes['edit-allowed']
          )}
        />
      </div>
      <ColorPicker
        isOpen={!disabled && popOverState}
        color={color}
        onChange={newColor => setColor(newColor)}
        onChangeComplete={color => {
          onSelect(color);
          setPopOverState();
        }}
        onCancel={() => {
          setColor(defaultColor);
          setPopOverState();
        }}
        targetElement={`id-${rowId}`}
      />
    </>
  );
};
export default BadgeColorPicker;
