import React from 'react';
import PropTypes from 'prop-types';
import classes from './DataArea.module.scss';
import Dropdowns from 'components/Dropdowns';

// user, client, sow, project, epic, story, task, timelog

const FilterDropdown = props => {
  const { name, label, value, onChange } = props;

  const options = [
    {
      text: 'User',
      value: 'user',
    },
    {
      text: 'Client',
      value: 'client',
    },
    {
      text: 'SOW',
      value: 'sow',
    },
    {
      text: 'Project',
      value: 'project',
    },
    {
      text: 'Epic',
      value: 'epic',
    },
    {
      text: 'Story',
      value: 'story',
    },
    {
      text: 'Assignment',
      value: 'task',
    },
    {
      text: 'Time Entry',
      value: 'timelog',
      subgroup: true,
    },
  ];

  return (
    <div className={classes.filterDropdown}>
      <span className={classes.label}>
        {label}:{' '}
        <Dropdowns
          options={
            name === 'subgroup'
              ? options.map(item => {
                  return {
                    ...item,
                    onClick: () =>
                      onChange({ text: item.text, value: item.value }),
                  };
                })
              : options
                  .filter(item => !item.subgroup)
                  .map(item => {
                    return {
                      ...item,
                      onClick: () =>
                        onChange({ text: item.text, value: item.value }),
                    };
                  })
          }
          active={value.value}
          text={value.text}
          dropdownClasses={classes.dropdownMenu}
        />
      </span>
    </div>
  );
};

FilterDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
};
FilterDropdown.defaultProps = {};

export default FilterDropdown;
