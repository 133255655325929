import React from 'react';
import classNames from 'classnames';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import pluralize from 'pluralize';
import get from 'lodash/get';
import each from 'lodash/each';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import Types from 'store/types/backlogs';
import SelectAsync from 'components/FormFields/SelectAsync';
import { analyticsConstants } from 'helpers/analytics';
import { massDeleteStories, massUpdateStories } from 'store/actions/backlogs';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { permissions, useAccess } from 'helpers/permission';
import classes from './projects.module.scss';

const ActionBar = ({
  totalSelectedStoriesCount,
  isMemberOfProject,
  projectId,
  analyticsSendEvent,
  getSelectedStoriesList,
}) => {
  const isMassUpdateAllowed = useAccess([
    permissions.MASS_UPDATE_STORIES_OF_ALL_SECTIONS,
    {
      permission: permissions.MASS_UPDATE_STORIES_OF_MY_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isMassDeleteAllowed = useAccess([
    permissions.MASS_DELETE_STORIES_OF_ALL_SECTIONS,
    {
      permission: permissions.MASS_DELETE_STORIES_OF_MY_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );

  const sectionState = useSelector(
    ({ backlog }) => get(backlog, 'sectionsState.data', []),
    isEqual
  );

  const backlogItemsState = useSelector(
    ({ backlog }) => get(backlog, 'itemsState.data', {}),
    isEqual
  );

  const deleteStories = () => {
    analyticsSendEvent({
      action: analyticsConstants.action.multiselect_delete,
    });
    dispatch(massDeleteStories(projectId, getSelectedStoriesList()));
  };
  const deleteAction = () => {
    AlertPopupHandler.open({
      onConfirm: deleteStories,
      confirmBtnText: `Delete ${pluralize('Story', totalSelectedStoriesCount)}`,
      text: `You are about to delete ${pluralize(
        'story',
        totalSelectedStoriesCount,
        true
      )}. Do you want to continue?`,
    });
  };

  const updateSelectedStories = (field, value) => {
    let storiesOrderedList = [];
    const sortedSectionList = orderBy(sectionState, ['sort_order'], ['asc']);
    each(sortedSectionList, section => {
      const sortedStoryIds = map(
        sortBy(get(backlogItemsState, [section.id, 'data'], []), [
          'sort_order',
        ]),
        'id'
      );
      storiesOrderedList = storiesOrderedList.concat(
        intersection(sortedStoryIds, getSelectedStoriesList())
      );
    });
    dispatch(massUpdateStories(projectId, field, value, storiesOrderedList));
  };

  const dispatch = useDispatch();
  return (
    <div
      className={classNames(
        'bg-primary shadow-lg text-white w-100 border-primary rounded-bottom p-3 ml-4',
        classes['stories-action-container']
      )}
    >
      <Button
        leftIcon={<i className="fas fa-check-square" />}
        className="shadow-none mr-4"
        color="primary"
        outline={false}
        size="sm"
        onClick={() => dispatch({ type: Types.DESELECT_ALL_STORIES })}
      >
        Deselect({totalSelectedStoriesCount})
      </Button>
      {isMassUpdateAllowed && (
        <>
          <SelectAsync
            url={`/initiatives/${projectId}/backlogSections/list/dropdown`}
            id="mass-update-section-move"
            className="d-inline-block"
            placeholder="Search Sections"
            popperClassName={classes['selector-dropdown']}
            onChange={data => {
              updateSelectedStories('backlog_section_id', data.id);
              analyticsSendEvent({
                action: analyticsConstants.action.multiselect_move_section,
              });
            }}
          >
            <Button
              leftIcon={<i className="fas fa-stream" />}
              className="shadow-none mr-4"
              color="primary"
              outline={false}
              size="sm"
            >
              Move Section
            </Button>
          </SelectAsync>
          <SelectAsync
            url={`/initiatives/${projectId}/epics/dropdown`}
            id="mass-update-epic-assign"
            className="d-inline-block"
            placeholder="Search Epics"
            popperClassName={classes['selector-dropdown']}
            onChange={data => {
              updateSelectedStories('epic_id', data.id);
              analyticsSendEvent({
                action: analyticsConstants.action.multiselect_assign_epic,
              });
            }}
          >
            <Button
              leftIcon={<i className="fas fa-book" />}
              className="shadow-none mr-4"
              color="primary"
              outline={false}
              size="sm"
            >
              + Assign Epic
            </Button>
          </SelectAsync>
          <UncontrolledDropdown direction="down" size="sm">
            <DropdownToggle
              color="primary"
              caret={false}
              className={classNames('shadow-none mr-4')}
            >
              <i className="fas fa-sync-alt mr-2" />
              Update Status
            </DropdownToggle>
            <DropdownMenu className={classes['status-dropdown']}>
              {orderBy(statuses, ['id'], 'asc').map((item, index) => (
                <DropdownItem
                  key={index}
                  className="d-flex w-100 align-items-center"
                  onClick={() => {
                    updateSelectedStories('status_id', item.id);
                    analyticsSendEvent({
                      action:
                        analyticsConstants.action.multiselect_update_status,
                    });
                  }}
                >
                  <Badge className="badge-dot m-0" color="">
                    <i
                      style={{
                        // TODO: this will be used from API so do not worry about static color
                        backgroundColor: item.color,
                      }}
                    />
                  </Badge>
                  <h5 className="font-weight-normal text-muted mb-0">
                    {item.status}
                  </h5>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )}
      {isMassDeleteAllowed && (
        <Button
          leftIcon={<i className="fas fa-trash" />}
          className="shadow-none mr-4"
          color="primary"
          outline={false}
          size="sm"
          onClick={deleteAction}
        >
          Delete
        </Button>
      )}
    </div>
  );
};

export default ActionBar;
