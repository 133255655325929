import React from 'react';
import moment from 'moment';
import DatePicker from 'components/FormFields/DatePicker';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';

const StartDate = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  defaultValue,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const startDate = moment(getFieldValue('date_start')).format('M/D/YY');
  const endDate = moment(getFieldValue('date_end')).format('M/D/YY');
  return (
    <>
      <h6 className="text-uppercase text-muted">Start Date</h6>
      <DatePicker
        dateFormat="M/D/YY"
        placeholder="Start Date"
        renderInput={(props, openCalendar, closeCalendar) => (
          <p
            onClick={() => isEditAllowed && openCalendar()}
            onBlur={closeCalendar}
            className={cx(
              isEditAllowed && classes.hoverHand,
              !moment(props.value).isValid() && isEditAllowed
                ? 'text-light'
                : 'text-muted'
            )}
          >
            {moment(props.value).isValid()
              ? props.value
              : isEditAllowed
              ? '+ Add a Start Date'
              : defaultValue}
          </p>
        )}
        isValidDate={current =>
          // current.isAfter(moment().subtract(1, 'day')) &&
          // moment(endDate).isValid() ? current.isBefore(endDate) :
          true
        }
        value={startDate}
        name="startDate"
        onChange={date => {
          analyticsSendEvent({
            action: analyticsConstants.action.edit_project_start_date,
          });
          moment(date).isValid()
            ? onChange('startDate', moment(date).format('YYYY/MM/DD'))
            : onChange('startDate', '');
          if (moment(endDate).isValid() && date.isAfter(endDate)) {
            analyticsSendEvent({
              action: analyticsConstants.action.edit_project_end_date,
            });

            onChange('endDate', null);
          }
        }}
        className={classes['calendar']}
      />
    </>
  );
};

export default StartDate;
