import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import Description from './Fields/Overview/Description';
import Owner from './Fields/ProjectDetails/Owner';
import Client from './Fields/ProjectDetails/Client';
import Budget from './Fields/ProjectDetails/Budget';
import BudgetType from './Fields/ProjectDetails/BudgetType';
import StartDate from './Fields/ProjectDetails/StartDate';
import LastUpdated from './Fields/ProjectDetails/LastUpdated';
import Color from './Fields/ProjectDetails/Color';
import Status from './Fields/ProjectDetails/Status';
import SOW from './Fields/ProjectDetails/SOW';
import BudgetHours from './Fields/ProjectDetails/BudgetHours';
import Billable from './Fields/ProjectDetails/Billable';
import EndDate from './Fields/ProjectDetails/EndDate';
import CreatedOn from './Fields/ProjectDetails/CreatedOn';
import { useAccess, permissions } from 'helpers/permission';
import find from 'lodash/find';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import { editProject } from 'store/actions/projects';
import useBreakPoint from 'helpers/useBreakPoint';
import analytics, { analyticsConstants } from 'helpers/analytics';

const Overview = () => {
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.project_dashboard,
      ...rest,
    });
  };
  const dispatch = useDispatch();
  const useGetFieldValue = (field, nullValue) =>
    useGetFieldFromObjects(
      'project',
      `getProject.data.data.${field}`,
      nullValue
    );
  const isMobile = useBreakPoint('xs', 'down');
  const projectMemberDetails = useGetFieldValue('team_members', []);
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const isUserMemberOfTeam = find(
    projectMemberDetails,
    member => member.id === userId
  );
  const isUserAllowedEdit = useAccess([
    permissions.EDIT_ALL_PROJECTS,
    {
      permission: permissions.EDIT_MY_PROJECTS,
      value: isUserMemberOfTeam,
    },
  ]);
  const projectId = useGetFieldValue('id');
  const name = useGetFieldValue('name');
  const onChange = (field, value) => {
    dispatch(editProject(projectId, { projectName: name, [field]: value }));
  };
  const commonProps = {
    getFieldValue: useGetFieldValue,
    isEditAllowed: isUserAllowedEdit,
    defaultValue: '--',
    onChange,
    isMobile,
    analyticsSendEvent,
    analyticsConstants,
  };
  return (
    <Card>
      <CardBody className={classes['overview']}>
        <div className="mb-4">
          <h3>Overview</h3>
          <Description {...commonProps} />
        </div>
        <h3>Project Details</h3>
        <div className={classes.projectOverview}>
          <Row>
            <Col xs={6}>
              <Owner {...commonProps} />
            </Col>
            <Col xs={6}>
              <Status {...commonProps} />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Client {...commonProps} />
            </Col>
            <Col xs={6}>
              <SOW {...commonProps} />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Budget {...commonProps} />
            </Col>
            <Col xs={6}>
              <BudgetHours {...commonProps} />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <BudgetType {...commonProps} />
            </Col>
            <Col xs={6}>
              <Billable {...commonProps} />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <StartDate {...commonProps} />
            </Col>
            <Col xs={6}>
              <EndDate {...commonProps} />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <LastUpdated {...commonProps} />
            </Col>
            <Col xs={6}>
              <CreatedOn {...commonProps} />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Color {...commonProps} />
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default Overview;
