import React from 'react';
import get from 'lodash/get';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import SelectAsync from 'components/FormFields/SelectAsync';

const Client = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  defaultValue,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const client = getFieldValue('client');
  const clientName = get(client, 'name');
  return (
    <>
      <h6 className="text-uppercase text-muted">Client</h6>
      <SelectAsync
        id="project-client-target"
        disabled={!isEditAllowed}
        onChange={selectedClient => {
          analyticsSendEvent({
            action: analyticsConstants.action.edit_project_client,
          });

          onChange('projectClient', selectedClient);
        }}
        url="/clients/list/dropdown"
        getOptionLabel={option => option.name}
        placeholder="Assign a Client"
      >
        <p
          className={cx(
            !clientName && isEditAllowed ? 'text-light' : 'text-muted',
            isEditAllowed && classes.hoverHand,
            classes['ellipsis']
          )}
        >
          {clientName || (isEditAllowed ? '+ Add a Client' : defaultValue)}
        </p>
      </SelectAsync>
    </>
  );
};

export default Client;
