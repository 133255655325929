import * as React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import classes from './plan.module.scss';
import Button from 'components/Button';
import useBreakpoint from 'helpers/useBreakPoint';

const Plan = props => {
  const {
    name,
    price,
    description,
    features = [],
    buttonText,
    proTheme,
    yellowTheme,
    onButtonClick,
    disableButton,
    isLoading,
    ribbonText,
    isVisible,
  } = props;
  const isMobile = useBreakpoint('md', 'down');

  if (!isVisible) return null;

  const ActionButton = (
    <Button
      className={cx(classes.outlineBtn, isMobile && classes.isMobile)}
      onClick={onButtonClick}
      disabled={disableButton}
      loading={isLoading}
    >
      {buttonText}
    </Button>
  );

  return (
    <div
      className={cx(
        classes.plan,
        proTheme && classes.pro,
        yellowTheme && classes.yellow,
        isMobile && classes.isMobile
      )}
    >
      <div className={classes.name}>{name}</div>
      <div className={classes.price}>{price}</div>
      <div className={classes.priceUser}>PER USER / MONTH</div>
      <div className={cx(classes.description, isMobile && classes.isMobile)}>
        {description}
      </div>
      {isMobile && ActionButton}
      <div className={classes.separator}></div>
      <ul className={classes.featureList}>
        {features.map((feature, index) => (
          <li className={classes.featureText} key={index}>
            {feature}
          </li>
        ))}
      </ul>
      {!isMobile && <div className={classes.growth}>Growth</div>}
      <div className={classes.ribbon}>
        <span>{ribbonText}</span>
      </div>
      {!isMobile && ActionButton}
    </div>
  );
};

Plan.prototype = {
  name: PropTypes.string,
  price: PropTypes.string,
  priceUser: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
  proTheme: PropTypes.bool,
  yellowTheme: PropTypes.bool,
  onButtonClick: PropTypes.func,
  disableButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  ribbonText: PropTypes.string,
  isVisible: PropTypes.bool,
};

Plan.defaultProps = {
  features: [],
};

export default Plan;
