import Request from './request';

export const addSprint = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}/sprints`,
    method: 'POST',
    data,
  });
};

export const getSprint = async sprintID => {
  return Request.call({
    url: `/initiatives/${sprintID}/sprints?q=`,
    method: 'GET',
  });
};

export const editSprint = async (sprintId, data) => {
  return Request.call({
    url: `/sprints/${sprintId}`,
    method: 'PUT',
    data,
  });
};

export const addStory = async (sprintID, data) => {
  return Request.call({
    url: `/sprints/${sprintID}/items`,
    method: 'POST',
    data,
  });
};

export const getStoryList = async (sprintID, q = '') => {
  return Request.call({
    url: `/sprints/${sprintID}/items`,
    method: 'GET',
    params: {
      q,
    },
  });
};

export const getKanbanBoard = async (sprintID, q = '') => {
  return Request.call({
    url: `/sprints/${sprintID}/board?q=${q}`,
    method: 'GET',
  });
};

export const addColumn = async (sprintId, data) => {
  return Request.call({
    url: `/sprints/${sprintId}/board/add-column`,
    method: 'POST',
    data,
  });
};

export const editColumn = async (sprintId, columnId, data) => {
  return Request.call({
    url: `/sprints/${sprintId}/board/update-column/${columnId}`,
    method: 'PUT',
    data,
  });
};

export const removeColumn = async (sprintId, columnId) => {
  return Request.call({
    url: `/sprints/${sprintId}/board/delete-column/${columnId}`,
    method: 'DELETE',
  });
};

export const reOrderColumns = async (sprintId, data) => {
  return Request.call({
    url: `/sprints/${sprintId}/board/re-order-columns`,
    method: 'POST',
    data,
  });
};

export const reOrderKanbanStories = async (sprintId, columnId, data) => {
  return Request.call({
    url: `/sprints/${sprintId}/board/${columnId}/prioritize-stories`,
    method: 'POST',
    data,
  });
};

export const updateStoryStatus = async (sprintId, storyId, data) => {
  return Request.call({
    url: `/stories/${storyId}`,
    method: 'PATCH',
    data,
  });
};
