import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Card, CardBody, CardTitle, Progress, Row } from 'reactstrap';
import { fetchTasksByUserId } from 'store/actions/profile';
import classes from './profile.module.scss';
import { useHistory } from 'react-router';
import { useAccess, permissions } from 'helpers/permission';

const TasksStats = ({ userId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tasks: tasksState } = useSelector(({ profile }) => profile);
  const loggedInUser = useSelector(({ auth }) => get(auth, 'user.id'));
  const total = get(tasksState, 'data.total', 0);
  const completed = get(tasksState, 'data.completed', 0);
  const progress = get(tasksState, 'data.progress', 0);
  useEffect(() => {
    dispatch(fetchTasksByUserId(userId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Card className={`border-0 ${classes.taskCompleted}`} tag="h5">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle className="text-uppercase text-muted mb-0 text-white">
              ASSIGNMENTS COMPLETED
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-white">
              {completed}/{total}
            </span>
          </div>
        </Row>
        <div className="mt-3 mb-0">
          <Progress color="success" value={progress} className="progress-xs" />
          {useAccess([permissions.VIEW_EVERYONES_KANBAN]) && (
            <button
              className="btn-link btn text-muted  text-white p-0 m-0"
              onClick={() => {
                history.push(
                  loggedInUser === Number(userId)
                    ? '/admin/kanban/personal'
                    : `/admin/users/${userId}/kanban`
                );
              }}
            >
              View Kanban
            </button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default TasksStats;
