import createTypes from 'redux-create-action-types';

export default createTypes(
  'CREATE_SPRINT_INPROGRESS',
  'CREATE_SPRINT_SUCCESS',
  'CREATE_SPRINT_FAILURE',
  'FETCH_SPRINT_INPROGRESS',
  'FETCH_SPRINT_SUCCESS',
  'FETCH_SPRINT_FAILURE',
  'EDIT_SPRINT_INPROGRESS',
  'EDIT_SPRINT_SUCCESS',
  'EDIT_SPRINT_FAILURE',
  'DELETE_SPRINT_INPROGRESS',
  'DELETE_SPRINT_SUCCESS',
  'DELETE_SPRINT_FAILURE',
  'CREATE_SPRINT_STORY_INPROGRESS',
  'CREATE_SPRINT_STORY_SUCCESS',
  'CREATE_SPRINT_STORY_FAILURE',
  'FETCH_STORY_LIST_INPROGRESS',
  'FETCH_STORY_LIST_SUCCESS',
  'FETCH_STORY_LIST_FAILURE',
  'REORDER_SPRINT_STORIES_INPROGRESS',
  'REORDER_SPRINT_STORIES_SUCCESS',
  'REORDER_SPRINT_STORIES_FAILURE',
  'UPDATE_SORT_ORDER_SPRINT_STATE',
  'UPDATE_SPRINT_STORIES_STATE',
  'UPDATE_SPRINT_ITEM_INPROGRESS',
  'UPDATE_SPRINT_ITEM_SUCCESS',
  'UPDATE_SPRINT_ITEM_FAILURE',
  'GET_SPRINT_KANBAN_INPROGRESS',
  'GET_SPRINT_KANBAN_SUCCESS',
  'GET_SPRINT_KANBAN_FAILURE',
  'CREATE_SPRINT_COLUMN_INPROGRESS',
  'CREATE_SPRINT_COLUMN_SUCCESS',
  'CREATE_SPRINT_COLUMN_FAILURE',
  'EDIT_SPRINT_COLUMN_INPROGRESS',
  'EDIT_SPRINT_COLUMN_SUCCESS',
  'EDIT_SPRINT_COLUMN_FAILURE',
  'DELETE_SPRINT_COLUMN_INPROGRESS',
  'DELETE_SPRINT_COLUMN_SUCCESS',
  'DELETE_SPRINT_COLUMN_FAILURE',
  'REORDER_SPRINT_COLUMN_INPROGRESS',
  'REORDER_SPRINT_COLUMN_SUCCESS',
  'REORDER_SPRINT_COLUMN_FAILURE',
  'SET_ACTION_CREATOR'
);
