import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './navigationdropdown.module.scss';
import cs from 'classnames';
import { Badge } from 'reactstrap';
import get from 'lodash/get';

const DesktopNavigation = ({ activeContent, options, handleOptionClick }) => {
  const [hoverContent, setHoverContent] = useState('');
  const onOptionClick = content => () => {
    handleOptionClick(content);
  };
  return options.map((content, index) => (
    <div
      key={index}
      className={cs(
        'shortcut-item p-0 text-center',
        classes.cursor,
        classes.desktopTabs
      )}
      id={content.id}
      onClick={onOptionClick(content)}
      onMouseEnter={() => {
        setHoverContent(content.pageLink);
      }}
      onMouseLeave={() => {
        setHoverContent();
      }}
    >
      <span
        className={cs(
          'shortcut-media avatar avatar-sm rounded-circle',
          get(activeContent, 'pageLink') === content.pageLink ||
            content.pageLink === hoverContent
            ? `bg-gradient-${content.gradientColor}`
            : 'border border-light bg-transparent text-gray'
        )}
      >
        <i className={content.iconClassName} />
      </span>
      <small
        className={cs(
          get(activeContent, 'pageLink') !== content.pageLink && 'text-gray',
          'm-0'
        )}
      >
        {content.name}
      </small>
      {content.showBadge && content.count ? (
        <Badge
          color="danger"
          className={cs(
            'badge-sm badge-circle badge-floating border-white',
            classes.badgeCount
          )}
        >
          {content.count > 99 ? '99+' : content.count}
        </Badge>
      ) : null}
    </div>
  ));
};

DesktopNavigation.propTypes = {
  activeContent: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  handleOptionClick: PropTypes.func,
};

export default DesktopNavigation;
