import React from 'react';
import { Badge } from 'reactstrap';
import classes from './Kanban.module.scss';
import get from 'lodash/get';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router';
import AssignmentCard from 'components/AssignmentCard';
import cx from 'classnames';

const Column = props => {
  const history = useHistory();

  const { name, id, data, filter } = props;

  const assignments = data || [];

  return (
    <div className={classes.column}>
      <div className="mt-1 ml-2 mb-0 flex-shrink-0">
        <div className="d-flex">
          <h4 className="m-0">{name}</h4>
          <Badge color="info" className="ml-2 h-100">
            {assignments.length}
          </Badge>
          {filter && <div className="ml-auto">{filter}</div>}
        </div>
      </div>

      <div className={cx(classes.list, { [classes.withFilter]: filter })}>
        <Droppable droppableId={`${id}`}>
          {provided => (
            <div ref={provided.innerRef}>
              {assignments.map((item, index) => (
                <Draggable
                  draggableId={`${item.id}`}
                  index={index}
                  key={item.id}
                  isDragDisabled={props.isGlobal}
                >
                  {(provided, snapshot) => {
                    const attrs = {
                      assignmentId: get(item, 'id'),
                      dependency: get(item, 'dependency', null),
                      projectName: get(item, 'initiative.name'),
                      assignmentTitle: get(item, 'task_type.name'),
                      dateDue: get(item, 'date_due'),
                      storyId: get(item, 'story.id'),
                      storyName: get(item, 'story.name'),
                      storyNumber: get(item, 'story.number'),
                      score: get(item, 'score'),
                      ownerAvatar: get(item, 'story_owner.avatar'),
                      epicName: get(item, 'epic.name'),
                      epicColor: `#${get(item, 'epic.color')}`,
                      epicBackground: `#${get(item, 'epic.color')}26`,
                      progressColorFrom: `#${get(item, 'health.color.0')}`,
                      progressColorTo: `#${get(item, 'health.color.1')}`,
                      progressPercent: get(item, 'health.progress') || 0,
                      totalHrs: get(item, 'health.total_hrs', 0) || 0,
                      consumedHrs: get(item, 'health.consumed_hrs', 0) || 0,
                    };

                    const draggableStyles = snapshot.isDragging
                      ? {
                          ...provided.draggableProps.style,
                          zIndex: 1,
                        }
                      : {
                          ...provided.draggableProps.style,
                          marginBottom: '10px',
                        };

                    const dragHandle = { ...provided.dragHandleProps };

                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...dragHandle}
                        style={draggableStyles}
                        key={item.id}
                      >
                        <AssignmentCard
                          {...attrs}
                          openStoryModal={async () => {
                            history.push(`?story=${item.story.id}`);
                          }}
                        />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default Column;
