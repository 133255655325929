import React, { useEffect } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { Col, Button, Form, FormGroup, Row } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import { isAdmin } from 'helpers/permission';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Input from 'components/FormFields/Input';
import RSelect from 'components/FormFields/RSelect';
import history from 'helpers/history';
import useDeepCompareEffect from 'helpers/useDeepCompareEffect';
import classes from './profile.module.scss';
import cs from 'classnames';

const ProfileForm = ({ formik, userId, onDirtyConfirm }) => {
  const details = useSelector(({ profile }) =>
    get(profile, 'details.data', {})
  );

  const timezones = useSelector(({ metadata }) =>
    get(metadata, 'timezones.data', {})
  );

  useDeepCompareEffect(() => {
    if (formik.errors) {
      for (const field in formik.errors) {
        formik.setFieldTouched(field, true);
      }
    }
  }, [formik.errors]);

  const compareValues = () => {
    return isEqual(formik.initialValue, formik.values);
  };

  const beforeUnload = event => {
    if (compareValues()) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.initialValue, formik.values]);

  const isLoading = get(details, 'isInProgress', false);

  const cancelEditProfile = () => {
    if (formik.dirty) {
      // if form is dirty then show cancel confirm modal
      AlertPopupHandler.open({
        onConfirm: () => {
          onDirtyConfirm(true);
          // here we have used setImmediate because we want to navigate on next tick after isDirtyConfirmed made true
          setImmediate(() => {
            history.push({
              pathname: `/admin/users/${userId}`,
            });
          });
        },
        confirmBtnText: 'Continue',
        text: 'If you leave all unsaved changes will be lost.',
      });
    } else {
      // if formik is not dirty then directly redirect
      history.push({
        pathname: `/admin/users/${userId}`,
      });
    }
  };

  const timezonesData = Object.entries(timezones).map(([id, name]) => ({
    value: id,
    label: name,
  }));

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="pl-lg-4">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="Full Name*"
                name="name"
                type="text"
                defaultValue={get(formik, 'values.name', '')}
                touched={get(formik, 'touched.name')}
                error={get(formik, 'errors.name')}
                onBlur={e => {
                  formik.handleBlur(e);
                }}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="First Name"
                name="first_name"
                type="text"
                defaultValue={get(formik, 'values.first_name')}
                error={get(formik, 'errors.first_name')}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                name="last_name"
                label="Last Name"
                type="text"
                defaultValue={get(formik, 'values.last_name')}
                error={get(formik, 'errors.last_name')}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className={`mb-3 ${!isAdmin() ? classes.disabled : null}`}
                label="Hours Available Per Week"
                name="weekly_availability"
                type="text"
                defaultValue={get(formik, 'values.weekly_availability')}
                error={get(formik, 'errors.weekly_availability')}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
                disabled={!isAdmin() ? true : false}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className={`mb-3 ${!isAdmin() ? classes.disabled : null}`}
                label="Job Title"
                name="title"
                type="text"
                defaultValue={get(formik, 'values.title')}
                error={get(formik, 'errors.title')}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
                disabled={!isAdmin() ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="pl-lg-4">
        <FormGroup>
          <Input
            className="mb-3"
            label={`Bio (${500 - formik.values.bio.length} characters left)`}
            name="bio"
            rows="4"
            type="textarea"
            defaultValue={get(formik, 'values.bio')}
            touched={get(formik, 'touched.bio')}
            error={get(formik, 'errors.bio')}
            onBlur={e => {
              formik.handleBlur(e);
            }}
            onChange={e => formik.setFieldValue('bio', e.target.value)}
          />
        </FormGroup>
      </div>
      <div className="pl-lg-4">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="Country"
                name="country"
                type="text"
                defaultValue={get(formik, 'values.country')}
                touched={get(formik, 'touched.country')}
                error={get(formik, 'errors.country')}
                onBlur={e => {
                  formik.handleBlur(e);
                }}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="City"
                name="city"
                type="text"
                defaultValue={get(formik, 'values.city')}
                touched={get(formik, 'touched.city')}
                error={get(formik, 'errors.city')}
                onBlur={e => {
                  formik.handleBlur(e);
                }}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-time-zone">
                Time Zone
              </label>
              <RSelect
                id="timezone"
                name="timzone"
                className={classes.timezone}
                isClearable={false}
                defaultValue={
                  formik.values.timezone
                    ? {
                        label:
                          get(formik, 'values.timezone') +
                          ' ' +
                          get(formik, 'values.timezone_offset'),
                        value: get(formik, 'values.timezone'),
                      }
                    : null
                }
                onChange={e => {
                  formik.setFieldValue('timezone', e.value);
                  formik.setFieldValue(
                    'timezone_offset',
                    e.label.match(/\((.*)\)/)[1]
                  );
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                touched={get(formik, 'touched.timezone')}
                error={get(formik, 'errors.timezone')}
                options={timezonesData}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="Email Address*"
                name="email"
                type="text"
                defaultValue={get(formik, 'values.email')}
                touched={get(formik, 'touched.email')}
                error={get(formik, 'errors.email')}
                onBlur={e => {
                  formik.handleBlur(e);
                }}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="Phone Number"
                name="phone"
                type="number"
                defaultValue={get(formik, 'values.phone')}
                error={get(formik, 'errors.phone')}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="Github Username"
                name="github_id"
                type="text"
                defaultValue={get(formik, 'values.github_id')}
                error={get(formik, 'errors.github_id')}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Input
                className="mb-3"
                label="Skype Username"
                name="skype_id"
                type="text"
                defaultValue={get(formik, 'values.skype_id')}
                error={get(formik, 'errors.skype_id')}
                onChange={formik.handleChange}
                showContentLoader={isLoading}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="pl-lg-4">
        <FormGroup>
          <label className="form-control-label">
            Skills ({10 - formik.values.skills.length} skills left)
          </label>
          <div
            className={cs(classes.skillTags, {
              [classes.disabled]: !isAdmin(),
            })}
          >
            {isAdmin() ? (
              <TagsInput
                className="bootstrap-tagsinput"
                name="skills"
                tagProps={{ className: 'tag badge mr-1' }}
                value={get(formik, 'values.skills', [])}
                inputProps={{
                  className: '',
                  placeholder: '',
                }}
                onChange={skills => {
                  if (skills.length <= 10) {
                    formik.setFieldValue('skills', skills);
                  }
                }}
              />
            ) : (
              <div className={classes.skills}>
                {formik.values.skills.map((skill, index) => (
                  <span key={index}>{skill}</span>
                ))}
              </div>
            )}
          </div>
        </FormGroup>
      </div>
      <div className="pl-lg-4">
        <Button
          onClick={e => {
            e.preventDefault();
            cancelEditProfile();
          }}
          color="link"
          className={'float-left'}
        >
          Cancel
        </Button>
      </div>
      <div className="pl-lg-4">
        <Button
          type="submit"
          disabled={!formik.isValid}
          color={formik.isValid ? 'primary' : 'secondary'}
          className={'float-right'}
        >
          Save Changes
        </Button>
      </div>
    </Form>
  );
};

export default ProfileForm;
