import Request from './request';

export const fetchList = async (page, sort, query, limit) => {
  return Request.call({
    url: '/users',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });
};

export const getRoleOptions = async () => {
  return Request.call({
    url: `/roles/list/dropdown`,
    method: 'GET',
  });
};

export const updateRole = async (id, data) => {
  return Request.call({
    url: `/users/${id}/roles`,
    method: 'POST',
    data,
  });
};

export const sendInvitations = async data => {
  return Request.call({
    url: `/users/invitations/send`,
    method: 'POST',
    data,
  });
};

export const resendInvitation = async id => {
  return Request.call({
    url: `/users/invitations/resend/${id}`,
    method: 'POST',
  });
};

export const reInvite = async (id, data) => {
  return Request.call({
    url: `/users/invitations/invite-deactivated/${id}`,
    method: 'POST',
    data,
  });
};

export const deactivate = async id => {
  return Request.call({
    url: `/users/${id}/deactivate`,
    method: 'POST',
  });
};

export const cancel = async id => {
  return Request.call({
    url: `/users/invitations/cancel/${id}`,
    method: 'POST',
  });
};
