import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import get from 'lodash/get';
import numeral from 'numeral';
import classes from './projects.module.scss';

export const nameRenderer = (isUserAllowedViewProject, handleProjectClick) => (
  cell,
  row,
  index
) => (
  <div
    className={classNames(
      'd-flex justify-content-between w-100 align-items-center',
      classes['project-name']
    )}
    title={cell}
  >
    {isUserAllowedViewProject[index] ? (
      <Link onClick={handleProjectClick} to={`/admin/projects/${row.id}`}>
        {cell}
      </Link>
    ) : (
      <>{cell}</>
    )}
  </div>
);

export const statusRenderer = cell => (
  <div className="d-flex justify-content-between w-100 align-items-center">
    <Badge className="badge-dot mr-4" color="">
      <i
        style={{
          backgroundColor: get(cell, 'color') ? get(cell, 'color') : '',
        }}
      />
      <span className={classes['project-status']}>{get(cell, 'status')}</span>
    </Badge>
  </div>
);

export const budgetRenderer = cell => (
  <div className={classNames(classes['project-budget'], 'align-items-center')}>
    {cell ? `$${numeral(cell).format('0,0.00')} USD` : ''}
  </div>
);

export const budgetTypeRenderer = cell => <p className="m-0">{cell}</p>;

export const ownerRenderer = ({
  isUserAllowedViewProject,
  viewProject,
  isUserAllowedEditProject,
  editProject,
  isUserAllowedDuplicateProject,
  duplicateProject,
  isUserAllowedDeleteProject,
  deleteProjectConfirm,
}) => (cell, row, index) => {
  const userId = get(cell, 'id', '');
  const imageLink = get(cell, 'avatar', '');
  const userName = get(cell, 'name');
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      {userId ? (
        <>
          <img
            id={`project-owner-${userId}`}
            alt="..."
            className="avatar avatar-sm rounded-circle"
            src={imageLink}
          />
          {userName && (
            <UncontrolledTooltip
              delay={0}
              placement="bottom"
              target={`project-owner-${userId}`}
            >
              {userName}
            </UncontrolledTooltip>
          )}
        </>
      ) : (
        <div
          className={classNames(
            'avatar-sm avatar text-lg d-flex rounded-circle align-items-center justify-content-center',
            classes['empty-avatar']
          )}
        >
          <span className="fa fa-user text-white" />
        </div>
      )}
      <span>
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {isUserAllowedViewProject[index] && (
              <DropdownItem
                onClick={() => {
                  viewProject(row);
                }}
              >
                View Project
              </DropdownItem>
            )}
            {isUserAllowedEditProject[index] && (
              <DropdownItem onClick={() => editProject(row)}>
                Edit Project
              </DropdownItem>
            )}
            {isUserAllowedDuplicateProject[index] && (
              <DropdownItem onClick={() => duplicateProject(row)}>
                Duplicate Project
              </DropdownItem>
            )}
            {isUserAllowedDeleteProject[index] && (
              <DropdownItem onClick={deleteProjectConfirm(row)}>
                Delete Project
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </span>
    </div>
  );
};
