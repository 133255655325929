import React from 'react';
import cs from 'classnames';
import ViewBacklogGradient from 'assets/img/icons/common/ViewBacklogGradient.svg';
import classes from '../QuickActions.module.scss';
import history from 'helpers/history';

const ViewBacklog = ({ projectId }) => {
  const handleOnClick = () => {
    history.push(`/admin/projects/${projectId}/backlog`);
  };
  return (
    <div className={classes.actionBox} onClick={handleOnClick}>
      <div className={cs(classes.gradient, classes.viewBacklog)} />
      <div className={classes.box}>
        <img src={ViewBacklogGradient} alt="" />
        <h2>View Backlog</h2>
      </div>
    </div>
  );
};

export default ViewBacklog;
