import React from 'react';
import classNames from 'classnames';
import { Col, ListGroupItem, Row } from 'reactstrap';
import moment from 'moment';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import classes from './Notification.module.scss';
import history from 'helpers/history';
import { analyticsConstants } from 'helpers/analytics';
import get from 'lodash/get';

const NotificationItem = props => {
  const { notification, markRead, limit, analyticsSendEvent } = props;
  const dispatch = useDispatch();

  return (
    <>
      <ListGroupItem
        className={classNames('list-group-item-action rounded-0', {
          [classes.unread]: isNil(notification.read_at),
          [classes.notificationItem]: isNil(notification.read_at),
        })}
        href="#"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(markRead(notification.id, limit));
          analyticsSendEvent({
            action: analyticsConstants.action.open_notification,
            notification_type: get(notification, 'name', ''),
          });
          history.push(
            notification.url.slice(notification.url.indexOf('/admin'))
          );
          props.toggle();
        }}
        tag="a"
      >
        <Row className="align-items-center">
          <Col className="col-auto">
            <Avatar url={notification.avatar} />
          </Col>
          <div className={classNames('col ml--2', classes.notificationContent)}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="mb-0 text-sm">{notification.name}</h4>
              </div>
              <div
                className={classNames('text-right text-muted', classes.time)}
              >
                <small>{moment(notification.created_at).fromNow()}</small>
              </div>
              {!notification.read_at ? (
                <div
                  className={classNames(
                    'text-right text-primary',
                    classes.markAsRead
                  )}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    analyticsSendEvent({
                      action: analyticsConstants.action.mark_notification_read,
                    });
                    dispatch(markRead(notification.id, limit));
                  }}
                >
                  <small>Mark as Read</small>
                </div>
              ) : null}
            </div>
            <div>
              {(notification.body || []).map((n, index) => (
                <span
                  key={index}
                  className={classNames('text-sm', {
                    'font-weight-600': n.className === 'bold',
                  })}
                >
                  {n.text}{' '}
                </span>
              ))}
            </div>
          </div>
        </Row>
      </ListGroupItem>
    </>
  );
};

NotificationItem.prototype = {
  notification: PropTypes.object,
  meta: PropTypes.object,
  markRead: PropTypes.func,
};

export default NotificationItem;
