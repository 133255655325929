import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cx from 'classnames';
import classes from '../teams.module.scss';

const ProjectAccessHeader = ({ column }) => {
  return (
    <>
      <span>{column.text}</span>
      <i
        id="project_access"
        className={cx('ml-2 fa fa-info-circle', classes.info)}
      />
      <UncontrolledTooltip placement="bottom" target="project_access">
        Please contact your system administrator to update Project Access
      </UncontrolledTooltip>
    </>
  );
};

export default ProjectAccessHeader;
