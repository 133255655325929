import React from 'react';
import cx from 'classnames';
import classes from './epics.module.scss';
import Button from 'components/Button';

const CollapseButton = props => {
  const { collapse, onCollapse } = props;
  return (
    <Button
      size="sm"
      className={cx(
        classes['collapse-button'],
        'float-right btn-icon btn-fab btn-icon-only bg-white rounded-circle text-light',
        {
          [classes.open]: !collapse,
          [classes.close]: collapse,
        }
      )}
      onClick={onCollapse}
    >
      <i
        className={cx('fas fa-chevron-right', {
          [classes.open]: !collapse,
          [classes.close]: collapse,
        })}
      />
    </Button>
  );
};

export default CollapseButton;
