import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import classes from './StoryToolTip.module.scss';
import replace from 'lodash/replace';
import split from 'lodash/split';
import useBreakPoint from 'helpers/useBreakPoint';

const StoryToolTip = ({ targetId, name, length, disabled, ...rest }) => {
  const regExp = new RegExp(
    `((.{${length},${length + 10}}?\\s)|(\\S{${length}}?))`,
    'g'
  );
  const toolTipText = split(replace(name, regExp, '$1\n'), '\n');
  const isMobile = useBreakPoint('sm', 'down');

  return (
    <UncontrolledTooltip
      boundariesElement="viewport"
      delay={0}
      fade={false}
      target={targetId}
      placement="bottom"
      innerClassName={classes['tooltip']}
      disabled={isMobile || disabled}
      {...rest}
    >
      {map(
        toolTipText,
        (text, index) =>
          !isEmpty(text) && <div key={`${targetId}-${index}`}>{text}</div>
      )}
    </UncontrolledTooltip>
  );
};

StoryToolTip.propTypes = {
  targetId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  length: PropTypes.number,
};

StoryToolTip.defaultProps = {
  name: '',
  length: 100,
  disabled: false,
};
export default StoryToolTip;
