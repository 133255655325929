import React from 'react';
import classes from './Kanban.module.scss';
import { createColumn, modifyColumn } from 'store/actions/kanbanActions';
import { createSprintColumn, modifySprintColumn } from 'store/actions/sprint';
import { useDispatch } from 'react-redux';
import Input from 'components/FormFields/Input';
import isEmpty from 'lodash/isEmpty';

const ColumnNameInput = ({
  projectId,
  id,
  closeColumnModification,
  columnModification,
  name,
  onColumnModification,
  isSprintKanban,
  sprintId,
}) => {
  const dispatch = useDispatch();
  return (
    <Input
      className={classes.columnNameInput}
      autoFocus
      onBlur={async e => {
        if (!isEmpty(e.target.value)) {
          if (columnModification === 'new') {
            if (isSprintKanban) {
              await dispatch(
                createSprintColumn(sprintId, { name: e.target.value })
              );
            } else {
              await dispatch(createColumn(projectId, { name: e.target.value }));
            }
          } else {
            if (isSprintKanban) {
              await dispatch(
                modifySprintColumn(sprintId, id, { name: e.target.value })
              );
            } else {
              await dispatch(
                modifyColumn(projectId, id, { name: e.target.value })
              );
            }
          }
          onColumnModification();
        }
        closeColumnModification();
      }}
      onKeyDown={async e => {
        if (e.keyCode === 13) {
          if (!isEmpty(e.target.value)) {
            let action;
            if (columnModification === 'new') {
              if (isSprintKanban) {
                action = dispatch(
                  createSprintColumn(sprintId, { name: e.target.value })
                );
              } else {
                action = dispatch(
                  createColumn(projectId, { name: e.target.value })
                );
              }
            } else {
              if (isSprintKanban) {
                action = dispatch(
                  modifySprintColumn(sprintId, id, { name: e.target.value })
                );
              } else {
                action = dispatch(
                  modifyColumn(projectId, id, { name: e.target.value })
                );
              }
            }
            action.finally(() => {
              onColumnModification();
            });
          }
          closeColumnModification();
        }
      }}
      placeholder="Untitled"
      {...{
        defaultValue: id === columnModification ? name : '',
      }}
    />
  );
};

export default ColumnNameInput;
