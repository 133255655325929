import React, { useState, useEffect } from 'react';
import classes from './Kanban.module.scss';
import fullScreenIcon from 'assets/img/icons/full-screen.svg';
import fullScreenCloseIcon from 'assets/img/icons/full-screen-close.svg';

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import cs from 'classnames';
import Input from 'components/FormFields/Input';

import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useDebounce, useEffectOnce } from 'react-use';
import DragContainer from './DragContainer';

import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { fetchStory } from 'store/actions/Story/details';

import StoryModal from 'views/pages/Backlogs/Stories/StoryModal';
import toNumber from 'lodash/toNumber';
import { openFullscreen, closeFullscreen } from 'helpers/fullscreen';
import CardsHeader from 'components/Headers/CardsHeader';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import analytics, { analyticsConstants } from 'helpers/analytics';
import isEmpty from 'lodash/isEmpty';
import Loading from 'components/Loading';
import { permissions, useAccess } from 'helpers/permission';
import Button from 'components/Button';
import useBreakpoint from 'helpers/useBreakPoint';
import DuplicateStoryForm from 'views/pages/Backlogs/Stories/DuplicateStory.Form';
import forEach from 'lodash/forEach';
import duplicateStoryFields from 'views/pages/Backlogs/Stories/duplicateStoryFields';
import { duplicateStoryForSection } from 'store/actions/backlogs';
import some from 'lodash/some';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const options = [
  {
    label: 'Personal Kanban',
    value: 'personal',
    path: '/admin/kanban/personal',
  },
  {
    label: 'Global Kanban',
    value: 'global',
    path: '/admin/kanban/company',
  },
];

const Kanban = props => {
  const dispatch = useDispatch();
  const isMobile = useBreakpoint('xs', 'down');
  const [keyword, setKeyword] = useState('');
  const [count, setCount] = useState(0);
  const [isFullScreen, setFullScreen] = useState(false);
  const [period, setPeriod] = useState('last_month');

  // show modal story detail
  const history = useHistory();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const queryStory = get(queryProps, 'story', '');

  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const [dataRetrievalCount, setDataRetrievalCount] = useState(0);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);

  useEffectOnce(() => {
    analyticsSendEvent({
      action: props.isGlobal
        ? analyticsConstants.action.view_global_kanban
        : analyticsConstants.action.view_personal_kanban,
    });
    const footerElement = document.getElementById('admin-footer');
    const parent = document.getElementById('admin-footer').parentElement;
    parent.removeChild(footerElement);
    return () => {
      parent.appendChild(footerElement);
    };
  });

  const loggedInUserId = useSelector(({ auth }) => get(auth, 'user.id'));

  const storyId = useGetFieldFromObject('story', 'details.data.id', '');

  const projectId = useGetFieldFromObject(
    'story',
    'details.data.initiative.id',
    ''
  );

  const analyticsSendEvent = ({ action, label, value, ...rest }) => {
    analytics.sendEvent({
      category: props.isGlobal
        ? analyticsConstants.category.global_kanban
        : analyticsConstants.category.personal_kanban,
      // is_logged_in_user: isLoggedInUser,
      action,
      label,
      value,
      ...rest,
    });
  };
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  useEffect(() => {
    if (storyId === toNumber(queryStory)) {
      analyticsSendEventStory({
        action: analyticsConstants.action.open_story,
        opened_from: 'Personal Kanban',
      });
      setIsStoryModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId, projectId, queryStory]);

  useEffect(() => {
    const getStoryDetail = story => dispatch(fetchStory(story));
    if (queryStory) getStoryDetail(queryStory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStory]);

  useDebounce(
    () => {
      if (!isEmpty(keyword))
        analyticsSendEvent({
          action: props.isGlobal
            ? analyticsConstants.action.search_global_kanban
            : analyticsConstants.action.search_personal_kanban,
          label: analyticsConstants.label.kanban_search,
        });
    },
    1000,
    [keyword]
  );

  useDebounce(
    () => {
      const getData = async (period, keyword) => {
        await props.fetchKanbanData(period, keyword);
        setDataRetrievalCount(currentCount => currentCount + 1);
      };

      getData(period, keyword);
    },
    500,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [period, keyword, count]
  );

  const reload = () => setCount(count + 1);

  const profile = useSelector(({ profile }) => get(profile, 'details.data'));

  const columns = get(props.data, 'columns', []) || [];
  let openAssignments = 0;
  columns.map(column => {
    if (column.status === 'Done') return false;

    column.assignments.map(assignment => {
      if (assignment.is_locked === false) openAssignments++;
      return true;
    });

    return true;
  });

  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);
  const storyName = useGetFieldValue('name');

  const isStoryFetchError = useGetFieldFromObject(
    'story',
    'details.isError',
    false
  );
  const storyFetchErrorMessage = useGetFieldFromObject(
    'story',
    'details.message',
    'Something went wrong. Please try again'
  );
  const errorStoryId = useGetFieldFromObject('story', 'details.id');

  useEffect(() => {
    const isStoryError = isStoryFetchError && errorStoryId === queryStory;
    if (isStoryError) {
      NotificationHandler.open({
        message: storyFetchErrorMessage,
        operation: 'failure',
      });
      history.push('?');
      setIsStoryModalOpen(false);
    }
  }, [
    errorStoryId,
    history,
    isStoryFetchError,
    queryStory,
    storyFetchErrorMessage,
  ]);
  const isGlobalKanbanAllowed = useAccess([permissions.ACCESS_GLOBAL_KANBAN]);
  const isPersonalKanbanAllowed = useAccess([
    permissions.ACCESS_PERSONAL_KANBAN,
  ]);

  const closeDuplicateModal = () => setDuplicateModal(false);

  const openDuplicateModal = () => setDuplicateModal(true);

  const handleDuplicateStory = async values => {
    setDuplicating(true);
    const { data: duplicatedStory, status, errorStatus } = await dispatch(
      duplicateStoryForSection(storyId, values, null)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showStorageLimit();
      return;
    }
    if (status) {
      history.push(`/admin/projects/${duplicatedStory.initiative.id}/backlog`);
    }
    setDuplicating(false);
    setDuplicateModal(false);
  };

  return (
    <>
      <CardsHeader
        currentPage={props.title}
        name={profile.name}
        parentName="People"
        parentLink={`/admin/users/${profile.id}`}
        isRoot={props.isGlobal}
      />
      <div
        id="kanban-page"
        className={cs(classes.root, {
          [classes.fullscreen]: isFullScreen,
        })}
      >
        {dataRetrievalCount === 0 ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <Container fluid>
            <div className={classes.header}>
              <Card>
                <CardBody>
                  <CardTitle
                    className="h3 d-flex justify-content-between mb-3 align-items-center"
                    id="myKanban"
                  >
                    {isGlobalKanbanAllowed && isPersonalKanbanAllowed ? (
                      <UncontrolledDropdown
                        className={cs(classes.dropdown)}
                        direction="down"
                      >
                        <DropdownToggle>
                          <div className="d-flex">
                            <div>{props.title}</div>
                            <i
                              className={cs(
                                'fas fa-caret-right',
                                classes.chevron
                              )}
                            />
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          {options.map((item, index) => (
                            <DropdownItem
                              key={index}
                              onClick={() => history.push(item.path)}
                              className={'d-flex w-100 align-items-center'}
                            >
                              <h5
                                className={cs(
                                  'font-weight-normal mb-0',
                                  classes.dropdownItem
                                )}
                              >
                                {item.label}
                              </h5>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : (
                      <div>{props.title}</div>
                    )}
                    <Button
                      className={isFullScreen && 'p-0 m-0'}
                      size="sm"
                      color={isFullScreen || isMobile ? 'link' : 'primary'}
                      type="button"
                      onClick={() => {
                        setFullScreen(!isFullScreen);
                        if (isFullScreen) closeFullscreen();
                        else {
                          analyticsSendEvent({
                            action: props.isGlobal
                              ? analyticsConstants.action.expand_global_kanban
                              : analyticsConstants.action
                                  .expand_personal_kanban,
                            label: analyticsConstants.label.kanban_expand,
                          });
                          openFullscreen(document.documentElement);
                        }
                      }}
                    >
                      {isFullScreen ? (
                        <img src={fullScreenCloseIcon} alt="Close Fullscreen" />
                      ) : isMobile ? (
                        <img src={fullScreenIcon} alt="Fullscreen" />
                      ) : (
                        'Fullscreen'
                      )}
                    </Button>
                  </CardTitle>
                  <Row>
                    <Col
                      md={6}
                      className="d-flex justify-content-start align-items-center mb-1"
                    >
                      Search:{' '}
                      <Input
                        className={classes.searchBox}
                        value={keyword}
                        onChange={e => {
                          setKeyword(e.target.value);
                        }}
                      />
                    </Col>
                    <Col
                      md={6}
                      className="d-flex justify-content-md-end justify-content-sm-start  align-items-center mt-1"
                    >
                      <div className={classes.status}>
                        <b>{openAssignments}</b> Open Assignments
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>

            <div className={classes.content}>
              <DragContainer
                isGlobal={props.isGlobal}
                userId={loggedInUserId}
                setPeriod={setPeriod}
                refresh={reload}
                data={props.data}
                analyticsSendEvent={analyticsSendEvent}
              />
            </div>
          </Container>
        )}
        {isStoryModalOpen && (
          <StoryModal
            storyId={toNumber(storyId)}
            projectId={toNumber(projectId)}
            isOpen={isStoryModalOpen}
            closeModal={() => {
              setIsStoryModalOpen(false);
              history.push(`?`);
            }}
            currentTab={get(queryProps, 'tab', 'comments')}
            onChange={() => reload()}
            openDuplicateModal={openDuplicateModal}
          />
        )}
        {isDuplicateModal ? (
          <DuplicateStoryForm
            storyData={{ name: storyName }}
            isModalOpen={isDuplicateModal}
            closeModal={closeDuplicateModal}
            submitValues={handleDuplicateStory}
            isLoading={isDuplicating}
            openDuplicateModal={openDuplicateModal}
          />
        ) : null}
      </div>
    </>
  );
};

export default Kanban;
