import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardFooter,
  CardBody,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import classes from './epics.module.scss';
import cx from 'classnames';
import Input from 'components/FormFields/Input';
import { get, isEmpty, trim, toNumber } from 'lodash';
import flatMap from 'lodash/flatMap';
import RenderData from './RenderEpicsList';
import NoEpicsImg from 'assets/img/theme/No_Epics.svg';
import { CSSTransition } from 'react-transition-group';
import {
  fetchEpicsList,
  addEpic,
  removeEpic,
  updateEpic as updateEpicAction,
  reorderEpics,
} from 'store/actions/epics';
import { getModifiedOrder } from 'helpers/dragDropHelpers';
import { setUserPreference } from 'store/actions/profile';
import types from 'store/types/epics';
import { DragDropContext } from 'react-beautiful-dnd';
import pluralize from 'pluralize';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import ViewEpicsBar from './ViewEpicsBar';
import CollapseButton from './CollapseButton';
import { useAccess, permissions } from 'helpers/permission';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { useHistory } from 'react-router';

const EpicList = ({ id, collapse, setCollapse, collapsePreference }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const history = useHistory();
  const shouldFocusInput = get(
    history,
    'location.state.shouldFocusEpicInput',
    false
  );

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.epics,
      ...rest,
    });
  };

  const toggleEpicListAndFocus = async () => {
    await setCollapse(val => !val);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    dispatch(fetchEpicsList(id));
    dispatch({
      type: types.RESET_CREATE_EPIC,
    });
    if (shouldFocusInput) {
      if (collapse) {
        toggleEpicListAndFocus();
      } else {
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const projectMemberDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data.team_members', [])
  );
  const isUserMemberOfTeam = find(
    projectMemberDetails,
    member => member.id === userId
  );
  const isUserAllowedViewEpics = useAccess([permissions.LIST_EPICS]);
  const isUserAllowedAddEpic = useAccess([
    permissions.ADD_EPICS_TO_ALL_PROJECTS,
    {
      permission: permissions.ADD_EPICS_TO_MY_PROJECTS,
      value: isUserMemberOfTeam,
    },
  ]);
  const isUserAllowedDeleteEpic = useAccess([
    permissions.DELETE_ALL_EPICS,
    { permission: permissions.DELETE_PROJECT_EPICS, value: isUserMemberOfTeam },
  ]);
  const isUserAllowedEditEpic = useAccess([
    permissions.EDIT_ALL_EPICS,
    { permission: permissions.EDIT_PROJECT_EPICS, value: isUserMemberOfTeam },
  ]);
  const [newEpicName, setNewEpicName] = useState('');
  const [editId, setEditId] = useState(null);
  const [newEpic, setNewEpic] = useState({});
  const isSelectedStoriesEmpty = useSelector(({ backlog }) =>
    isEmpty(flatMap(get(backlog, 'selectedStories', {})))
  );
  const createdProjectId = useSelector(({ epic }) =>
    get(epic, 'createEpic.id')
  );
  const isCreateInprogress = useSelector(({ epic }) =>
    get(epic, 'createEpic.isInProgress')
  );

  const isDeleteInprogress = useSelector(({ epic }) =>
    get(epic, 'deleteEpic.isInProgress')
  );
  const isEditInprogress = useSelector(({ epic }) =>
    get(epic, 'editEpic.isInProgress')
  );

  const epicData = useSelector(
    ({ epic }) => get(epic, 'epicState.data.data', []),
    isEqual
  );
  const isLoading = useSelector(({ epic }) =>
    get(epic, 'epicState.isInProgress', false)
  );
  // const [placeholder, showPlaceHolder] = useState(collpase);

  const changeEpicPreference = () => {
    const updatedEpicPreference = {
      category: 'epic',
      key: 'toggle_epics_window',
      value: collapse ? 'expand' : 'collapse',
    };
    dispatch(setUserPreference(updatedEpicPreference));
  };
  const elementToFocus = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (elementToFocus.current) {
      const offsetTop = elementToFocus.current.offsetTop;
      containerRef.current.parentNode.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });

      dispatch({
        type: types.RESET_CREATE_EPIC,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdProjectId]);

  const createEpic = () => {
    analyticsSendEvent({ action: analyticsConstants.action.create_epic });
    dispatch(addEpic(id, newEpicName, epicData));
    setNewEpicName('');
  };
  const editEpic = row => {
    setEditId(row.id);
    setNewEpic(row);
  };

  const updateEpic = name => {
    if (!(isEmpty(trim(name)) || isEmpty(newEpic.color))) {
      analyticsSendEvent({ action: analyticsConstants.action.rename_epic });
      dispatch(
        updateEpicAction(
          id,
          {
            name: trim(name),
            color: newEpic.color,
          },
          editId
        )
      );
    }
    setEditId(null);
    setNewEpicName('');
    setNewEpic({});
  };

  const setNewColor = (color, { name, id: epicId }) => {
    analyticsSendEvent({ action: analyticsConstants.action.update_epic_color });
    dispatch(
      updateEpicAction(
        id,
        { name: name, color: color.replace('#', '') },
        epicId
      )
    );
    setEditId(null);
    setNewEpicName('');
    setNewEpic({});
  };
  const moveEpicToTop = index => {
    analyticsSendEvent({ action: analyticsConstants.action.move_epic_to_top });
    reorderEpic({ source: { index }, destination: { index: 0 } });
  };

  const moveEpicToBottom = index => {
    analyticsSendEvent({
      action: analyticsConstants.action.move_epic_to_bottom,
    });
    reorderEpic({
      source: { index },
      destination: { index: epicData.length - 1 },
    });
  };

  const deleteEpic = data => {
    analyticsSendEvent({ action: analyticsConstants.action.delete_epic });
    dispatch(removeEpic(id, data, data.id));
  };
  const deleteEpicConfirm = row => {
    const text =
      row.stories_count === 0
        ? `You are about to delete "${row.name}". Do you want to continue?`
        : `There ${pluralize('is', toNumber(row.stories_count))} ${
            row.stories_count
          } ${pluralize('story', toNumber(row.stories_count))} assigned to "${
            row.name
          }". These will be unassigned. Do you want to continue?`;
    AlertPopupHandler.open({
      onConfirm: deleteEpic,
      confirmBtnText: 'Delete Epic',
      text,
      data: row,
    });
  };
  const reorderEpic = ({ destination, source }) => {
    const reorderedData = getModifiedOrder({
      source,
      destination,
      data: epicData,
    });
    if (reorderedData) {
      const { modifiedOrder, modifiedData } = reorderedData;
      dispatch(reorderEpics(id, modifiedOrder, modifiedData));
    }
  };

  return (
    isUserAllowedViewEpics && (
      <Card
        className={cx('ml-4', classes.card, {
          [classes.cardCollapsed]: collapse,
          [classes.storiesMultiSelect]: !isSelectedStoriesEmpty,
        })}
      >
        <CollapseButton
          collapse={!collapse}
          onCollapse={() => {
            setCollapse(val => !val);
            changeEpicPreference();
          }}
        />
        <ViewEpicsBar collapse={collapse} />
        <CSSTransition
          timeout={200}
          in={!collapse}
          classNames={{
            enter: classes['content-wrapper-enter'],
            enterActive: classes['content-wrapper-enter-active'],
            exit: classes['content-wrapper-exit'],
            exitActive: classes['content-wrapper-exit-active'],
          }}
          unmountOnExit
        >
          <div className={cx('d-flex flex-column', classes['content-wrapper'])}>
            <div className={classes['epic-header']}>
              <h3 className={cx('text-gray-dark p-3 m-0')}>Epics</h3>
              {!isEmpty(epicData) && (
                <h6
                  className={cx(
                    classes['epic-title'],
                    'text-gray text-weight-bold pr-4 mb-0 border-bottom border-top'
                  )}
                >
                  Title
                </h6>
              )}
            </div>
            <DragDropContext
              onDragEnd={(...args) => {
                analyticsSendEvent({
                  action: analyticsConstants.action.drag_epic,
                });
                reorderEpic(...args);
              }}
            >
              <CardBody
                className={cx(
                  'p-0',
                  classes['card-body'],
                  isEmpty(epicData) && classes['no-epic-img-container']
                )}
              >
                {isEmpty(epicData) && !isLoading ? (
                  <div className="d-flex justify-content-center flex-column align-items-stretch  w-100">
                    <img
                      className={cx(
                        classes['no-epic-img'],
                        'm-auto h-auto w-100 px-2'
                      )}
                      src={NoEpicsImg}
                      alt="No Epics"
                    />
                  </div>
                ) : (
                  <div ref={containerRef}>
                    <RenderData
                      isLoading={isLoading}
                      isEditInprogress={isEditInprogress}
                      isCreateInprogress={isCreateInprogress}
                      isDeleteInprogress={isDeleteInprogress}
                      epicData={epicData}
                      setNewColor={setNewColor}
                      setNewEpic={setNewEpic}
                      elementToFocus={elementToFocus}
                      moveEpicToTop={moveEpicToTop}
                      editEpic={editEpic}
                      updateEpic={updateEpic}
                      newEpic={newEpic}
                      deleteEpicConfirm={deleteEpicConfirm}
                      moveEpicToBottom={moveEpicToBottom}
                      editId={editId}
                      createdProjectId={createdProjectId}
                    />
                  </div>
                )}
              </CardBody>
            </DragDropContext>
            <CardFooter>
              <div
                id="epicCreate"
                className="d-flex justify-content-between w-100 align-items-center"
              >
                <Input
                  innerRef={inputRef}
                  placeholder="Create a new epic"
                  onChange={event => setNewEpicName(event.target.value)}
                  onKeyDown={event =>
                    event.keyCode === 13 && createEpic(newEpicName)
                  }
                  value={newEpicName}
                  maxLength={255}
                />
                {shouldFocusInput ? (
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="epicCreate"
                  >
                    Create an Epic Here
                  </UncontrolledTooltip>
                ) : null}
                <div>
                  <Button
                    outline={isEmpty(trim(newEpicName))}
                    className={cx(
                      'btn-icon btn-icon-only rounded-circle ml-4',
                      classes['create-epic-btn']
                    )}
                    color={isEmpty(trim(newEpicName)) ? '' : 'primary'}
                    onClick={event => createEpic(newEpicName)}
                    disabled={isEmpty(trim(newEpicName))}
                  >
                    <i className="fas fa-plus" />
                  </Button>
                </div>
              </div>
            </CardFooter>
          </div>
        </CSSTransition>
      </Card>
    )
  );
};

export default EpicList;
