import React, { useEffect, useLayoutEffect, useState } from 'react';
import cs from 'classnames';
import AuthHeader from 'components/Headers/AuthHeader';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import classes from './Invalid.module.scss';
import Button from 'components/Button';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import history from 'helpers/history';
import { createOwnCompany, resendInvite } from 'store/actions/authActions';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

const Invalid = props => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleCreateCompany = async () => {
    if (userId) {
      setLoading(true);
      await dispatch(createOwnCompany(userId));
      setLoading(false);
    } else {
      history.push(redirectTo);
    }
  };

  const redirectTo = get(props, 'location.state.redirectTo', '/auth/register');
  const btnText = get(props, 'location.state.btnText', 'Create Account');
  const userId = get(props, 'location.state.userId', null);
  const description = get(props, 'location.state.description', null);
  const isInvalidIvite = get(props, 'location.state.isInvalidInvite', false);
  const userEmail = get(props, 'location.state.email');

  useLayoutEffect(() => {
    document.body.classList.add('bg-default');
    // document.getElementById('footer-main').classList.add('d-none');
    document.getElementById('navbar-main').classList.add('invisible');
    return () => {
      // document.getElementById('footer-main').classList.remove('d-none');
      document.getElementById('navbar-main').classList.remove('invisible');
    };
  }, []);

  return (
    <div className="main-content">
      <AuthNavbar />
      <AuthHeader
        title="Invalid Link"
        lead="Looks like the link you received is no longer valid."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={cs('border-0 mb-0', classes.invalid)}>
              <CardBody className="px-lg-5 py-lg-5 align-items-center d-flex">
                <div className={classes.content}>
                  <h4 className="text-center w-75">{description}</h4>
                  <Button
                    className="mt-4"
                    color="info"
                    onClick={() => {
                      if (isInvalidIvite) {
                        dispatch(resendInvite({ email: userEmail }));
                      } else {
                        handleCreateCompany();
                      }
                    }}
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    {btnText}
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Row className="mt-2">
              <Col xs="6">
                <a className="text-light" href="/auth/login">
                  <small>Back to Sign In</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a className="text-light" href="/auth/register">
                  <small>Create a free account</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Invalid;
