import React from 'react';
import ProjectStatus from 'components/Status';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const StartDate = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  isMobile,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const status = getFieldValue('status', {
    status: 'Active',
    color: '#2DCE89',
  });
  const statusOptions = useSelector(({ project }) =>
    get(project, 'initiative_statuses.data', {})
  );
  return (
    <>
      <h6 className="text-uppercase text-muted">Status</h6>
      <ProjectStatus
        statuses={statusOptions}
        selectedStatus={status}
        disabled={!isEditAllowed}
        handleChange={item => {
          analyticsSendEvent({
            action: analyticsConstants.action.edit_project_status,
          });
          onChange('status', item);
        }}
        right={isMobile}
      />
    </>
  );
};

export default StartDate;
