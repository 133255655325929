import React from 'react';
import classes from '../teams.module.scss';

const TodayCell = ({ cell, row }) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <span className={classes.ellipsis}>{cell ? `${cell} hours` : '--'}</span>
    </div>
  );
};

export default TodayCell;
