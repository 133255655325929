import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { resendInvite } from 'store/actions/authActions';

import classes from './Register.module.scss';
import { Link } from 'react-router-dom';
// core components
import Button from 'components/Button';
import Alert from 'components/Alert';
import get from 'lodash/get';
import RegisterForm from './Register.Form';
import AuthCardHeader from 'components/Headers/AuthCardHeader';
import BrandLogo from 'assets/img/brand/Lyght_whiteLogo.svg';

const Register = props => {
  const [step, setStep] = useState(1);
  const [sentEmail, setSentEmail] = useState('');
  const isError = useSelector(({ auth }) => get(auth, 'register.isError'));
  const message = useSelector(({ auth }) => get(auth, 'register.message'));
  const isResendEmailLoading = useSelector(({ auth }) =>
    get(auth, 'resendInvite.loading')
  );
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    document.getElementById('navbar-main').classList.add('d-none');
    document.getElementById('footer-main').classList.add('invisible');
    return () => {
      document.getElementById('navbar-main').classList.remove('d-none');
      document.getElementById('footer-main').classList.remove('invisible');
    };
  }, []);
  const renderRegisterForm = props => {
    return (
      <CardBody className="px-lg-5 py-lg-5">
        {isError && (
          <Alert text={message} dismiss={true} fade={true} color="danger" />
        )}
        <AuthCardHeader
          head="Create an account"
          title="Sign up for a free trial, with no obligations or credit card required."
        />
        <RegisterForm {...{ setStep, setSentEmail }} />
        <p className="text-center text-sm mt-3 mb-0 font-weight-400">
          Already have an account?{' '}
          <Link className="text-info" to="/auth/login">
            Sign In.
          </Link>
        </p>
      </CardBody>
    );
  };

  const rednerSuccessMessage = () => {
    return (
      <>
        <CardBody className="px-lg-5 py-lg-5 text-center">
          <div className="text-muted mb-4">Check Your Email</div>
          We sent an email to <span className="text-primary">{sentEmail}</span>.
          Click the link in the email to verify your email address.
          <div className="text-center">
            <Button
              color="info"
              className="mt-4"
              onClick={() => dispatch(resendInvite({ email: sentEmail }))}
              loading={isResendEmailLoading}
            >
              Resend Email
            </Button>
          </div>
        </CardBody>
      </>
    );
  };

  return (
    <>
      <AuthHeader
        title={<img className={classes.brandImage} alt="..." src={BrandLogo} />}
        titleClassName="mt--3 mb-0"
      />
      <Container className={classnames('mt--9 pb-5', classes.root)}>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className={classnames(classes.card, 'border-0')}>
              {step === 1 && renderRegisterForm(props)}
              {step === 2 && rednerSuccessMessage()}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
