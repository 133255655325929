import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import StoryGradientOrange from 'assets/img/icons/common/StoryGradientOrange.svg';
import CreateStoryForm from 'views/pages/QuickActions/CreateStory/CreateStory.Form';
import { createStoryQuickAction } from 'store/actions/quickActions';
import history from 'helpers/history';
import classes from '../QuickActions.module.scss';
import InfoToggle from 'views/pages/QuickActions/CreateStory/InfoToggle';

const CreateStory = ({
  isPersonalDashboard,
  setStoryId,
  setProjectId,
  setIsStoryModalOpen,
}) => {
  const dispatch = useDispatch();
  const [isCreateStory, setCreateStory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setCreateStory(true);
  };

  const closeModal = () => {
    setCreateStory(false);
  };

  const submitValues = async values => {
    setIsLoading(true);

    const { section, name, project } = values;
    const { data, status } = await dispatch(
      createStoryQuickAction(section.id, { name })
    );
    if (status) {
      if (isPersonalDashboard) {
        setStoryId(data.id);
        setProjectId(project.id);
        setIsStoryModalOpen(true);
      }
      history.push(`/admin/projects/${project.id}/dashboard/?story=${data.id}`);
    }
    closeModal();
    setIsLoading(false);
  };

  return (
    <>
      <div className={classes.actionBox} onClick={openModal}>
        <div className={cs(classes.gradient, classes.createStory)} />
        <InfoToggle />
        <div className={classes.box}>
          <img src={StoryGradientOrange} alt="" />
          <h2>Create a Story</h2>
        </div>
      </div>
      {isCreateStory ? (
        <CreateStoryForm
          isModalOpen={isCreateStory}
          closeModal={closeModal}
          submitValues={submitValues}
          isLoading={isLoading}
          isPersonalDashboard={isPersonalDashboard}
        />
      ) : null}
    </>
  );
};

export default CreateStory;
