import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_RECENTLY_VIEWED_PROJECTS_INPROGRESS',
  'FETCH_RECENTLY_VIEWED_PROJECTS_SUCCESS',
  'FETCH_RECENTLY_VIEWED_PROJECTS_FAILURE',
  'FETCH_WEEKLY_USER_TIME_LOGGED_INPROGRESS',
  'FETCH_WEEKLY_USER_TIME_LOGGED_SUCCESS',
  'FETCH_WEEKLY_USER_TIME_LOGGED_FAILURE',
  'FETCH_OPEN_ASSIGNMENTS_INPROGRESS',
  'FETCH_OPEN_ASSIGNMENTS_SUCCESS',
  'FETCH_OPEN_ASSIGNMENTS_FAILURE',
  'FETCH_TIME_SPENT_GRAPH_INPROGRESS',
  'FETCH_TIME_SPENT_GRAPH_SUCCESS',
  'FETCH_TIME_SPENT_GRAPH_FAILURE',
  'FETCH_RECENTLY_VIEWED_STORIES_INPROGRESS',
  'FETCH_RECENTLY_VIEWED_STORIES_SUCCESS',
  'FETCH_RECENTLY_VIEWED_STORIES_FAILURE',
  'UPDATE_STORY',
  'FETCH_DASHBOARD_ACTIVITIES_INPROGRESS',
  'FETCH_DASHBOARD_ACTIVITIES_SUCCESS',
  'FETCH_DASHBOARD_ACTIVITIES_FAILURE',
  'FETCH_MORE_DASHBOARD_ACTIVITIES_INPROGRESS',
  'FETCH_MORE_DASHBOARD_ACTIVITIES_SUCCESS',
  'FETCH_MORE_DASHBOARD_ACTIVITIES_FAILURE'
);
