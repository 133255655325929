import React from 'react';
import cs from 'classnames';
import StoryGradientPurple from 'assets/img/icons/common/StoryGradientPurple.svg';
import classes from '../QuickActions.module.scss';
import history from 'helpers/history';

const CreateEpic = ({ projectId }) => {
  const handleOnClick = () => {
    history.push({
      pathname: `/admin/projects/${projectId}/backlog`,
      state: { shouldFocusEpicInput: true },
    });
  };

  return (
    <div className={classes.actionBox} onClick={handleOnClick}>
      <div className={cs(classes.gradient, classes.createEpic)} />
      <div className={classes.box}>
        <img src={StoryGradientPurple} alt="" />
        <h2>Create an Epic</h2>
      </div>
    </div>
  );
};

export default CreateEpic;
