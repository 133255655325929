import React from 'react';
import cx from 'classnames';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import classes from './billing.module.scss';

export const showSuccessAlert = msg => {
  AlertPopupHandler.open({
    onConfirm: () => {},
    onCancel: () => {},
    confirmBtnText: 'OK',
    showCancel: false,
    warning: false,
    type: 'success',
    title: 'Success',
    text: <span className={classes.alertMessageText}>{msg}</span>,
    confirmBtnBsStyle: 'success px-4',
  });
};

export const showDowngradeErrors = (errors, isMobile) => {
  if (errors.length > 0) {
    AlertPopupHandler.open({
      onConfirm: () => {},
      onCancel: () => {},
      confirmBtnText: 'OK',
      showCancel: false,
      warning: false,
      type: 'info',
      title: 'Info',
      text: (
        <span
          className={cx(classes.alertMessageText, isMobile && classes.isMobile)}
        >
          You are unable to switch your plan because
          <ul>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        </span>
      ),
      confirmBtnBsStyle: 'info px-4',
    });
    return false;
  }
  return true;
};
