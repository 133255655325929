import {
  getRecentlyViewedProjects,
  getDashboardActivities,
  getWeeklyHoursLogged as getWeeklyHoursLoggedApi,
  getOpenAssignments as getOpenAssignmentsApi,
  getTimeSpent as getTimeSpentApi,
  getRecentlyViewedStories as getRecentlyViewedStoriesApi,
} from 'api/userDashboard';
import Types from 'store/types/userDashboard';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import findIndex from 'lodash/findIndex';

export const getViewedProjects = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_RECENTLY_VIEWED_PROJECTS_INPROGRESS });
    try {
      const resp = await getRecentlyViewedProjects();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_PROJECTS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_PROJECTS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_RECENTLY_VIEWED_PROJECTS_FAILURE,
        message: error,
      });
    }
  };
};

export const getViewedStories = (page = 1) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_RECENTLY_VIEWED_STORIES_INPROGRESS });
    try {
      const resp = await getRecentlyViewedStoriesApi(page);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_STORIES_SUCCESS,
          data: pickBy(resp, (value, key) => key !== 'status'),
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_STORIES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_RECENTLY_VIEWED_STORIES_FAILURE,
        message: error,
      });
    }
  };
};

export const getWeeklyHoursLogged = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_WEEKLY_USER_TIME_LOGGED_INPROGRESS });
    try {
      const resp = await getWeeklyHoursLoggedApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_WEEKLY_USER_TIME_LOGGED_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_WEEKLY_USER_TIME_LOGGED_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_WEEKLY_USER_TIME_LOGGED_FAILURE,
        message: error,
      });
    }
  };
};

export const getOpenAssignments = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_OPEN_ASSIGNMENTS_INPROGRESS });
    try {
      const resp = await getOpenAssignmentsApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_OPEN_ASSIGNMENTS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_OPEN_ASSIGNMENTS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_OPEN_ASSIGNMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const getTimeSpent = (group = 'week', startDate, endDate) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_TIME_SPENT_GRAPH_INPROGRESS });
    try {
      const resp = await getTimeSpentApi({ group, startDate, endDate });
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_TIME_SPENT_GRAPH_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TIME_SPENT_GRAPH_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TIME_SPENT_GRAPH_FAILURE,
        message: error,
      });
    }
  };
};

export const updateStoryInRecentyViewedStories = (storyId, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const stories = get(state, 'userDashboard.viewedStories.data.data', []);
    const foundIndex = findIndex(stories, s => s.id === storyId);
    dispatch({
      type: Types.UPDATE_STORY,
      data: [
        ...stories.slice(0, foundIndex),
        data,
        ...stories.slice(foundIndex + 1),
      ],
    });
  };
};
export const fetchDashboardActivities = (page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DASHBOARD_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDashboardActivities(page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DASHBOARD_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DASHBOARD_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DASHBOARD_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreDashboardActivities = page => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MORE_DASHBOARD_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDashboardActivities(page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_DASHBOARD_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_MORE_DASHBOARD_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MORE_DASHBOARD_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};
