import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import Dropdown from 'components/Dropdowns';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import cx from 'classnames';

const BudgetType = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const contractType = getFieldValue('contract_type');
  const budgetType = get(contractType, 'type', '--');
  const budgetOptions = useSelector(({ project }) =>
    get(project, 'contract_types.data', {})
  );
  const budgetTypes = map(budgetOptions, (value, key) => ({
    text: <p>{value}</p>,
    onClick: () => {
      analyticsSendEvent({
        action: analyticsConstants.action.edit_project_budget_type,
      });
      onChange('budgetType', { id: key });
    },
  }));

  return (
    <>
      <h6 className="text-uppercase text-muted">Budget Type</h6>
      <Dropdown
        caret={false}
        options={budgetTypes}
        text={budgetType}
        tag="p"
        right={false}
        disabled={!isEditAllowed}
        className={cx(isEditAllowed && classes.hoverHand)}
      />
    </>
  );
};

export default BudgetType;
