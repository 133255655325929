import get from 'lodash/get';
import {
  getCompanyDetails as getCompanyDetailsApi,
  updateCompanyDetails as updateCompanyDetailsApi,
} from 'api/company';
import { getDropdown } from 'api/metadata';
import Types from 'store/types/company';

export const getCompanyDetails = () => {
  return async (dispatch, getState) => {
    const companyId = get(getState(), 'auth.user.company.id');
    dispatch({ type: Types.FETCH_COMPANY_INPROGRESS });
    try {
      const resp = await getCompanyDetailsApi(companyId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch(getCompanyMetadata('company_industries'));
        dispatch(getCompanyMetadata('company_types'));
        dispatch(getCompanyMetadata('company_sizes'));
        dispatch(getCompanyMetadata('states'));
        dispatch({ type: Types.FETCH_COMPANY_SUCCESS, data });
      } else {
        dispatch({ type: Types.FETCH_COMPANY_FAILURE, message });
      }
    } catch (e) {
      dispatch({ type: Types.FETCH_COMPANY_FAILURE });
    }
  };
};

export const updateCompanyDetails = data => {
  return async (dispatch, getState) => {
    const companyId = get(getState(), 'auth.user.company.id');
    dispatch({ type: Types.UPDATE_COMPANY_INPROGRESS });
    try {
      const resp = await updateCompanyDetailsApi(companyId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const respData = get(resp, 'data');
        dispatch({ type: Types.UPDATE_COMPANY_SUCCESS, data: respData });
      } else {
        dispatch({ type: Types.UPDATE_COMPANY_FAILURE, message });
      }
    } catch (e) {
      dispatch({ type: Types.UPDATE_COMPANY_FAILURE });
    }
  };
};

export const getCompanyMetadata = type => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_COMPANY_METADATA_INPROGRESS,
      payload: { type },
    });
    try {
      const resp = await getDropdown(type);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_COMPANY_METADATA_SUCCESS,
          payload: { type, data },
        });
      } else {
        dispatch({
          type: Types.FETCH_COMPANY_METADATA_FAILURE,
          payload: { type, message },
        });
      }
    } catch (e) {
      dispatch({
        type: Types.FETCH_COMPANY_METADATA_FAILURE,
        payload: { type },
      });
    }
  };
};
