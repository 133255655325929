import {
  getBudgetSpent as getBudgetSpentApi,
  getTimeLoggedThisWeek as getTimeLoggedThisWeekApi,
  getSevenDaysHours as getSevenDaysHoursApi,
  getStoriesCompleted as getStoriesCompletedApi,
  getTimeSpentOnProject,
} from 'api/projects';
import Types from 'store/types/projectDashboard';
import get from 'lodash/get';

export const getBudgetSpent = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_PROJECT_SPENT_INPROGRESS });
    try {
      const resp = await getBudgetSpentApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PROJECT_SPENT_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PROJECT_SPENT_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PROJECT_SPENT_FAILURE,
        message: error,
      });
    }
  };
};

export const getTimeLoggedThisWeek = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_TIME_LOG_CURRENT_WEEK_INPROGRESS });
    try {
      const resp = await getTimeLoggedThisWeekApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_TIME_LOG_CURRENT_WEEK_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TIME_LOG_CURRENT_WEEK_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TIME_LOG_CURRENT_WEEK_FAILURE,
        message: error,
      });
    }
  };
};

export const getSevenDaysHours = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_LAST_WEEK_HOURS_INPROGRESS });
    try {
      const resp = await getSevenDaysHoursApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_LAST_WEEK_HOURS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_LAST_WEEK_HOURS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_LAST_WEEK_HOURS_FAILURE,
        message: error,
      });
    }
  };
};

export const getStoriesCompleted = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_COMPLETED_STORIES_INPROGRESS });
    try {
      const resp = await getStoriesCompletedApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_COMPLETED_STORIES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_COMPLETED_STORIES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_COMPLETED_STORIES_FAILURE,
        message: error,
      });
    }
  };
};

export const getTimeSpent = (
  projectId,
  group = 'week',
  startDate = '',
  endDate = ''
) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_TIME_LOG_GRAPH_INPROGRESS });
    try {
      const resp = await getTimeSpentOnProject(projectId, {
        group,
        startDate,
        endDate,
      });
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_TIME_LOG_GRAPH_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TIME_LOG_GRAPH_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TIME_LOG_GRAPH_FAILURE,
        message: error,
      });
    }
  };
};
