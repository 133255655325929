import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmailResetPassword } from 'store/actions/authActions';
// nodejs library that concatenates classes
import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
// reactstrap components
import {
  Card,
  CardBody,
  //   CardHeader,
  Col,
  Container,
  //   Form,
  FormGroup,
  Row,
} from 'reactstrap';

// core components
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
// import history from 'helpers/history';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [focus, setFocus] = useState({});
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required')
      .max(255, 'The email must be less than 255 chars'),
  });

  const renderForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup
          className={classnames(
            {
              focused: focus.email,
            },
            'mb-3'
          )}
        >
          <Input
            name="email"
            placeholder="Email"
            type="text"
            error={errors.email}
            value={values.email}
            onChange={handleChange}
            touched={touched.email}
            onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
            leftIcon={<i className="ni ni-email-83" />}
          />
        </FormGroup>
        <div className="text-center">
          <Button className="mt-4" color="info" type="submit" loading={loading}>
            Send Link
          </Button>
        </div>
      </form>
    );
  };

  return (
    <>
      <AuthHeader
        title="Forgot Password"
        lead="Enter your email below and we’ll send you the reset password link."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                {auth.passwordReset.isError === true && (
                  <Alert
                    text={auth.passwordReset.message}
                    dismiss={true}
                    fade={true}
                    color="danger"
                  />
                )}

                {auth.passwordReset.isError === false &&
                  auth.passwordReset.status === 1 && (
                    <Alert
                      text={auth.passwordReset.message}
                      dismiss={true}
                      fade={true}
                      color="success"
                    />
                  )}
                <div className="text-center text-muted mb-4">
                  Reset Your Password
                </div>
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={schema}
                  enableReinitialize
                  onSubmit={async (values, actions) => {
                    setLoading(true);
                    await dispatch(sendEmailResetPassword(values.email));
                    actions.resetForm();
                    setLoading(false);
                  }}
                >
                  {renderForm}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
