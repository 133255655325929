// react library for routing
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import AuthFooter from 'components/Footers/AuthFooter.js';
import LockScreen from 'views/pages/Auth/LockScreen';

import SupportRequestModal from 'components/SupportRequestModal';

import get from 'lodash/get';
import { getUserPreference, setUserPreference } from 'store/actions/profile';
import { showProfileUser } from 'store/actions/authActions';
import { showSupportForm } from 'store/actions/app';
import QuickStartTour from 'components/QuickStartTour';

class Admin extends React.Component {
  state = {
    sidenavOpen: window.innerWidth > 1199.95 ? true : false,
    width: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);

    if (!this.props.isActiveSession) {
      document.body.classList.add('bg-default');
    } else {
      document.body.classList.remove('bg-default');
      // isActiveSession is there so we should fetch s preference and profile
      this.props.getUserPreference();
      this.props.showProfileUser();
    }
  }

  componentDidUpdate(e) {
    if (get(e, 'history.location.pathname') !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }

    if (!this.props.isActiveSession) {
      document.body.classList.add('bg-default');
    } else {
      document.body.classList.remove('bg-default');
    }

    if (
      this.props.preferenceLoading === false &&
      e.preferenceLoading !== this.props.preferenceLoading
    ) {
      if (
        this.state.width > 1199.95 &&
        'collapse' === this.props.navigation_toggle_state
      ) {
        document.body.classList.remove('g-sidenav-pinned');
        document.body.classList.add('g-sidenav-hidden');
        document.body.classList.remove('g-sidenav-show');
      } else if (this.state.width < 1199.95) {
        document.body.classList.remove('g-sidenav-pinned');
      }
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.isExact}
          />
        );
      }
      return null;
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      document.body.classList.remove('g-sidenav-show');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState(
      {
        sidenavOpen: !this.state.sidenavOpen,
      },
      () => {
        if (this.state.width > 1199.95) {
          let status = 'expand';
          if (this.props.navigation_toggle_state === 'expand') {
            status = 'collapse';
          }

          this.props.toggle(status);
        }
      }
    );
  };

  getNavbarTheme = () => {
    return 'light';
  };

  renderSupportModal = () => {
    return (
      <SupportRequestModal
        isModalOpen={this.props.isSupportFormOpen}
        closeModal={e => {
          this.props.showSupportForm(false);
        }}
      />
    );
  };

  componentWillUnmount = () => {
    document.body.classList.remove('bg-default');

    window.removeEventListener('resize', this.handleWindowSizeChange);
  };

  render() {
    if (!this.props.isActiveSession) {
      return (
        <>
          <div className="main-content" ref="mainContent">
            <AuthNavbar page="lockscreen" />
            <LockScreen {...this.props} />
          </div>
          <AuthFooter />
        </>
      );
    }

    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: '#',
            imgSrc: require('assets/img/brand/lyght-logo.png'),
            imgAlt: '...',
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
            notification={this.props.notification}
          />

          <div className="admin-body" id="admin-body">
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
              <Redirect from="/admin/*" to="/admin/dashboard" />
            </Switch>
          </div>
          <AdminFooter />
          {this.renderSupportModal()}
          <QuickStartTour />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isActiveSession: get(state, 'auth.isActiveSession'),
    notification: state.notification,
    preferenceLoading: get(state.profile, 'preference.loading'),
    navigation_toggle_state: get(
      get(state.profile, 'preference.data', []).find(
        item =>
          item.category === 'general' && item.key === 'navigation_toggle_state'
      ),
      'value'
    ),
    isSupportFormOpen: get(state.app, 'isSupportFormOpen', false),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggle: value => {
      dispatch(
        setUserPreference({
          category: 'general',
          key: 'navigation_toggle_state',
          value: value,
        })
      );
    },
    getUserPreference: () => {
      dispatch(getUserPreference());
    },
    showProfileUser: () => {
      dispatch(showProfileUser());
    },
    showSupportForm: isOpen => {
      dispatch(showSupportForm(isOpen));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
