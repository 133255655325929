import React from 'react';
import customStyles from 'assets/scss/custom/_variables.scss';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import classes from './Kanban.module.scss';
import get from 'lodash/get';
import StoryCard from 'components/StoryCard';
import cs from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router';
import Dropdowns from 'components/Dropdowns';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { deleteSprintColumn, modifySprintColumn } from 'store/actions/sprint';
import { deleteColumn, modifyColumn } from 'store/actions/kanbanActions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import StoryStatus from 'components/Status/Status';
import ColumnNameInput from './ColumnNameInput';
import Button from 'components/Button';

const Column = ({
  data,
  expanded,
  toggleExpanded,
  isAllowedEdit,
  projectId,
  closeColumnModification,
  columnModification,
  openEdit,
  isAllowedKanbanEdit,
  columnIndex,
  onColumnModification,
  openCreateStory,
  isSprintKanban,
  sprintId,
  isSprintUpdateAllowed,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stories = get(data, 'stories', []) || [];
  const confirmDelete = async data => {
    if (isSprintKanban) {
      dispatch(deleteSprintColumn(sprintId, data.id));
    } else {
      dispatch(deleteColumn(projectId, data.id));
    }
  };
  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );
  const name = get(data, 'name');
  const status = get(data, 'status');
  const id = get(data, 'id');

  const deleteAction = row => {
    if (stories.length === 0) {
      AlertPopupHandler.open({
        onConfirm: confirmDelete,
        confirmBtnText: 'Delete Column',
        text: 'You are about to delete this column. Do you want to continue?',
        data: row,
      });
    } else {
      AlertPopupHandler.open({
        onConfirm: () => {},
        showCancel: false,
        title: 'Sorry, you can’t do that',
        text:
          'Please remove all of the stories from the column before deleting.',
      });
    }
  };
  return (
    <Draggable
      draggableId={`column-${id}`}
      index={columnIndex}
      isDragDisabled={!!columnModification}
    >
      {(dragProvided, snapshot) => {
        return (
          <div
            className={cs(classes.column)}
            {...dragProvided.draggableProps}
            ref={dragProvided.innerRef}
          >
            <div className="d-flex ml-2 mb-1 align-items-center">
              <h4
                className={cs(
                  'mr-2 mb-0 d-flex align-items-center overflow-hidden',
                  {
                    'w-100':
                      id === columnModification ||
                      (!id && columnModification === 'new'),
                  }
                )}
              >
                <i
                  className={cs(
                    'fas fa-grip-vertical text-light mr-2',
                    classes.grabHand
                  )}
                  {...dragProvided.dragHandleProps}
                />
                {id === columnModification ||
                (!id && columnModification === 'new') ? (
                  <ColumnNameInput
                    {...{
                      closeColumnModification,
                      projectId,
                      id,
                      columnModification,
                      name,
                      onColumnModification,
                      isSprintKanban: isSprintKanban,
                      sprintId,
                    }}
                  />
                ) : (
                  <>
                    <div
                      className={cs('overflow-hidden d-flex', {
                        [classes.pointerHand]: isAllowedKanbanEdit,
                      })}
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (isAllowedKanbanEdit) openEdit(id);
                      }}
                    >
                      <div
                        id={`column-name-${id}`}
                        className={classes.ellipsis}
                      >
                        {name}
                      </div>
                      <Badge color="info" className="ml-2">
                        {stories.length}
                      </Badge>
                    </div>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`#column-name-${id}`}
                      boundariesElement="viewport"
                    >
                      {name}
                    </UncontrolledTooltip>
                  </>
                )}
              </h4>
              <StoryStatus
                selectedStatus={status}
                right
                statuses={statuses}
                disabled={!isAllowedKanbanEdit}
                handleChange={async newStatus => {
                  if (isSprintKanban) {
                    await dispatch(
                      modifySprintColumn(sprintId, id, {
                        status_id: newStatus.id,
                      })
                    );
                  } else {
                    await dispatch(
                      modifyColumn(projectId, id, { status_id: newStatus.id })
                    );
                  }
                  onColumnModification();
                }}
                wrapperClass={cs(
                  'd-flex align-items-center',
                  classes.badgeWrapper
                )}
                toggleClass={cs('m-0')}
              >
                <Badge
                  style={{ backgroundColor: get(status, 'color') }}
                  className={classes.statusBadge}
                >
                  {get(status, 'status')}
                </Badge>
              </StoryStatus>
              {isAllowedKanbanEdit && (
                <Dropdowns
                  text={<i className="fas fa-ellipsis-v" />}
                  className="btn-icon-only m-0"
                  caret={false}
                  size="sm"
                  color=""
                  options={[
                    {
                      text: 'Delete Column',
                      onClick: event => {
                        event.stopPropagation();
                        event.preventDefault();
                        deleteAction(data);
                      },
                    },
                  ]}
                />
              )}
            </div>
            {id && isAllowedEdit && !isSprintKanban && (
              <div className="mx-2">
                <Button
                  size="sm"
                  className="w-100"
                  color="success"
                  onClick={() => {
                    openCreateStory(data);
                  }}
                >
                  + Create Story
                </Button>
              </div>
            )}
            <div className={classes.list}>
              <Droppable
                droppableId={`${id}`}
                type="columns"
                isDropDisabled={!!columnModification}
              >
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {stories.map((item, index) => {
                      return (
                        <Draggable
                          draggableId={`${get(item, 'id')}`}
                          index={index}
                          key={get(item, 'id')}
                          isDragDisabled={!!columnModification}
                        >
                          {(provided, snapshot) => {
                            const attrs = {
                              storyId: get(item, 'id'),
                              storyName: get(item, 'name'),
                              storyNumber: get(item, 'number'),
                              score: get(item, 'score'),
                              ownerName: get(item, 'owner.name'),
                              epicName: get(item, 'epic.name'),
                              epicColor: `#${get(item, 'epic.color')}`,
                              epicBackground: `#${get(item, 'epic.color')}26`,
                              progressColorFrom: `#${get(
                                item,
                                'health.color.0'
                              )}`,
                              progressColorTo: `#${get(
                                item,
                                'health.color.1'
                              )}`,
                              progressPercent:
                                get(item, 'health.progress') || 0,
                              totalHrs: get(item, 'health.total_hrs', 0) || 0,
                              consumedHrs:
                                get(item, 'health.consumed_hrs', 0) || 0,
                              tasks: get(item, 'tasks', []),
                              tasksSummary: get(item, 'tasks_summary', {}),
                            };

                            const draggableStyles = snapshot.isDragging
                              ? {
                                  ...provided.draggableProps.style,
                                  zIndex: 1,
                                }
                              : {
                                  ...provided.draggableProps.style,
                                  marginBottom: '10px',
                                };

                            const dragHandle =
                              (!isSprintKanban && isAllowedEdit) ||
                              (isSprintKanban && isSprintUpdateAllowed)
                                ? { ...provided.dragHandleProps }
                                : {};

                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...dragHandle}
                                style={draggableStyles}
                                key={get(item, 'id')}
                              >
                                <StoryCard
                                  {...attrs}
                                  openStoryModal={async () => {
                                    history.push(
                                      `?story=${get(item, 'id')}&tab=comments`
                                    );
                                  }}
                                  expanded={expanded}
                                  toggleExpanded={toggleExpanded}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

Column.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
  expanded: PropTypes.array,
  toggleExpanded: PropTypes.func,
  isAllowedEdit: PropTypes.bool,
  status: PropTypes.object,
  projectId: PropTypes.string,
  closeColumnModification: PropTypes.func,
  columnModification: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  openEdit: PropTypes.func,
};

Column.defaultProps = {
  closeColumnModification: () => {},
  openEdit: () => {},
  status: {
    status: 'In Progress',
    color: customStyles.lyghtBlue,
  },
};

export default Column;
