import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Table from 'components/Table';
import moment from 'moment';
import 'moment-duration-format';
import InfiniteScroll from 'react-infinite-scroller';
import useBreakPoint from 'helpers/useBreakPoint';
import { getRecentTimeEntries } from 'store/actions/clientProfile';
import classes from './ClientProfile.module.scss';
import cx from 'classnames';
import NoTimeLog from 'assets/img/theme/No_TimeLog.svg';

const RecentTimeEntries = ({ clientId }) => {
  const dispatch = useDispatch();
  const recentTimeEntries = useSelector(({ clientProfile }) =>
    get(clientProfile, 'recentTimeEntries.data.data', [])
  );
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const currentPage = useSelector(({ clientProfile }) =>
    get(clientProfile, 'recentTimeEntries.data.meta.current_page')
  );
  const lastPage = useSelector(({ clientProfile }) =>
    get(clientProfile, 'recentTimeEntries.data.meta.last_page')
  );
  const loading = useSelector(({ clientProfile }) =>
    get(clientProfile, 'recentTimeEntries.isInProgress', false)
  );
  const isError = useSelector(({ clientProfile }) =>
    get(clientProfile, 'recentTimeEntries.isError', false)
  );
  const isMobile = useBreakPoint('xs', 'down');

  return (
    <Card className={classes.recentTimeEntries}>
      <CardHeader className="h3 mb-0">Recent Time Entries</CardHeader>
      <CardBody
        className={cx('p-0', classes.cardBody, !isMobile && classes.scroll)}
      >
        <InfiniteScroll
          useWindow={isMobile}
          hasMore={currentPage < lastPage && !loading && !isError}
          initialLoad={false}
          pageStart={1}
          loadMore={() => {
            dispatch(getRecentTimeEntries(clientId, currentPage + 1));
          }}
        >
          <Table
            keyField="id"
            classes={cx('m-0', classes.table)}
            rowClasses={classes.row}
            defaultSorted={null}
            noDataIndication={() => (
              <div className="d-flex align-items-center justify-content-center p-3">
                <img
                  className={cx(classes.noTimeLogImg, 'm-auto w-25')}
                  src={NoTimeLog}
                  alt="No Time Logged"
                />
              </div>
            )}
            search={false}
            bootstrap4
            paginationOptions={false}
            remote
            bordered={false}
            loading={loading}
            data={recentTimeEntries}
            columns={[
              {
                dataField: 'user',
                text: 'User',
                sort: false,
                classes: classes.userColumn,
                sortCaret: null,
                formatter: (cell, row) => (
                  <div>
                    <h5 className={cx('overflow-hidden', classes.initiative)}>
                      {get(row, 'user.name', '')}
                    </h5>
                    <h5
                      className={cx('overflow-hidden', classes.initiative)}
                      style={{ color: '#' + get(row, 'initiative.color') }}
                    >
                      {get(row, 'initiative.name', '')}
                    </h5>
                  </div>
                ),
              },
              {
                dataField: 'story',
                text: 'Story',
                sort: false,
                classes: classes.storyColumn,
                sortCaret: null,
                formatter: (cell, row) => (
                  <>
                    <h5>{get(cell, 'name', '')}</h5>
                    <p className="text-muted text-sm">
                      {get(row, 'epic.name', '')} |{' '}
                      {get(row, 'task_type.name', '')}
                    </p>
                    <p className="text-sm">{get(row, 'description', '')}</p>
                  </>
                ),
              },
              {
                dataField: 'date_start',
                text: 'Date',
                sort: false,
                sortCaret: null,
                formatter: (cell, row) => (
                  <p className="text-gray text-sm m-0">
                    {moment(cell)
                      .tz(userTimezone)
                      .format('MMM/DD')}
                  </p>
                ),
              },
              {
                dataField: 'duration_hrs',
                text: 'Time',
                sort: false,
                sortCaret: null,
                formatter: (cell, row) => {
                  const timeDuration = moment.duration(
                    get(row, 'duration_hrs'),
                    'hours'
                  );
                  return (
                    <div>
                      <h5 className="mb-0">
                        {timeDuration.format('hh:mm:ss', { trim: false })}
                      </h5>
                      <p className="text-muted text-sm">
                        {moment(get(row, 'date_start', ''))
                          .tz(userTimezone)
                          .format('hh:mmA')}{' '}
                        -{' '}
                        {moment(get(row, 'date_end', ''))
                          .tz(userTimezone)
                          .format('hh:mmA')}
                      </p>
                    </div>
                  );
                },
              },
            ]}
          />
        </InfiniteScroll>
      </CardBody>
    </Card>
  );
};

export default RecentTimeEntries;
