import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../DataArea.module.scss';
import cs from 'classnames';

import FilterDropdown from '../FilterDropdown';
import RowReport from './Rows/RowReport';
import SortedByDropdown from '../SortedByDropdown';

import { Card, CardTitle, CardBody } from 'reactstrap';
import get from 'lodash/get';

import {
  doPostDataSummary,
  doPostDataSummarySubGroup,
} from 'store/actions/timelogReportActions';
import Button from 'components/Button';
import { postExportSummaryReport } from 'api/timelogReportApi';
import useDebounce from 'react-use/lib/useDebounce';
import { analyticsConstants } from 'helpers/analytics';

const SummaryTab = ({ formik, setTab, analyticsSendEvent }) => {
  const dispatch = useDispatch();

  const [openAll, setOpenAll] = useState(false);
  const [groupsOpen, setGroupsOpen] = useState([]);

  const [group, setGroup] = useState({ text: 'Project', value: 'project' });
  const [subgroup, setSubGroup] = useState({
    text: 'User',
    value: 'user',
  });
  const [sortedBy, setSort] = useState({
    text: 'Title',
    value: 'title',
  });
  const [sortedType, setSortType] = useState('');

  const values = get(formik, 'values');

  useDebounce(
    () => {
      const getData = async () => {
        if (values) {
          const users = values.users.map(item => item.id);
          const clients = values.clients.map(item => item.id);
          const themes = values.themes.map(item => item.id);
          const initiatives = values.initiatives.map(item => item.id);
          const epics = values.epics.map(item => item.id);
          const stories = values.stories.map(item => item.id);
          const tasks = values.tasks.map(item => item.name);
          const date_from = values.startDate
            ? values.startDate.format('YYYY/MM/DD')
            : null;
          const date_to = values.endDate
            ? values.endDate.format('YYYY/MM/DD')
            : null;
          const billable = get(values, 'billable.id')
            ? [values.billable.text]
            : null;
          const description = values.description;

          let postDataGroup = {
            users,
            clients,
            themes,
            initiatives,
            epics,
            stories,
            tasks,
            date_from,
            date_to,
            billable,
            description,
          };
          postDataGroup.group = group.value;

          const sort = `${sortedType}${sortedBy.value}`;
          const rs = await dispatch(doPostDataSummary(postDataGroup, sort));
          const groups = get(rs, 'data', []) || [];
          const selectedGroupIds = groups.map(item =>
            item ? item.group_id : null
          );

          if (selectedGroupIds.length > 0) {
            postDataGroup.subGroup = subgroup.value;
            postDataGroup.selectedGroupIds = selectedGroupIds;
            dispatch(doPostDataSummarySubGroup(postDataGroup, sort));
          }
        }
      };

      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    500,
    [values, group, subgroup, sortedBy, sortedType]
  );

  const summaryGroup = useSelector(({ timelogReport }) =>
    get(timelogReport, 'summaryGroup')
  );

  const summarySubGroup = useSelector(({ timelogReport }) =>
    get(timelogReport, 'summarySubGroup')
  );

  const groups = get(summaryGroup, 'data', []) || [];
  const subGroups = get(summarySubGroup, 'data', []) || [];

  const exportData = async () => {
    if (values) {
      const users = values.users.map(item => item.id);
      const clients = values.clients.map(item => item.id);
      const themes = values.themes.map(item => item.id);
      const initiatives = values.initiatives.map(item => item.id);
      const epics = values.epics.map(item => item.id);
      const stories = values.stories.map(item => item.id);
      const tasks = values.tasks.map(item => item.name);
      const date_from = values.startDate
        ? values.startDate.format('YYYY/MM/DD')
        : null;
      const date_to = values.endDate
        ? values.endDate.format('YYYY/MM/DD')
        : null;
      const billable = get(values, 'billable.id')
        ? [values.billable.text]
        : null;
      const description = values.description;

      let postDataGroup = {
        users,
        clients,
        themes,
        initiatives,
        epics,
        stories,
        tasks,
        date_from,
        date_to,
        billable,
        description,
      };
      postDataGroup.group = group.value;

      const sort = `${sortedType}${sortedBy.value}`;

      let selectedGroupIds = groupsOpen;

      if (selectedGroupIds.length > 0) {
        postDataGroup.subGroup = subgroup.value;
        postDataGroup.selectedGroupIds = selectedGroupIds;
      }

      postExportSummaryReport(postDataGroup, sort);
    }
  };

  return (
    <div className={classes.summary}>
      <Card>
        <CardBody className="pb-3">
          <CardTitle className="h3 mb-0 d-flex justify-content-between">
            Summary{' '}
            <Button
              leftIcon={<i className="fas fa-file-export"></i>}
              size="sm"
              color="primary"
              onClick={() => {
                analyticsSendEvent({
                  action: analyticsConstants.action.export_timelog_report,
                });
                exportData();
              }}
            >
              Export
            </Button>
          </CardTitle>
          <div className={classes.filter}>
            <button
              className={cs('btn btn-link', classes.collapseAll)}
              type="button"
              onClick={() => setOpenAll(!openAll)}
            >
              {openAll ? (
                <i className="fas fa-minus-circle"></i>
              ) : (
                <i className="fas fa-plus-circle"></i>
              )}
            </button>
            <FilterDropdown
              name="group"
              label="Group"
              value={group}
              onChange={data => {
                analyticsSendEvent({
                  action: analyticsConstants.action.group_timelog_report,
                  grouped_by:
                    get(data, 'value', '') === 'task'
                      ? 'assignment'
                      : get(data, 'value', ''),
                });
                setGroup(data);
              }}
            />
            <FilterDropdown
              name="subgroup"
              label="Subgroup"
              value={subgroup}
              onChange={data => {
                analyticsSendEvent({
                  action: analyticsConstants.action.subgroup_timelog_report,
                  grouped_by:
                    get(data, 'value', '') === 'task'
                      ? 'assignment'
                      : get(data, 'value', ''),
                });
                setSubGroup(data);
              }}
            />
            <SortedByDropdown
              sortedBy={sortedBy}
              sortedType={sortedType}
              onChange={(by, type) => {
                setSort(by);
                setSortType(type);
              }}
            />
          </div>
        </CardBody>
        <div className="react-bootstrap-table table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th width="200px">{group.text}</th>
                <th width="200px">Hours</th>
                <th width="200px">USD</th>
              </tr>
            </thead>
            <tbody>
              {groups.map(item => (
                <RowReport
                  setTab={setTab}
                  formik={formik}
                  openAll={openAll}
                  key={`group-${item.group_id}`}
                  data={item}
                  group={group}
                  subgroup={subgroup}
                  subData={subGroups.filter(
                    sub => sub.group_id === item.group_id
                  )}
                  groupsOpen={groupsOpen}
                  onChangeGroupsOpen={data => {
                    setGroupsOpen(data || []);
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default SummaryTab;
