import React, { useEffect } from 'react';
import Kanban from 'views/pages/Kanban/Kanban';
import { doGetDataPersonalKanban } from 'store/actions/kanbanActions';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { fetchDetailsByUserId } from 'store/actions/profile';
import Can from 'components/Can';
import { permissions } from 'helpers/permission';

const MyKanban = () => {
  const dispatch = useDispatch();
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));

  useEffect(() => {
    dispatch(fetchDetailsByUserId(userId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  const fetchKanbanData = async (period, keyword) => {
    await dispatch(doGetDataPersonalKanban(userId, period, keyword));
  };

  const data = useSelector(({ kanban }) =>
    get(kanban, 'getPersonalKanban.data', {})
  );

  return (
    <Can permissions={[permissions.ACCESS_PERSONAL_KANBAN]}>
      <Kanban
        title={'Personal Kanban'}
        fetchKanbanData={fetchKanbanData}
        data={data}
      />
    </Can>
  );
};

export default MyKanban;
