import Types from 'store/types/projectDashboard';

const initialState = {
  budgetSpent: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  timeLoggedThisWeek: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  lastWeekHours: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  storiesCompleted: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  timeLogGraph: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_PROJECT_SPENT_INPROGRESS:
      return {
        ...state,
        budgetSpent: {
          ...state.budgetSpent,
          isInProgress: true,
        },
      };
    case Types.FETCH_PROJECT_SPENT_SUCCESS:
      return {
        ...state,
        budgetSpent: {
          ...state.budgetSpent,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PROJECT_SPENT_FAILURE:
      return {
        ...state,
        budgetSpent: {
          ...state.budgetSpent,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_TIME_LOG_CURRENT_WEEK_INPROGRESS:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIME_LOG_CURRENT_WEEK_SUCCESS:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_TIME_LOG_CURRENT_WEEK_FAILURE:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_LAST_WEEK_HOURS_INPROGRESS:
      return {
        ...state,
        lastWeekHours: {
          ...state.lastWeekHours,
          isInProgress: true,
        },
      };
    case Types.FETCH_LAST_WEEK_HOURS_SUCCESS:
      return {
        ...state,
        lastWeekHours: {
          ...state.lastWeekHours,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_LAST_WEEK_HOURS_FAILURE:
      return {
        ...state,
        lastWeekHours: {
          ...state.lastWeekHours,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_COMPLETED_STORIES_INPROGRESS:
      return {
        ...state,
        storiesCompleted: {
          ...state.storiesCompleted,
          isInProgress: true,
        },
      };
    case Types.FETCH_COMPLETED_STORIES_SUCCESS:
      return {
        ...state,
        storiesCompleted: {
          ...state.storiesCompleted,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_COMPLETED_STORIES_FAILURE:
      return {
        ...state,
        storiesCompleted: {
          ...state.storiesCompleted,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_TIME_LOG_GRAPH_INPROGRESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIME_LOG_GRAPH_SUCCESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_TIME_LOG_GRAPH_FAILURE:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};
