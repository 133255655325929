import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import Input from 'components/FormFields/Input';
import numeral from 'numeral';
import Button from 'components/Button';

const MemberRateModal = ({ open, currentRate, updateRate, closeModal }) => {
  const [rate, setRate] = useState(currentRate);
  useEffect(() => {
    setRate(currentRate);
  }, [currentRate]);

  return (
    <Modal title="Update Rate" isOpen={open} toggle={closeModal}>
      <ModalBody>
        <Input
          min={0}
          alternative={false}
          inputId="member-rate"
          focused={document.activeElement.id === 'member-rate'}
          value={numeral(rate).format('0,0[.]00')}
          onChange={event => {
            const newRate = numeral(event.target.value);
            setRate(Math.abs(newRate.value()));
          }}
          label="Project Rate"
          leftIcon="$"
          rightIcon="per hour"
          placeholder="Rate"
        />
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button color="link" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              updateRate(rate);
            }}
          >
            Update
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

MemberRateModal.propTypes = {};

export default MemberRateModal;
