import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'components/Avatar';

const NameCell = ({ cell, row }) => {
  return (
    <div className="d-flex w-100 align-items-center">
      <Avatar url={row.avatar} />
      <div className="ml-3 d-flex flex-column  justify-content-center">
        <Link to={`/admin/users/${row.id}`}>{cell}</Link>
        <p className="mb-0 text-muted text-sm">{row.title}</p>
      </div>
    </div>
  );
};

export default NameCell;
