import React from 'react';

const ProjectRateCell = ({ cell }) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      ${cell}/hour
    </div>
  );
};

export default ProjectRateCell;
