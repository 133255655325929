export default {
  project_details: {
    label: 'Project Details',
    name: 'project_details',
    fields: [
      {
        label: 'Overview',
        name: 'description',
        objKey: 'fields',
      },
      {
        label: 'Owner',
        name: 'owner_id',
        objKey: 'fields',
      },
      {
        label: 'Status',
        name: 'status_id',
        objKey: 'fields',
      },
      {
        label: 'Client',
        name: 'client_id',
        objKey: 'fields',
      },
      {
        label: 'SOW',
        name: 'theme_id',
        objKey: 'fields',
      },
      {
        label: 'Project Color',
        name: 'color',
        objKey: 'fields',
      },
      {
        label: 'Budget',
        name: 'total_budget',
        objKey: 'fields',
      },
      {
        label: 'Budget Hours',
        name: 'budget_hours',
        objKey: 'fields',
      },
      {
        label: 'Budget Type',
        name: 'contract_type_id',
        objKey: 'fields',
      },
      {
        label: 'Billable Status',
        name: 'billable',
        objKey: 'fields',
      },
      {
        label: 'Start Date',
        name: 'date_start',
        objKey: 'fields',
      },
      {
        label: 'End Date',
        name: 'date_end',
        objKey: 'fields',
      },
      {
        label: 'Team Members',
        name: 'team',
        objKey: 'relationships',
      },
    ],
  },
  backlog: {
    label: 'Backlog',
    name: 'backlog',
    fields: [
      {
        label: 'Epics',
        name: 'epics',
        objKey: 'relationships',
      },
      {
        name: 'backlogSections',
        label: 'Section Titles',
        objKey: 'relationships',
      },
      {
        name: 'stories',
        label: 'Stories',
        objKey: 'relationships',
      },
    ],
  },
  kanban: {
    label: 'Kanban',
    name: 'kanban',
    fields: [
      {
        name: 'boardColumns',
        label: 'Column Names',
        objKey: 'relationships',
      },
      {
        name: 'boardColumnStatuses',
        label: 'Column Statuses',
        objKey: 'relationships',
      },
    ],
  },
};
