import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doResetPassword } from 'store/actions/authActions';
// nodejs library that concatenates classes
import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
// reactstrap components
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';

// core components
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { validatePassword } from 'helpers/constants';
// import history from 'helpers/history';

const ResetPassword = props => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [focus, setFocus] = useState({});
  const [loading, setLoading] = useState(false);

  const registerSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Your password should contain at least 8 characters.')
      .test(
        'validate-password',
        'Your password should contain a combination of numbers, upper and lower case letters, and special characters.',
        validatePassword
      )
      .required('Your password should contain at least 8 characters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'The passwords you entered don’t match. ')
      .required('The passwords you entered don’t match. '),
  });

  const renderForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup
          className={classnames(
            {
              focused: focus.password,
            },
            'mb-3'
          )}
        >
          <Input
            name="password"
            placeholder="Password"
            type="password"
            error={errors.password}
            value={values.password}
            onChange={handleChange}
            touched={touched.password}
            onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
            leftIcon={<i className="ni ni-lock-circle-open" />}
          />
        </FormGroup>
        <FormGroup
          className={classnames(
            {
              focused: focus.password_confirmation,
            },
            'mb-3'
          )}
        >
          <Input
            name="password_confirmation"
            placeholder="Confirm Password"
            type="password"
            error={errors.password_confirmation}
            value={values.password_confirmation}
            onChange={handleChange}
            touched={touched.password_confirmation}
            onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
            leftIcon={<i className="ni ni-lock-circle-open" />}
          />
        </FormGroup>
        <div className="text-center">
          <Button className="mt-4" color="info" type="submit" loading={loading}>
            Set Password
          </Button>
        </div>
      </form>
    );
  };

  const params = queryString.parse(props.location.search);

  return (
    <>
      <AuthHeader
        title="Reset Password"
        lead="Use these awesome forms to login or create new account in your project for free."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                {auth.emailVerify.isError === true && (
                  <Alert
                    text={auth.emailVerify.message}
                    dismiss={true}
                    fade={true}
                    color="danger"
                  />
                )}
                <div className="text-center text-muted mb-4">
                  Set Your Password
                </div>
                <Formik
                  initialValues={{
                    email: params.email,
                    token: params.token,
                    password: '',
                    password_confirmation: '',
                  }}
                  validationSchema={registerSchema}
                  enableReinitialize
                  onSubmit={async values => {
                    const data = {
                      email: values.email,
                      token: values.token,
                      password: values.password,
                      password_confirmation: values.password_confirmation,
                    };

                    setLoading(true);
                    await dispatch(doResetPassword(data));
                    setLoading(false);
                  }}
                >
                  {renderForm}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
