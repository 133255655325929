import Request from './request';

export const getDataKanban = async (projectId, q) => {
  return Request.call({
    url: `/initiatives/${projectId}/board?q=${q}`,
    method: 'GET',
  });
};

export const addColumn = async (projectId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/add-column`,
    method: 'POST',
    data,
  });
};

export const editColumn = async (projectId, sectionId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/update-column/${sectionId}`,
    method: 'PUT',
    data,
  });
};

export const removeColumn = async (projectId, sectionId) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/delete-column/${sectionId}`,
    method: 'DELETE',
  });
};

export const postReOrderStories = async (projectId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/prioritize-stories`,
    method: 'POST',
    data,
  });
};

export const postReOrderColumns = async (projectId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/re-order-columns`,
    method: 'POST',
    data,
  });
};

export const getDataPersonalKanban = async (userId, period, q) => {
  return Request.call({
    url: `/users/${userId}/board?period=${period}&q=${q}`,
    method: 'GET',
  });
};

export const postReOrderAssignment = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}/board/prioritize-tasks`,
    method: 'POST',
    data,
  });
};

export const getGlobalKanban = async (companyId, period, q) => {
  return Request.call({
    url: `/companies/${companyId}/kanban?period=${period}&q=${q}`,
    method: 'GET',
  });
};

export const patchUpdateStatusAssignments = async (userId, taskId, data) => {
  return Request.call({
    url: `/users/${userId}/board/${taskId}/update-status`,
    method: 'PATCH',
    data,
  });
};
