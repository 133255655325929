import React, { useEffect, useState, useRef } from 'react';
import { get, isEqual } from 'lodash';
import orderBy from 'lodash/orderBy';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardBody,
  Row,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  fetchDetailsByUserId,
  updateAvatarByUserId,
} from 'store/actions/profile';
import { showProfileUser } from 'store/actions/authActions';
import history from 'helpers/history';
import classes from './profile.module.scss';
import cs from 'classnames';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import Loading from 'components/Loading';
import path from 'path';
import RoleSelector from 'components/RoleSelector';
import { updateUserRoles, fetchRoles } from 'store/actions/users';
import Types from 'store/types/profile';
import { useAccess, permissions } from 'helpers/permission';
import { analyticsConstants } from 'helpers/analytics';

const ProfileDetails = props => {
  const { mode, values, userId, analyticsSendEvent } = props;
  const uploadFile = useRef(null);
  const dispatch = useDispatch();
  const details = useSelector(({ profile }) =>
    get(profile, 'details.data', {})
  );
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [isProfileLoading, setProfileLoading] = useState(false);

  const roleOptions = useSelector(({ user }) => user.roleOptions);
  const roles = get(roleOptions, 'data.data', []);
  useEffect(() => {
    if (!roles.length) {
      dispatch(fetchRoles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [profileOpenDropdown, setProfileOpenDropdown] = useState(false);
  const toggle = () => setProfileOpenDropdown(!profileOpenDropdown);

  const authUserId = useSelector(({ auth }) => get(auth, 'user.id'));

  const isUserAllowedEdit = useAccess([
    permissions.EDIT_ALL_USER_PROFILES,
    {
      permission: permissions.EDIT_MY_PROFILE,
      value: isEqual(parseInt(authUserId), parseInt(userId)),
    },
  ]);

  if (mode === 'edit') {
    details.name = values.name;
    details.first_name = values.first_name;
    details.last_name = values.last_name;
    details.title = values.title;
    details.weekly_availability = values.weekly_availability;
    details.bio = values.bio;
    details.timezone = values.timezone;
    details.timezone_offset = values.timezone_offset || details.timezone_offset;
    details.skills = values.skills;
    details.city = values.city;
    details.country = values.country;
    details.email = values.email;
    details.phone = values.phone;
    details.skype_id = values.skype_id;
    details.github_id = values.github_id;
  }

  useEffect(() => {
    if (mode === 'view') {
      dispatch(fetchDetailsByUserId(userId));
    }
  }, [userId, isUserAllowedEdit, mode, dispatch]);

  const getBio = bio => {
    if (bio) {
      return (
        <Row>
          <div className="col">
            <h5 className="h3">Bio</h5>
            <p className={cs('h4', 'font-weight-normal', classes.bio)}>{bio}</p>
          </div>
        </Row>
      );
    }
    return null;
  };

  const getSkills = (skills = []) => {
    if (skills.length > 0) {
      return (
        <Row className={classes.section}>
          <div className="col">
            <h5 className="h3">Skills</h5>
            <p className={`h4 ${classes.skills}`}>
              {skills.map((skill, index) => (
                <span key={index}>{skill}</span>
              ))}
            </p>
          </div>
        </Row>
      );
    }
    return null;
  };

  const updateAvatar = async target => {
    setProfileLoading(true);
    let file = target.files[0];
    if (file) {
      if (file.size > 10485760) {
        NotificationHandler.open({
          title: 'File too big!',
          message: 'Maximum allowed file size is 10 MB',
          icon: 'fas fa-bell',
          operation: 'failure',
        });
      } else if (
        !['.png', '.jpg', '.jpeg'].includes(
          path.extname(file.name).toLowerCase()
        )
      ) {
        NotificationHandler.open({
          title: 'Invalid file format',
          message: 'Please use a png, jpeg, or jpg',
          icon: 'fas fa-bell',
          operation: 'failure',
        });
      } else {
        const formData = new FormData();
        formData.append('avatar', file);
        analyticsSendEvent({
          action: analyticsConstants.action.edit_user_avatar,
        });
        await dispatch(updateAvatarByUserId(userId, formData));
        setProfileLoading(false);
        await dispatch(fetchDetailsByUserId(userId));
        await dispatch(showProfileUser());
      }
      target.value = null;
    }
  };

  const handleRoleChange = async (id, values) => {
    const { status, record } = await dispatch(
      updateUserRoles(id, [values], false)
    );
    if (status) {
      dispatch({
        type: Types.UPDATE_DETAILS_SUCCESS,
        data: record,
      });
    }
  };

  return (
    <>
      <Card className="card-profile">
        {mode === 'view' && isUserAllowedEdit ? (
          <div>
            <ButtonDropdown
              isOpen={profileOpenDropdown}
              toggle={toggle}
              className="float-right"
            >
              <DropdownToggle className={`${classes.dropdown}`}>
                <i className="fas fa-ellipsis-v"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={e => {
                    e.preventDefault();
                    history.push(`/admin/users/${userId}/edit`);
                  }}
                >
                  Edit Profile
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        ) : null}
        <CardBody className={cs('pt-0', 'pb-md-4')}>
          <div
            className={cs(
              'd-flex justify-content-center',
              {
                'pt-5': mode === 'edit' || !isUserAllowedEdit,
              },
              mode === 'edit' || !isUserAllowedEdit
                ? classes.editImgContainer
                : classes.imgContainer
            )}
          >
            <Loading
              wrapperClass={cs(
                'rounded-circle position-absolute',
                classes.avatar,
                {
                  'd-none': isImgLoaded,
                }
              )}
            />
            <img
              className={cs('rounded-circle', classes.avatar)}
              src={details.avatar}
              alt="User Avatar"
              onLoad={() => {
                setIsImgLoaded(true);
              }}
            />
            {!isProfileLoading && isUserAllowedEdit && (
              <div
                className={classes.overlay}
                onClick={() => {
                  if (mode === 'view') {
                    uploadFile.current.click();
                  }
                }}
              >
                <div className={classes.text}>
                  <i className="fas fa-camera"></i>
                  <p>Edit Picture</p>
                </div>
              </div>
            )}
            {isProfileLoading && (
              <div className={classes.loadingOverlay}>
                <Loading size={80} />
              </div>
            )}
          </div>

          {mode === 'edit' ? (
            <Row
              className={cs(
                'justify-content-center',
                'pt-5',
                classes.uploadBtnRow
              )}
            >
              <label className="btn btn-primary">
                <input
                  type="file"
                  name="upload-file"
                  accept=".png, .jpg, .jpeg"
                  onChange={e => {
                    e.preventDefault();
                    updateAvatar(e.target);
                  }}
                />
                <i className="fas fa-camera"></i> Edit Picture
              </label>
            </Row>
          ) : (
            <input
              type="file"
              name="upload-file"
              accept=".png, .jpg, .jpeg"
              className={classes.viewImageUpload}
              ref={uploadFile}
              onChange={e => {
                e.preventDefault();
                updateAvatar(e.target);
              }}
            />
          )}
          <Row>
            <div className="col pt-md-4 pt-6">
              <div className="text-center">
                <h3>
                  {mode === 'view' ? details.name : values.name}
                  {details.title ? (
                    <span className="font-weight-light">, {details.title}</span>
                  ) : (
                    ''
                  )}
                </h3>
                <div
                  className={cs(
                    'h5',
                    'font-weight-300',
                    classes.locationDetails
                  )}
                >
                  {details.city}
                  {details.city && details.country ? ',' : null}{' '}
                  {details.country}{' '}
                  {(details.city || details.country) && details.timezone ? (
                    <i className="fas fa-circle"></i>
                  ) : null}{' '}
                  {details.timezone_offset}
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col">
              <div
                className={`card-profile-stats d-flex justify-content-center ${classes.cardStats}`}
              >
                <div>
                  <span className={classes.userStats}>{details.projects}</span>
                  <span className="description">Projects</span>
                </div>
                <div>
                  <span className={classes.userStats}>
                    {details.weekly_availability > 0
                      ? details.weekly_availability
                      : '--'}{' '}
                    hrs
                  </span>
                  <span className="description">Weekly</span>
                </div>
                <div>
                  <span className={classes.userStats}>{details.tasks}</span>
                  <span className="description">Tasks</span>
                </div>
              </div>
            </div>
          </Row>

          {getBio(details.bio)}
          {getSkills(details.skills)}

          <Row className={classes.section}>
            <div className="col">
              <h5 className="h3">Contact</h5>
              <p
                className={cs(
                  'h4',
                  'font-weight-normal',
                  classes.contactDetails
                )}
              >
                <span>
                  <i className="fas fa-envelope"></i>
                  {details.email}
                </span>
                {details.phone ? (
                  <span>
                    <i className="fas fa-phone"></i>
                    {details.phone}
                  </span>
                ) : (
                  ''
                )}
                {details.skype_id ? (
                  <span>
                    <i className="fab fa-skype"></i>
                    {details.skype_id}
                  </span>
                ) : (
                  ''
                )}
                {details.github_id ? (
                  <span>
                    <i className="fab fa-github"></i>
                    {details.github_id}
                  </span>
                ) : (
                  ''
                )}
              </p>
              {useAccess([permissions.EDIT_USER_ROLES]) && mode === 'edit' ? (
                <>
                  <h4>Role</h4>
                  <RoleSelector
                    id={userId}
                    showDetails
                    selectedValue={
                      details.roles && details.roles.length
                        ? details.roles[0]
                        : null
                    }
                    handleChange={handleRoleChange}
                  />
                </>
              ) : null}
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ProfileDetails;
