export const openFullscreen = elem => {
  if (typeof elem.requestFullscreen === 'function') {
    elem.requestFullscreen();
  } else if (typeof elem.mozRequestFullScreen === 'function') {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (typeof elem.webkitRequestFullscreen === 'function') {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (typeof elem.msRequestFullscreen === 'function') {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.fullscreenElement === null) return true;

  if (typeof document.exitFullscreen === 'function') {
    document.exitFullscreen();
  } else if (typeof document.mozCancelFullScreen === 'function') {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (typeof document.webkitExitFullscreen === 'function') {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (typeof document.msExitFullscreen === 'function') {
    /* IE/Edge */
    document.msExitFullscreen();
  }
};
