import React, { useEffect, useState, useRef } from 'react';
import classes from './StoryPriority.module.scss';
import {
  Badge,
  Button,
  ButtonGroup,
  DropdownMenu,
  DropdownToggle,
  PopoverBody,
  UncontrolledPopover,
  Dropdown,
} from 'reactstrap';
import classNames from 'classnames';
import map from 'lodash/map';
import toString from 'lodash/toString';
import get from 'lodash/get';
import find from 'lodash/find';
import PropTypes from 'prop-types';

const StoryPriority = ({
  size,
  priority: selectedPriority,
  complexity: selectedComplexity,
  score: selectedScore,
  onChange,
  priorities,
  complexities,
  isEditAllowed,
  scoreMatrix,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [priority, setPriority] = useState(get(selectedPriority, 'id', null));
  const [complexity, setComplexity] = useState(
    get(selectedComplexity, 'id', null)
  );
  const [score, setScore] = useState(selectedScore || null);
  const badgeRef = useRef(null);

  useEffect(() => setScore(selectedScore), [selectedScore]);
  useEffect(() => {
    if (priority && complexity) {
      setScore(
        get(
          find(
            scoreMatrix,
            ({ priority_id, complexity_id }) =>
              toString(priority_id) === toString(priority) &&
              toString(complexity_id) === toString(complexity)
          ),
          'score',
          null
        )
      );
    } else setScore(null);
  }, [priority, complexity, scoreMatrix]);

  useEffect(() => {
    setPriority(get(selectedPriority, 'id', null));
  }, [selectedPriority]);

  useEffect(() => {
    setComplexity(get(selectedComplexity, 'id', null));
  }, [selectedComplexity]);

  useEffect(() => {
    setScore(selectedScore);
  }, [selectedScore]);

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      className={classNames(classes.dropdown)}
      toggle={() => {
        if (isDropdownOpen)
          onChange({
            priority_id: priority,
            complexity_id: complexity,
          });
        setDropdownOpen(currentState => !currentState);
      }}
      disabled={!isEditAllowed}
    >
      <DropdownToggle
        className={classNames(
          classes['toggle'],
          !isEditAllowed && classes['default-cursor']
        )}
      >
        {score ? (
          <>
            <Badge
              className={classNames(
                classes['badge'],
                classes['score-badge'],
                `icon-${size}`
              )}
              innerRef={badgeRef}
            >
              {score}
            </Badge>
            <UncontrolledPopover
              trigger="hover"
              placement="right"
              hidden={isDropdownOpen}
              target={badgeRef}
            >
              <PopoverBody className="d-flex">
                <div className="d-flex flex-column">
                  <h5
                    className={classNames(
                      'text-uppercase text-center mb-0 d-flex justify-content-end mr-3',
                      classes['text']
                    )}
                  >
                    Difficulty
                  </h5>
                  <div className="d-flex">
                    <div
                      className={classNames(
                        'd-flex flex-column position-relative mb-0',
                        classes['importance-text-wrapper']
                      )}
                    >
                      <h5
                        className={classNames(
                          'text-uppercase',
                          classes['importance-text'],
                          classes['text']
                        )}
                      >
                        Importance
                      </h5>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="p-2 text-center">&nbsp;</div>
                      <h5 className="p-2 m-0 font-weight-bold text-uppercase text-dark text-center">
                        High
                      </h5>
                      <h5 className="p-2 m-0 font-weight-bold text-uppercase text-dark text-center">
                        Low
                      </h5>
                    </div>
                    <div className="d-flex flex-column">
                      <h5 className="p-2 m-0 font-weight-bold text-uppercase text-dark text-center">
                        Low
                      </h5>
                      <div className="p-2 text-dark text-center border-right border-bottom">
                        <span
                          className={classNames({
                            [classes['active-badge']]: score === 1,
                          })}
                        >
                          1
                        </span>
                      </div>
                      <div className="p-2 text-dark text-center border-right">
                        <span
                          className={classNames({
                            [classes['active-badge']]: score === 3,
                          })}
                        >
                          3
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <h5 className="p-2 m-0 font-weight-bold text-uppercase text-dark text-center">
                        High
                      </h5>
                      <div className="p-2 text-dark text-center border-bottom">
                        <span
                          className={classNames({
                            [classes['active-badge']]: score === 2,
                          })}
                        >
                          2
                        </span>
                      </div>
                      <div className="p-2 text-dark text-center">
                        <span
                          className={classNames({
                            [classes['active-badge']]: score === 4,
                          })}
                        >
                          4
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        ) : (
          <Badge
            className={classNames(
              classes['plus-icon'],
              classes['badge'],
              { [classes['default-cursor']]: !isEditAllowed },
              `icon-${size}`
            )}
          >
            +
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu className="p-3">
        <div className="d-flex flex-column">
          <div className={classNames('text-muted', classes['dropdown-title'])}>
            Importance
          </div>
          <ButtonGroup className="mt-2">
            {map(priorities, (value, id) => (
              <Button
                key={id}
                size="sm"
                className={classNames('shadow-none', {
                  [classes['active-button']]:
                    toString(priority) === toString(id),
                })}
                onClick={() =>
                  toString(priority) !== toString(id)
                    ? setPriority(id)
                    : setPriority(null)
                }
              >
                {value}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        <div className="d-flex flex-column mt-3">
          <div className={classNames('text-muted', classes['dropdown-title'])}>
            Difficulty
          </div>
          <ButtonGroup className="mt-2">
            {map(complexities, (text, id) => (
              <Button
                key={id}
                size="sm"
                className={classNames('shadow-none', {
                  [classes['active-button']]:
                    toString(complexity) === toString(id),
                })}
                onClick={() =>
                  toString(complexity) === toString(id)
                    ? setComplexity(null)
                    : setComplexity(id)
                }
              >
                {text}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

StoryPriority.propTypes = {
  priority: PropTypes.object,
  complexity: PropTypes.object,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priorities: PropTypes.object.isRequired,
  complexities: PropTypes.object.isRequired,
  scoreMatrix: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditAllowed: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'lg', 'xl']),
};
StoryPriority.defaultProps = {
  priorities: {},
  complexities: {},
  scoreMatrix: [],
  onChange: () => {},
  isEditAllowed: true,
  size: 'sm',
};

export default StoryPriority;
