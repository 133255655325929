import React from 'react';
import UserInfo from 'components/UserInfo';
import PeopleSelector from 'components/PeopleSelector';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import isEmpty from 'lodash/isEmpty';

const Owner = ({
  getFieldValue,
  isEditAllowed,
  defaultValue,
  onChange,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const owner = getFieldValue('owner', {});
  const getDisplayValue = () => {
    if (isEmpty(owner) && !isEditAllowed) {
      return defaultValue;
    }
    return <UserInfo info={owner} altText="+ Add Owner" />;
  };
  return (
    <>
      <h6 className="text-uppercase text-muted">Owner</h6>
      <PeopleSelector
        id="project-owner-target"
        disabled={!isEditAllowed}
        onChange={selectedOwner => {
          analyticsSendEvent({
            action: analyticsConstants.action.edit_project_owner,
          });
          onChange('owner', selectedOwner);
        }}
        url="/users/list/dropdown?status=Active&role=manager"
        placeholder="Assign a Project Owner"
      >
        <div className={cx(isEditAllowed && classes.hoverHand)}>
          {getDisplayValue()}
        </div>
      </PeopleSelector>
    </>
  );
};

export default Owner;
