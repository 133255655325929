import React, { useState } from 'react';
import cs from 'classnames';
import classes from './roleSelector.module.scss';
import RSelect from '../FormFields/RSelect';
import { components } from 'react-select';
import Radio from '../FormFields/Radio';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isObject from 'lodash/isObject';
import { roles } from 'helpers/permission';
import { useSelector } from 'react-redux';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-sort" />
    </components.DropdownIndicator>
  );
};

const CustomOption = ({ innerProps, selectProps, data, ...rest }) => {
  const { value } = selectProps.extraProps;
  return (
    <div
      className={cs('d-flex flex-column p-2', {
        [classes.disabled]: rest.isDisabled,
      })}
      {...innerProps}
    >
      <div className={classes.optionWrapper}>
        <Radio
          id={`role-${data.id}`}
          checked={value && value.id === data.id}
          label={
            <div className={cs('d-flex', classes.label)}>
              <i className={`mr-2 fas ${data.icon}`} />
              <h4 className={cs('m-0', classes.title)}>{data.title}</h4>
            </div>
          }
        />
      </div>
      <div className={classes.description} onClick={innerProps.onClick}>
        {data.description}
      </div>
    </div>
  );
};

const ControlComponent = controlProps => {
  const {
    id,
    isOpen,
    onMenuClose,
    onMenuOpen,
  } = controlProps.selectProps.extraProps;
  return (
    <div id={`role-${id}`} onClick={isOpen ? onMenuClose : onMenuOpen}>
      <components.Control {...controlProps} />
    </div>
  );
};

const SingleValue = ({ children, data, selectProps, ...rest }) => {
  if (isObject(data)) {
    return `${data.title}`;
  } else {
    const selectedOption = selectProps.options.find(o => o.id === data);
    return `${selectedOption.title}`;
  }
};

const ValueContainer = ({ children, data, selectProps, ...rest }) => {
  const { value, showDetails } = selectProps.extraProps;
  if (showDetails) {
    return (
      <components.ValueContainer {...rest}>
        <div className="d-flex flex-column">
          <div className={cs('d-flex', classes.label)}>
            <i className={`mr-2 fas ${value ? value.icon : ''}`} />
            <h4 className={cs('m-0', classes.title)}>
              {value ? value.title : ''}
            </h4>
          </div>
          <div className="mt-1">{value ? value.description : ''}</div>
        </div>
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...rest}>
      {value.title}
    </components.ValueContainer>
  );
};

const RoleSelector = props => {
  const {
    id,
    selectedValue,
    handleChange,
    isDisabled,
    isOptionDisabled,
    showDetails,
    tooltipPlacement,
    ...rest
  } = props;
  const roleOptions = useSelector(({ user }) => user.roleOptions);
  const options = get(roleOptions, 'data.data', []);
  const [value, setValue] = useState(selectedValue);
  const [isOpen, setIsOpen] = useState(false);

  const onMenuOpen = () => {
    setIsOpen(true);
  };
  const handleRoleChange = values => {
    if (!values) {
      const val = options.find(o => o.name === roles.BASIC_TEAM_MEMBER);
      setValue(val);
      handleChange(id, val);
    } else {
      setValue(values);
      handleChange(id, values);
    }
    onMenuClose();
  };

  const onMenuClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="position-relative w-100">
      <RSelect
        name="roles"
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled}
        isClearable={false}
        value={orderBy(value || [], ['level', 'level_order'], ['desc', 'asc'])}
        onChange={handleRoleChange}
        hideSelectedOptions={false}
        closeMenuOnSelect
        isSearchable={false}
        options={options}
        components={{
          SingleValue: SingleValue,
          ValueContainer: ValueContainer,
          Option: CustomOption,
          Control: ControlComponent,
          DropdownIndicator,
        }}
        onMenuOpen={onMenuOpen}
        menuIsOpen={isOpen}
        onMenuClose={onMenuClose}
        getOptionValue={option => option.id}
        styles={{
          multiValue: base => ({
            ...base,
            backgroundColor: '#fff',
            minWidth: 'unset',
          }),
          valueContainer: base => ({
            ...base,
            ...(value && value.length > 1
              ? {
                  textOverflow: 'ellipsis',
                  maxWidth: '95%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'initial',
                }
              : { flexWrap: 'unset' }),
          }),
        }}
        extraProps={{ ...props, value, onMenuOpen, onMenuClose, isOpen }}
        {...rest}
      />
      {isOpen ? (
        <div
          className="position-fixed top-0 left-0 w-100 h-100"
          onClick={onMenuClose}
        />
      ) : null}
    </div>
  );
};

export default RoleSelector;
