import React, { useState, useEffect } from 'react';
import classes from './Kanban.module.scss';
import fullScreenIcon from 'assets/img/icons/full-screen.svg';
import fullScreenCloseIcon from 'assets/img/icons/full-screen-close.svg';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Container,
  CardHeader,
} from 'reactstrap';
import cs from 'classnames';
import Input from 'components/FormFields/Input';

import { doGetDataKanban } from 'store/actions/kanbanActions';
import { doGetDataSprintKanban } from 'store/actions/sprint';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import { useDebounce, useEffectOnce } from 'react-use';
import useBreakpoint from 'helpers/useBreakPoint';
import DragContainer from './DragContainer';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Button from 'components/Button';
import StoryModal from 'views/pages/Backlogs/Stories/StoryModal';
import toNumber from 'lodash/toNumber';
import { openFullscreen, closeFullscreen } from 'helpers/fullscreen';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import analytics, { analyticsConstants } from 'helpers/analytics';
import isEmpty from 'lodash/isEmpty';
import Loading from 'components/Loading';
import forEach from 'lodash/forEach';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { useAccess, permissions } from 'helpers/permission';
import Dropdowns from 'components/Dropdowns';
import DuplicateStoryForm from 'views/pages/Backlogs/Stories/DuplicateStory.Form';
import SelectAsync from 'components/FormFields/SelectAsync';

const Kanban = ({
  projectId,
  isAllowedEdit,
  isMockUp,
  childMockUpData: mockUpData,
  isSprintKanban,
  openEditModal,
  deleteSprintAction,
  id,
  sectionID,
  isSprintEditAllowed = false,
  isSprintViewAllowed = false,
  isSprintDeleteAllowed = false,
  isSprintAddAllowed = false,
  isAddStoryToSprintAllowed = false,
}) => {
  const analyticsSendEvent = ({ ...rest }) => {
    if (!isMockUp) {
      analytics.sendEvent({
        category: analyticsConstants.category.project_boards,
        ...rest,
      });
    }
  };
  const isAllowedKanbanEdit = useAccess([
    permissions.CUSTOMIZE_ALL_PROJECTS_KANBAN,
    permissions.CUSTOMIZE_MY_PROJECTS_KANBAN,
  ]);
  const dispatch = useDispatch();
  const isMobile = useBreakpoint('xs', 'down');
  const isSmallerThanTablet = useBreakpoint('sm', 'down');
  const [keyword, setKeyword] = useState('');
  const [count, setCount] = useState(0);
  const [expanded, setExpanded] = useState([]);
  const [isFullScreen, setFullScreen] = useState(false);
  const [totalStoriesCanBeExpanded, setTotalStoriesCanBeExpanded] = useState(0);
  const [dataRetrievalCount, setDataRetrievalCount] = useState(0);
  const [columnModification, setColumnModification] = useState(false);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);

  const loggedInUserID = useSelector(({ auth }) => get(auth, 'user.id', null));

  const projectMembers = useSelector(({ project }) =>
    get(project, `getProject.data.data.team_members`, [])
  );
  const isMemberOfProject =
    projectMembers.findIndex(m => m.id === loggedInUserID) > -1;

  const isSectionEditAllowed = useAccess([
    permissions.EDIT_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.EDIT_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintUpdateAllowed = useAccess([
    permissions.EDIT_ALL_SPRINTS,
    {
      permission: permissions.EDIT_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  const isSectionDeleteAllowed = useAccess([
    permissions.DELETE_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.DELETE_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const dropdownColumns = isSprintKanban
    ? isSectionEditAllowed || isSectionDeleteAllowed
      ? [
          ...(isSectionEditAllowed
            ? [
                {
                  text: 'Edit Sprint',
                  onClick: event => {
                    event.stopPropagation();
                    openEditModal();
                  },
                },
              ]
            : []),
          ...(isSectionDeleteAllowed
            ? [
                {
                  text: 'Delete Sprint',
                  onClick: event => {
                    event.stopPropagation();
                    deleteSprintAction(sectionID);
                  },
                },
              ]
            : []),
        ]
      : []
    : [
        {
          text: 'Add Column',
          onClick: event => {
            event.stopPropagation();
            setColumnModification('new');
          },
        },
      ];

  useEffectOnce(() => {
    const footerElement = document.getElementById('admin-footer');
    dispatch(fetchStoryStatuses());
    const parent = get(
      document.getElementById('admin-footer'),
      'parentElement'
    );
    if (parent) parent.removeChild(footerElement);
    if (!isMockUp)
      analyticsSendEvent({
        action: analyticsConstants.action.view_project_board,
      });
    return () => {
      if (parent) parent.appendChild(footerElement);
    };
  });
  // show modal story detail

  const history = useHistory();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const queryStory = get(queryProps, 'story', '');
  const storyId = useGetFieldFromObject('story', 'details.data.id', '');
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);

  const sprintKanban = useSelector(({ sprint }) =>
    get(sprint, 'getSprintKanban', {})
  );
  const kanban = useSelector(({ kanban }) => get(kanban, 'getKanban', {}));

  const useGetSelectedKanbanProperty = (property, defaultValue) => {
    let returnValue = null;
    if (isSprintKanban) {
      returnValue = get(sprintKanban, `data.${property}`, defaultValue);
    } else {
      returnValue = get(kanban, `data.${property}`, defaultValue);
    }
    return !isMockUp
      ? returnValue
      : get(mockUpData, property, defaultValue) || defaultValue;
  };

  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);
  const storyName = useGetFieldValue('name');

  const columns = useGetSelectedKanbanProperty('columns', []);
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  useEffect(() => {
    let count = 0;
    forEach(columns, col => {
      const stories = get(col, 'stories', []);
      if (stories && stories.length) {
        forEach(stories, s => {
          if (s.tasks && s.tasks.length) {
            count++;
          }
        });
      }
    });
    setTotalStoriesCanBeExpanded(count);
  }, [columns]);

  useEffect(() => {
    if (toNumber(queryStory) === storyId) {
      analyticsSendEventStory({
        action: analyticsConstants.action.open_story,
        opened_from: 'Project Kanban',
      });
      setIsStoryModalOpen(true);
    }
  }, [queryStory, storyId]);

  useDebounce(
    () => {
      const getData = async (projectId, keyword = '') => {
        if (isSprintKanban) {
          await dispatch(doGetDataSprintKanban(id, keyword));
        } else {
          await dispatch(doGetDataKanban(projectId, keyword));
        }
        setDataRetrievalCount(currentCount => currentCount + 1);
      };
      if (projectId && !isMockUp) getData(projectId, keyword);
    },
    500,
    [projectId, keyword, dispatch, count, id]
  );

  const onColumnModification = () => {
    if (isSprintKanban) {
      dispatch(doGetDataSprintKanban(id, keyword));
    } else {
      dispatch(doGetDataKanban(projectId, keyword));
    }
  };

  useDebounce(
    () => {
      if (!isEmpty(keyword))
        analyticsSendEvent({
          action: analyticsConstants.action.search_project_board,
        });
    },
    1000,
    [keyword]
  );

  const reload = () => setCount(count + 1);

  const storiesSummary = useGetSelectedKanbanProperty('stories_summary');
  const hoursSummary = useGetSelectedKanbanProperty('hours_summary');
  const dateStart = useGetSelectedKanbanProperty('date_start');
  const dateEnd = useGetSelectedKanbanProperty('date_end');
  const sprintName = useGetSelectedKanbanProperty('name');

  const closeDuplicateModal = () => setDuplicateModal(false);

  const openDuplicateModal = () => setDuplicateModal(true);

  const toggleAllStories = e => {
    e.stopPropagation();
    let updatedExpanded = [];
    if (expanded.length < totalStoriesCanBeExpanded) {
      columns.forEach(col => {
        if (col.stories && col.stories.length) {
          col.stories.forEach(s => {
            if (s.tasks && s.tasks.length) {
              updatedExpanded.push(s.id);
            }
          });
        }
      });
    }
    if (!isEmpty(updatedExpanded))
      analyticsSendEvent({
        action: analyticsConstants.action.expand_all_stories_on_board,
      });
    setExpanded([...updatedExpanded]);
  };

  const handleSearchSprint = value => {
    history.push(
      `/admin/projects/${projectId}/backlog/sprint/${value.id}/board`
    );
  };

  const toggleExpanded = id => e => {
    e.stopPropagation();
    if (expanded.includes(id)) {
      setExpanded([...expanded.filter(c => c !== id)]);
    } else {
      analyticsSendEvent({
        action: analyticsConstants.action.expand_single_story_on_board,
      });
      setExpanded([...expanded, id]);
    }
  };

  const renderProjectTime = () => {
    if (!dateStart || !dateEnd) return '';

    return (
      (isMobile ? '' : ' - ') +
      `${moment(dateStart, 'YYYY-MM-DD').format('MMM DD, YYYY')} - ${moment(
        dateEnd,
        'YYYY-MM-DD'
      ).format('MMM DD, YYYY')}`
    );
  };

  return (
    <div
      id="kanban-page"
      className={cs(classes.root, {
        [classes.fullscreen]: isFullScreen,
      })}
    >
      {dataRetrievalCount === 0 ? (
        <Loading wrapperClass={classes.loading} />
      ) : (
        <Container fluid>
          {isSprintKanban && (
            <CardHeader>
              <div className={classes.sprintKanbanHeader}>
                {sprintKanban ? (
                  <>
                    <div>{sprintName}</div>
                    <div>
                      {moment(dateStart).format('MMM D')} -{' '}
                      {moment(dateEnd).format('MMM D, YYYY')}
                    </div>
                  </>
                ) : null}
              </div>
            </CardHeader>
          )}
          <div className={classes.header}>
            <Card>
              <CardBody>
                <CardTitle className="h3 d-flex justify-content-between mb-3 align-items-center">
                  <div>
                    {isSprintKanban ? (
                      <SelectAsync
                        id="select-sprint"
                        onChange={handleSearchSprint}
                        url={`/initiatives/${projectId}/sprints/list/dropdown`}
                        getOptionLabel={option => option.name}
                        placeholder="Search"
                      >
                        <div className="d-flex">
                          <div className="mr-5">{sprintName}</div>
                          <i className="ml-5 fas fa-sort-down" />
                        </div>
                      </SelectAsync>
                    ) : (
                      'Project Kanban'
                    )}
                    {!isSprintKanban && (
                      <span className={classes.subText}>
                        {renderProjectTime()}
                      </span>
                    )}
                  </div>
                  <div className="d-flex">
                    <Button
                      className={isFullScreen && 'p-0 m-0'}
                      size="sm"
                      color={
                        isFullScreen || isSmallerThanTablet ? 'link' : 'primary'
                      }
                      type="button"
                      onClick={() => {
                        setFullScreen(!isFullScreen);
                        if (isFullScreen) closeFullscreen();
                        else {
                          analyticsSendEvent({
                            action:
                              analyticsConstants.action.expand_project_board,
                          });
                          openFullscreen(document.documentElement);
                        }
                      }}
                    >
                      {isFullScreen ? (
                        <img src={fullScreenCloseIcon} alt="Close Fullscreen" />
                      ) : isSmallerThanTablet ? (
                        <img src={fullScreenIcon} alt="Close Fullscreen" />
                      ) : (
                        'Fullscreen'
                      )}
                    </Button>
                    {((!isSprintKanban && isAllowedKanbanEdit) ||
                      (isSprintKanban &&
                        (isSectionEditAllowed || isSectionDeleteAllowed))) && (
                      <Dropdowns
                        onClick={event => event.stopPropagation()}
                        dropdownClasses={classes.dropdown}
                        text={<i className="fas fa-ellipsis-v" />}
                        className="btn-icon-only m-0 text-light float-right"
                        options={dropdownColumns}
                        caret={false}
                        size="sm"
                        color=""
                      />
                    )}
                  </div>
                </CardTitle>
                <Row>
                  <Col
                    md={6}
                    className="d-flex justify-content-start align-items-center mb-1"
                  >
                    Search:
                    <Input
                      className={classes.searchBox}
                      value={keyword}
                      onChange={e => {
                        setKeyword(e.target.value);
                      }}
                    />
                    {isSprintKanban ? null : (
                      <div
                        className={cs('ml-4', classes.expand)}
                        onClick={toggleAllStories}
                      >
                        {expanded.length > 0 &&
                        expanded.length === totalStoriesCanBeExpanded
                          ? 'Collapse'
                          : 'Expand'}{' '}
                        All Stories
                        <i
                          className={cs(
                            'ml-2 fas',
                            {
                              'fa-chevron-down':
                                expanded.length === 0 ||
                                expanded.length !== totalStoriesCanBeExpanded,
                            },
                            {
                              'fa-chevron-up':
                                expanded.length > 0 &&
                                expanded.length === totalStoriesCanBeExpanded,
                            }
                          )}
                        />
                      </div>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="d-flex justify-content-md-end justify-content-sm-start  align-items-center mt-1"
                  >
                    {isSprintKanban ? (
                      <div className={classes.statis}>
                        <b>{get(storiesSummary, 'active')}</b> Active Stories
                      </div>
                    ) : (
                      <>
                        {!isMobile && (
                          <div className={classes.statis}>
                            <b>{get(storiesSummary, 'active')}</b> Active
                            Stories
                          </div>
                        )}

                        <div className={classes.statis}>
                          <b>
                            {get(storiesSummary, 'completed')}/
                            {get(storiesSummary, 'total')}
                          </b>{' '}
                          Stories Completed
                        </div>

                        {!isMobile && (
                          <div className={classes.statis}>
                            <b>
                              {get(hoursSummary, 'consumed', 0)
                                ? Math.round(
                                    get(hoursSummary, 'consumed', 0) * 10
                                  ) / 10
                                : '0'}
                              /{get(hoursSummary, 'total')}
                            </b>{' '}
                            Hrs
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <DragContainer
            {...{
              projectId,
              expanded,
              toggleExpanded,
              isAllowedEdit,
              isAllowedKanbanEdit,
              analyticsSendEvent,
              columns,
              setColumnModification,
              columnModification,
              onColumnModification,
              isSprintKanban: isSprintKanban,
              id,
              sectionID,
              isSprintEditAllowed,
              isSprintViewAllowed,
              isSprintDeleteAllowed,
              isSprintAddAllowed,
              isAddStoryToSprintAllowed,
              isSprintUpdateAllowed,
            }}
          />
        </Container>
      )}

      <StoryModal
        storyId={toNumber(queryStory)}
        projectId={toNumber(projectId)}
        isOpen={isStoryModalOpen}
        closeModal={() => {
          setIsStoryModalOpen(false);
          history.replace(history.location.pathname);
        }}
        currentTab={get(queryProps, 'tab', 'comments')}
        onTabChange={tab => history.push(`?story=${queryStory}&tab=${tab}`)}
        onChange={() => reload()}
        openDuplicateModal={openDuplicateModal}
      />
      {isDuplicateModal ? (
        <DuplicateStoryForm
          storyData={{ name: storyName }}
          isModalOpen={isDuplicateModal}
          closeModal={closeDuplicateModal}
          submitValues={() => {}}
          isLoading={isDuplicating}
        />
      ) : null}
    </div>
  );
};

Kanban.propTypes = {
  isMockUp: PropTypes.bool,
};
Kanban.defaultProps = {
  isMockUp: false,
};

export default Kanban;
