import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import isNil from 'lodash/isNil';
import { UncontrolledTooltip } from 'reactstrap';
import pluralize from 'pluralize';
import QuickView from 'views/widgets/QuickView';

const TimeLogged = () => {
  const loggedHours = useSelector(({ projectDashboard }) =>
    get(projectDashboard, 'lastWeekHours.data.logged_hours', '--')
  );
  const isMonthly = useSelector(({ projectDashboard }) =>
    get(projectDashboard, 'lastWeekHours.data.isMonthly')
  );
  const recommendedHours = useSelector(({ projectDashboard }) =>
    get(projectDashboard, 'lastWeekHours.data.recommended')
  );

  return (
    <QuickView
      heading={
        <span>
          Time Logged{' '}
          <span className="text-xs font-weight-light">Last 7 days spend</span>
        </span>
      }
      content={`${loggedHours} ${pluralize('hour', toNumber(loggedHours))}`}
      iconBg="warning"
      icon="far fa-clock"
      footer={
        <>
          <span className="d-flex align-items-center">
            <p>
              Recommended {isNil(recommendedHours) ? '--' : recommendedHours}{' '}
              {pluralize('hr', toNumber(recommendedHours))} / week
            </p>
            <i id="week-expenditure" className="ml-2 fa fa-info-circle" />
          </span>
          <UncontrolledTooltip target="week-expenditure" placement="bottom">
            {isNil(recommendedHours)
              ? `Set your budget${
                  isMonthly ? '' : ' and due date'
                } to see your recommended 7 days burn`
              : `Recommended budget is calculated using the number of days left ${
                  isMonthly
                    ? 'until the due date and your remaining budget hours'
                    : 'in the month and your remaining budget hours'
                }`}
          </UncontrolledTooltip>
        </>
      }
    />
  );
};

export default TimeLogged;
