import React, { Fragment } from 'react';
import get from 'lodash/get';
import cs from 'classnames';
import classes from './QuickStartTour.module.scss';

const ToursRenderer = ({
  availableTours,
  quickStartTour,
  onBoardingTour,
  handleTourClick,
}) => {
  return availableTours.map(({ tourKey, iconClass, title, content }, i) => {
    const status = get(onBoardingTour, `value.steps.${tourKey}.status`, '');
    return (
      <Fragment key={i}>
        <div
          className={cs(classes.section, {
            [classes.active]:
              quickStartTour.activeTour &&
              quickStartTour.activeTour === tourKey,
            [classes.completed]:
              status === 'Completed' ||
              (status === 'Completed' && quickStartTour.isRestarted),
          })}
          onClick={handleTourClick(tourKey, status === 'Completed')}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <i className={iconClass} />
              <div className={classes.sectionTitle}>{title}</div>
            </div>
            {status === 'Completed' ? (
              <i className={cs(classes.success, 'fas fa-check-circle')} />
            ) : null}
          </div>
          <div className={cs(classes.description, 'mt-3')}>{content}</div>
        </div>
      </Fragment>
    );
  });
};

export default ToursRenderer;
