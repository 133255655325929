import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import pluralize from 'pluralize';
import toNumber from 'lodash/toNumber';
import classes from 'views/pages/dashboards/Dashboard.module.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QuickView from 'views/widgets/QuickView';

const WeeklyTimeLogs = ({ getDetails }) => {
  const timeLoggedThisWeek = getDetails('timeLoggedThisWeek.data', {});
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const userName = useSelector(({ auth }) => get(auth, 'user.name'));
  const hours = get(timeLoggedThisWeek, 'hours', 0);
  const startDate = moment(get(timeLoggedThisWeek, 'date_range.date_from')).tz(
    userTimezone,
    true
  );
  const history = useHistory();
  const endDate = moment(get(timeLoggedThisWeek, 'date_range.date_to')).tz(
    userTimezone,
    true
  );

  return (
    <QuickView
      heading="Time logged this week"
      content={pluralize('hour', toNumber(hours), true)}
      iconBg="success"
      icon="far fa-clock"
      footer={
        <p
          className={classNames(classes.hoverHand)}
          onClick={() => {
            history.push(
              `/admin/timelog?values=${JSON.stringify({
                users: [{ id: userId, name: userName }],
                range_date: `${moment(startDate).format(
                  'MMM DD, YYYY'
                )} - ${moment(endDate).format('MMM DD, YYYY')}`,
                startDate,
                endDate,
              })}`
            );
          }}
        >
          View Timelogs
          <i className="fas fa-arrow-right ml-2 text-xs" />
        </p>
      }
    />
  );
};

WeeklyTimeLogs.propTypes = {
  getDetails: PropTypes.func,
};

export default WeeklyTimeLogs;
