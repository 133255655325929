import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import {
  Card,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardBody,
} from 'reactstrap';
import CardsHeader from 'components/Headers/CardsHeader.js';
import BaseTable from 'components/Table';
import Button from 'components/Button';
import {
  fetchClientsList,
  createClient,
  updateClient,
  deleteClient,
} from 'store/actions/clients';
import NoClientsImg from 'assets/img/theme/No_Clients_List.png';
import ClientForm from './Client.Form';

import classes from './clients.module.scss';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import history from 'helpers/history';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
} from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';

const Clients = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isModal, setModal] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.clients,
      ...rest,
    });
  };
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const clientState = useSelector(({ client }) => client.clientState);
  const createReducer = useSelector(({ client }) => client.createClient);
  const deleteReducer = useSelector(({ client }) => client.deleteClient);
  const editReducer = useSelector(({ client }) => client.editClient);

  useEffect(() => {
    analyticsSendEvent({ action: analyticsConstants.action.view_clients_list });
  }, []);

  const clients = get(clientState, 'data.data', []);
  const clientMetadata = get(clientState, 'data.meta', {});

  const listLoading = get(clientState, 'isInProgress', false);
  const createLoading = get(createReducer, 'isInProgress', false);
  const editLoading = get(editReducer, 'isInProgress', false);
  const deleteLoading = get(deleteReducer, 'isInProgress', false);

  if (!useAccess([permissions.LIST_CLIENTS])) {
    showUnAuhtorizedError();
  }

  const NoDataIndication = () => {
    return (
      <div
        className={classNames(
          'd-flex align-items-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column w-100">
          <img
            className={classNames('m-auto w-100', classes.image)}
            src={NoClientsImg}
            alt="No Clients"
          />
          <h4 className="display-4 mb-0 text-center px-2">
            Go ahead and create your first client!
          </h4>
          <p className="text-center">
            To add a client, click on the{' '}
            <span className={classes.addClient} onClick={openModal}>
              Add Client
            </span>{' '}
            button in the upper right corner
          </p>
        </div>
      </div>
    );
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const openModal = () => {
    setModal(true);
  };

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const handleButtonClick = () => {
    openModal();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'client_creation' &&
      quickStartTour.step === 1
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step: 2,
          })
        );
      }, 1000);
    }
  };
  const closeModal = () => {
    setModal(false);
    setViewMode('');
    setEditValues(null);
  };

  const submitValues = async values => {
    setLoading(true);
    if (viewMode === 'edit') {
      analyticsSendEvent({ action: analyticsConstants.action.edit_client });
      const sort =
        sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
      await dispatch(
        updateClient(editValues.id, values, { page, sort, q: '' })
      );
      setViewMode('');
      setEditValues(null);
    } else {
      analyticsSendEvent({ action: analyticsConstants.action.add_client });
      const { status, data } = await dispatch(createClient(values));
      if (
        quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'client_creation' &&
        quickStartTour.step === 3
      ) {
        dispatch(
          updateActiveTourStatus({
            step: null,
            activeTour: null,
          })
        );
        analytics.sendEvent({
          category: analyticsConstants.category.onboarding,
          action:
            analyticsConstants.action.complete_create_a_client_onboarding_flow,
        });
        if (quickStartTour) {
          await dispatch(
            setUserPreference({
              ...onBoardingTour,
              value: {
                ...get(onBoardingTour, 'value', {}),
                status: 'Active',
                steps: {
                  ...get(onBoardingTour, 'value.steps', {}),
                  [`${quickStartTour.activeTour}`]: {
                    status: 'Completed',
                  },
                },
              },
            })
          );
          didAllOnboardingFlowCompleted();
        }
      }
      if (status) {
        // passing the prop so that we can get this prop on client profile page to open the popup
        history.push(`/admin/clients/${data.id}`, { shouldOpenPopup: true });
      }
    }
    setModal(false);
    setLoading(false);
  };

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    analyticsSendEvent({ action: analyticsConstants.action.delete_client });

    await dispatch(deleteClient(data.id, { page, sort, q: '' }));
  };

  const editAction = row => e => {
    e.preventDefault();
    setModal(true);
    setEditValues({ ...row });
    setViewMode('edit');
  };

  const deleteAction = row => e => {
    e.preventDefault();
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Client',
      text: `You are about to delete "${row.name}". Do you want to continue?`,
      data: row,
    });
  };

  const handleTableChange = async (
    type,
    { page, sortOrder, sortField, searchText }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setPage(1);
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
      analyticsSendEvent({
        action: analyticsConstants.action.search_clients_list,
      });
      setPage(1);
    }
    const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
    await dispatch(fetchClientsList(page, sort, searchText));
  };

  const handleClearTour = () => {
    dispatch(
      setUserPreference({
        ...onBoardingTour,
        value: {
          ...get(onBoardingTour, 'value', {}),
          status: 'Active',
          ...(!quickStartTour.isRestarted
            ? {
                steps: {
                  ...get(onBoardingTour, 'value.steps', {}),
                  [`${quickStartTour.activeTour}`]: {
                    status: 'Completed',
                  },
                },
              }
            : {}),
        },
      })
    );
    dispatch(
      updateActiveTourStatus({
        activeTour: null,
        step: null,
        isRestarted: false,
      })
    );
  };

  return (
    <>
      {isModal ? (
        <ClientForm
          isModalOpen={isModal}
          submitValues={submitValues}
          closeModal={closeModal}
          viewMode={viewMode}
          editValues={editValues}
          quickStartTour={quickStartTour}
          loading={loading}
        />
      ) : null}
      <div className={classes.clients}>
        <CardsHeader name="Clients" parentName="Clients" isRoot={true} />
        <div className="px-4">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center w-100">
                <h3 className="mb-0" id="clientTitle">
                  Client List
                </h3>
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={handleButtonClick}
                  id="addClientBtn"
                >
                  Add Client
                </Button>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <BaseTable
                keyField="id"
                defaultSorted={[sortBy]}
                noDataIndication={NoDataIndication}
                bootstrap4
                remote
                bordered={false}
                loading={
                  listLoading || createLoading || editLoading || deleteLoading
                }
                paginationOptions={{
                  page: page,
                  totalSize: clientMetadata.total,
                  sizePerPage: parseInt(clientMetadata.per_page),
                }}
                data={clients}
                columns={[
                  {
                    dataField: 'name',
                    text: 'Name',
                    sort: true,
                    classes: classes.clientName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row, index) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <Link
                          to={`/admin/clients/${row.id}`}
                          {...(quickStartTour.activeTour ===
                            'client_creation' && index === 0
                            ? {
                                id: 'firstClientRow',
                                onClick: handleClearTour,
                              }
                            : {})}
                        >
                          {cell}
                        </Link>
                      </div>
                    ),
                  },
                  {
                    dataField: 'created_at',
                    text: 'Date Added',
                    sort: true,
                    attrs: { width: '200px' },
                    headerAttrs: { width: '200px' },
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <span>
                          {moment(cell)
                            .tz(userTimezone)
                            .format('MMM Do, YYYY')}
                        </span>
                        <span>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                to={`/admin/clients/${row.id}`}
                                tag={Link}
                              >
                                View Client
                              </DropdownItem>
                              <DropdownItem href="#" onClick={editAction(row)}>
                                Edit Client
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                onClick={deleteAction(row)}
                              >
                                Delete Client
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </span>
                      </div>
                    ),
                  },
                ]}
                onTableChange={handleTableChange}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Clients;
