import { createBrowserHistory } from 'history';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';

export default createBrowserHistory({
  getUserConfirmation: function(message, callback) {
    AlertPopupHandler.open({
      onConfirm: () => callback(true),
      confirmBtnText: 'Continue',
      text: message,
    });
  },
});
