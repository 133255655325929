import get from 'lodash/get';
import Types from '../types/backlogs';
import filter from 'lodash/filter';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import toNumber from 'lodash/toNumber';

const initialState = {
  sectionsState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  itemsState: {
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  selectedStories: {},
  deleteStory: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteSection: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  massDeleteStories: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  massUpdateStories: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  createStory: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  duplicateStory: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  createSection: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editSection: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  reorderStories: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  reorderSections: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  updateItem: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updateStory: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_BACKLOG_SECTIONS_INPROGRESS:
      return {
        ...state,
        sectionsState: {
          ...state.sectionsState,
          isInProgress: true,
        },
      };
    case Types.FETCH_BACKLOG_SECTIONS_SUCCESS:
      return {
        ...state,
        sectionsState: {
          ...state.sectionsState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_BACKLOG_SECTIONS_FAILURE:
      return {
        ...state,
        sectionsState: {
          ...state.sectionsState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_BACKLOG_SECTION_ITEMS_INPROGRESS:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: true,
            },
          },
        },
      };
    case Types.FETCH_BACKLOG_SECTION_ITEMS_SUCCESS:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: false,
              ...action.data.items,
            },
          },
        },
      };
    case Types.FETCH_BACKLOG_SECTION_ITEMS_FAILURE:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: false,
              isError: true,
              message: action.message,
            },
          },
        },
      };
    case Types.CREATE_STORY_INPROGRESS:
      return {
        ...state,
        createStory: {
          ...state.createStory,
          sectionId: action.data.sectionId,
          isInProgress: true,
        },
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: true,
            },
          },
        },
      };
    case Types.RESET_CREATE_STORY:
      return {
        ...state,
        createStory: {
          ...state.createStory,
          sectionId: null,
        },
      };
    case Types.CREATE_STORY_SUCCESS:
      return {
        ...state,
        createStory: {
          ...state.createStory,
          isInProgress: false,
          status: 1,
        },
        sectionsState: {
          ...state.sectionsState,
          data: map(get(state, 'sectionsState.data', []), section =>
            section.id === action.data.sectionId
              ? {
                  ...section,
                  stories: ++section.stories,
                }
              : section
          ),
        },
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              data: [
                ...get(
                  state,
                  `itemsState.data.${action.data.sectionId}.data`,
                  []
                ),
                action.data.item,
              ],
              sorted_list: [
                ...get(
                  state,
                  `itemsState.data.${action.data.sectionId}.sorted_list`,
                  []
                ),
                action.data.item,
              ],
              isInProgress: false,
            },
          },
        },
      };
    case Types.CREATE_STORY_FAILURE:
      return {
        ...state,
        createStory: {
          ...state.createStory,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: false,
              isError: true,
              message: action.message,
            },
          },
        },
      };
    case Types.ADD_BACKLOG_SECTION_INPROGRESS:
      return {
        ...state,
        createSection: {
          ...state.createSection,
          isInProgress: true,
        },
      };
    case Types.ADD_BACKLOG_SECTION_SUCCESS:
      return {
        ...state,
        createSection: {
          ...state.createSection,
          isInProgress: false,
          status: 1,
        },
        sectionsState: {
          ...state.sectionsState,
          data: [action.data, ...state.sectionsState.data],
        },
      };
    case Types.ADD_BACKLOG_SECTION_FAILURE:
      return {
        ...state,
        createSection: {
          ...state.createSection,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_STORY_DATA:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              data: action.data.items,
            },
          },
        },
      };
    case Types.DELETE_STORY_INPROGRESS:
      return {
        ...state,
        deleteStory: {
          ...state.deleteStory,
          isInProgress: true,
        },
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: true,
            },
          },
        },
      };
    case Types.DELETE_STORY_SUCCESS:
      return {
        ...state,
        deleteStory: {
          ...state.deleteStory,
          isInProgress: false,
          status: 1,
        },
        sectionsState: {
          ...state.sectionsState,
          data: map(get(state, 'sectionsState.data', []), section =>
            section.id === action.data.sectionId
              ? {
                  ...section,
                  stories: --section.stories,
                }
              : section
          ),
        },
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: false,
            },
          },
        },
      };
    case Types.DELETE_STORY_FAILURE:
      return {
        ...state,
        deleteStory: {
          ...state.deleteStory,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              isInProgress: false,
              isError: true,
              message: action.message,
            },
          },
        },
      };
    case Types.DELETE_SECTION_INPROGRESS:
      return {
        ...state,
        deleteSection: {
          ...state.deleteSection,
          isInProgress: true,
        },
      };
    case Types.DELETE_SECTION_SUCCESS:
      return {
        ...state,
        deleteSection: {
          ...state.deleteSection,
          isInProgress: false,
          status: 1,
        },
        sectionsState: {
          ...state.sectionsState,
          data: filter(
            state.sectionsState.data,
            section => section.id !== action.data
          ),
        },
      };
    case Types.DELETE_SECTION_FAILURE:
      return {
        ...state,
        deleteSection: {
          ...state.deleteSection,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SECTION_INPROGRESS:
      return {
        ...state,
        editSection: {
          ...state.editSection,
          isInProgress: true,
        },
      };
    case Types.UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        editSection: {
          ...state.editSection,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_SECTION_FAILURE:
      return {
        ...state,
        editSection: {
          ...state.editSection,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SECTION_DATA:
      return {
        ...state,
        sectionsState: {
          ...state.sectionsState,
          data: action.data,
        },
      };
    case Types.REORDER_STORIES_INPROGRESS:
      return {
        ...state,
        reorderStories: {
          ...state.reorderStories,
          isInProgress: true,
        },
      };
    case Types.REORDER_STORIES_SUCCESS:
      return {
        ...state,
        reorderStories: {
          ...state.reorderStories,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.REORDER_STORIES_FAILURE:
      return {
        ...state,
        reorderStories: {
          ...state.reorderStories,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SORT_ORDER_STATE:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              sorted_list: action.data.items,
            },
          },
        },
      };
    case Types.UPDATE_STORIES_STATE:
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          data: {
            ...get(state, 'itemsState.data', {}),
            [action.data.sectionId]: {
              ...get(state, `itemsState.data.${action.data.sectionId}`, {}),
              data: action.data.items,
            },
          },
        },
        sectionsState: {
          ...state.sectionsState,
          data: map(get(state, `sectionsState.data`, []), section =>
            toNumber(section.id) === toNumber(action.data.sectionId)
              ? {
                  ...section,
                  stories: size(action.data.items),
                }
              : section
          ),
        },
      };
    case Types.UPDATE_ITEM_INPROGRESS:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          isInProgress: true,
        },
      };
    case Types.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        updateItem: {
          ...state.updateItem,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_STORY_DETAILS_INPROGRESS:
      return {
        ...state,
        updateStory: {
          ...state.updateStory,
          isInProgress: true,
        },
      };
    case Types.UPDATE_STORY_DETAILS_SUCCESS:
      return {
        ...state,
        updateStory: {
          ...state.updateStory,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_STORY_DETAILS_FAILURE:
      return {
        ...state,
        updateStory: {
          ...state.updateStory,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.SELECT_STORY:
      return {
        ...state,
        selectedStories: {
          ...state.selectedStories,
          [action.data.sectionId]: [
            ...get(state, ['selectedStories', action.data.sectionId], []),
            action.data.storyId,
          ],
        },
      };
    case Types.DESELECT_STORY:
      return {
        ...state,
        selectedStories: {
          ...state.selectedStories,
          [action.data.sectionId]: without(
            get(state, `selectedStories.${action.data.sectionId}`, []),
            action.data.storyId
          ),
        },
      };
    case Types.DESELECT_ALL_STORIES:
      return {
        ...state,
        selectedStories: {},
      };
    case Types.SELECT_ALL_STORIES_IN_SECTION:
      return {
        ...state,
        selectedStories: {
          ...state.selectedStories,
          [action.data]: uniq([
            ...get(state, `selectedStories.${action.data.sectionId}`, []),
            ...map(
              get(state, `itemsState.data.${action.data}.data`, []),
              story => story.id
            ),
          ]),
        },
      };
    case Types.DESELECT_ALL_STORIES_IN_SECTION:
      return {
        ...state,
        selectedStories: pickBy(
          get(state, 'selectedStories', {}),
          (value, key) => toNumber(key) !== toNumber(action.data)
        ),
      };
    case Types.STORIES_MASS_DELETE_INPROGRESS:
      return {
        ...state,
        massDeleteStories: {
          ...state.massDeleteStories,
          isInProgress: true,
        },
      };
    case Types.STORIES_MASS_DELETE_SUCCESS:
      return {
        ...state,
        massDeleteStories: {
          ...state.massDeleteStories,
          isInProgress: false,
          status: 1,
        },
        selectedStories: {},
      };
    case Types.STORIES_MASS_DELETE_FAILURE:
      return {
        ...state,
        massDeleteStories: {
          ...state.massDeleteStories,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.STORIES_MASS_UPDATE_INPROGRESS:
      return {
        ...state,
        massUpdateStories: {
          ...state.massUpdateStories,
          isInProgress: true,
        },
      };
    case Types.STORIES_MASS_UPDATE_SUCCESS:
      return {
        ...state,
        massUpdateStories: {
          ...state.massUpdateStories,
          isInProgress: false,
          status: 1,
        },
        selectedStories: {},
      };
    case Types.STORIES_MASS_UPDATE_FAILURE:
      return {
        ...state,
        massUpdateStories: {
          ...state.massUpdateStories,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.REORDER_SECTIONS_INPROGRESS:
      return {
        ...state,
        reorderSections: {
          ...state.reorderSections,
          isInProgress: true,
        },
      };
    case Types.REORDER_SECTIONS_SUCCESS:
      return {
        ...state,
        reorderSections: {
          ...state.reorderSections,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.REORDER_SECTIONS_FAILURE:
      return {
        ...state,
        reorderSections: {
          ...state.reorderSections,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SECTIONS_STATE:
      return {
        ...state,
        sectionsState: {
          ...state.sectionsState,
          data: action.data,
        },
      };
    case Types.DUPLICATE_STORY_INPROGRESS:
      return {
        ...state,
        duplicateStory: {
          ...state.duplicateStory,
          isInProgress: true,
        },
      };
    case Types.DUPLICATE_STORY_SUCCESS:
      return {
        ...state,
        duplicateStory: {
          ...state.duplicateStory,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DUPLICATE_STORY_FAILURE:
      return {
        ...state,
        duplicateStory: {
          ...state.duplicateStory,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};
