import Types from '../types/metadata';

const initialState = {
  timezones: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default function Metadata(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_TIMEZONES_INPROGRESS:
      return {
        ...state,
        timezones: {
          ...state.timezones,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: {
          ...state.timezones,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_TIMEZONES_FAILURE:
      return {
        ...state,
        timezones: {
          ...state.timezones,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
