/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import classNames from 'classnames';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { getAssignedProjects as getAssignedProjectsAction } from 'store/actions/profile';
import BaseTable from 'components/Table';
import { Link } from 'react-router-dom';
import NoProjectsAssignedImg from 'assets/img/theme/No_Projects_Assigned.svg';
import classes from './assignedProjects.module.scss';
const assignedProject = ({ userId }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'desc',
  });

  useEffect(() => {
    dispatch(getAssignedProjectsAction(userId, 1, 'name'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const assignedProjectData = useSelector(
    ({ assignedProjects }) => assignedProjects.assignedProjectState
  );
  const assignedProjects = get(assignedProjectData, 'data.data', []);
  const loading = get(assignedProjectData, 'isInProgress', []);
  const assignedProjectsMetadata = get(assignedProjectData, 'data.meta', {});

  const renderSortCaret = order => {
    if (!order)
      return <i className={classNames('fas fa-sort', classes['sort-caret'])} />;
    else if (order === 'asc')
      return (
        <i className={classNames('fas fa-sort-up', classes['sort-caret'])} />
      );
    else if (order === 'desc')
      return (
        <i className={classNames('fas fa-sort-down', classes['sort-caret'])} />
      );
    return null;
  };

  return (
    <Card>
      <h3 className={classNames('text-gray-dark px-4 py-3 m-0')}>
        Assigned Projects
      </h3>
      <CardBody className="p-0">
        <BaseTable
          search={false}
          keyField="id"
          defaultSorted={[sortBy]}
          noDataIndication={() => (
            <div className="d-flex justify-content-center flex-column align-items-center m-auto w-100 py-4">
              <img
                className={classNames(
                  classes['no-project-img'],
                  'm-auto h-auto w-100 px-2'
                )}
                src={NoProjectsAssignedImg}
                alt="No Epics"
              />
            </div>
          )}
          bootstrap4
          remote
          bordered={false}
          loading={loading}
          paginationOptions={{
            page: page,
            totalSize: assignedProjectsMetadata.total,
            sizePerPage: 5,
            paginationSize: 3,
          }}
          data={assignedProjects}
          columns={[
            {
              dataField: 'name',
              text: 'Project',
              sort: true,
              classes: classes['project-name-column'],
              sortCaret: renderSortCaret,
              formatter: (cell, row) => (
                <div
                  className={classNames(
                    'd-flex justify-content-start h-100 align-items-center',
                    classes['project-name']
                  )}
                  title={cell}
                >
                  <i
                    style={{
                      color: `#${row.color}`,
                      fontSize: '6px',
                    }}
                    className="fas fa-circle mr-1"
                  />
                  <Link to={`/admin/projects/${row.id}`}>{cell}</Link>
                </div>
              ),
            },
            {
              dataField: 'status',
              text: 'Status',
              sort: true,
              sortCaret: renderSortCaret,
              formatter: cell => (
                <div className="d-flex h-100 w-100 align-items-center">
                  <span className={classes['project-status']}>{cell}</span>
                </div>
              ),
            },
          ]}
          onTableChange={(type, { page, sortOrder, sortField, ...rest }) => {
            if (type === 'pagination') {
              setPage(page);
            } else if (type === 'sort') {
              setSortBy({
                dataField: sortField,
                order: sortOrder,
              });
              setPage(1);
            }
            const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
            dispatch(
              getAssignedProjectsAction(
                userId,
                type === 'sort' ? 0 : page,
                sort
              )
            );
          }}
        />
      </CardBody>
    </Card>
  );
};
export default assignedProject;
