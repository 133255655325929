import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_BACKLOG_SECTIONS_INPROGRESS',
  'FETCH_BACKLOG_SECTIONS_SUCCESS',
  'FETCH_BACKLOG_SECTIONS_FAILURE',
  'FETCH_BACKLOG_SECTION_ITEMS_INPROGRESS',
  'FETCH_BACKLOG_SECTION_ITEMS_SUCCESS',
  'FETCH_BACKLOG_SECTION_ITEMS_FAILURE',
  'DELETE_STORY_INPROGRESS',
  'DELETE_STORY_SUCCESS',
  'DELETE_STORY_FAILURE',
  'DELETE_SECTION_INPROGRESS',
  'DELETE_SECTION_SUCCESS',
  'DELETE_SECTION_FAILURE',
  'CREATE_STORY_INPROGRESS',
  'RESET_CREATE_STORY',
  'CREATE_STORY_SUCCESS',
  'CREATE_STORY_FAILURE',
  'UPDATE_SECTION_INPROGRESS',
  'UPDATE_SECTION_SUCCESS',
  'UPDATE_SECTION_FAILURE',
  'UPDATE_SECTION_DATA',
  'STORIES_MASS_UPDATE_INPROGRESS',
  'STORIES_MASS_UPDATE_SUCCESS',
  'STORIES_MASS_UPDATE_FAILURE',
  'STORIES_MASS_DELETE_INPROGRESS',
  'STORIES_MASS_DELETE_SUCCESS',
  'STORIES_MASS_DELETE_FAILURE',
  'REORDER_STORIES_INPROGRESS',
  'UPDATE_STORIES_STATE',
  'REORDER_STORIES_SUCCESS',
  'REORDER_STORIES_FAILURE',
  'UPDATE_ITEM_INPROGRESS',
  'UPDATE_ITEM_SUCCESS',
  'UPDATE_ITEM_FAILURE',
  'UPDATE_STORY_DETAILS_INPROGRESS',
  'UPDATE_STORY_DETAILS_SUCCESS',
  'UPDATE_STORY_DETAILS_FAILURE',
  'UPDATE_STORY_DATA',
  'ADD_BACKLOG_SECTION_INPROGRESS',
  'ADD_BACKLOG_SECTION_SUCCESS',
  'ADD_BACKLOG_SECTION_FAILURE',
  'SELECT_STORY',
  'DESELECT_STORY',
  'DESELECT_ALL_STORIES',
  'SELECT_ALL_STORIES_IN_SECTION',
  'DESELECT_ALL_STORIES_IN_SECTION',
  'REORDER_SECTIONS_INPROGRESS',
  'UPDATE_SECTIONS_STATE',
  'UPDATE_SORT_ORDER_STATE',
  'REORDER_SECTIONS_SUCCESS',
  'REORDER_SECTIONS_FAILURE',
  'DUPLICATE_STORY_INPROGRESS',
  'DUPLICATE_STORY_SUCCESS',
  'DUPLICATE_STORY_FAILURE'
);
