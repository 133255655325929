import { get } from 'lodash';
import { fetchTimezones as fetchMetaTimezones } from 'api/metadata';
import Types from '../types/metadata';

export const fetchTimezones = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_TIMEZONES_INPROGRESS,
    });

    try {
      const resp = await fetchMetaTimezones();

      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_TIMEZONES_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_TIMEZONES_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TIMEZONES_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};
