import get from 'lodash/get';
import Types from 'store/types/story';

const initialState = {
  storyComments: {
    isInProgress: true,
    isInitialCall: false,
    isError: false,
    shouldMakeCall: true,
    status: 0,
    message: '',
    data: {},
  },
  postStoryComment: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteStoryComment: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  markCommentRead: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  typingUsers: {
    users: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_INTITIAL_COMMENTS_INPROGRESS:
      return {
        ...state,
        storyComments: {
          ...state.storyComments,
          isInitialCall: action.data,
        },
      };
    case Types.FETCH_STORY_COMMENTS_INPROGRESS:
      return {
        ...state,
        storyComments: {
          ...state.storyComments,
          isInProgress: true,
        },
      };
    case Types.SET_STORY_COMMENTS:
      return {
        ...state,
        storyComments: {
          ...state.storyComments,
          isInProgress: false,
          status: 1,
          data: {
            data: action.data.updatedComments,
          },
          ...(action.data.unreadFrom
            ? {
                unreadFrom: action.data.unreadFrom,
              }
            : {}),
        },
      };
    case Types.SET_STORY_COMMENT_UNREAD_FROM:
      return {
        ...state,
        storyComments: {
          ...state.storyComments,
          unreadFrom: action.data,
        },
      };
    case Types.FETCH_STORY_COMMENTS_SUCCESS:
      return {
        ...state,
        storyComments: {
          ...state.storyComments,
          isInProgress: false,
          status: 1,
          data: action.data.data,
          ...(action.data.shouldMakeCall
            ? { shouldMakeCall: action.data.shouldMakeCall }
            : {}),
        },
      };
    case Types.FETCH_MORE_STORY_COMMENTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations

      return {
        ...state,
        storyComments: {
          ...state.storyComments,
          data: {
            ...get(state, 'storyComments.data', {}),
            data: action.data.data,
          },
          shouldMakeCall: action.data.shouldMakeCall,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.FETCH_STORY_COMMENTS_FAILURE:
      return {
        ...state,
        storyComments: {
          ...state.storyComments,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CLEAR_STORY_COMMENTS:
      return {
        ...state,
        storyComments: {
          isInProgress: true,
          isError: false,
          shouldMakeCall: false,
          status: 0,
          message: '',
          data: {},
        },
      };
    case Types.POST_STORY_COMMENT_INPROGRESS:
      return {
        ...state,
        postStoryComment: {
          ...state.postStoryComment,
          isInProgress: true,
        },
      };
    case Types.POST_STORY_COMMENT_SUCCESS:
      return {
        ...state,
        postStoryComment: {
          ...state.postStoryComment,
          isInProgress: false,
          status: 1,
        },
        storyComments: {
          ...state.storyComments,
          data: {
            ...get(state, 'storyComments.data', {}),
            data: action.data,
          },
        },
      };
    case Types.POST_STORY_COMMENT_FAILURE:
      return {
        ...state,
        postStoryComment: {
          ...state.postStoryComment,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_STORY_COMMENT_INPROGRESS:
      return {
        ...state,
        deleteStoryComment: {
          ...state.deleteStoryComment,
          isInProgress: true,
        },
      };
    case Types.DELETE_STORY_COMMENT_SUCCESS:
      return {
        ...state,
        deleteStoryComment: {
          ...state.deleteStoryComment,
          isInProgress: false,
          status: 1,
        },
        storyComments: {
          ...state.storyComments,
          data: {
            ...get(state, 'storyComments.data', {}),
            data: action.data,
          },
        },
      };
    case Types.DELETE_STORY_COMMENT_FAILURE:
      return {
        ...state,
        deleteStoryComment: {
          ...state.deleteStoryComment,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.MARK_READ_COMMENTS_INPROGRESS:
      return {
        ...state,
        markCommentRead: {
          ...state.markCommentRead,
          isInProgress: true,
        },
      };
    case Types.MARK_READ_COMMENTS_SUCCESS:
      return {
        ...state,
        markCommentRead: {
          ...state.markCommentRead,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.MARK_READ_COMMENTS_FAILURE:
      return {
        ...state,
        markCommentRead: {
          ...state.markCommentRead,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.SET_TYPING_USERS:
      return {
        ...state,
        typingUsers: {
          ...state.typingUsers,
          users: action.data,
        },
      };
    default:
      return state;
  }
};
