import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import classes from './Editor.module.scss';
import cs from 'classnames';
import 'quill-mention';
import { getSourceList } from 'helpers/mentions';

const mention = {
  source: getSourceList,
  minChars: 0,
  offsetLeft: -11,
  offsetTop: 7,
  // this is to allow spaces in mentions, if you remove this it will not support spaces.
  allowedChars: /^(?:[a-zA-Z0-9_]+\s?)*$/,
  showDenotationChar: false,
  dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'userId'],
  linkTarget: '',
  mentionListClass: cs(classes['mention-list'], 'mention-list'),
  listItemClass: cs(classes['mention-list-item'], 'mention-list-item'),
  mentionContainerClass: cs(
    classes['mention-list-container'],
    'mention-list-container'
  ),
  renderItem: item => {
    return `<div class="d-flex flex-row flex-nowrap align-items-center overflow-hidden" id="${item.id}">
    <img class="avatar rounded-circle avatar-xs mr-2" src="${item.avatar}" >
    <span>${item.name}</span>
    </div>`;
  },
  onSelect: (item, insert) => {
    insert(item);
  },
};

const Editor = props => {
  const {
    value,
    onChange,
    className,
    error,
    assignRef,
    mentionProps,
    restModules,
    shouldAllowMention = false,
    ...rest
  } = props;

  return (
    <div
      className={cs(className, {
        [classes['is-invalid']]: error,
      })}
    >
      <ReactQuill
        value={value}
        onChange={onChange}
        theme="snow"
        modules={{
          toolbar: [
            ['bold', 'italic'],
            ['link', 'blockquote', 'code', 'image'],
            [
              {
                list: 'ordered',
              },
              {
                list: 'bullet',
              },
            ],
          ],
          mention: shouldAllowMention
            ? { ...mention, ...mentionProps }
            : undefined,
          ...(restModules ? { ...restModules } : {}),
        }}
        ref={assignRef}
        {...rest}
      />

      {error && <div className={classes['invalid-feedback']}>{error}</div>}
    </div>
  );
};

Editor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.string,
  assignRef: PropTypes.func,
  mentionProps: PropTypes.object,
  restModules: PropTypes.object,
};

Editor.defaultProps = {
  assignRef: () => {},
  mentionProps: {},
};

export default Editor;
