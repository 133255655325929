import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './KnowledgeBase.module.scss';
import cs from 'classnames';
import CardsHeader from 'components/Headers/CardsHeader';
import HeaderImg from 'assets/img/theme/header-knowledge-base.svg';
import moment from 'moment';
import CategoriesModal from './CategoriesModal';
import {
  Badge,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from 'reactstrap';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
} from 'helpers/permission';
import Button from 'components/Button';
import Dropdowns from 'components/Dropdowns';
import LikeAndDislike from 'components/LikeAndDislike';
import Pagination from 'components/Pagination';
import history from 'helpers/history';
import KnowledgeBaseForm from './KnowledgeBaseForm';
import Loading from 'components/Loading';

import {
  doGetCategories,
  doGetAllCategories,
  doGetArticles,
  doPostLikeDisLikeArticleById,
} from 'store/actions/knowledgeBaseActions';
import { get, isEqual } from 'lodash';
import NoArticlesImg from 'assets/img/theme/No_Articles.png';
import analytics, { analyticsConstants } from 'helpers/analytics';

const KnowledgeBase = () => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.knowledge_base,
      ...rest,
    });
  };
  const categories = useSelector(
    ({ knowledgeBase }) => get(knowledgeBase.categories, 'data', []) || []
  );
  const articles = useSelector(
    ({ knowledgeBase }) => get(knowledgeBase, 'articles', []) || []
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilter] = useState({
    status: 'published',
    myArticles: '',
  });
  const [textDropdown, setTextDropDown] = useState('All Publications');
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const isAllowedListArticles = useAccess(permissions.LIST_ARTICLES);
  const isAllowedViewAllArticles = useAccess(permissions.VIEW_ALL_ARTICLES);
  const isAllowedViewOwnArticles = useAccess(permissions.VIEW_MY_ARTICLES);

  if (!isAllowedListArticles) showUnAuhtorizedError();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await dispatch(doGetCategories(filters));
      setLoading(false);
    };
    getData();
  }, [dispatch, filters]);

  useEffect(() => {
    dispatch(doGetAllCategories());
  }, [dispatch]);

  const onCloseModal = useCallback(() => {
    setIsOpenCategoryModal(false);
  }, []);

  const authUserId = useSelector(({ auth }) => get(auth, 'user.id'));
  const companyGuid = useSelector(({ auth }) => get(auth, 'user.company.guid'));

  const NoDataIndication = () => {
    return (
      <div
        className={cs(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column w-100">
          <img
            className={cs('m-auto w-100', classes.image)}
            src={NoArticlesImg}
            alt="No Clients"
          />
          <h4 className="display-4 mb-0 text-center px-2">
            Publish an article!
          </h4>
          <p className="text-center">
            Share your knowledge with your team to unlock everyone's full
            potential
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {loading && <Loading wrapperClass={classes.loading} />}
      <CategoriesModal isOpen={isOpenCategoryModal} closeModal={onCloseModal} />
      <div className={classes.header}>
        <CardsHeader
          name="Knowledge Base"
          parentName="Knowledge Base"
          parentLink="/admin/knowledge-base"
          dark={true}
          isRoot={true}
        />
        <Container fluid>
          <div className={cs('header-body', classes.body)}>
            <Row className="align-items-center pb-4">
              <Col md="6">
                <div className={classes.title}>
                  Welcome to the Knowledge Base
                </div>
                <div className={classes.intro}>
                  Create, share, and manage knowledge across your whole company
                  to deliver a better training experience for your team.
                </div>
              </Col>
              <Col md="6">
                <img src={HeaderImg} alt="header" className={classes.image} />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className={classes.actions}>
        {useAccess([permissions.CREATE_ARTICLES]) && (
          <Button color="primary" onClick={() => setIsOpen(true)}>
            Create a New Article
          </Button>
        )}
        <Dropdowns
          text={textDropdown}
          options={[
            ...(isAllowedViewAllArticles
              ? [
                  {
                    text: 'All Publications',
                    onClick: () => {
                      analyticsSendEvent({
                        action: analyticsConstants.action.view_all_publications,
                      });
                      setTextDropDown('All Publications');
                      setFilter({
                        status: 'published',
                        myArticles: '',
                      });
                    },
                  },
                ]
              : []),
            {
              text: 'My Publications',
              onClick: () => {
                analyticsSendEvent({
                  action: analyticsConstants.action.view_my_publications,
                });
                setTextDropDown('My Publications');
                setFilter({
                  status: 'published',
                  myArticles: 'true',
                });
              },
            },
            {
              text: 'My Drafts',
              onClick: () => {
                analyticsSendEvent({
                  action: analyticsConstants.action.view_my_drafts,
                });
                setTextDropDown('My Drafts');
                setFilter({
                  status: 'draft',
                  myArticles: 'true',
                });
              },
            },
          ]}
        />
        <Button
          hidden={!useAccess([permissions.MANGAGE_KB_CATEGORIES])}
          onClick={() => {
            setIsOpenCategoryModal(true);
          }}
          leftIcon={<i className="ni ni-settings-gear-65" />}
        >
          Manage Categories
        </Button>
      </div>
      <div className={classes.mainContent}>
        {categories.length ? (
          categories.map(cate => {
            const data = get(articles, `${cate.id}.data`, []) || [];
            const meta = get(articles, `${cate.id}.meta`) || null;

            const currentPage = get(meta, 'current_page');
            const totalPage = get(meta, 'last_page');

            return (
              <div className={classes.section} key={cate.id}>
                <h1 className="display-4">{cate.name}</h1>

                <Row>
                  {data.map((item, index) => {
                    const itemGuid = get(cate, 'guid');
                    return (
                      <Col md={6} key={index}>
                        <Card
                          onClick={e => {
                            const tag = get(e, 'target.parentNode.tagName');
                            if (
                              ['A', 'BUTTON'].indexOf(tag) === -1 &&
                              (isAllowedViewAllArticles ||
                                (isAllowedViewOwnArticles &&
                                  isEqual(
                                    parseInt(get(item, 'created_by.id')),
                                    parseInt(authUserId)
                                  )))
                            )
                              history.push(`/admin/knowledge-base/${item.id}`);
                          }}
                        >
                          <CardBody>
                            {item.status === 0 && (
                              <Badge
                                color="info"
                                pill
                                className="float-right ml-2"
                              >
                                Draft
                              </Badge>
                            )}

                            <CardTitle className="h2 mb-0">
                              {item.name}
                            </CardTitle>
                            <small className="text-muted">
                              by {get(item, 'created_by.name')}
                              {moment(get(item, 'created_at')).format(
                                ' on MMM Do [at] h:mm A'
                              )}
                            </small>
                            <CardText className={cs('mt-4', classes.content)}>
                              {item.content.replace(/<\/?[^>]+(>|$)/g, '')}
                            </CardText>

                            <div
                              className={cs(
                                'd-flex justify-content-between',
                                classes.footer
                              )}
                            >
                              {(isAllowedViewAllArticles ||
                                (isAllowedViewOwnArticles &&
                                  isEqual(
                                    parseInt(get(item, 'created_by.id')),
                                    parseInt(authUserId)
                                  ))) && (
                                <Button
                                  className="px-0"
                                  color="link"
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault();
                                    history.push(
                                      `/admin/knowledge-base/${item.id}`
                                    );
                                  }}
                                >
                                  Read article
                                </Button>
                              )}
                              {((itemGuid === '1' &&
                                companyGuid === itemGuid) ||
                                itemGuid !== '1') && (
                                <LikeAndDislike
                                  isLiked={item.isLiked}
                                  isDisLiked={item.isDisLiked}
                                  like={item.likes}
                                  dislike={item.dislikes}
                                  onClick={async type => {
                                    await dispatch(
                                      doPostLikeDisLikeArticleById(
                                        item.id,
                                        type
                                      )
                                    );
                                    await dispatch(
                                      doGetArticles(
                                        cate.id,
                                        filters.status,
                                        filters.myArticles,
                                        currentPage
                                      )
                                    );
                                  }}
                                />
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>

                {totalPage > 1 && (
                  <Pagination
                    totalPage={totalPage}
                    currentPage={currentPage}
                    onClick={async page => {
                      setLoading(true);
                      await dispatch(
                        doGetArticles(
                          cate.id,
                          filters.status,
                          filters.myArticles,
                          page
                        )
                      );
                      setLoading(false);
                    }}
                  />
                )}
              </div>
            );
          })
        ) : (
          <NoDataIndication />
        )}
      </div>

      {isOpen && (
        <KnowledgeBaseForm
          isOpen={isOpen}
          toggle={toggle}
          mode="add"
          success={() => {
            dispatch(doGetCategories(filters));
          }}
        />
      )}
    </div>
  );
};

export default KnowledgeBase;
