import React, { useRef, useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import every from 'lodash/every';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import some from 'lodash/some';
import PropTypes from 'prop-types';
import CheckBox from 'components/FormFields/CheckBox';
import Input from 'components/FormFields/Input/Input';
import FormModal from 'components/FormFields/FormModal';
import classes from './projects.module.scss';
import duplicateFields from './duplicateFields';

const DuplicateProjectForm = ({
  closeModal,
  submitValues,
  isModalOpen,
  isLoading,
  projectName,
}) => {
  const [focus, setFocus] = useState({});
  const [focusOnInitial, setFocusOnInitial] = useState(true);
  const inputRef = useRef(null);

  if (inputRef && inputRef.current && focusOnInitial) {
    inputRef.current.focus();
    setFocusOnInitial(false);
  }

  const DuplicateProjectSchema = Yup.object().shape({
    name: Yup.string().required('Project Name is required'),
  });

  const RenderForm = ({
    handleSubmit,
    isValid,
    values,
    setFieldValue,
    setValues,
    touched,
    errors,
    handleBlur,
    handleChange,
  }) => {
    const renderAllModules = () => {
      return map(duplicateFields, module => {
        const subfields = module.fields.map(sub => (
          <FormGroup key={sub.name} className="mb-2">
            <CheckBox
              id={sub.name}
              name={sub.name}
              label={sub.label}
              checked={values[sub.name]}
              value={values[sub.name]}
              onChange={e => {
                setFieldValue(sub.name, e.target.checked);
              }}
            />
          </FormGroup>
        ));
        return (
          <>
            <FormGroup className="mb-2">
              <CheckBox
                id={module.name}
                name={module.name}
                label={module.label}
                indeterminate={some(
                  duplicateFields[module.name].fields,
                  field => values[field.name]
                )}
                checked={every(
                  duplicateFields[module.name].fields,
                  field => values[field.name]
                )}
                onChange={e => {
                  setValues({
                    ...values,
                    ...duplicateFields[module.name].fields.reduce(
                      (acc, cur) => ({
                        ...acc,
                        [cur.name]: e.target.checked,
                      }),
                      {}
                    ),
                  });
                }}
              />
            </FormGroup>
            <div className="ml-3 mb-4">{subfields}</div>
          </>
        );
      });
    };

    return (
      <FormModal
        size="md"
        toggle={closeModal}
        isOpen={isModalOpen}
        heading="Duplicate Project"
        submit={{
          onClick: handleSubmit,
          loading: isLoading,
          isValid: isValid,
          buttonText: 'Duplicate',
        }}
        cancel={{
          onClick: closeModal,
          buttonText: 'Cancel',
        }}
        bodyClassName={classes.duplicateModalBody}
      >
        <Form role="form" onSubmit={handleSubmit}>
          <Input
            autoFocus
            name="name"
            placeholder="Project Name"
            label="Project Name"
            type="text"
            error={errors.name}
            value={values.name}
            onChange={handleChange}
            touched={touched.name}
            innerRef={inputRef}
            onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
          />
          <div className="my-3">
            Choose the items of the project you would like to duplicate:
          </div>
          {renderAllModules()}
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        ...reduce(
          duplicateFields,
          (acc, cur) => ({
            ...acc,
            ...cur.fields.reduce((a, c) => ({ ...a, [c.name]: true }), {}),
          }),
          {}
        ),
        name: `${projectName} (Copy)`,
      }}
      validationSchema={DuplicateProjectSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        await submitValues(values);
        resetForm();
      }}
    >
      {RenderForm}
    </Formik>
  );
};

DuplicateProjectForm.propTypes = {
  closeModal: PropTypes.func,
  submitValues: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DuplicateProjectForm;
