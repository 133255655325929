import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import classes from './Dashboard.module.scss';
import classNames from 'classnames';
import moment from 'moment';
import numeral from 'numeral';
import QuickView from 'views/widgets/QuickView';

const BudgetSpent = () => {
  const consumedHours = useSelector(({ projectDashboard }) =>
    numeral(get(projectDashboard, 'budgetSpent.data.consumed_budget')).format(
      '0,0.00'
    )
  );
  const totalHours = useSelector(({ projectDashboard }) =>
    numeral(get(projectDashboard, 'budgetSpent.data.total')).format('0,0[.]00')
  );
  const isMonthly = useSelector(({ projectDashboard }) =>
    get(projectDashboard, 'budgetSpent.data.isMonthly')
  );
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const projectId = useSelector(({ project }) =>
    get(project, 'getProject.data.data.id')
  );
  const projectName = useSelector(({ project }) =>
    get(project, 'getProject.data.data.name')
  );
  const startDate = useSelector(({ projectDashboard }) =>
    moment(get(projectDashboard, 'budgetSpent.data.data_range.from')).tz(
      userTimezone,
      true
    )
  );
  const endDate = useSelector(({ projectDashboard }) =>
    moment(get(projectDashboard, 'budgetSpent.data.data_range.to')).tz(
      userTimezone,
      true
    )
  );
  const history = useHistory();

  return (
    <QuickView
      heading={`${isMonthly ? 'Monthly' : 'Fixed'} Budget Utilization`}
      content={`$${consumedHours} / $${totalHours}`}
      iconBg="primary"
      icon="fas fa-money-bill-alt"
      footer={
        <p
          className={classNames(classes.hoverHand)}
          onClick={() =>
            history.push(
              `/admin/timelog?values=${JSON.stringify({
                initiatives: [{ id: projectId, name: projectName }],
                range_date: `${moment(startDate).format(
                  'MMM DD, YYYY'
                )} - ${moment(endDate).format('MMM DD, YYYY')}`,
                startDate,
                endDate,
              })}`
            )
          }
        >
          See Timelogs
          <i className="fas fa-arrow-right ml-2 text-xs" />
        </p>
      }
    />
  );
};

export default BudgetSpent;
