import React from 'react';

const ThisWeekCell = ({ cell, row }) => {
  return (
    <div className="d-flex w-100 align-items-center">
      {cell ? `${cell} hours` : '--'}
    </div>
  );
};

export default ThisWeekCell;
