import AuthHeader from 'components/Headers/AuthHeader';
import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
// import cs from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  // FormGroup,
  Row,
} from 'reactstrap';
import classes from './Login.module.scss';
import cs from 'classnames';
// core components
import { FormikInput } from 'components/FormFields/Input';
import Button from 'components/Button';
import ButtonSocialLogin from 'components/ButtonSocialLogin';
import MicrosoftIcon from 'assets/img/icons/common/microsoft.svg';
import Alert from 'components/Alert';
import { doLogin } from 'store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import history from 'helpers/history';

const Login = props => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  // const [focus, setFocus] = useState({});
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required')
      .max(255, 'The email must be less than 255 chars'),
    password: Yup.string().required('Password is required'),
  });

  useEffect(() => {
    // If user's not logged in we will have stored the route they are on in router state
    const pathname = get(props, 'location.state.from.pathname', '');
    // when user hits logout this will be true, so pathname won't be set in that case
    const isLogout = get(props, 'history.location.state.isLogOut', false);
    const search = get(props, 'location.state.from.search', '');
    // if route is present setting as redirect_url so on login user will be taken on that route
    if (pathname && !isLogout) {
      sessionStorage.setItem(
        'redirect_URL',
        search ? `${pathname}/${search}` : pathname
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async values => {
    const email = values.email;
    const password = values.password;

    setLoading(true);
    const rs = await dispatch(doLogin(email, password));
    if (rs !== 1) setLoading(false);
  };

  const renderLoginForm = ({
    // values,
    // errors,
    // touched,
    // handleBlur,
    // handleChange,
    handleSubmit,
  }) => {
    return (
      <Card className={cs(classes.card, 'border-0 mb-0')}>
        <CardHeader className="bg-transparent">
          <div className="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div className="btn-wrapper text-center">
            <CardBody>
              <ButtonSocialLogin history={props.history}>
                <span className="btn-inner--text">Google</span>
              </ButtonSocialLogin>
              <ButtonSocialLogin
                social="microsoft"
                className="ml-0 mt-4 font-weight-400"
                history={props.history}
                leftIcon={<img alt="Microsoft" src={MicrosoftIcon} />}
              >
                Continue with Microsoft
              </ButtonSocialLogin>
            </CardBody>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          {auth.isError === true && (
            <Alert
              text={auth.message}
              dismiss={true}
              fade={true}
              color="danger"
            />
          )}
          <div className="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div>
          <Form role="form" onSubmit={handleSubmit}>
            <FormikInput
              groupClassName="mb-3"
              name="email"
              placeholder="Email"
              type="text"
              leftIcon={<i className="ni ni-email-83" />}
            />
            <FormikInput
              name="password"
              placeholder="Password"
              type="password"
              leftIcon={<i className="ni ni-lock-circle-open" />}
            />
            <div className="text-center">
              <Button
                className="my-4"
                color="info"
                type="submit"
                loading={loading}
              >
                Sign in
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <AuthHeader
        title="Welcome Back!"
        lead="Login below to access your Lyght account and start collaborating now!"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={schema}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {renderLoginForm}
            </Formik>

            <Row className="mt-3">
              <Col xs={12} className="d-flex">
                <a
                  className="text-light"
                  href="/auth/register"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/auth/register');
                  }}
                >
                  <small>Create a free account</small>
                </a>
                <a
                  className="text-light ml-auto"
                  href="/auth/email/reset-password"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/auth/email/reset-password');
                  }}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              {/*<Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/auth/register');
                  }}
                >
                  <small>Create new account</small>
                </a>
              </Col>*/}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
