import React from 'react';
import get from 'lodash/get';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useBreakpoint from 'helpers/useBreakPoint';

export const useData = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search || '');
  const [loadingPlan, setLoadingPlan] = React.useState('');
  const [openingStripe, setOpeningStripe] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('starter');
  const companyId = useSelector(state => get(state, 'auth.user.company.id'));
  const allPlans = useSelector(state => get(state, 'billing.plans.data')) || [];
  const subscription = useSelector(state =>
    get(state, 'auth.user.company.subscription', {})
  );
  const subId = get(subscription, 'sub_id');
  const starterPlan = allPlans.find(p => p.name === 'Startup');
  const proPlan = allPlans.find(p => p.name === 'Pro');

  let subscribedPlanId =
    get(subscription, 'plan_id') || get(starterPlan, 'plan_id');

  const isMobile = useBreakpoint('md', 'down');

  const isStarter = subscribedPlanId === get(starterPlan, 'plan_id');
  const isPro = subscribedPlanId === get(proPlan, 'plan_id');

  const shouldShowStarterPlan =
    !isMobile || (isMobile && activeTab === 'starter');
  const shouldShowProPlan = !isMobile || (isMobile && activeTab === 'pro');
  const shouldShowEnterprisePlan =
    !isMobile || (isMobile && activeTab === 'enterprise');

  React.useEffect(() => {
    if (isPro) setActiveTab('pro');
    if (isStarter) setActiveTab('starter');
  }, [isPro, isStarter]);

  return {
    queryParams,
    companyId,
    subId,
    starterPlan,
    proPlan,
    isMobile,
    isStarter,
    isPro,
    shouldShowStarterPlan,
    shouldShowProPlan,
    shouldShowEnterprisePlan,
    loadingPlan,
    openingStripe,
    activeTab,
    setLoadingPlan,
    setOpeningStripe,
    setActiveTab,
  };
};
