import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

export const validatePassword = (value = '') => {
  // Note: We can use regex /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&]).{8})/ to validate password
  // instead of below logic, but regex contains look ahead operations and it is not supporting in safari as of now
  // until safari and IE doesnt support it, we will use below logic

  if (value.length < 8) {
    // password should be atleast 8 characters
    return false;
  }
  if (value.search(/[a-z]/) < 0) {
    // password should have atleast 1 lowercase character
    return false;
  }
  if (value.search(/[A-Z]/) < 0) {
    // password should have atleast 1 uppercase character
    return false;
  }
  if (value.search(/[0-9]/) < 0) {
    // password should have atleast 1 digit
    return false;
  }
  if (value.search(/[~`!@#$%^&*(),./;'[\]|}{":?><_+\-= ]/) < 0) {
    // password should have atleast 1 special character
    return false;
  }
  return true;
};

/**
 * This function will allow us to download the file
 * @param file - Response of /download endpoint which is file data
 * @param fileName - Name of the file
 */
export const fileDownload = (file, fileName) => {
  const url = window.URL.createObjectURL(new Blob([file.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

/**
 * Converts the bytes value to readable format
 * @param bytes - Value to be converted
 * @returns {string}
 */
export const byteToReadbleSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const fileExtensionList = [
  'ai',
  'aif',
  'apk',
  'arj',
  'asp',
  'bat',
  'bin',
  'bmp',
  'cda',
  'cer',
  'cfm',
  'cgi',
  'com',
  'css',
  'csv',
  'dat',
  'db',
  'deb',
  'doc',
  'docx',
  'exe',
  'fnt',
  'fon',
  'gif',
  'html',
  'ico',
  'jar',
  'jpg',
  'js',
  'jsp',
  'key',
  'log',
  'mdb',
  'mid',
  'mp3',
  'mpa',
  'odp',
  'ogg',
  'otf',
  'pdf',
  'php',
  'pkg',
  'png',
  'pps',
  'ppt',
  'ps',
  'psd',
  'py',
  'rar',
  'rpm',
  'rss',
  'rtf',
  'sav',
  'sql',
  'svg',
  'tar',
  'tex',
  'tif',
  'ttf',
  'txt',
  'wav',
  'wks',
  'wma',
  'wpd',
  'wpl',
  'wsf',
  'xhtml',
  'xls',
  'xml',
  'z',
  'zip',
];
export const imageFileExtensionList = [
  'ai',
  'bmp',
  'gif',
  'ico',
  'jpg',
  'png',
  'psd',
  'ps',
  'svg',
  'tif',
];

export const generateRandomNumber = (digit = 5) => {
  let min = Math.pow(10, digit - 1); // 10000;
  let max = Math.pow(10, digit) - 1; // 99999;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getUniqDataInOrder = (
  data,
  uniqueField = 'id',
  orderField = 'id',
  order = 'asc'
) => uniqBy([...orderBy([...data], orderField, order)], uniqueField);
