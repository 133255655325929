import createTypes from 'redux-create-action-types';

export default createTypes(
  'DELETE_PROJECT_INPROGRESS',
  'DELETE_PROJECT_SUCCESS',
  'DELETE_PROJECT_FAILURE',
  'FETCH_PROJECTS_INPROGRESS',
  'FETCH_PROJECTS_SUCCESS',
  'FETCH_PROJECTS_FAILURE',
  'CREATE_PROJECT_INPROGRESS',
  'CREATE_PROJECT_SUCCESS',
  'CREATE_PROJECT_FAILURE',
  'UPDATE_PROJECT_INPROGRESS',
  'UPDATE_PROJECT_SUCCESS',
  'UPDATE_PROJECT_FAILURE',
  'GET_PROJECT_INPROGRESS',
  'GET_PROJECT_SUCCESS',
  'GET_PROJECT_FAILURE',
  'GET_PROJECT_RESET',
  'FETCH_PROJECT_METADATA_SUCCESS',
  'FETCH_PROJECT_METADATA_INPROGRESS',
  'FETCH_PROJECT_METADATA_FAILURE',
  'FETCH_PROJECT_ACTIVITIES_INPROGRESS',
  'FETCH_PROJECT_ACTIVITIES_SUCCESS',
  'FETCH_PROJECT_ACTIVITIES_FAILURE',
  'FETCH_MORE_PROJECT_ACTIVITIES_INPROGRESS',
  'FETCH_MORE_PROJECT_ACTIVITIES_SUCCESS',
  'FETCH_MORE_PROJECT_ACTIVITIES_FAILURE',
  'DUPLICATE_PROJECT_INPROGRESS',
  'DUPLICATE_PROJECT_SUCCESS',
  'DUPLICATE_PROJECT_FAILURE'
);
