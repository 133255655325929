import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { updateStoryPriorities } from 'store/actions/backlogs';
import StoryPriority from 'components/StoryPriority';
import { analyticsConstants } from 'helpers/analytics';
const StoryPriorityDropdown = ({
  story,
  sectionId,
  isStoryEditAllowed,
  analyticsSendEventStory,
}) => {
  const dispatch = useDispatch();
  const storyId = get(story, 'id');
  const priority = get(story, 'priority', null);
  const complexity = get(story, 'complexity', null);
  const score = get(story, 'score', null);
  const priorities = useSelector(({ story }) =>
    get(story, 'priorityOptions.data', [])
  );
  const complexities = useSelector(({ story }) =>
    get(story, 'complexityOptions.data', [])
  );

  const scoreMatrix = useSelector(({ story }) =>
    get(story, 'scoreMatrix.data', [])
  );

  return (
    <StoryPriority
      size="xs"
      onChange={data => {
        analyticsSendEventStory({
          action: analyticsConstants.action.update_story_priority,
          update_from: 'Project Backlog',
        });
        dispatch(
          updateStoryPriorities(
            storyId,
            { ...data, score: story.score },
            sectionId
          )
        );
      }}
      priorities={priorities}
      complexities={complexities}
      scoreMatrix={scoreMatrix}
      priority={priority}
      complexity={complexity}
      score={score}
      isEditAllowed={isStoryEditAllowed}
    />
  );
};

export default StoryPriorityDropdown;
