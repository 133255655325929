import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../DataArea.module.scss';
import cs from 'classnames';
import BaseTable from 'components/Table';
import { Card, CardTitle, CardBody } from 'reactstrap';
import get from 'lodash/get';

import {
  doPostDataDetails,
  doGetBarChart,
  doGetDoughnutChart,
} from 'store/actions/timelogReportActions';
import { postExportDetailReport } from 'api/timelogReportApi';

import TimerModal from 'components/TimerModal';
import Button from 'components/Button';
import moment from 'moment-timezone';

import useDebounce from 'react-use/lib/useDebounce';
import { StoryCell, TimeCell } from './Cells';
import { analyticsConstants } from 'helpers/analytics';

const DetailsTab = props => {
  const dispatch = useDispatch();
  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(null);
  const [entryId, setEntryId] = useState(null);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [sortBy, setSortBy] = useState({
    dataField: 'date_start',
    order: 'desc',
  });

  const details = useSelector(({ timelogReport }) =>
    get(timelogReport, 'details')
  );

  const entries = useSelector(({ timelogReport }) =>
    get(timelogReport, 'details.data.data', [])
  );

  const loading = get(details, 'loading', false);
  const entriesMetadata = get(details, 'data.meta', {});

  const values = get(props.formik, 'values');

  useEffect(() => {
    setPage(1);
    setCount(count + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useDebounce(
    () => {
      const sort =
        sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;

      getData(page, sort);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    200,
    [page, sortBy.dataField, sortBy.order, count]
  );

  const getPostData = useCallback(() => {
    const users = values.users.map(item => item.id);
    const clients = values.clients.map(item => item.id);
    const themes = values.themes.map(item => item.id);
    const initiatives = values.initiatives.map(item => item.id);
    const epics = values.epics.map(item => item.id);
    const stories = values.stories.map(item => item.id);
    const tasks = values.tasks.map(item => item.name);
    const date_from = values.startDate
      ? values.startDate.format('YYYY/MM/DD')
      : null;
    const date_to = values.endDate ? values.endDate.format('YYYY/MM/DD') : null;
    const billable = get(values, 'billable.id') ? [values.billable.text] : null;
    const description = values.description;

    return {
      users,
      clients,
      themes,
      initiatives,
      epics,
      stories,
      tasks,
      date_from,
      date_to,
      billable,
      description,
    };
  }, [values]);

  const getData = async (page, sort) => {
    if (values) {
      dispatch(doPostDataDetails(getPostData(), sort, page));
    }
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort" />;
    else if (order === 'asc') return <i className="fas fa-sort-up" />;
    else if (order === 'desc') return <i className="fas fa-sort-down" />;
    return null;
  };

  const NoDataIndication = () => {
    return (
      <div className={cs('d-flex align-items-center')}>
        <div className="d-flex justify-content-between align-items-center flex-column w-100">
          <h4 className="display-4 mb-4 text-center px-2">No Timelog Entry</h4>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (values) {
      dispatch(doGetBarChart(getPostData()));
      dispatch(doGetDoughnutChart(getPostData()));
    }
  }, [count, dispatch, getPostData, values]);

  const exportData = async () => {
    if (values) {
      const sort =
        sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;

      const users = values.users.map(item => item.id);
      const clients = values.clients.map(item => item.id);
      const themes = values.themes.map(item => item.id);
      const initiatives = values.initiatives.map(item => item.id);
      const epics = values.epics.map(item => item.id);
      const stories = values.stories.map(item => item.id);
      const tasks = values.tasks.map(item => item.name);
      const date_from = values.startDate
        ? values.startDate.format('YYYY/MM/DD')
        : null;
      const date_to = values.endDate
        ? values.endDate.format('YYYY/MM/DD')
        : null;
      const billable = get(values, 'billable.id')
        ? [values.billable.text]
        : null;
      const description = values.description;

      let postDataGroup = {
        users,
        clients,
        themes,
        initiatives,
        epics,
        stories,
        tasks,
        date_from,
        date_to,
        billable,
        description,
      };

      postExportDetailReport(postDataGroup, sort);
    }
  };

  return (
    <div className={classes.details}>
      <Card>
        <CardBody className="pb-3">
          <CardTitle className="h3 mb-0 d-flex justify-content-between">
            Details{' '}
            <Button
              leftIcon={<i className="fas fa-file-export"></i>}
              size="sm"
              color="primary"
              onClick={() => {
                props.analyticsSendEvent({
                  action: analyticsConstants.action.export_timelog_report,
                });

                exportData();
              }}
            >
              Export
            </Button>
          </CardTitle>
        </CardBody>
        <BaseTable
          keyField="id"
          defaultSorted={[sortBy]}
          search={false}
          noDataIndication={NoDataIndication}
          bootstrap4
          remote
          bordered={false}
          loading={loading}
          paginationOptions={{
            sizePerPage: 10,
            page: page,
            totalSize: entriesMetadata.total,
          }}
          data={entries}
          columns={[
            {
              dataField: 'user_name',
              text: 'User',
              sort: true,
              sortCaret: renderSortCaret,
              formatter: (cell, row) => {
                return (
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <b>{row.user.name}</b>
                  </div>
                );
              },
            },
            {
              dataField: 'initiative',
              text: 'Project',
              sort: true,
              style: () => {
                return {
                  whiteSpace: 'normal',
                  minWidth: '200px',
                };
              },
              sortCaret: renderSortCaret,
              formatter: (cell, row) => {
                return (
                  <div className="">
                    {row.initiative.id ? (
                      <button className="btn-link btn p-0 text-left">
                        {row.initiative.name}
                      </button>
                    ) : (
                      <button className="btn-link btn p-0 text-left">
                        <i>{row.initiative.name}</i>
                      </button>
                    )}
                  </div>
                );
              },
            },
            {
              dataField: 'story',
              text: 'Story',
              sort: true,
              style: () => {
                return {
                  whiteSpace: 'normal',
                  minWidth: '400px',
                  width: '600px',
                };
              },
              headerAttrs: { width: '400px' },
              sortCaret: renderSortCaret,
              formatter: (cell, row) => <StoryCell row={row} cell={cell} />,
            },
            {
              dataField: 'date_start',
              text: 'Date',
              sort: true,
              sortCaret: renderSortCaret,
              formatter: (cell, row) => (
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-muted">
                    {moment(cell)
                      .tz(timezone)
                      .format('MMM/DD')}
                  </span>
                </div>
              ),
            },
            {
              dataField: 'duration',
              text: 'Time',
              sort: true,
              attrs: { width: '200px' },
              headerAttrs: { width: '200px' },
              sortCaret: renderSortCaret,
              formatter: (cell, row) => (
                <TimeCell
                  row={row}
                  cell={cell}
                  reload={() => setCount(count + 1)}
                  openTimeModal={id => {
                    setMode('edit');
                    setEntryId(id);
                    toggle();
                  }}
                  analyticsSendEvent={props.analyticsSendEvent}
                />
              ),
            },
          ]}
          onTableChange={(
            type,
            { page, sizePerPage, sortOrder, sortField, searchText, ...rest }
          ) => {
            if (type === 'pagination') {
              setPage(page);
            } else if (type === 'sort') {
              setSortBy({
                dataField: sortField,
                order: sortOrder,
              });
            }
          }}
        />
      </Card>

      {isOpen && (
        <TimerModal
          entryId={entryId}
          isOpen={isOpen}
          toggle={toggle}
          mode={mode}
          source="Timelog Report"
          success={() => {
            toggle();
            setCount(count + 1);
          }}
        />
      )}
    </div>
  );
};

export default DetailsTab;
