import Request from './request';

export const fetchEpics = async id => {
  return Request.call({
    url: `/initiatives/${id}/epics`,
    method: 'GET',
  });
};

export const createEpic = async (id, name) => {
  return Request.call({
    url: `/initiatives/${id}/epics`,
    method: 'POST',
    data: {
      name,
    },
  });
};

export const removeEpic = async (id, data) => {
  return Request.call({
    url: `/epics/${id}`,
    method: 'DELETE',
    data,
  });
};

export const reOrderEpic = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}/epics/prioritize`,
    method: 'POST',
    data,
  });
};

export const editEpic = async (id, data) => {
  return Request.call({
    url: `/epics/${id}`,
    method: 'PUT',
    data,
  });
};
