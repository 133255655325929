import React from 'react';
import classes from './CreateSprint.module.scss';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import DatePicker from 'components/FormFields/DatePicker';
import FormikInput from 'components/FormFields/Input/FormikInput';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import cs from 'classnames';
import * as moment from 'moment';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  date_start: Yup.date().required('Start Date is Required'),
  date_end: Yup.date().required('End Date is required'),
});

const CreateSprint = ({
  isOpen,
  closeModal,
  viewMode,
  submitValues,
  sprintValues,
  isLoading,
}) => {
  const renderCreateSprintForm = ({
    handleSubmit,
    isValid,
    handleReset,
    setFieldValue,
    values,
  }) => {
    return (
      <Form onSubmit={handleSubmit} role="form">
        <ModalBody>
          <FormikInput placeholder="Sprint Name" name="name" />
          <DatePicker
            name="date_start"
            placeholder="Sprint Start Date"
            className="mb-4"
            value={values.date_start}
            isValidDate={current =>
              current.isAfter(moment().subtract(1, 'day')) &&
              (values.date_end
                ? current.isBefore(moment(values.date_end))
                : true)
            }
            renderInput={(props, openCalendar) => {
              return (
                <div className={classes.dateWrapper}>
                  <input {...props} />
                  <i
                    className={cs('fas fa-calendar', classes.icon)}
                    onClick={openCalendar}
                  />
                </div>
              );
            }}
            onChange={date => {
              moment(date).isValid()
                ? setFieldValue('date_start', moment(date).format('YYYY/MM/DD'))
                : setFieldValue('date_start', '');
            }}
          />
          <DatePicker
            name="date_end"
            placeholder="Sprint End Date"
            value={values.date_end}
            isValidDate={current => {
              const date = values.date_start
                ? moment(values.date_start, 'YYYY/MM/DD')
                : moment();
              return current.isAfter(date);
            }}
            renderInput={(props, openCalendar) => {
              return (
                <div className={classes.dateWrapper}>
                  <input {...props} />
                  <i
                    className={cs('fas fa-calendar', classes.icon)}
                    onClick={openCalendar}
                  />
                </div>
              );
            }}
            onChange={date => {
              moment(date).isValid()
                ? setFieldValue('date_end', moment(date).format('YYYY/MM/DD'))
                : setFieldValue('date_end', '');
            }}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between pt-0">
          <Button
            onClick={() => {
              handleReset();
              closeModal();
            }}
            color="link"
            className={cs('float-right', classes.cancelButton)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={cs('float-left', classes.createButton)}
            disabled={!isValid || isLoading}
            color={!isValid || isLoading ? 'secondary' : 'primary'}
            loading={isLoading}
          >
            {viewMode ? 'Save Changes' : 'Create Sprint'}
          </Button>
        </ModalFooter>
      </Form>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      title={viewMode ? 'Edit Sprint' : 'Create A Sprint'}
      size="md"
      className={classes.createSprintModal}
    >
      <Formik
        validateOnMount
        initialValues={
          sprintValues
            ? sprintValues
            : {
                name: '',
                date_start: '',
                date_end: '',
              }
        }
        validationSchema={schema}
        enableReinitialize
        onSubmit={values => submitValues(values)}
      >
        {renderCreateSprintForm}
      </Formik>
    </Modal>
  );
};

export default CreateSprint;
