import React from 'react';
import classes from './InitialRegister.module.scss';
import Register from 'views/pages/Auth/Register';
import ViewProject from 'views/pages/Project/ViewProject';
import { mockUpData, childMockUpData } from './mocks';
import Modal from 'components/FormFields/Modal';
import cs from 'classnames';
import { ModalHeader, CardBody } from 'reactstrap';
import RegisterForm from '../Register/Register.Form';
const InitialRegister = () => {
  return (
    <div className={classes.container}>
      <Modal noHeader isOpen className={classes.registerFormModal}>
        <CardBody className={cs(classes.cardBody, 'p-lg-5 bg-gray-100')}>
          <div className="d-flex flex-column align-items-center justify-content-center text-center">
            <h1 className="m-0 font-weight-700">Create an account</h1>
            <p className="mb-5">
              Sign up for a free trial, with no obligations or credit card
              required.
            </p>
          </div>
          <RegisterForm
            setSentEmail={() => {}}
            setStep={() => {}}
            initialRegister
          />
        </CardBody>
      </Modal>
      <ViewProject
        isMockUp={true}
        mockUpPage="boards"
        mockUpData={mockUpData}
        childMockUpData={childMockUpData}
      />
    </div>
  );
};

export default InitialRegister;
