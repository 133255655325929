import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_USERS_INPROGRESS',
  'FETCH_USERS_SUCCESS',
  'FETCH_USERS_FAILURE',
  'UPDATE_USERS_DATA',
  'FETCH_USER_ROLES_INPROGRESS',
  'FETCH_USER_ROLES_SUCCESS',
  'FETCH_USER_ROLES_FAILURE',
  'UPDATE_USER_ROLE_INPROGRESS',
  'UPDATE_USER_ROLE_SUCCESS',
  'UPDATE_USER_ROLE_FAILURE',
  'INVITE_MEMBERS_INPROGRESS',
  'INVITE_MEMBERS_SUCCESS',
  'INVITE_MEMBERS_FAILURE'
);
