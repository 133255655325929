import React from 'react';
import classes from '../StoryModal.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import EpicSelect from 'components/EpicSelect';

const Epic = ({ onChange, epic, isEditAllowed, projectId }) => {
  const handleEpicClick = selectedEpic => {
    onChange(selectedEpic);
  };

  return (
    <>
      <h4>Epic</h4>
      <EpicSelect
        id="story-epic-selector"
        onChange={handleEpicClick}
        projectId={projectId}
        disabled={!isEditAllowed}
        epic={epic}
        noEpicPlaceHolder={
          isEditAllowed ? (
            <div
              className={classnames(
                classes['epic-name'],
                classes['empty-content'],
                'rounded'
              )}
              id="epic-target"
            >
              <p className="p-2 text-light">Link story to an Epic</p>
            </div>
          ) : (
            <p className="text-muted">No Epic</p>
          )
        }
      />
    </>
  );
};

Epic.propTypes = {
  epic: PropTypes.object,
  isEditAllowed: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

Epic.defaultProps = {
  epic: null,
  isEditAllowed: false,
};

export default Epic;
