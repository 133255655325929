import { storePushSubscription } from 'api/profile';

class WebPushNotifications {
  initPushNotifications() {
    if (
      !('Notification' in window) ||
      !('serviceWorker' in navigator) ||
      !('PushManager' in window)
    ) {
      return;
    }

    let self = this;
    navigator.serviceWorker
      .register('../../lyght-notifications-sw.js')
      .then(() => {
        self.askUserPermission();
      })
      .catch(err => {
        console.warn('Registering service worker failed', err);
      });
  }

  askUserPermission() {
    if (!navigator.serviceWorker.ready) {
      return;
    }

    let self = this;
    new Promise(function(resolve, reject) {
      const permissionResult = Notification.requestPermission(function(result) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(permissionResult => {
      if (permissionResult !== 'granted') {
        console.warn('User denied access for push notifications');
      }

      self.subscribeUser();
    });
  }

  subscribeUser() {
    let self = this;
    navigator.serviceWorker.ready
      .then(registration => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: self.urlBase64ToUint8Array(
            process.env.REACT_APP_VAPID_PUBLIC_KEY || ''
          ),
        };

        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then(pushSubscription => {
        storePushSubscription(JSON.stringify(pushSubscription));
      })
      .catch(err => {
        console.error('Push manager subscription failed', err);
      });
  }

  urlBase64ToUint8Array(base64String) {
    let padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    let base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }
}

export default new WebPushNotifications();
