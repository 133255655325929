import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRoles, inviteMembers } from 'store/actions/users';
import UpgradeAlert from 'components/UpgradeAlert';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import InviteUserForm from 'views/pages/Users/InviteUser.Form';

const InviteUserFromSidebar = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_management,
      ...rest,
    });
  };

  const handleModalClose = () => {
    closeModal(false);
  };
  const submitValues = async values => {
    setIsLoading(true);
    const { email, role, projects } = values;
    const allEmails = email.split(',').filter(f => f.trim());
    const data = {
      roles: [role.name],
      initiatives: projects.map(p => p.id),
    };
    data.email_addresses = allEmails;
    analyticsSendEvent({
      action: analyticsConstants.action.invite_users,
      number_users: allEmails.length,
    });

    const { errorStatus } = await dispatch(inviteMembers(data));
    setIsLoading(false);
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showTeamLimit();
      return;
    }
    handleModalClose();
  };

  return (
    <InviteUserForm
      isModalOpen
      submitValues={submitValues}
      closeModal={handleModalClose}
      editValues={null}
      isLoading={isLoading}
    />
  );
};

export default InviteUserFromSidebar;
