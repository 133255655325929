import React from 'react';
import { useSelector } from 'react-redux';
import RecentlyViewedProjectsWidget from 'views/widgets/RecentlyViewedProjects';
import get from 'lodash/get';
import NoProjectsAssigned from 'assets/img/theme/No_Project_Assigned_Client.svg';

const RecentlyViewedProjects = () => {
  const recentlyViewedProjects = useSelector(({ clientProfile }) =>
    get(clientProfile, 'viewedProjects.data', [])
  );
  const loading = useSelector(({ clientProfile }) =>
    get(clientProfile, 'recentlyViewedProjects.isInProgress', false)
  );
  return (
    <RecentlyViewedProjectsWidget
      title="Projects"
      placeHolderImg={NoProjectsAssigned}
      data={recentlyViewedProjects}
      loading={loading}
    />
  );
};

export default RecentlyViewedProjects;
