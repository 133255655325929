import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import cs from 'classnames';
import classes from './QuickStartTour.module.scss';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import { getSteps } from './TourHelpers';
import analytics, { analyticsConstants } from 'helpers/analytics';

const Tours = () => {
  const dispatch = useDispatch();
  const [arrowClass, setArrowClass] = useState('');
  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  useEffect(() => {
    if (quickStartTour && !quickStartTour.activeTour) {
      const html = document.getElementsByTagName('html')[0];
      if (html && html.classList.contains('hide-scrollbar')) {
        html.classList.remove('hide-scrollbar');
      }
    }
  }, [quickStartTour]);

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const assignedProjects = useSelector(({ auth }) =>
    get(auth, 'user.assigned_initiatives', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  if (!quickStartTour.activeTour) {
    return null;
  }

  const handleDismiss = () => {
    if (
      quickStartTour.activeTour === 'project_creation' ||
      quickStartTour.withCreateProject
    ) {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action.complete_create_a_project_onboarding_flow,
      });
    }

    if (quickStartTour.activeTour === 'story_creation') {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action.complete_create_a_story_onboarding_flow,
      });
    }

    if (quickStartTour.activeTour === 'view_projects') {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action.complete_view_projects_onboarding_flow,
      });
    }

    if (quickStartTour.activeTour === 'view_personal_kanban') {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action
            .complete_view_personal_kanban_onboarding_flow,
      });
    }

    dispatch(
      setUserPreference({
        ...onBoardingTour,
        value: {
          ...get(onBoardingTour, 'value', {}),
          status: 'Active',
          ...(!quickStartTour.isRestarted
            ? {
                steps: {
                  ...get(onBoardingTour, 'value.steps', {}),
                  [`${quickStartTour.activeTour}`]: {
                    status: 'Completed',
                  },
                  ...(quickStartTour.activeTour === 'story_creation' &&
                  quickStartTour.withCreateProject
                    ? {
                        project_creation: {
                          status: 'Completed',
                        },
                      }
                    : {}),
                },
              }
            : {}),
        },
      })
    );
    dispatch(
      updateActiveTourStatus({
        activeTour: null,
        step: null,
        isRestarted: false,
        withCreateProject: false,
      })
    );
    didAllOnboardingFlowCompleted();
  };
  const Tooltip = ({ step, tooltipProps }) => {
    return (
      <div
        className={cs(classes.tooltip, step.extraPlacementClass)}
        {...tooltipProps}
      >
        <div className={cs(classes.arrow, classes[arrowClass])} />
        <div className="d-flex justify-content-between align-items-center w-100">
          {step.title && (
            <div className={classes.tooltipTitle}>{step.title}</div>
          )}
          {step.shouldShowDismiss && (
            <div className={classes.dismiss} onClick={handleDismiss}>
              Dismiss
            </div>
          )}
        </div>
        {step.content && (
          <div className={classes.tooltipContent}>{step.content}</div>
        )}
      </div>
    );
  };

  return (
    <Joyride
      run={!!quickStartTour.activeTour}
      stepIndex={quickStartTour.step}
      disableScrollParentFix={true}
      scrollOffset={200}
      continuous={true}
      showProgress={false}
      disableOverlayClose={true}
      disableCloseOnEsc={true}
      floaterProps={{
        disableAnimation: true,
        disableFlip: true,
      }}
      showSkipButton={false}
      tooltipComponent={Tooltip}
      steps={getSteps(quickStartTour.activeTour, {
        assignedProjects,
        role: onBoardingTour.key,
      })}
      callback={value => {
        if (get(value, 'step.placement')) setArrowClass(value.step.placement);
      }}
      styles={{
        options: {
          zIndex: 999999,
          width: '240px',
        },
      }}
    />
  );
};

export default Tours;
