import React from 'react';
import Button from 'components/Button';
import { useAccess, permissions } from 'helpers/permission';

const TeamsTableHeader = ({ teamMembers, openModal }) => {
  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div className="d-flex align-items-center">
        <h3 className="mb-0">Team Members</h3>
        <span className="ml-3 badge badge-info badge-circle">
          {teamMembers.length}
        </span>
      </div>
      {useAccess(permissions.ADD_TEAM_MEMBER) ? (
        <Button
          color="primary"
          size="sm"
          type="button"
          onClick={openModal}
          id="addTeamMemberBtn"
        >
          <i className="fas fa-user-plus mr-2" />
          Add Members
        </Button>
      ) : null}
    </div>
  );
};

export default TeamsTableHeader;
