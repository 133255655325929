import Request from './request';

export const fetchTimezones = async () => {
  return Request.call({
    url: 'meta/dropdowns?list=timezones',
    method: 'GET',
  });
};

export const getDropdown = param => {
  return Request.call({
    url: `/meta/dropdowns`,
    method: 'GET',
    params: {
      list: param,
    },
  });
};
