import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cs from 'classnames';
import AuthHeader from 'components/Headers/AuthHeader';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import classes from './Deactivated.module.scss';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import { createOwnCompany } from 'store/actions/authActions';

const Deactivated = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('bg-default');
  }, []);

  const handleCreateCompany = async () => {
    setLoading(true);
    await dispatch(createOwnCompany(user.id));
    setLoading(false);
  };

  return (
    <div className="main-content">
      <AuthNavbar />
      <AuthHeader
        title="Welcome Back!"
        lead="Looks like you have not joined any teams."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={cs('border-0 mb-0', classes.deactivated)}>
              <CardBody className="px-lg-5 py-lg-5">
                <div className={classes.content}>
                  <div className="text-center text-muted mb-4">
                    <Avatar
                      url={user ? user.avatar : ''}
                      className={classes.avatar}
                    />
                  </div>
                  <h4 className={cs('text-center w-75', classes.bold)}>
                    You can create your own Company Account here to get started.
                  </h4>
                  <Button
                    className="mt-4"
                    color="info"
                    onClick={handleCreateCompany}
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    Create Account
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Row className="mt-2">
              <Col xs="12">
                <span className="text-light d-flex text-center disabled px-2">
                  Please contact your system administrator if you should already
                  be part of a team.
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Deactivated;
