import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import classes from './StoryDetails.module.scss';
import {
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Badge,
} from 'reactstrap';
import useBreakPoint from 'helpers/useBreakPoint';
import findIndex from 'lodash/findIndex';
import StoryInfo from './Tabs/Info/StoryInfo';
import StoryComments from './Tabs/Comments/StoryComments';
import Can from 'components/Can';
import { useAccess, permissions } from 'helpers/permission';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Loading from 'components/Loading';
import StoryActivity from './Tabs/Activity/StoryActivity';
import { analyticsConstants } from 'helpers/analytics';

const StoryDetails = ({
  projectId,
  storyId,
  currentTab,
  comment,
  owner,
  projectName,
  priority,
  createdBy,
  updatedOn,
  createdOn,
  status,
  assignStoryOwner,
  isUserAllowedEdit,
  changeStatus,
  complexity,
  complexities,
  scoreMatrix,
  score,
  priorities,
  changePriority,
  onTabChange,
  analyticsSendEvent,
}) => {
  const comments = useSelector(({ story }) =>
    get(story, 'comments.storyComments.data.data', [])
  );
  const isInitialCall = useSelector(({ story }) =>
    get(story, 'comments.storyComments.isInitialCall', false)
  );
  const unreadFrom = useSelector(({ story }) =>
    get(story, 'comments.storyComments.unreadFrom', null)
  );

  const getCommentsCount = () => {
    if (unreadFrom) {
      const foundIndex = comments.findIndex(c => c.id === unreadFrom);
      if (foundIndex > -1) {
        return comments.length - foundIndex;
      } else {
        return 0;
      }
    }
    return 0;
  };

  const contents = [
    ...(useAccess([permissions.VIEW_COMMENTS])
      ? [
          {
            name: 'Comments',
            iconClassName: 'fas fa-comments',
            gradientColor: 'orange',
            pageLink: 'comments',
            showBadge: true,
            count: getCommentsCount(),
            gaEventTag: analyticsConstants.action.view_story_comments,
          },
        ]
      : []),
    {
      name: 'Activity',
      iconClassName: 'ni ni-chart-bar-32',
      gradientColor: 'info',
      pageLink: 'activity',
      gaEventTag: analyticsConstants.action.view_story_activity,
    },
    {
      name: 'Info',
      iconClassName: 'fas fa-info-circle',
      gradientColor: 'red',
      pageLink: 'info',
      gaEventTag: analyticsConstants.action.view_story_info,
    },
  ];

  useEffect(() => {
    const tabIndex = findIndex(contents, obj => obj.pageLink === currentTab);
    analyticsSendEvent({
      action:
        analyticsConstants.action[
          contents[tabIndex > -1 ? tabIndex : 0].gaEventTag
        ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentTab = () => {
    const tabIndex = findIndex(contents, obj => obj.pageLink === currentTab);
    return tabIndex > -1 ? contents[tabIndex].pageLink : contents[0].pageLink;
  };

  const isMobile = useBreakPoint('xs', 'down');
  const [tabs, setTabs] = useState(getCurrentTab());

  const toggleTabs = tab => {
    setTabs(tab);
    onTabChange(tab);
    // history.push(`?story=${storyId}&tab=${tab}`);
  };
  return (
    <Col
      className={cs(
        classes['right-column'],
        'pb-3 d-flex flex-column',
        isMobile ? 'border-top' : 'border-left',
        { 'w-100': isMobile }
      )}
    >
      <div className={classes.tabWrapper}>
        <Nav className="nav-fill flex-row" id="tabs-icons-text" pills>
          {contents.map(content => (
            <NavItem key={content.pageLink} className={classes.tabItem}>
              <NavLink
                className={
                  tabs === content.pageLink ? 'text-primary' : 'text-muted'
                }
                onClick={() => {
                  analyticsSendEvent({
                    action: analyticsConstants.action[content.gaEventTag],
                  });
                  toggleTabs(content.pageLink);
                }}
                href="#"
                role="tab"
              >
                <i className={content.iconClassName} />
                {content.name}
                {content.showBadge && content.count ? (
                  <Badge
                    color="danger"
                    className={cs(
                      'badge-sm badge-circle badge-floating border-white',
                      classes.badgeCount
                    )}
                  >
                    {content.count > 99 ? '99+' : content.count}
                  </Badge>
                ) : null}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <TabContent activeTab={tabs} className="h-100">
        <Can permissions={[permissions.VIEW_COMMENTS]}>
          <TabPane tabId="comments" className="h-100">
            {/* Once initial call is made we will render component other wise loader*/}
            {isInitialCall ? (
              <Loading />
            ) : (
              <StoryComments
                projectId={projectId}
                storyId={storyId}
                commentToScroll={comment}
              />
            )}
          </TabPane>
        </Can>
        <TabPane tabId="activity" className="h-100">
          <StoryActivity
            storyId={storyId}
            updatedOn={updatedOn}
            createdOn={createdOn}
          />
        </TabPane>
        <TabPane tabId="info" className="h-100">
          <StoryInfo
            owner={owner}
            projectName={projectName}
            priority={priority}
            createdBy={createdBy}
            updatedOn={updatedOn}
            createdOn={createdOn}
            status={status}
            assignStoryOwner={assignStoryOwner}
            isUserAllowedEdit={isUserAllowedEdit}
            changeStatus={changeStatus}
            complexity={complexity}
            complexities={complexities}
            scoreMatrix={scoreMatrix}
            score={score}
            priorities={priorities}
            changePriority={changePriority}
          />
        </TabPane>
      </TabContent>
    </Col>
  );
};

export default StoryDetails;
