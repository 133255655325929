/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { fetchTimeSpent } from 'store/actions/profile';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import TimeLoggedGraph from 'views/widgets/TimeLoggedGraph';

const timeSpent = ({ userId }) => {
  const dispatch = useDispatch();
  const getData = useSelector(({ timeSpent }) =>
    get(timeSpent, 'timeSpentState.data', {})
  );
  const loading = useSelector(({ timeSpent }) =>
    get(timeSpent, 'timeSpentState.isInProgress', false)
  );

  useEffect(() => {
    dispatch(fetchTimeSpent(userId, 'week'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <TimeLoggedGraph
      data={{ ...getData.data }}
      timePeriod="week"
      loading={loading}
      onChange={async (timePeriod, startTime, endTime) => {
        await dispatch(fetchTimeSpent(userId, timePeriod, startTime, endTime));
      }}
      fieldName="project"
      title="Time Logged On Projects"
    />
  );
};

export default timeSpent;
