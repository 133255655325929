import moment from 'moment';

export const secondsToHHMMSS = secs => {
  let negative = false;
  if (secs < 0) {
    negative = true;
    secs = Math.abs(secs);
  }

  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  const time = [hours, minutes, seconds]
    .map(v => (v < 10 ? '0' + v : v))
    .filter((v, i) => v === '00' || i >= 0)
    .join(':');

  return negative ? `-${time}` : time;
};

export const getFormattedDate = (date, userTimezone) => {
  const NOW = moment().tz(userTimezone);
  const TODAY = NOW.clone().startOf('day');
  const YESTERDAY = NOW.clone()
    .subtract(1, 'days')
    .startOf('day');
  const A_WEEK_OLD = NOW.clone()
    .subtract(7, 'days')
    .startOf('day');

  if (
    moment(date)
      .tz(userTimezone)
      .isSame(TODAY, 'd')
  ) {
    return 'Today';
  } else if (
    moment(date)
      .tz(userTimezone)
      .isSame(YESTERDAY, 'd')
  ) {
    return 'Yesterday';
  } else if (
    moment(date)
      .tz(userTimezone)
      .isAfter(A_WEEK_OLD)
  ) {
    return moment(date)
      .tz(userTimezone)
      .format('dddd');
  } else {
    return moment(date).format('dddd, MMMM D, YYYY');
  }
};
