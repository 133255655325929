import React from 'react';
import { Card, CardHeader, CardBody, UncontrolledTooltip } from 'reactstrap';
import BaseTable from 'components/Table';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import classes from './RecentlyViewedProjects.module.scss';
import PropTypes from 'prop-types';
import NoViewedProjects from 'assets/img/theme/No_Viewed_Projects.svg';
import useBreakPoint from 'helpers/useBreakPoint';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { isEmpty } from 'lodash';

const RecentlyViewedProjects = ({
  data,
  loading,
  title,
  placeHolderImg,
  analyticsObj,
}) => {
  const isMobile = useBreakPoint('sm', 'down');

  return (
    <Card className={classes['viewed-projects']}>
      <CardHeader>
        <h3 className="mb-0">{title}</h3>
      </CardHeader>
      <CardBody
        className={cx(
          'p-0',
          !isMobile && classes['scroll'],
          classes['card-body']
        )}
      >
        <div className={classes.projectTitle}>Project</div>
        <BaseTable
          keyField="id"
          defaultSorted={null}
          noDataIndication={() => (
            <div className="h-100 d-flex flex-column justify-content-centre align-items-centre p-3">
              <img
                src={placeHolderImg}
                className={cx(classes.noDataImg, 'm-auto h-75', {
                  [classes.loading]: loading,
                })}
                alt={`No ${title}`}
              />
            </div>
          )}
          search={false}
          bordered={false}
          loading={loading}
          paginationOptions={false}
          data={data}
          classes="mb-0"
          wrapperClasses={classes.tableWrapper}
          columns={[
            {
              dataField: 'name',
              text: 'Project',
              sort: false,
              formatter: (cell, row) => (
                <>
                  <div
                    className={cx(
                      'd-flex align-items-center',
                      classes['project-name']
                    )}
                  >
                    <i
                      style={{
                        color: `#${row.color}`,
                        fontSize: '6px',
                      }}
                      className="fas fa-circle mr-1"
                    />
                    <Link
                      to={`/admin/projects/${row.id}`}
                      id={`project-name-${row.id}`}
                      onClick={() => {
                        if (!isEmpty(analyticsObj)) {
                          analytics.sendEvent({
                            type: analyticsConstants.action.view_project,
                            ...analyticsObj,
                          });
                        }
                      }}
                    >
                      {cell}
                    </Link>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target={`project-name-${row.id}`}
                    placement="bottom"
                    innerClassName={classes['tooltip']}
                    boundariesElement="viewport"
                  >
                    {cell}
                  </UncontrolledTooltip>
                </>
              ),
            },
          ]}
        />
      </CardBody>
    </Card>
  );
};

RecentlyViewedProjects.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  title: PropTypes.string,
  placeHolderImg: PropTypes.any,
  analyticsObj: PropTypes.object,
};
RecentlyViewedProjects.defaultProps = {
  data: [],
  title: 'Recently Viewed Projects',
  loading: false,
  placeHolderImg: NoViewedProjects,
  analyticsObj: {},
};

export default RecentlyViewedProjects;
