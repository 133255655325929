import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BudgetSpent from './BudgetSpent';
import Overview from './Overview';
import TimeLoggedGraphOnProject from './TimeLoggedGraphOnProject';
import LastWeekHours from './LastWeekHours';
import { Row, Col, Container } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import toNumber from 'lodash/toNumber';
import {
  getBudgetSpent,
  getSevenDaysHours,
  getTimeSpent,
} from 'store/actions/projectDashboard';
import get from 'lodash/get';
import classes from 'views/pages/Project/projects.module.scss';
import cx from 'classnames';
import RecentActivity from './RecentActivity';
import StoryModal from '../../Backlogs/Stories/StoryModal';
import { fetchStory } from 'store/actions/Story/details';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { fetchProjectActivities } from 'store/actions/projects';
import Loading from 'components/Loading';
import QuickActions from 'views/pages/QuickActions';
import { permissions, useAccess } from 'helpers/permission';
import DuplicateStoryForm from 'views/pages/Backlogs/Stories/DuplicateStory.Form';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import forEach from 'lodash/forEach';
import duplicateStoryFields from 'views/pages/Backlogs/Stories/duplicateStoryFields';
import some from 'lodash/some';
import { duplicateStoryForSection } from 'store/actions/backlogs';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const Dashboard = () => {
  const params = useParams();
  const id = get(params, 'id');
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.project_dashboard,
      ...rest,
    });
  };
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const canSeeAllProjectQuickActions = useAccess(
    permissions.VIEW_ALL_PROJECTS_QUICK_ACTION_WIDGET
  );
  const canSeeMyProjectQuickActions = useAccess(
    permissions.VIEW_MY_PROJECTS_QUICK_ACTION_WIDGET
  );
  const loggedInUserID = useSelector(({ auth }) => get(auth, 'user.id', null));

  const projectMembers = useSelector(({ project }) =>
    get(project, `getProject.data.data.team_members`, [])
  );
  const isMemberOfProject =
    projectMembers.findIndex(m => m.id === loggedInUserID) > -1;

  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_project_dashboard,
    });
    window.scrollTo(0, 0);
    const getAllDetails = async () => {
      dispatch(getSevenDaysHours(id));
      dispatch(getBudgetSpent(id));
      await dispatch(getTimeSpent(id));
      await dispatch(fetchProjectActivities(id, 1));
    };
    setIsDataLoading(true);
    getAllDetails();
    setIsDataLoading(false);
  }, [dispatch, id]);

  const history = useHistory();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [storyId, setStoryId] = useState('');
  const [count, setCount] = useState(0);
  const [comment, setComment] = useState(null);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);

  useEffect(() => {
    const getStoryDetail = async story => {
      const { status } = await dispatch(fetchStory(story));

      if (status === 1) {
        setStoryId(story);
        analyticsSendEventStory({
          action: analyticsConstants.action.open_story,
          opened_from: 'Project Dashboard Recent Activity',
        });
        setIsStoryModalOpen(true);
      }
    };

    const story = get(queryProps, 'story', '');
    if (story) {
      getStoryDetail(story);
      setComment(get(queryProps, 'comment', ''));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps.story]);

  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);
  const storyName = useGetFieldValue('name');

  const reload = () => setCount(count + 1);

  const closeDuplicateModal = () => setDuplicateModal(false);

  const openDuplicateModal = () => setDuplicateModal(true);

  const handleDuplicateStory = async values => {
    setDuplicating(true);
    const { data: duplicatedStory, status, errorStatus } = await dispatch(
      duplicateStoryForSection(storyId, values, null)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showStorageLimit();
      return;
    }
    if (status) {
      history.push(`/admin/projects/${duplicatedStory.initiative.id}/backlog`);
    }
    setDuplicating(false);
    setDuplicateModal(false);
  };

  return (
    <Container fluid className={cx(classes['dashboard'])}>
      {isDataLoading && <Loading wrapperClass={classes.loading} />}

      <Row>
        <Col md={4}>
          <Overview />
        </Col>
        <Col md={8}>
          {canSeeAllProjectQuickActions ||
          (canSeeMyProjectQuickActions && isMemberOfProject) ? (
            <Row>
              <Col sm={12}>
                <QuickActions
                  isMemberOfProject={isMemberOfProject}
                  projectId={id}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col sm={6}>
              <BudgetSpent />
            </Col>
            <Col sm={6}>
              <LastWeekHours />
            </Col>
          </Row>
          <TimeLoggedGraphOnProject projectId={id} />
          <RecentActivity projectId={id} />
        </Col>
      </Row>
      {isStoryModalOpen && (
        <StoryModal
          storyId={toNumber(storyId)}
          projectId={toNumber(id)}
          isOpen={isStoryModalOpen}
          closeModal={() => {
            setIsStoryModalOpen(false);
            history.push(`?`);
          }}
          currentTab={get(queryProps, 'tab', 'comments')}
          onChange={() => reload()}
          comment={comment}
          openDuplicateModal={openDuplicateModal}
        />
      )}
      {isDuplicateModal ? (
        <DuplicateStoryForm
          storyData={{ name: storyName }}
          isModalOpen={isDuplicateModal}
          closeModal={closeDuplicateModal}
          submitValues={handleDuplicateStory}
          isLoading={isDuplicating}
          openDuplicateModal={openDuplicateModal}
        />
      ) : null}
    </Container>
  );
};

export default Dashboard;
