import Request from './request';

export const postLogin = async (email, password) => {
  return Request.call({
    url: '/login',
    method: 'POST',
    data: { email, password },
  });
};

export const postLogout = async () => {
  return Request.call(
    {
      url: '/logout',
      method: 'POST',
    },
    true
  );
};

export const postRegister = async user => {
  return Request.call({
    url: '/register',
    method: 'POST',
    data: user,
  });
};

export const postEmailVerify = async data => {
  return Request.call({
    url: '/email/verify',
    method: 'POST',
    data,
  });
};

export const checkEmailVerify = async data => {
  return Request.call({
    url: '/validate-token?tokenType=email_verification',
    method: 'POST',
    data,
  });
};

export const postResendVerifyEmail = async data => {
  return Request.call({
    url: '/email/resend',
    method: 'POST',
    data,
  });
};

export const postEmailResetPassword = async email => {
  return Request.call({
    url: '/password/email',
    method: 'POST',
    data: { email },
  });
};

export const postResetPassword = async data => {
  return Request.call({
    url: '/password/reset',
    method: 'POST',
    data,
  });
};

export const getProfileUser = async () => {
  return Request.call({
    url: '/me',
    method: 'GET',
  });
};

export const acceptAndSetup = async (companyId, data) => {
  return Request.call({
    url: `/users/invitations/accept-and-setup/${companyId}`,
    method: 'POST',
    data,
  });
};

export const accept = async (id, data) => {
  return Request.call({
    url: `/users/invitations/accept/${id}`,
    method: 'POST',
    data,
  });
};

export const getInviteStatus = async (id, data) => {
  return Request.call({
    url: `/users/invitations/${id}/user-status`,
    method: 'POST',
    data,
  });
};

export const createCompany = id => {
  return Request.call({
    url: `/register/company/${id}`,
    method: 'POST',
  });
};
