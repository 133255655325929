import React, { useState } from 'react';
import Kanban from 'views/pages/Project/Kanban';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CreateSprint from 'views/pages/QuickActions/CreateSprint';
import { createSprint, updateSprint } from 'store/actions/sprint';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import classes from '../Backlogs.module.scss';
import get from 'lodash/get';
import { deleteSection } from 'store/actions/backlogs';
import { permissions, useAccess } from 'helpers/permission';

const SprintKanban = () => {
  const params = useParams();
  const { sprintID, id: projectId } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreateSprint, setCreateSprint] = useState(false);
  const [sprintViewMode, setSprintViewMode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sprintKanban = useSelector(({ sprint }) =>
    get(sprint, 'getSprintKanban.data', null)
  );

  const sectionId = get(sprintKanban, 'backlog_section.id', null);

  const closeEditModal = () => {
    setSprintViewMode(null);
    setCreateSprint(false);
  };

  const openEditModal = () => {
    setSprintViewMode('edit');
    setCreateSprint(true);
  };

  const sprintSubmitValues = async values => {
    setIsLoading(true);
    if (sprintViewMode === 'edit') {
      const { name, date_start, date_end } = values;
      const updatedValues = {
        name,
        date_start,
        date_end,
      };
      await dispatch(updateSprint(projectId, sprintID, updatedValues, true));
      closeEditModal();
    } else {
      await dispatch(createSprint(projectId, values));
      closeEditModal();
    }
    setIsLoading(false);
  };

  const deleteSprintAction = id => {
    if (sprintKanban?.stories_summary?.total === 0) {
      AlertPopupHandler.open({
        onConfirm: async () => {
          await dispatch(deleteSection(sectionId));
          history.push(`/admin/projects/${projectId}/backlog`);
        },
        text: 'You are about to delete this sprint. Do you want to continue?',
        data: id,
        confirmBtnText: 'Delete Sprint',
      });
    } else {
      AlertPopupHandler.open({
        onConfirm: () => {},
        showCancel: false,
        title: 'Sorry, you can’t do that',
        text:
          'Please remove all of the stories from the sprint before deleting.',
      });
    }
  };
  const loggedInUserID = useSelector(({ auth }) => get(auth, 'user.id', null));

  const projectMembers = useSelector(({ project }) =>
    get(project, `getProject.data.data.team_members`, [])
  );
  const isMemberOfProject =
    projectMembers.findIndex(m => m.id === loggedInUserID) > -1;

  const isSprintDeleteAllowed = useAccess([
    permissions.DELETE_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.DELETE_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintEditAllowed = useAccess([
    permissions.EDIT_ALL_SPRINTS,
    {
      permission: permissions.EDIT_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintViewAllowed = useAccess([
    permissions.VIEW_ALL_SPRINTS,
    {
      permission: permissions.VIEW_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  const isSprintAddAllowed = useAccess([
    permissions.ADD_SPRINTS_TO_ALL_PROJECTS,
    {
      permission: permissions.ADD_SPRINTS_TO_MY_PROJECTS,
      value: isMemberOfProject,
    },
  ]);

  const isAddStoryToSprintAllowed = useAccess([
    permissions.ADD_STORIES_TO_PROJECT_SPRINTS,
    {
      permission: permissions.ADD_STORIES_TO_MY_PROJECT_SPRINTS,
      value: isMemberOfProject,
    },
  ]);

  return (
    <div className={classes.sprintKanban}>
      {isCreateSprint ? (
        <CreateSprint
          isLoading={isLoading}
          isOpen={isCreateSprint}
          closeModal={closeEditModal}
          submitValues={sprintSubmitValues}
          viewMode={sprintViewMode}
          sprintValues={sprintKanban}
        />
      ) : null}
      <Kanban
        projectId={projectId}
        isAllowedEdit={true}
        openEditModal={openEditModal}
        deleteSprintAction={deleteSprintAction}
        isSprintKanban={true}
        isMockUp={false}
        id={sprintID}
        sectionID={sectionId}
        isSprintEditAllowed={isSprintEditAllowed}
        isSprintViewAllowed={isSprintViewAllowed}
        isSprintDeleteAllowed={isSprintDeleteAllowed}
        isSprintAddAllowed={isSprintAddAllowed}
        isAddStoryToSprintAllowed={isAddStoryToSprintAllowed}
      />
    </div>
  );
};

export default SprintKanban;
