import React from 'react';
import SelectAsync from 'components/FormFields/SelectAsync';
import get from 'lodash/get';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';

const SOW = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  defaultValue,
  isMobile,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const contract = getFieldValue('contract');
  const contractName = get(contract, 'name');
  const client = get(getFieldValue('client'), 'id');
  return (
    <>
      <h6 className="text-uppercase text-muted">SOW</h6>
      <SelectAsync
        id="project-sow-target"
        disabled={!(isEditAllowed && client)}
        onChange={selectedSOW => {
          analyticsSendEvent({
            action: analyticsConstants.action.edit_project_sow,
          });

          onChange('projectContract', selectedSOW);
        }}
        url={`/clients/${client}/themes`}
        getOptionLabel={option => option.name}
        placeholder="Assign a Contract"
        placement={isMobile ? 'bottom-end' : 'bottom-start'}
      >
        <p
          className={cx(
            !contractName && isEditAllowed ? 'text-light' : 'text-muted',
            isEditAllowed && client && classes.hoverHand,
            classes['ellipsis']
          )}
        >
          {contractName || (isEditAllowed ? '+ Add an SOW' : defaultValue)}
        </p>
      </SelectAsync>
    </>
  );
};

export default SOW;
