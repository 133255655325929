import React, { useState, useRef, useEffect } from 'react';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import Input from 'components/FormFields/Input';
import { useClickAway } from 'react-use';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import numeral from 'numeral';

const BudgetHours = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  defaultValue,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const budgetHours = numeral(getFieldValue('budget_hours') || 0).format(
    '0[.]00'
  );
  const displayValue = () => {
    if (isEmpty(budgetHours) && !isEditAllowed) return defaultValue;
    const hours = numeral(budgetHours || 0).format('0[.]00');
    return pluralize('hour', toNumber(hours), true);
  };
  const [showInput, setShowInput] = useState(false);
  const [newHours, setNewHours] = useState(budgetHours || '');
  useEffect(() => {
    setNewHours(budgetHours);
  }, [budgetHours]);
  const inputRef = useRef(null);
  const changeHours = () => {
    analyticsSendEvent({
      action: analyticsConstants.action.edit_project_budget_hours,
    });
    setShowInput(false);
    onChange('monthlyHours', newHours);
  };
  useClickAway(inputRef, () => {
    changeHours();
  });
  return (
    <>
      <h6 className="text-uppercase text-muted">Budget Hours</h6>
      {showInput ? (
        <Input
          value={newHours}
          focused={true}
          type="number"
          innerRef={inputRef}
          alternative={false}
          onChange={e => setNewHours(e.target.value)}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              changeHours();
            }
          }}
          autoFocus
          placeholder="Total Budget Hours"
        />
      ) : (
        <p
          className={cx(
            isEditAllowed && classes.hoverHand,
            !budgetHours && 'text-muted'
          )}
          onClick={() => {
            if (isEditAllowed) {
              setNewHours(budgetHours);
              setShowInput(true);
            }
          }}
        >
          {displayValue()}
        </p>
      )}
    </>
  );
};

export default BudgetHours;
