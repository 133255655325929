import {
  fetchClientProfile,
  fetchAgreements,
  download,
  create,
  remove,
  update,
  deleteFile,
  getAgreement,
  getAgreementTypes,
  getTimeSpentForClient,
  getRecentlyViewedProjects,
  getRecentTimeEntries as getRecentTimeEntriesApi,
} from 'api/clientProfile';
import { get, omit, pickBy } from 'lodash';
import Types from '../types/clientProfile';
import { fileDownload } from 'helpers/constants';
import { NotificationHandler } from 'components/Notifications';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';

export const getClientProfile = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_PROFILE_INPROGRESS,
      id,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchClientProfile(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        if (cb) {
          cb(resp);
        }
        dispatch({
          type: Types.FETCH_CLIENT_PROFILE_SUCCESS,
          data: resp.data || {},
          id,
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_PROFILE_FAILURE,
          message: message,
          id,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_PROFILE_FAILURE,
        message: error,
        id,
      });
    }
  };
};

export const fetchAgreementsList = (id, page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_AGREEMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchAgreements(id, page, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_AGREEMENTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_AGREEMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_AGREEMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const createAgreement = (data, filters, clientId) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await create(data, clientId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.CREATE_AGREEMENT_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchAgreementsList(clientId, page, sort, q));

        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_AGREEMENT_FAILURE,
          message: message,
        });
        if (errorStatus !== SHOW_UPGRADE_ALERT_CODE) {
          NotificationHandler.open({
            operation: 'failure',
            message,
          });
        }
      }
      // returning status and data as we need this to open the create project modal
      return { status, data: record, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.CREATE_AGREEMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updateAgreement = (id, data, filters, clientId) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await update(data, id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.UPDATE_AGREEMENT_SUCCESS,
          data: {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchAgreementsList(clientId, page, sort, q));

        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.UPDATE_AGREEMENT_FAILURE,
          message: message,
        });
        if (errorStatus !== SHOW_UPGRADE_ALERT_CODE) {
          NotificationHandler.open({
            operation: 'failure',
            message: message,
          });
        }
      }
      // returning status and data as we need this to open the create project modal
      return { status, data: record, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.UPDATE_AGREEMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const deleteAgreement = (id, filters, clientId) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await remove(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_AGREEMENT_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchAgreementsList(clientId, page, sort, q));
        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_AGREEMENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_AGREEMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const getAgreementData = id => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getAgreement(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_AGREEMENT_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.EDIT_AGREEMENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_AGREEMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchAgreementTypes = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_AGREEMENT_TYPES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getAgreementTypes();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_AGREEMENT_TYPES_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_AGREEMENT_TYPES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_AGREEMENT_TYPES_FAILURE,
        message: error,
      });
    }
  };
};

export const clearEditAgreement = () => {
  return async dispatch => {
    dispatch({
      type: Types.CLEAR_EDIT_AGREEMENT,
    });
  };
};

export const deleteAttachedFile = async id => {
  try {
    await deleteFile(id);
  } catch (error) {
    NotificationHandler.open({
      operation: 'failure',
      message: error,
    });
  }
};

export const downloadFile = doc => {
  return async dispatch => {
    dispatch({
      type: Types.DOWNLOAD_FILE_INPROGRESS,
    });
    try {
      const response = await download(doc.id);
      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, doc.name);
      dispatch({
        type: Types.DOWNLOAD_FILE_SUCCESS,
      });
      NotificationHandler.open({
        title: 'Success',
        operation: 'success',
        message: 'File Downloaded Successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_FILE_FAILURE,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const getTimeSpent = (
  clientId,
  group = 'week',
  startDate = '',
  endDate = ''
) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_INPROGRESS });
    try {
      const resp = await getTimeSpentForClient(clientId, {
        group,
        startDate,
        endDate,
      });
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_FAILURE,
        message: error,
      });
    }
  };
};

export const getViewedProjects = clientId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_INPROGRESS });
    try {
      const resp = await getRecentlyViewedProjects(clientId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_FAILURE,
        message: error,
      });
    }
  };
};

export const getRecentTimeEntries = (clientId, page = 1) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_INPROGRESS });
    try {
      const resp = await getRecentTimeEntriesApi(clientId, page);
      const status = get(resp, 'status');
      if (status) {
        const data = pickBy(resp, (value, key) => key !== 'status');
        dispatch({
          type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_FAILURE,
        message: error,
      });
    }
  };
};
