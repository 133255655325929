import React from 'react';
import { CardBody, Card } from 'reactstrap';
import { permissions, useAccess } from 'helpers/permission';
import CreateStory from './CreateStory';
import InviteTeamMember from './InviteTeamMember';
import CreateProject from './CreateProject';
import PropTypes from 'prop-types';
import CreateClient from './CreateClient';

const PersonalDashboardQuickActions = ({
  setStoryId,
  setProjectId,
  setIsStoryModalOpen,
}) => {
  const isInviteUserAllowed = useAccess(permissions.INVITE_USERS);
  const isCreateStoryAllowed = useAccess([
    permissions.ADD_STORIES_TO_ALL_BACKLOG_SECTIONS,
    permissions.ADD_STORIES_TO_PROJECT_BACKLOG_SECTIONS,
  ]);
  const isUserAllowedCreateProjects = useAccess(permissions.CREATE_PROJECTS);

  const isUserAllowedCreateClients = useAccess(permissions.CREATE_CLIENTS);
  return (
    (isUserAllowedCreateProjects ||
      isCreateStoryAllowed ||
      isInviteUserAllowed ||
      isUserAllowedCreateClients) && (
      <Card className="d-flex">
        <CardBody className="d-flex flex-column position-relative pt-3">
          <h5 className="text-uppercase text-muted mb-0 card-title">
            Quick Actions
          </h5>
          <div className="d-flex w-100 mt-2 justify-content-between flex-wrap">
            {isUserAllowedCreateProjects && <CreateProject />}
            {isCreateStoryAllowed && (
              <CreateStory
                isPersonalDashboard
                setStoryId={setStoryId}
                setProjectId={setProjectId}
                setIsStoryModalOpen={setIsStoryModalOpen}
              />
            )}
            {isInviteUserAllowed && <InviteTeamMember isPersonalDashboard />}
            {isUserAllowedCreateClients && <CreateClient />}
          </div>
        </CardBody>
      </Card>
    )
  );
};

PersonalDashboardQuickActions.propTypes = {};

export default PersonalDashboardQuickActions;
