import React, { useMemo, useState } from 'react';
import numeral from 'numeral';
import get from 'lodash/get';
import classNames from 'classnames';
import { Label } from 'reactstrap';
import map from 'lodash/map';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import { useDispatch, useSelector } from 'react-redux';
import BaseTable from 'components/Table';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import NoProjectsImg from 'assets/img/theme/No_Projects_List.png';
import { permissions, useGetAvailablePermission } from 'helpers/permission';
import { analyticsConstants } from 'helpers/analytics';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import history from 'helpers/history';
import {
  deleteProject,
  duplicateProjectById,
  fetchProjectsList as fetchProjects,
  getProject as getProjectAction,
} from 'store/actions/projects';
import { updateActiveTourStatus } from 'store/actions/profile';
import {
  budgetRenderer,
  budgetTypeRenderer,
  nameRenderer,
  ownerRenderer,
  statusRenderer,
} from './TableFieldsRenderer';
import DuplicateProjectForm from './DuplicateProject.Form';
import duplicateFields from './duplicateFields';
import classes from './projects.module.scss';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';

const ProjectsTable = ({
  page,
  sortBy,
  isUserAllowedCreateProjects,
  setPage,
  setSortBy,
  analyticsSendEvent,
  setShowProjectForm,
  setViewMode,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isDuplicating, setDuplicating] = useState(false);
  const [projectDataToDuplicate, setProjectDataToDuplicate] = useState({});

  const assignedProjects = get(auth, 'user.assigned_initiatives', []);

  const projects = useGetFieldFromObjects(
    'project',
    'projectState.data.data',
    []
  );
  const loading = useGetFieldFromObjects(
    'project',
    'projectState.isInProgress',
    false
  );
  const projectMetadata = useGetFieldFromObjects(
    'project',
    'projectState.data.meta',
    {}
  );

  const isUserMemberOfProjects = useMemo(
    () => map(projects, project => includes(assignedProjects, project.id)),
    [projects, assignedProjects]
  );

  const isUserAllowedViewProject = useGetAvailablePermission(
    permissions.VIEW_ALL_PROJECTS,
    permissions.VIEW_MY_PROJECTS,
    isUserMemberOfProjects
  );

  const isUserAllowedEditProject = useGetAvailablePermission(
    permissions.EDIT_ALL_PROJECTS,
    permissions.EDIT_MY_PROJECTS,
    isUserMemberOfProjects
  );

  const isUserAllowedDuplicateProject = useGetAvailablePermission(
    permissions.DUPLICATE_ALL_PROJECTS,
    permissions.DUPLICATE_MY_PROJECTS,
    isUserMemberOfProjects
  );

  const isUserAllowedDeleteProject = useGetAvailablePermission(
    permissions.DELETE_ALL_PROJECTS,
    permissions.DELETE_MY_PROJECTS,
    isUserMemberOfProjects
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const editProject = ({ id }) => {
    dispatch(getProjectAction(id));
    setViewMode('edit');
    setShowProjectForm(true);
  };

  const viewProject = ({ id }) => {
    history.push(`/admin/projects/${id}`);
  };

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    analyticsSendEvent({ action: analyticsConstants.action.delete_project });
    await dispatch(deleteProject(data.id, { page, sort, q: '' }));
  };

  const deleteProjectConfirm = row => () => {
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Project',
      text: `You are about to delete "${row.name}". Do you want to continue?`,
      data: row,
    });
  };

  const openAddProject = () => {
    setShowProjectForm(true);
  };

  const handleProjectClick = () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      (quickStartTour.activeTour === 'invite_team' ||
        quickStartTour.activeTour === 'story_creation') &&
      quickStartTour.step === 1 &&
      assignedProjects.length
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step: 5,
          })
        );
      }, 1000);
    } else if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'view_projects'
    ) {
      dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
        })
      );
    }
  };

  const closeDuplicateModal = () => {
    setDuplicateModal(false);
    setProjectDataToDuplicate({});
  };

  const duplicateProject = data => {
    setDuplicateModal(true);
    setProjectDataToDuplicate(data);
  };

  const handleDuplicateProject = async values => {
    setDuplicating(true);
    let data = {
      fields: [],
      relationships: [],
    };
    forEach(duplicateFields, module => {
      forEach(module.fields, obj => {
        if (values[obj.name]) {
          data[obj.objKey].push(obj.name);
        }
      });
    });
    const { data: duplicatedProject, status, errorStatus } = await dispatch(
      duplicateProjectById(projectDataToDuplicate.id, {
        name: values.name,
        ...data,
      })
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showProjectsLimit();
      setDuplicating(false);
      setDuplicateModal(false);
      setProjectDataToDuplicate({});
      return;
    }
    if (status) {
      history.push(`/admin/projects/${duplicatedProject.id}`);
    }
    setDuplicating(false);
    setDuplicateModal(false);
    setProjectDataToDuplicate({});
  };

  const NoDataIndication = () => (
    <div
      className={classNames('d-flex align-items-center', classes.noDataWrapper)}
    >
      <div className="d-flex justify-content-between align-items-center flex-column w-100">
        <img
          className={classNames('mx-auto w-100', classes.image)}
          src={NoProjectsImg}
          alt="No Projects"
        />
        {isUserAllowedCreateProjects ? (
          <>
            <h4 className="display-4 mb-0 text-center px-2">
              Go ahead and create your first project!
            </h4>
            <p className="text-center">
              To add a project, click on the{' '}
              <Label
                type="sm"
                className={classNames(
                  'text-info p-0 m-0',
                  classes['add-project-label']
                )}
                color="link"
                onClick={openAddProject}
              >
                Add Project
              </Label>{' '}
              button in the upper right corner.
            </p>
          </>
        ) : (
          <h4 className="display-4 mb-0 text-center px-2 pb-2">
            You don't have any projects assigned yet!
          </h4>
        )}
      </div>
    </div>
  );

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort" />;
    else if (order === 'asc') return <i className="fas fa-sort-up" />;
    else if (order === 'desc') return <i className="fas fa-sort-down" />;
    return null;
  };

  const handleTableChange = (
    type,
    { page, sortOrder, sortField, searchText }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
      analyticsSendEvent({
        action: analyticsConstants.action.search_projects_list,
      });
    }
    const correctSortFieldName =
      sortField === 'owner' ? 'owner_name' : sortField;
    const sort =
      sortOrder === 'desc' ? `-${correctSortFieldName}` : correctSortFieldName;
    dispatch(fetchProjects(page, sort, searchText));
  };

  return (
    <>
      <BaseTable
        id="projectsTable"
        keyField="id"
        defaultSorted={[sortBy]}
        noDataIndication={NoDataIndication}
        bootstrap4
        remote
        bordered={false}
        loading={loading}
        paginationOptions={{
          page,
          totalSize: projectMetadata.total,
          sizePerPage: numeral(get(projectMetadata, 'per_page', 50)).value(),
        }}
        data={projects}
        columns={[
          {
            dataField: 'name',
            text: 'Project',
            sort: true,
            classes: classes['project-name-column'],
            sortCaret: renderSortCaret,
            formatter: nameRenderer(
              isUserAllowedViewProject,
              handleProjectClick
            ),
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: statusRenderer,
          },
          {
            dataField: 'budget',
            text: 'Budget',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: budgetRenderer,
          },
          {
            dataField: 'budget_type',
            text: 'Budget Type',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: budgetTypeRenderer,
          },
          {
            dataField: 'owner',
            text: 'Owner',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: ownerRenderer({
              isUserAllowedViewProject,
              viewProject,
              isUserAllowedEditProject,
              editProject,
              isUserAllowedDuplicateProject,
              duplicateProject,
              isUserAllowedDeleteProject,
              deleteProjectConfirm,
            }),
          },
        ]}
        onTableChange={handleTableChange}
      />
      {isDuplicateModal ? (
        <DuplicateProjectForm
          projectName={projectDataToDuplicate.name}
          isModalOpen={isDuplicateModal}
          closeModal={closeDuplicateModal}
          submitValues={handleDuplicateProject}
          isLoading={isDuplicating}
        />
      ) : null}
    </>
  );
};

export default ProjectsTable;
