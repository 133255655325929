import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { fetchMoreProjectActivities } from 'store/actions/projects';
import Loading from 'components/Loading';
import Activity from 'components/Activity';
import classes from './Dashboard.module.scss';

const RecentActivity = ({ projectId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const activities = useSelector(({ project }) =>
    get(project, 'activities.data.data', [])
  );
  const activitiesMeta = useSelector(({ project }) =>
    get(project, 'activities.data.meta', {})
  );
  const isInProgress = useSelector(({ project }) =>
    get(project, 'activities.isInProgress', false)
  );

  const hasMore =
    activitiesMeta.current_page &&
    activitiesMeta.last_page &&
    activitiesMeta.current_page < activitiesMeta.last_page;

  const loadMoreActivities = async () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      await dispatch(
        fetchMoreProjectActivities(
          projectId,
          parseInt(activitiesMeta.current_page) + 1
        )
      );
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Recent Activity</h3>
      </CardHeader>
      <CardBody className={classes.recentActivities}>
        {isInProgress ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-start">
            <Loading key="loader" size={100} />
          </div>
        ) : (
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={loadMoreActivities}
            hasMore={hasMore}
            loader={<Loading key="loader" size={100} />}
            useWindow={false}
          >
            {orderBy(activities, ['created_at'], 'desc').map((d, i) => (
              <Activity key={i} item={d} isLargeAvatar />
            ))}
          </InfiniteScroll>
        )}
      </CardBody>
    </Card>
  );
};

export default RecentActivity;
