import React, { useState } from 'react';
import cs from 'classnames';
import classes from '../QuickActions.module.scss';
import ProjectForm from 'views/pages/Project/Project.Form';
import { createProjectQuickAction } from 'store/actions/quickActions';
import { useDispatch } from 'react-redux';
import history from 'helpers/history';
import get from 'lodash/get';

const CreateProject = () => {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={classes.actionBox}
        onClick={() => {
          setShowCreateProjectModal(true);
        }}
      >
        <div className={cs(classes.box, classes.createProject)}>
          <i className="fas fa-cubes text-success text-xl"></i>
          <h2>Create Project</h2>
        </div>
      </div>
      {showCreateProjectModal && (
        <ProjectForm
          isModalOpen={showCreateProjectModal}
          loading={isSubmitting}
          closeModal={() => {
            setShowCreateProjectModal(false);
          }}
          submitValues={async values => {
            setIsSubmitting(true);
            const response = await dispatch(createProjectQuickAction(values));
            setIsSubmitting(false);
            if (get(response, 'status')) {
              history.push(`/admin/projects/${get(response, 'data.id')}`);
              setShowCreateProjectModal(false);
            }
          }}
        />
      )}
    </>
  );
};

export default CreateProject;
