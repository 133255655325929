import React, { useState } from 'react';
import TimeLoggedGraph from 'views/widgets/TimeLoggedGraph';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { getTimeSpent } from 'store/actions/projectDashboard';
import classes from './Dashboard.module.scss';
const TimeLoggedGraphOnProject = ({ projectId }) => {
  const dispatch = useDispatch();
  const data = useSelector(({ projectDashboard }) =>
    get(projectDashboard, 'timeLogGraph.data', {})
  );
  const [period, setPeriod] = useState('week');
  const loading = useSelector(({ projectDashboard }) =>
    get(projectDashboard, 'timeLogGraph.isInProgress', false)
  );
  return (
    <TimeLoggedGraph
      data={data}
      timePeriod="week"
      loading={loading}
      onChange={async (timePeriod, startTime, endTime) => {
        await dispatch(getTimeSpent(projectId, timePeriod, startTime, endTime));
        if (period !== timePeriod) setPeriod(timePeriod);
      }}
      fieldName={period === 'week' ? 'assignment_type' : 'project'}
      wrapperClassName={classes.timeSpentChart}
      title="Time Logged on Project"
    />
  );
};

export default TimeLoggedGraphOnProject;
