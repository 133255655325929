import Types from '../types/clientProfile';
import get from 'lodash/get';

const initialState = {
  clientProfile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  agreementState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  downloadFile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  createAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  recentTimeEntries: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: null,
  },
  deleteAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  agreementTypes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  timeLogGraph: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  viewedProjects: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

export default function ClientProfile(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CLIENT_PROFILE_INPROGRESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          isInProgress: true,
          isError: false,
          message: initialState.clientProfile.message,
          id: action.id,
        },
      };
    case Types.FETCH_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          message: initialState.clientProfile.message,
          id: action.id,
        },
      };
    case Types.FETCH_CLIENT_PROFILE_FAILURE:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          isInProgress: false,
          isError: true,
          message: action.message,
          id: action.id,
        },
      };
    case Types.FETCH_AGREEMENTS_INPROGRESS:
      return {
        ...state,
        agreementState: {
          ...state.agreementState,
          isInProgress: true,
        },
      };
    case Types.FETCH_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreementState: {
          ...state.agreementState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_AGREEMENTS_FAILURE:
      return {
        ...state,
        agreementState: {
          ...state.agreementState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_AGREEMENT_INPROGRESS:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          isInProgress: true,
        },
      };
    case Types.CREATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_AGREEMENT_FAILURE:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.EDIT_AGREEMENT_INPROGRESS:
      return {
        ...state,
        editAgreement: {
          ...state.editAgreement,
          isInProgress: true,
        },
      };
    case Types.EDIT_AGREEMENT_SUCCESS:
      return {
        ...state,
        editAgreement: {
          ...state.editAgreement,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.EDIT_AGREEMENT_FAILURE:
      return {
        ...state,
        editAgreement: {
          ...state.editAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CLEAR_EDIT_AGREEMENT:
      return {
        ...state,
        editAgreement: {
          isInProgress: false,
          isError: false,
          status: 0,
          message: '',
          data: null,
        },
      };
    case Types.UPDATE_AGREEMENT_INPROGRESS:
      return {
        ...state,
        updateAgreement: {
          ...state.updateAgreement,
          isInProgress: true,
        },
      };
    case Types.UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        updateAgreement: {
          ...state.updateAgreement,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_AGREEMENT_FAILURE:
      return {
        ...state,
        updateAgreement: {
          ...state.updateAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CLEAR_AGREEMENT_DATA:
      return {
        ...state,
        editAgreement: {
          isInprogress: false,
          isError: false,
          status: 0,
          message: '',
          data: null,
        },
      };
    case Types.DOWNLOAD_FILE_INPROGRESS:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          isInProgress: true,
        },
      };
    case Types.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_AGREEMENT_INPROGRESS:
      return {
        ...state,
        deleteAgreement: {
          ...state.deleteAgreement,
          isInProgress: true,
        },
      };
    case Types.DELETE_AGREEMENT_SUCCESS:
      return {
        ...state,
        deleteAgreement: {
          ...state.deleteAgreement,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_AGREEMENT_FAILURE:
      return {
        ...state,
        deleteAgreement: {
          ...state.deleteAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_AGREEMENT_TYPES_INPROGRESS:
      return {
        ...state,
        agreementTypes: {
          ...state.agreementTypes,
          isInProgress: true,
        },
      };
    case Types.FETCH_AGREEMENT_TYPES_SUCCESS:
      return {
        ...state,
        agreementTypes: {
          ...state.agreementTypes,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_AGREEMENT_TYPES_FAILURE:
      return {
        ...state,
        agreementTypes: {
          ...state.agreementTypes,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_TIME_LOG_GRAPH_INPROGRESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_TIME_LOG_GRAPH_SUCCESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_TIME_LOG_GRAPH_FAILURE:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_INPROGRESS:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_SUCCESS:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_FAILURE:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_INPROGRESS:
      return {
        ...state,
        recentTimeEntries: {
          ...state.recentTimeEntries,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_SUCCESS:
      return {
        ...state,
        recentTimeEntries: {
          ...state.recentTimeEntries,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'recentTimeEntries.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
          isError: initialState.recentTimeEntries.isError,
          message: initialState.recentTimeEntries.message,
        },
      };
    case Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_FAILURE:
      return {
        ...state,
        recentTimeEntries: {
          ...state.recentTimeEntries,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
