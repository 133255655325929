import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import classes from 'views/pages/QuickActions/QuickActions.module.scss';

const InfoToggle = () => {
  const [isActive, setActive] = useState(true);
  const stopAnimation = () => {
    setTimeout(() => setActive(false), 4500);
  };

  useEffect(() => {
    stopAnimation();
  }, []);

  return (
    <div
      className={cs(classes.infoDiv, {
        [classes.expand]: isActive,
      })}
      onClick={e => {
        e.stopPropagation();
        setActive(!isActive);
      }}
    >
      <i className={cs('fas fa-info-circle', classes.infoIcon)} />
      <span className={cs(classes.word, classes.word1)}>Tasks</span>
      <span className={cs(classes.word, classes.word2)}>Jobs</span>
      <span className={cs(classes.word, classes.word3)}>To-Do</span>
    </div>
  );
};

export default InfoToggle;
