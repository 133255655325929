import Request from './request';

export const getSections = async (projectId, q = '') => {
  return Request.call({
    url: `/initiatives/${projectId}/backlogSections`,
    method: 'GET',
    params: {
      q,
    },
  });
};

export const addBacklogSection = async (projectId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/backlogSections`,
    method: 'POST',
    data,
  });
};

export const getItemsForSection = async (sectionId, q = '') => {
  return Request.call({
    url: `/backlogSections/${sectionId}/items`,
    method: 'GET',
    params: {
      q,
    },
  });
};

export const removeStory = async id => {
  return Request.call({
    url: `/stories/${id}`,
    method: 'DELETE',
  });
};

export const removeSection = async id => {
  return Request.call({
    url: `/backlogSections/${id}`,
    method: 'DELETE',
  });
};

export const createStory = async (id, data) => {
  return Request.call({
    url: `/backlogSections/${id}/items`,
    method: 'POST',
    data,
  });
};

export const updateSection = async (id, data) => {
  return Request.call({
    url: `/backlogSections/${id}`,
    method: 'PATCH',
    data,
  });
};

export const massUpdateStories = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}/stories/mass-update`,
    method: 'PATCH',
    data,
  });
};

export const massDeleteStories = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}/stories/mass-delete`,
    method: 'DELETE',
    data,
  });
};

export const reorderStories = async (id, data) => {
  return Request.call({
    url: `/backlogSections/${id}/items/prioritize`,
    method: 'POST',
    data,
  });
};

export const reorderSections = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}/backlogSections/prioritize`,
    method: 'POST',
    data,
  });
};

export const updateItemSection = async (id, data) => {
  return Request.call({
    url: `/stories/${id}/update-section`,
    method: 'PATCH',
    data,
  });
};

export const getStatusOptions = async () => {
  return Request.call({
    url: `/meta/dropdowns?list=story_statuses`,
    method: 'GET',
  });
};

export const getPriorityOptions = async () => {
  return Request.call({
    url: `/meta/dropdowns?list=priorities`,
    method: 'GET',
  });
};

export const getComplexityOptions = async () => {
  return Request.call({
    url: `/meta/dropdowns?list=complexities`,
    method: 'GET',
  });
};

export const getScoreMatrixOptions = async () => {
  return Request.call({
    url: `/meta/dropdowns?list=backlog_score_matrix`,
    method: 'GET',
  });
};

export const duplicateStory = async (id, data) => {
  return Request.call({
    url: `/stories/duplicate/${id}`,
    method: 'POST',
    data,
  });
};
