import React from 'react';
import CardsHeader from 'components/Headers/CardsHeader.js';
import PropTypes from 'prop-types';

const ProfileHeader = props => {
  return (
    <>
      <div className="header pb-6 d-flex align-items-center">
        <CardsHeader
          currentPage="User Profile"
          parentName="People"
          parentLink={`/admin/users`}
          name={props.name}
        />
      </div>
    </>
  );
};

ProfileHeader.prototype = {
  name: PropTypes.string,
};

export default ProfileHeader;
