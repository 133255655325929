import Types from '../types/billing';

const initialState = {
  plans: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

export default function Billing(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_BILLING_PLANS_REQUEST:
      return {
        ...state,
        plans: {
          ...state.plans,
          isInProgress: true,
        },
      };
    case Types.FETCH_BILLING_PLANS_SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_BILLING_PLANS_FAILURE:
      return {
        ...state,
        plans: {
          ...state.plans,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
