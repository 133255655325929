import React, { useState, useRef, useEffect } from 'react';
import numeral from 'numeral';
import Input from 'components/FormFields/Input';
import { useClickAway } from 'react-use';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';

const Budget = ({
  getFieldValue,
  onChange,
  isEditAllowed,
  defaultValue,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const budget = getFieldValue('total_budget');
  const [showInput, setShowInput] = useState(false);
  const [newBudget, setNewBudget] = useState(budget);
  useEffect(() => {
    setNewBudget(budget);
  }, [budget]);
  const inputRef = useRef(null);
  const changeBudget = () => {
    setShowInput(false);
    analyticsSendEvent({
      action: analyticsConstants.action.edit_project_budget_$,
    });

    onChange('monthlyBudget', newBudget);
  };
  useClickAway(inputRef, () => {
    changeBudget();
  });
  return (
    <>
      <h6 className="text-uppercase text-muted">Budget($)</h6>
      {showInput ? (
        <Input
          value={newBudget || ''}
          leftIcon="$"
          type="number"
          innerRef={inputRef}
          alternative={false}
          focused
          onChange={e => {
            setNewBudget(e.target.value);
          }}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              changeBudget();
            }
          }}
          autoFocus
          placeholder="Budget Amount"
        />
      ) : (
        <p
          className={cx(
            isEditAllowed && classes.hoverHand,
            !budget && 'text-muted'
          )}
          onClick={() => {
            if (isEditAllowed) {
              setNewBudget(budget);
              setShowInput(true);
            }
          }}
        >
          {budget
            ? '$' + numeral(budget).format('0,0[.].00')
            : isEditAllowed
            ? '$' + 0
            : defaultValue}
        </p>
      )}
    </>
  );
};

export default Budget;
