import React from 'react';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import cx from 'classnames';
import Dropdown from 'components/Dropdowns';

const Billable = ({
  getFieldValue,
  onChange,
  isEditAllowed,
  isMobile,
  analyticsSendEvent,
  analyticsConstants,
}) => {
  const billable = getFieldValue('billable', true);
  const changeBillingState = isBillable => {
    analyticsSendEvent({
      action: analyticsConstants.action.edit_project_isbillable,
    });
    onChange('billable', isBillable);
  };
  const options = [
    { text: <p>Yes</p>, onClick: () => changeBillingState(true) },
    { text: <p>No</p>, onClick: () => changeBillingState(false) },
  ];
  return (
    <>
      <h6 className="text-uppercase text-muted">Is Billable</h6>
      <Dropdown
        caret={false}
        options={options}
        text={billable ? 'Yes' : 'No'}
        tag="p"
        right={isMobile}
        disabled={!isEditAllowed}
        dropdownClasses={cx(isMobile && 'w-100')}
        className={cx(isEditAllowed && classes.hoverHand)}
      />
    </>
  );
};

export default Billable;
